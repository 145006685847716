import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const removeUserFromOrg = (uId, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'REMOVING_USER_FROM_ORG' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const reqId = uuidv4();
        try {
          const response = await axios.delete(`/us/org/member/${uId}`, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              // 'current-role': 'candidate',
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'REMOVING_USER_FROM_ORG_DONE',
            });
            if (typeof callback === 'function') {
              callback(uId);
            }
          } else {
            dispatch({
              type: 'REMOVING_USER_FROM_ORG_FAILED',
              payload: `Failed to remove user from org`,
            });
            if (typeof callback === 'function') {
              callback();
            }
          }
        } catch (e) {
          dispatch({
            type: 'REMOVING_USER_FROM_ORG_FAILED',
            payload: `Failed to remove user from org`,
          });
          if (typeof callback === 'function') {
            callback();
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'REMOVING_USER_FROM_ORG_FAILED',
        payload: `Failed to remove user from org`,
      });
      if (typeof callback === 'function') {
        callback();
      }
    }
  };
};

export default removeUserFromOrg;
