import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const updateGlobalOrganizationConfig = (payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'UPDATING_ORGANIZATION_GLOBAL_CONFIG' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.put(`/us/organization`, payload, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'UPDATING_ORGANIZATION_GLOBAL_CONFIG_DONE',
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'UPDATING_ORGANIZATION_GLOBAL_CONFIG_FAILED',
              payload: `Failed to update global config for organization`,
            });
            if (typeof callback === 'function') {
              callback(null, response?.response?.data);
            }
          }
        } catch (e) {
          dispatch({
            type: 'UPDATING_ORGANIZATION_GLOBAL_CONFIG_FAILED',
            payload: `Failed to update global config for organization`,
          });
          if (typeof callback === 'function') {
            callback(null, e?.response?.data);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'UPDATING_ORGANIZATION_GLOBAL_CONFIG_FAILED',
        payload: `Failed to update global config for organization`,
      });
      if (typeof callback === 'function') {
        callback(null, e?.response?.data);
      }
    }
  };
};

export default updateGlobalOrganizationConfig;
