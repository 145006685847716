import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import Container from 'components/Container';
import {
  Topbar,
  Sidebar,
  Footer,
  BottomNavbar,
  // ThemeModeToggler
} from './components';

import pages from '../navigation';
import AlertPopup from 'components/AlertPopup';
import { useDispatch, useSelector } from 'react-redux';
import hideAlert from 'redux/actions/Common/hiderAlert';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import getCandidateDetails from 'redux/actions/Candidate/getCandidateDetails';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import { messaging } from '../../firebase';
import { getToken, onMessage } from 'firebase/messaging';
import { Close, Lock } from '@mui/icons-material';
import updateNotificationPreferences from 'redux/actions/Common/updateNotificationPreferences.js';
import getSalesCallReviews from 'redux/actions/Common/getSalesCallReviews';
import { AccountSettings } from 'views';
import ProfileCompletion from 'components/ProfileCompletion';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import getRemainingCredits from 'redux/actions/Common/getRemainingCredits';
import { useHistory } from 'react-router-dom';
import getTeams from 'redux/actions/Common/getTeams';
import updateUserDetails from 'redux/actions/Common/updateUserDetails';
import { initializePendo } from 'utils';

const Dashboard = ({
  children,
  colorInvert = false,
  bgcolor = 'transparent',
  noTopbar = false,
  noFooter = true,
  leftSidebar = true,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: false,
  });
  const uiStates = useSelector((state) => state.uiStates);
  const {
    dashboardSidebarExpanded,
    showOnboardingDialog,
    onBoardingStep,
    onBoardingCompletionPercentage,
    navExpandedStatus,
  } = uiStates;
  const teamId = localStorage.getItem('teamId');
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const candidateDetails = useSelector((state) => state.candidateDetails);
  const { details } = candidateDetails;
  const { userDetails } = useSelector((state) => state.userDetails);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [showBottomNav, setShowBottomNav] = useState(false);
  const alertState = useSelector((state) => state.alertState);
  const [completedStep, setCompletedStep] = useState({});
  const [methodologiesAvailable, setMethodologiesAvailable] = useState([]);
  const [selectedPlaybook, setSelectedPlaybook] = useState(null);
  const [loading, setLoading] = useState(false);

  const [
    notificationPermissionStatus,
    setNotificationPermissionStatus,
  ] = useState('ask');

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  const checkNotificationPromise = async () => {
    try {
      if (!('Notification' in window)) {
        throw 'This browser does not support desktop notification';
      }
      const permission = await Notification.requestPermission();
      setNotificationPermissionStatus(permission);
      if (permission === 'granted') {
        const notToken = await getToken(messaging, {
          vapidKey:
            process.env.REACT_APP_TARGET_ENV === 'local' ||
            process.env.REACT_APP_TARGET_ENV === 'development'
              ? 'BJniguQbz234pBc8uVFNUDKA9PiABl85gqftUdZ5IOMNnOvYJplR1OgCbS_KJXBEV5sTiE4LvIqUbNCw9A6itU8'
              : 'BOG23-8imwPCCqu7H4GBkAoMVBe1KZ8dqBRIsOSLKnnB3FcT7OC6wB37AHvg3foTlVlb0GRXS3O9n_Vwq-8q24Q',
        });
        localStorage.setItem('notificationToken', notToken);
        dispatch(
          updateNotificationPreferences(
            {
              pushnotification: true,
              pushnotificationToken: notToken,
            },
            () => {},
          ),
        );
        onMessage(messaging, (payload) => {
          console.log('coming here');
          console.log(payload);
          if (
            payload?.notification?.title === 'Sales Call Analysis Completed!'
          ) {
            dispatch(
              getSalesCallReviews(
                {
                  keywords: '',
                  lastSeen: null,
                  lastTime: null,
                  size: 10,
                  teamId: teamId,
                },
                (data) => {
                  // console.log(data);
                },
              ),
            );
          }
          //   {
          //     "from": "1090205382087",
          //     "messageId": "2bf13188-cfbf-4ede-89e3-025682ed0078",
          //     "notification": {
          //         "title": "Sales Call Analysis Completed!",
          //         "body": "Your sales call media file 'prod_test_1' has been successfully analyzed. Check out the detailed report now"
          //     }
          // }
        });
      } else {
        console.log('permission not granted');
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isXs) {
      setShowBottomNav(true);
    } else {
      setShowBottomNav(false);
    }
  }, [isXs]);

  useEffect(() => {
    if (isMd) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'dashboardSidebarExpanded',
          value: true,
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'dashboardSidebarExpanded',
          value: false,
        },
      });
    }
  }, [isMd]);

  useEffect(() => {
    // console.log('candidate details', details);
    let percent = 0;
    percent =
      (details?.firstName ? 5 : 0) +
      (details?.lastName ? 5 : 0) +
      (details?.customFields?.resumeId ? 15 : 0) +
      (details?.phoneNumber ? 10 : 0) +
      (details?.linkedIn ? 15 : 0) +
      (details?.experience ? 10 : 0) +
      (details?.country ? 10 : 0) +
      (details?.jobTypes &&
      Array.isArray(details?.jobTypes) &&
      details?.jobTypes?.length > 0
        ? 5
        : 0) +
      (details?.preferences &&
      Array.isArray(details?.preferences) &&
      details?.preferences?.length > 0
        ? 5
        : 0) +
      (details?.city ? 5 : 0) +
      (details?.state ? 5 : 0) +
      (details?.zipCode ? 10 : 0);
    dispatch({
      type: 'SET_PROFILE_COMPLETION_PERCENT',
      payload: percent,
    });
  }, [details]);

  useEffect(() => {
    if (showOnboardingDialog) {
      dispatch(getOrganizationConfigs((data) => {}));
    }
  }, [showOnboardingDialog]);

  useEffect(() => {
    if (showOnboardingDialog && onBoardingStep === 1) {
      dispatch(
        getPlaybookHistory(teamId || null, (data) => {
          setSelectedPlaybook(data?.selectedPlaybook);
        }),
      );
    }
  }, [showOnboardingDialog, onBoardingStep]);

  useEffect(() => {
    if (
      orgConfigs &&
      ((orgConfigs?.methodologies && orgConfigs?.methodologies?.length > 0) ||
        (orgConfigs?.customMethodologies &&
          orgConfigs?.customMethodologies?.length > 0))
    ) {
      setMethodologiesAvailable(true);
    } else {
      setMethodologiesAvailable(false);
    }
  }, [orgConfigs]);

  // useEffect(() => {
  // if (showOnboardingDialog) {
  //   if (userDetails?.custom?.orgType === 'demo_org') {
  //     dispatch({
  //       type: 'UPDATE_UI_STATE',
  //       payload: {
  //         key: 'onBoardingStep',
  //         value: 1,
  //       },
  //     });
  //   } else {
  //     console.log('coming here');
  //     dispatch({
  //       type: 'UPDATE_UI_STATE',
  //       payload: {
  //         key: 'onBoardingStep',
  //         value: 0,
  //       },
  //     });
  //   }
  // }
  // }, [showOnboardingDialog, userDetails]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
      payload: {
        key: 1,
        value: Math.floor(
          (orgConfigs?.botVerticals &&
          Array.isArray(orgConfigs?.botVerticals) &&
          orgConfigs?.botVerticals.length > 0
            ? 12.5
            : 0) +
            (orgConfigs?.botDesignations &&
            Array.isArray(orgConfigs?.botDesignations) &&
            orgConfigs?.botDesignations.length > 0
              ? 12.5
              : 0),
        ),
      },
    });
    if (
      orgConfigs?.botVerticals &&
      Array.isArray(orgConfigs?.botVerticals) &&
      orgConfigs?.botVerticals.length > 0 &&
      orgConfigs?.botDesignations &&
      Array.isArray(orgConfigs?.botDesignations) &&
      orgConfigs?.botDesignations.length > 0
    ) {
      setCompletedStep((prev) => {
        return {
          ...prev,
          [1]: true,
        };
      });
    } else {
      setCompletedStep((prev) => {
        return {
          ...prev,
          [1]: false,
        };
      });
    }
    if (userDetails?.custom?.orgType !== 'demo_org') {
      if (methodologiesAvailable) {
        if (orgConfigs?.isGeneralAutoAnalysis !== null) {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [3]: true,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 3,
              value: 25,
            },
          });
        } else {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [3]: false,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 3,
              value: 0,
            },
          });
        }
      } else {
        if (orgConfigs?.isGeneralAutoAnalysis !== null) {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [2]: true,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 2,
              value: 25,
            },
          });
        } else {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [2]: false,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 2,
              value: 0,
            },
          });
        }
      }
    } else {
      setCompletedStep((prev) => {
        return {
          ...prev,
          [3]: true,
        };
      });
      dispatch({
        type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
        payload: {
          key: 3,
          value: 0,
        },
      });
    }
    if (methodologiesAvailable) {
      if (
        orgConfigs?.selectedMethodologies &&
        Array.isArray(orgConfigs?.selectedMethodologies) &&
        orgConfigs?.selectedMethodologies?.length > 0
      ) {
        setCompletedStep((prev) => {
          return {
            ...prev,
            [2]: true,
          };
        });
        dispatch({
          type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
          payload: {
            key: 2,
            value: 25,
          },
        });
      } else {
        setCompletedStep((prev) => {
          return {
            ...prev,
            [2]: false,
          };
        });
        dispatch({
          type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
          payload: {
            key: 2,
            value: 0,
          },
        });
      }
    }
  }, [orgConfigs]);

  useEffect(() => {
    if (onBoardingStep === 1 || onBoardingStep === 2) {
      dispatch(
        getPlaybookHistory(teamId || null, (data) => {
          if (data?.selectedPlaybook) {
            setCompletedStep((prev) => {
              return {
                ...prev,
                [0]: true,
              };
            });
            dispatch({
              type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
              payload: {
                key: 0,
                value: userDetails?.custom?.orgType === 'demo_org' ? 50 : 25,
              },
            });
          } else {
            setCompletedStep((prev) => {
              return {
                ...prev,
                [0]: false,
              };
            });
            dispatch({
              type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
              payload: {
                key: 0,
                value: 0,
              },
            });
          }
        }),
      );
    }
  }, [onBoardingStep]);

  useEffect(() => {
    if (
      (userDetails?.orgRoles?.includes('ORG_ADMIN') ||
        userDetails?.orgRoles?.includes('WL_ORG_ADMIN')) &&
      orgConfigs
    ) {
      dispatch(
        getTeams(10, null, (teams) => {
          // console.log(teams);
          // setLoading(false);
          dispatch({
            type: 'UPDATE_UI_STATE',
            payload: {
              key: 'pinnedTeams',
              value: teams || [],
            },
          });
          // dispatch({
          //   type: 'UPDATE_UI_STATE',
          //   payload: {
          //     key: 'navExpandedStatus',
          //     value: {
          //       ...navExpandedStatus,
          //       [teams?.find((team) => team?.defaultTeam)?.id]:
          //         userDetails?.userSetting?.navbarTeamCollapsState?.[
          //           teams?.find((team) => team?.defaultTeam)?.id
          //         ] !== undefined
          //           ? userDetails?.userSetting?.navbarTeamCollapsState?.[
          //               teams?.find((team) => team?.defaultTeam)?.id
          //             ]
          //           : true,
          //     },
          //   },
          // });
        }),
      );
    }
  }, [userDetails, orgConfigs]);

  useEffect(() => {
    if (
      userDetails &&
      (process.env.REACT_APP_TARGET_ENV === 'local' ||
        process.env.REACT_APP_TARGET_ENV === 'development')
    ) {
      initializePendo(userDetails);
    }
  }, [userDetails]);

  // useEffect(() => {
  //   dispatch(
  //     updateUserDetails(
  //       {
  //         userSetting: {
  //           navbarTeamCollapsState: {
  //             ...navExpandedStatus,
  //           },
  //         },
  //       },
  //       () => {},
  //     ),
  //   );
  // }, [navExpandedStatus]);

  useEffect(() => {
    dispatch(getOrganizationConfigs((data) => {}));
    dispatch(
      getPlaybookHistory(teamId || null, (data) => {
        if (data?.selectedPlaybook) {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [0]: true,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 0,
              value: userDetails?.custom?.orgType === 'demo_org' ? 50 : 25,
            },
          });
        } else {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [0]: false,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 0,
              value: 0,
            },
          });
        }
      }),
    );
    checkNotificationPromise();
    dispatch(
      getUserDetails((data) => {
        dispatch(
          updateUserDetails(
            {
              userSetting: {
                navbarTeamCollapsState: {
                  ...navExpandedStatus,
                  [data?.organizationId]:
                    data?.userSetting?.navbarTeamCollapsState?.[
                      data?.organizationId
                    ] !== undefined
                      ? data?.userSetting?.navbarTeamCollapsState?.[
                          data?.organizationId
                        ]
                      : true,
                },
              },
            },
            () => {
              dispatch({
                type: 'UPDATE_UI_STATE',
                payload: {
                  key: 'navExpandedStatus',
                  value: {
                    ...(data?.userSetting?.navbarTeamCollapsState || {}),
                    [data?.organizationId]:
                      data?.userSetting?.navbarTeamCollapsState?.[
                        data?.organizationId
                      ] !== undefined
                        ? data?.userSetting?.navbarTeamCollapsState?.[
                            data?.organizationId
                          ]
                        : true,
                  },
                },
              });
            },
          ),
        );
        if (data?.custom?.plan?.price?.pricingScheme === 'usage_based') {
          dispatch(
            getRemainingCredits((data) => {
              console.log(data);
              dispatch({
                type: 'UPDATE_UI_STATE',
                payload: {
                  key: 'creditsRemaining',
                  value: data,
                },
              });
            }),
          );
        }
        if (
          (data?.teamRoles?.includes('TEAM_ADMIN') ||
            data?.teamRoles?.includes('STANDARD')) &&
          !data?.orgRole?.includes('ORG_ADMIN')
        ) {
          setLoading(true);
          dispatch(
            getTeams(10, null, (teams) => {
              // console.log(teams);
              setLoading(false);
              dispatch({
                type: 'UPDATE_UI_STATE',
                payload: {
                  key: 'loggedInUserTeamDetails',
                  value:
                    teams?.length > 1
                      ? teams?.filter(
                          (team) => team?.id !== data?.organizationId,
                        )?.[0]
                      : teams?.[0],
                },
              });
            }),
          );
        } else {
          dispatch({
            type: 'UPDATE_UI_STATE',
            payload: {
              key: 'loggedInUserTeamDetails',
              value: null,
            },
          });
        }
      }),
    );
  }, []);

  // console.log(showOnboardingDialog);
  // console.log(onBoardingStep);
  // console.log(userDetails);
  console.log(uiStates);

  return (
    <ErrorBoundary>
      <Box>
        {/* <Box>
            <ThemeModeToggler />
          </Box> */}
        {!noTopbar && !showBottomNav ? (
          <AppBar
            position={'sticky'}
            sx={{
              top: 0,
              backgroundColor: trigger
                ? theme.palette.background.paper
                : bgcolor,
              background: '#fff',
              borderBottom: `1px solid ${theme.palette.background.level1}`,
            }}
            elevation={trigger ? 1 : 0}
          >
            <Container
              paddingY={0}
              paddingX={isMd ? 4 : 1}
              maxWidth={{ sm: 1, md: 1 }}
            >
              <Topbar
                onSidebarOpen={handleSidebarOpen}
                pages={pages}
                colorInvert={trigger ? false : colorInvert}
              />
            </Container>
          </AppBar>
        ) : null}
        {leftSidebar && !showBottomNav ? (
          <Sidebar
            onClose={() => {}}
            open={true}
            variant="permanent"
            pages={pages}
            expanded={dashboardSidebarExpanded}
          />
        ) : null}
        {showBottomNav ? (
          <BottomNavbar
            onClose={() => {}}
            open={true}
            variant="permanent"
            pages={pages}
            expanded={dashboardSidebarExpanded}
          />
        ) : null}
        <main>
          <AlertPopup
            // classes={{ root: classes.browserInfoSnackbar }}
            open={alertState.open}
            autoHideDuration={6000}
            handleClose={() => dispatch(hideAlert())}
            message={alertState.message}
            type={alertState.type}
            verticalLocation={alertState.vertical}
            horizontalLocation={alertState.horizontal}
          ></AlertPopup>
          {loading ? null : (
            <Box paddingTop={showBottomNav ? 6 : 0}>{children}</Box>
          )}
          {!noFooter ? <Divider /> : null}
        </main>
        {!noFooter ? (
          <Container paddingY={1} maxWidth={{ sm: 1, md: 1236 }}>
            <Footer />
          </Container>
        ) : null}
        <Dialog
          open={Boolean(
            notificationPermissionStatus === 'ask' ||
              notificationPermissionStatus === 'denied',
          )}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            zIndex: 10002,
            '& .MuiPaper-root-MuiDialog-paper': {
              maxWidth: '70vw',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {notificationPermissionStatus === 'denied'
              ? 'Notification permissions are blocked'
              : 'Notification permissions are required'}
          </DialogTitle>
          <DialogContent>
            <Box display={'flex'} alignItems={'center'} gap={2}>
              {/* <Box flex={1}>
                <img src="/microphonehelp.png" width={'100%'} height={'100%'} />
              </Box> */}
              <DialogContentText id="alert-dialog-description" flex={1}>
                We need browser notifications enabled to show you updated
                information. You may click the Lock <Lock /> Icon (usually in
                your browser&apos;s address bar) and allow browser
                notifications. You will still be able to customize and turn off
                notifications.
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setNotificationPermissionStatus('dontshowagain');
                // if (testMode) setShowTestDeviceDialog(false);
              }}
              color="primary"
              autoFocus
            >
              Dismiss
            </Button>
          </DialogActions>
        </Dialog>
        {process.env.REACT_APP_TARGET_ENV === 'local' ||
        process.env.REACT_APP_TARGET_ENV === 'development' ||
        userInfo?.custom?.orgType === 'demo_org' ? (
          <Modal
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            open={Boolean(showOnboardingDialog)}
            onClose={(e, reason) => {
              if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                return;
              }
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                width: '60vw',
                borderRadius: 1,
                position: 'relative',
              }}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                p={2}
                borderBottom={'1px solid #d3d3d3'}
              >
                <Typography variant="body1" textAlign={'center'}>
                  {userDetails?.custom?.orgType === 'demo_org'
                    ? 'Welcome'
                    : 'Qualification AI Onboarding'}
                </Typography>
              </Box>
              <Box
                // p={2}
                sx={{
                  maxHeight: '75vh',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                }}
              >
                {onBoardingStep === 0 ? (
                  <Box p={2}>
                    <Typography variant="body1" textAlign={'center'}>
                      Welcome to Qualification AI user onboarding. Please
                      complete few steps to get started. <br /> Follow these
                      steps and complete all the steps to make your application
                      experience smooth.
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingTop={2}
                  >
                    <Stepper
                      nonLinear
                      activeStep={onBoardingStep - 1}
                      alternativeLabel
                    >
                      {[
                        'Generate Playbook',
                        "Prospect's Target & Vertical",
                        'Sales Methodologies',
                        'Auto Analysis',
                      ]
                        ?.filter(
                          (step) =>
                            (orgConfigs?.methodologies &&
                              orgConfigs?.methodologies?.length > 0) ||
                            (orgConfigs?.customMethodologies &&
                              orgConfigs?.customMethodologies?.length > 0 &&
                              step === 'Sales Methodologies') ||
                            step !== 'Sales Methodologies',
                        )
                        ?.filter(
                          (step) =>
                            (userDetails?.custom?.orgType === 'demo_org' &&
                              step !== 'Auto Analysis') ||
                            userDetails?.custom?.orgType !== 'demo_org',
                        )
                        .map((label, index) => (
                          <Step key={label} completed={completedStep[index]}>
                            <StepButton
                              color="inherit"
                              // onClick={() => {
                              //   dispatch({
                              //     type: 'UPDATE_UI_STATE',
                              //     payload: {
                              //       key: 'onBoardingStep',
                              //       value: index + 1,
                              //     },
                              //   });
                              // }}
                            >
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                    </Stepper>
                    <AccountSettings
                      defaultTab="playbook"
                      onBoardingStep={onBoardingStep}
                      setCompletedStep={setCompletedStep}
                    />
                  </Box>
                )}
              </Box>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                p={2}
                borderTop={'1px solid #d3d3d3'}
              >
                {userDetails?.custom?.orgType === 'demo_org' ? (
                  <></>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      dispatch({
                        type: 'UPDATE_UI_STATE',
                        payload: {
                          key: 'showOnboardingDialog',
                          value: false,
                        },
                      });
                      dispatch({
                        type: 'UPDATE_UI_STATE',
                        payload: {
                          key: 'onBoardingStep',
                          value: 0,
                        },
                      });
                    }}
                  >
                    Skip and Continue Manually
                  </Button>
                )}
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={1}
                >
                  <ProfileCompletion
                    percentCompleted={Object.values(
                      onBoardingCompletionPercentage || {},
                    )?.reduce((ac, cr) => {
                      ac += cr;
                      return ac;
                    }, 0)}
                    circleSize={50}
                    circleTextSize={12}
                    fontSize={12}
                    thickness={5}
                    dontShowExtraText={true}
                  />
                  <Typography variant="body2" color={'text.secondary'}>
                    Onboarding Completed
                  </Typography>
                </Box>
                {Object.values(onBoardingCompletionPercentage || {})?.reduce(
                  (ac, cr) => {
                    ac += cr;
                    return ac;
                  },
                  0,
                ) === 100 &&
                ((userDetails?.custom?.orgType === 'demo_org' &&
                  onBoardingStep === 3) ||
                  (userDetails?.custom?.orgType !== 'demo_org' &&
                    onBoardingStep === 4)) ? (
                  <Box display={'flex'} alignItems={'center'} gap={1}>
                    {userDetails?.custom?.orgType === 'demo_org' ? (
                      <Typography variant="body1">
                        You are all set! Let&apos;s begin your first roleplay.
                      </Typography>
                    ) : null}
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        dispatch({
                          type: 'UPDATE_UI_STATE',
                          payload: {
                            key: 'showOnboardingDialog',
                            value: false,
                          },
                        });
                        dispatch({
                          type: 'UPDATE_UI_STATE',
                          payload: {
                            key: 'onBoardingStep',
                            value: 0,
                          },
                        });
                        if (userDetails?.custom?.orgType === 'demo_org') {
                          history.push('/conversation-ai?new=true');
                        }
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                ) : (
                  <Box display={'flex'} alignItems={'center'} gap={2}>
                    {onBoardingStep === 0 ? null : (
                      <Button
                        variant="contained"
                        size="small"
                        disabled={
                          userDetails?.custom?.orgType === 'demo_org' &&
                          onBoardingStep === 1
                        }
                        onClick={() => {
                          dispatch({
                            type: 'UPDATE_UI_STATE',
                            payload: {
                              key: 'onBoardingStep',
                              value: onBoardingStep - 1,
                            },
                          });
                        }}
                      >
                        Previous
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      size="small"
                      disabled={
                        onBoardingStep === 4 ||
                        (onBoardingStep === 1 && !selectedPlaybook) ||
                        (onBoardingStep === 2 &&
                          (!orgConfigs?.botVerticals ||
                            orgConfigs?.botVerticals?.length === 0 ||
                            !orgConfigs?.botDesignations ||
                            orgConfigs?.botDesignations?.length === 0))
                      }
                      onClick={() => {
                        // if(onBoardingStep === 1){
                        //   if(selecte)
                        // }
                        dispatch({
                          type: 'UPDATE_UI_STATE',
                          payload: {
                            key: 'onBoardingStep',
                            value: onBoardingStep + 1,
                          },
                        });
                      }}
                    >
                      {onBoardingStep === 0
                        ? 'Continue with onboarding'
                        : 'Next'}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Modal>
        ) : null}
      </Box>
    </ErrorBoundary>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
  noTopbar: PropTypes.bool,
  noFooter: PropTypes.bool,
  leftSidebar: PropTypes.bool,
};

export default Dashboard;
