import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const ParticipationPieChart = React.memo(function ParticipationPieChart({
  title,
  labels,
  styles = {},
  speakers = {},
  data,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...styles,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="caption" color={'text.secondary'}>
          {title}
        </Typography>
        <Box
          mt={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {labels?.map((sp, index) => (
            <Box key={sp} display={'flex'} alignItems={'center'} gap={1}>
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  background:
                    sp === 'Speaker 1'
                      ? '#79155B'
                      : sp === 'Speaker 2'
                      ? '#468B97'
                      : sp === 'Speaker 3'
                      ? '#EF6262'
                      : sp === 'Speaker 4'
                      ? '#F3AA60'
                      : sp === 'Speaker 5'
                      ? '#F1C93B'
                      : sp === 'Speaker 6'
                      ? '#461959'
                      : '#d3d3d3',
                }}
              ></Box>
              <Typography variant="caption" color={'text.secondary'}>
                {speakers[sp] || 'silence'}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Pie
        options={{
          responsive: true,
          layout: {
            padding: 5,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              callbacks: {
                // title: (xDatapoint) => {return formatXValue(xDatapoint.raw)},
                label: (yDatapoint) => {
                  // console.log(yDatapoint);
                  return speakers?.[yDatapoint?.label];
                },
              },
            },
            title: {
              display: false,
              text: title,
            },
            datalabels: {
              color: 'white',
              textStrokeColor: '#000',
              textStrokeWidth: '1px',
              font: {
                weight: 'bold',
                size: 16,
              },
              formatter: function(value, context) {
                return `${value}%`;
              },
            },
          },
          elements: {
            arc: {
              borderRadius: 5,
              borderSkipped: true,
            },
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              display: false,
            },
          },
        }}
        data={{
          labels: labels,
          datasets: [
            {
              animation: false,
              label: '',
              data: data,
              backgroundColor: [
                ...[
                  '#79155B',
                  '#468B97',
                  '#EF6262',
                  '#F3AA60',
                  '#F1C93B',
                  '#461959',
                ].slice(0, data?.length),
                '#d3d3d3',
              ],
            },
          ],
        }}
      />
    </Box>
  );
});

ParticipationPieChart.propTypes = {
  title: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
  speakers: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default ParticipationPieChart;
