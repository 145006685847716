import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Alert,
  Button,
  Card,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import AlertPopup from 'components/AlertPopup';
import showAlert from 'redux/actions/Common/showAlert';
import hideAlert from 'redux/actions/Common/hiderAlert';
import { Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import getEmailByToken from 'redux/actions/Common/getEmailByToken';
import Container from 'components/Container';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import unsubscribe from 'redux/actions/Common/unsubscribe';
import parse from 'html-react-parser';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import RichTextEditor from 'components/RichTextEditor';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    // borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ZoomIntegrationHelp = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const { userDetails } = useSelector((state) => state.userDetails);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const alertState = useSelector((state) => state.alertState);

  const params = new URLSearchParams(window.location.search);
  const unsubscribeToken = params.get('token');
  const [unsubscribed, setUnsubscribed] = useState(false);

  const [loading, setLoading] = useState(false);
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [error, setError] = useState(false);

  const [expanded, setExpanded] = useState('');

  const [primarySteps, setPrimarySteps] = useState([
    {
      step: 'How do I get started?',
      description: '',
      secondarySteps: [
        {
          stepTitle: 'Login to your Qualification AI Account.',
          stepDescription:
            'Go to <a href="https://app.qualification.ai" target="_blank">Qualification AI</a> and Login to your account.',
          img: './login.png',
          imgWidth: '30%',
        },
        {
          stepTitle: 'Go to the integrations page.',
          stepDescription:
            'Navigate to MyTools > Integrations page from the navigation bar on the left.',
          img: './integrations-left-nav.png',
          imgWidth: '30%',
        },
        {
          stepTitle: 'Turn On Zoom Integration.',
          stepDescription:
            'From the Zoom integration card turn on the switch to enable Zoom integration.',
          imgWidth: '30%',
          img: './zoom.png',
          afterImageText:
            'That&apos;s it! You can now send your meeting bot to attend and record your Zoom meetings.',
        },
      ],
    },
    {
      step: 'How do I send my Qualification AI bot to a Zoom meeting?',
      description:
        'You can send a bot to a Zoom meeting instantly using these steps.',
      secondarySteps: [
        {
          stepTitle:
            'Navigate to MyTools > Integrations page from the navigation bar on the left.',
          stepDescription: '',
          img: './integrations-left-nav.png',
          imgWidth: '30%',
        },
        {
          stepTitle: 'Click "Send Bot" Button.',
          stepDescription: '',
          img: './zoom-send-bot.png',
          imgWidth: '40%',
        },
        {
          stepTitle: 'Provide the Zoom meeting link',
          stepDescription:
            'When sending the bot you need to provide the link to your Zoom meeting in the dialog that appears.',
          img: './send-bot.png',
          afterImageText:
            'You can also turn on Qualification AI&apos;s calendar integration to automatically record and analyze meetings scheduled via your calendar as described below.',
          imgWidth: '40%',
        },
      ],
    },
    {
      step:
        'Can I have my Qualification AI bot automatically join my Zoom meetings?',
      description:
        'Yes, you can turn on Qualification AI&apos;s calendar integration from MyTools > Integrations page and have your bot sent to your scheduled meetings automatically.',
      secondarySteps: [
        {
          stepTitle: 'Turn on the calendar integration.',
          stepDescription:
            'From the Google Calendar integration card turn on the switch to enable Calendar integration.',
          img: './calendar.png',
          imgWidth: '30%',
          afterImageText:
            'The bot will try to join the meeting a couple of minutes before the scheduled time and wait for a few minutes or until the host lets it in. After the meeting is over the recording will be available in your Qualification AI account from the Analysis History page.',
        },
      ],
    },
    {
      step: 'How do I view my Zoom meeting recording and its analysis?',
      description:
        'You can view the recording of your Zoom meeting by navigating to the Analysis History page from the navigation menu.',
      secondarySteps: [
        {
          stepTitle: 'Navigate to the Analysis History page.',
          stepDescription:
            'Navigate to the Analysis History page from the left navigation menu.',
          img: './leftnavhistory.png',
          imgWidth: '20%',
          afterImageText: '',
        },
        {
          stepTitle:
            'View Call Analyis for the call automatically imported and analyzed.',
          stepDescription:
            'If you have auto analysis enabled, you would be able to view the analysis of the recording using the View report icon. You can also manually analyze a recording if auto-analysis is disabled.',
          img: './report.png',
          imgWidth: '50%',
          afterImageText: '',
        },
      ],
    },
  ]);

  const faqs = [
    {
      question: 'Bot didn&apos;t join the meeting automatically.',
      answer:
        'Make sure that Zoom integration is turned on. Bot will only join meetings automatically if they were set up after the Zoom integration is turned on. <br /><br /> By default the bot will attempt to join the meeting 2 minutes before the start of the meeting and will wait for 4 minutes before dropping off if it is not let in.',
    },
    {
      question: 'How do I send a bot to a meeting if I am not the host?',
      answer:
        'You can use the manual send option from the Integration page by providing the meeting link then request the meeting host to let the bot in.',
    },
    {
      question:
        'My meeting got over but the recording is not available in my account.',
      answer:
        'Depending on the length of the meeting it can take a short while before the recording and analysis becomes available in your account. If you do not see the recording in your account after a few hours please contact support@qualification.ai.',
    },
  ];

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md: isLoggedIn
            ? dashboardSidebarExpanded
              ? 'calc(100% - 240px)'
              : 'calc(100% - 80px)'
            : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md: isLoggedIn ? (dashboardSidebarExpanded ? '240px' : '80px') : 0,
        },
        height: 1,
        minHeight: isLoggedIn ? 'calc(100vh - 60px)' : 'calc(100vh - 110px)',
        borderTop: '1px solid #d3d3d3',
      }}
    >
      <Container
        paddingX={
          isLoggedIn
            ? dashboardSidebarExpanded
              ? 2
              : { lg: 16, md: 8, sm: 4, xs: 1 }
            : 8
        }
        paddingY={{ lg: 2, md: 2, sm: 2, xs: 3 }}
        maxWidth={{ xs: 1, sm: 1, md: isLoggedIn ? 1 : 1236 }}
        borderRight={'1px solid #d3d3d3'}
        borderLeft={'1px solid #d3d3d3'}
      >
        <Box sx={{ py: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h5">
            Analyze your Zoom calls with Qualification AI
          </Typography>
          <Typography>
            This document is a step by step guide for getting your zoom calls
            recorded and analyzed automatically through your Qualification AI
            bot.
          </Typography>
          <Box
            sx={{ width: '100%' }}
            display={'flex'}
            flexDirection={'column'}
            gap={4}
          >
            {primarySteps.map((step, index) => (
              <Box
                key={step.step}
                display={'flex'}
                gap={2}
                flexDirection={'column'}
              >
                <Typography variant="h6" fontWeight={700}>
                  {index + 1}. {parse(step.step)}
                </Typography>
                <Typography variant="body1" sx={{ ml: 3 }}>
                  {parse(step.description)}
                </Typography>
                {step.secondarySteps &&
                  step.secondarySteps.length > 0 &&
                  step.secondarySteps.map((secondaryStep, index) => (
                    <Box
                      key={secondaryStep.stepTitle}
                      display={'flex'}
                      gap={4}
                      flexDirection={'column'}
                      sx={{ ml: 3 }}
                    >
                      <Typography variant="body1" fontWeight={600}>
                        {String.fromCharCode(65 + index)}.{' '}
                        {parse(secondaryStep.stepTitle)}
                      </Typography>
                      <Typography variant="body1" sx={{ ml: 3 }}>
                        {parse(secondaryStep.stepDescription)}
                      </Typography>
                      <Box
                        width={secondaryStep.imgWidth || '100%'}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: '1px solid #d3d3d3',
                          p: 2,
                        }}
                      >
                        <img
                          src={secondaryStep.img}
                          style={{
                            width: '100%',
                          }}
                        />
                      </Box>
                      {secondaryStep.afterImageText ? (
                        <Typography variant="body1">
                          {parse(secondaryStep.afterImageText)}
                        </Typography>
                      ) : null}
                    </Box>
                  ))}
              </Box>
            ))}
          </Box>
          <Typography variant="h5" mt={4}>
            Troubleshooting: Zoom Integration
          </Typography>
          <Box
            sx={{ width: '100%' }}
            display={'flex'}
            flexDirection={'column'}
            gap={2}
          >
            {faqs?.map((faq) => (
              <Accordion
                key={faq?.question}
                expanded={expanded === faq?.question}
                onChange={() => {
                  if (expanded === faq?.question) setExpanded(null);
                  else setExpanded(faq?.question);
                }}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography>{parse(faq?.question)}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{parse(faq?.answer)}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ZoomIntegrationHelp;
