import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';
// import showAlert from './showAlert';

const getAllHubspotDeals = (callback) => {
  return async (dispatch) => {
    dispatch({ type: 'GET_ALL_HUBSPOT_DEALS' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(`/us/hb/deals`, {
            params: {
              'request-id': reqId,
            },
            headers: token
              ? {
                  Authorization: `Bearer ${token}`,
                  // 'current-role': currentRole,
                  // 'x-api-version': 'v2',
                }
              : {},
          });
          if (response.status === 200) {
            await dispatch({
              type: 'GET_ALL_HUBSPOT_DEALS_DONE',
              payload: response?.data,
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'GET_ALL_HUBSPOT_DEALS_FAILED',
              payload: `Failed to get hubspot deals.`,
            });
            if (typeof callback === 'function') {
              callback(null, response?.response?.data);
            }
          }
        } catch (e) {
          dispatch({
            type: 'GET_ALL_HUBSPOT_DEALS_FAILED',
            payload: `Failed to get hubspot deals.`,
          });
          if (typeof callback === 'function') {
            callback(null, e?.response?.data);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'GET_ALL_HUBSPOT_DEALS_FAILED',
        payload: `Failed to get hubspot deals.`,
      });
      if (typeof callback === 'function') {
        callback(null, e?.response?.data);
      }
    }
  };
};

export default getAllHubspotDeals;
