import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Alert,
  Autocomplete,
  Badge,
  Popper,
  Fab,
  Menu,
  ListSubheader,
  Stepper,
  Step,
  StepButton,
  Switch,
  Popover,
  Avatar,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  ArrowBack,
  ArrowDropDown,
  ArrowForward,
  ArrowLeft,
  ArrowRight,
  ArrowRightAlt,
  Article,
  Assistant,
  AudioFile,
  Audiotrack,
  AutoAwesome,
  Bookmarks,
  Cached,
  CancelPresentation,
  Chat,
  Check,
  CheckBoxOutlineBlank,
  ChevronRight,
  Close,
  CloudUpload,
  Delete,
  DisabledByDefault,
  ErrorOutline,
  Explore,
  FindReplace,
  Flag,
  HelpOutline,
  Info,
  KeyboardArrowDown,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardDoubleArrowRight,
  Label,
  LocationOn,
  Lock,
  Loop,
  Mic,
  MoreVert,
  PlayArrow,
  Redo,
  Refresh,
  Search,
  Send,
  Settings,
  StopCircle,
  Subject,
  TaskAlt,
  TextSnippet,
  Timeline,
  Troubleshoot,
  Visibility,
  Warning,
  WarningAmber,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import createSalesAnalysisThread from 'redux/actions/Candidate/createSalesAnalysisThread';
import showAlert from 'redux/actions/Common/showAlert';
import checkSalesCallTranscriptStatus from 'redux/actions/Common/checkSalesCallTranscriptStatus';
import getSalesCallReviews from 'redux/actions/Common/getSalesCallReviews';
import analyzeSalesCall from 'redux/actions/Common/analyzeSalesCall';

import { PropTypes } from 'prop-types';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';
import parse from 'html-react-parser';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import { capitalizeText, formatSeconds, getFileType } from 'utils';
import getAccounts from 'redux/actions/Compass/getAccounts';
import { useFormik, withFormik } from 'formik';
import * as yup from 'yup';
import createAccount from 'redux/actions/Compass/createAccount';
import updateAccountDetails from 'redux/actions/Compass/updateAccountDetails';
import deleteAccount from 'redux/actions/Compass/deleteAccount';
import FormField from 'components/Compass/FormField';
import getAllTeamMembers from 'redux/actions/Common/getAllTeamMembers';
import getCompanyProfileById from 'redux/actions/Compass/getCompanyProfileById';
import updateCompanyProfile from 'redux/actions/Compass/updateCompanyProfile';
import getCompanyProspects from 'redux/actions/Compass/getCompanyProspects';
import assignAccountToTeamMember from 'redux/actions/Compass/assignAccountToTeamMember';
import updateCompanyProspectDetails from 'redux/actions/Compass/updateCompanyProspectDetails';
import ProspectMembers from 'components/Compass/ProspectMembers';
import DealActivities from 'components/Compass/DealActivities';
import getAllDealActivities from 'redux/actions/Compass/getAllDealActivities';
import OrgChart from 'components/Compass/OrgChart';
import getQuestionFramework from 'redux/actions/Compass/getQuestionFramework';
import updateQuestionAnswers from 'redux/actions/Compass/updateQuestionAnswers';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import updateOption from 'redux/actions/Compass/updateOption';
import getSectionParents from 'redux/actions/Compass/getSectionParents';
import getSectionsByLevel from 'redux/actions/Compass/getSectionsByLevel';
import markSectionNonRelevant from 'redux/actions/Compass/markSectionNonRelevant';
import getHelpSections from 'redux/actions/Compass/getHelpSections';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const validationSchema = yup.object({
  companyName: yup
    .string()
    .trim()
    .required('Please enter company name')
    .min(3, 'Company name should have at least 3 characters'),
  primaryContactEmail: yup
    .string()
    .trim()
    .email('Please enter a valid email address'),
  primaryContactName: yup
    .string()
    .required('Please specify primary contact name')
    .min(3, 'Primary contact name should have at least 3 characters'),
  primaryContactDesignation: yup
    .string()
    .min(3, 'Primary contact designation should have at least 3 characters'),
  primaryContactLinkedInurl: yup
    .string()
    .min(3, 'Primary contact linkedin url should have at least 3 characters'),
  organizationLevel: yup.string(),
  title: yup.string(),
  description: yup.string(),
  additionalNotes: yup.string(),
});

const profileValidationSchema = yup.object({
  industrySegment: yup.string().trim(),
  size: yup.string().trim(),
  location: yup.string().trim(),
  fundingSources: yup.array(),
  lengthInBusiness: yup.string().trim(),
  dealType: yup.string(),
  stageOfGrowth: yup.string(),
  technologyAdoptionProfile: yup.string(),
  hasManagementConsultant: yup.bool(),
  hasInterimCLevelExecutive: yup.bool(),
  hasPeOrVentureAdvisor: yup.bool(),
  outsourceFunction: yup.bool(),
  managementConsultantDetails: yup.string().trim(),
  outsourcedFunctionDetails: yup.string().trim(),
  peOrVentureAdvisorDetails: yup.string().trim(),
  interimCLevelExecutiveDetails: yup.string().trim(),
  // relevantHistoricalOrPresetActivity: yup.string().trim(),
});

const defaultProcessQA = [
  { question: 'What is the sales process?', answer: '' },
  {
    question: 'What is your buying process?',
    answer: '',
    type: 'select',
    options: ['Ad Hoc', 'Repeatable', 'Defined', 'Managed', 'Optimized'],
  },
  { question: 'Are they aligned?', answer: '' },
  {
    question:
      'Do they have a budget and what does the budget approval process look like?',
    answer: '',
  },
  {
    question:
      'Do you have a RFP process (did you help influence writing the RFP)?',
    answer: '',
  },
  {
    question:
      'Where in the sales process the prospect is: Suspect, prospect, MQL, SQL, opportunity, technical validation, business validation',
    answer: '',
  },
];

const VideoPreview = memo(
  function VideoPreview({ video, onDurationChange }) {
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        {video ? (
          <Box width={'90%'} display={'flex'} flexDirection={'column'} gap={1}>
            <video
              style={{ width: '100%' }}
              preload={false}
              src={
                typeof video === 'string'
                  ? video
                  : window.URL.createObjectURL(video)
              }
              controls
              controlsList="nodownload"
              disablePictureInPicture
              onDurationChange={onDurationChange}
            />
          </Box>
        ) : null}
      </Box>
    );
  },
  (pre, post) => {
    return pre?.video === post?.video;
  },
);

const QnAs = ({
  key,
  question,
  questionIndex,
  currentAccount,
  setQuestionFramework,
  selectedAnswers,
  setSelectedAnswers,
  selectedOptions,
  selectedAnswerText,
  tags,
  notes,
  editMode,
  setHighlightedSectionId,
  section,
  setBackReferenceSections,
  activeStep,
  activeSecondaryStep,
  activeQuestionFrameworkStep,
  setActiveStep,
  listOfGroups,
  listOfPages,
  setExpandedSections,
  questionFramework,
  showNotRelevantCheckbox,
  setShowNotRelevantCheckbox,
  notRelevant,
  setShowHelpSidebar,
  helpSections,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAdditionalNotesBox, setShowAdditionalNotesBox] = useState(null);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [answer, setAnswer] = useState(
    selectedAnswers?.[question?.id]?.answerText,
  );
  const [options, setOptions] = useState(
    selectedAnswers?.[question?.id]?.options || [],
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  useEffect(() => {
    setAnswer(selectedAnswers?.[question?.id]?.answerText || '');
  }, [activeSecondaryStep, activeStep, activeQuestionFrameworkStep]);

  return (
    <Box
      key={key}
      display={'flex'}
      flexDirection={'column'}
      gap={
        question?.type === 'radio' || question?.type === 'checkbox'
          ? '4px'
          : '8px'
      }
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={1}
        onMouseEnter={() =>
          editMode ? setShowNotRelevantCheckbox(question?.id) : null
        }
        onMouseLeave={() =>
          editMode ? setShowNotRelevantCheckbox(null) : null
        }
      >
        {!editMode ? (
          <Flag
            sx={{
              color:
                Math.round(Number(selectedAnswers?.[question?.id]?.score)) === 0
                  ? 'red'
                  : Math.round(
                      Number(selectedAnswers?.[question?.id]?.score),
                    ) === 1
                  ? 'orange'
                  : Math.round(
                      Number(selectedAnswers?.[question?.id]?.score),
                    ) === 2
                  ? '#ffe599'
                  : Math.round(
                      Number(selectedAnswers?.[question?.id]?.score),
                    ) === 3
                  ? 'green'
                  : Math.round(
                      Number(selectedAnswers?.[question?.id]?.score),
                    ) === 4 ||
                    Math.round(
                      Number(selectedAnswers?.[question?.id]?.score),
                    ) === 5
                  ? 'blue'
                  : 'blue',
            }}
          />
        ) : null}
        <Typography
          sx={{
            color: notRelevant ? '#aaa' : theme.palette.text.secondary,
          }}
        >
          {question?.questionText}
        </Typography>
        {tags && tags?.length > 0
          ? tags?.map((tag) => (
              <Chip
                key={tag}
                label={
                  <Typography
                    variant="caption"
                    color={notRelevant ? '#aaa' : 'default'}
                  >
                    {tag}
                  </Typography>
                }
                size="small"
                color={notRelevant ? 'default' : 'primary'}
              />
            ))
          : null}
        {editMode ? (
          <Tooltip arrow placement="top" title="Add Label">
            <Bookmarks
              sx={{
                fontSize: 18,
                cursor: 'pointer',
                color: notRelevant ? '#aaa' : '#626366',
              }}
              onClick={(e) => {
                setAnchorEl(e.target);
                setOpenedPopoverId(question?.id);
              }}
            />
          </Tooltip>
        ) : null}
        <Popover
          elevation={3}
          id={question?.id}
          open={openedPopoverId === question?.id}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setOpenedPopoverId(null);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            '.MuiPaper-root': {
              background: '#fff',
              border: '1px solid #d3d3d3',
              borderRadius: 1,
              padding: 1,
            },
          }}
        >
          <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
            {['Next Meeting', 'Risk', 'Strategic', 'Internal']?.map(
              (option) => {
                return (
                  <MenuItem
                    key={option}
                    value={option}
                    sx={{ width: 1 }}
                    onClick={(e) => {
                      // console.log(option);
                      dispatch(
                        updateQuestionAnswers(
                          currentAccount?.id,
                          {
                            ...(selectedAnswers?.[question?.id] | []),
                            tags: tags?.includes(option)
                              ? [
                                  ...(tags?.filter((tag) => tag !== option) ||
                                    []),
                                ]
                              : [...(tags || []), option],
                            questionId: question?.id,
                          },
                          (data) => {
                            // console.log(data);
                            setAnchorEl(null);
                            setOpenedPopoverId(null);
                            setSelectedAnswers((prev) => {
                              return {
                                ...prev,
                                [question?.id]: {
                                  ...prev?.[question?.id],
                                  tags: tags?.includes(option)
                                    ? [
                                        ...(tags?.filter(
                                          (tag) => tag !== option,
                                        ) || []),
                                      ]
                                    : [...(tags || []), option],
                                },
                              };
                            });
                          },
                        ),
                      );
                    }}
                  >
                    <Typography variant="caption">{option}</Typography>
                    {tags?.includes(option) ? (
                      <Check
                        sx={{ fontSize: 18, color: theme.palette.primary.main }}
                      />
                    ) : null}
                  </MenuItem>
                );
              },
            )}
          </Box>
        </Popover>
        {question?.type === 'radio' ||
        question?.type === 'checkbox' ||
        question?.type === 'select' ||
        question?.type === 'multiselect' ? (
          selectedOptions &&
          Array.isArray(selectedOptions) &&
          selectedOptions?.length > 0 &&
          editMode ? (
            <Tooltip arrow placement="top" title="Additional Notes">
              <TextSnippet
                sx={{
                  fontSize: 18,
                  cursor: 'pointer',
                  color: notRelevant ? '#aaa' : '#626366',
                }}
                onClick={() => {
                  setShowAdditionalNotesBox((prev) => (prev ? null : question));
                }}
              />
            </Tooltip>
          ) : null
        ) : null}
        {editMode && question?.helpSectionId ? (
          <Tooltip arrow placement="top" title="Open Help Section">
            <HelpOutline
              sx={{
                cursor: 'pointer',
                color: theme.palette.primary.main,
                fontSize: 20,
              }}
              onClick={() => {
                setShowHelpSidebar(
                  helpSections?.find(
                    (section) => section?.id === question?.helpSectionId,
                  ),
                );
              }}
            />
          </Tooltip>
        ) : null}
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              value={selectedAnswers?.[question?.id]?.notRelevant}
              defaultChecked={selectedAnswers?.[question?.id]?.notRelevant}
              name="notRelevant"
              icon={<CheckBoxOutlineBlank />}
              checkedIcon={<DisabledByDefault />}
              sx={{
                padding: '4px',
              }}
            />
          }
          sx={{
            border: '1px solid #d3d3d3',
            marginLeft: 1,
            paddingRight: 1,
            visibility:
              showNotRelevantCheckbox === question?.id ||
              selectedAnswers?.[question?.id]?.notRelevant
                ? 'visible'
                : 'hidden',
            '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
              color: theme.palette.text.secondary,
            },
          }}
          label={
            <Typography variant="caption" color={'text.secondary'}>
              Not relevant
            </Typography>
          }
          onChange={(e) => {
            dispatch(
              updateQuestionAnswers(
                currentAccount?.id,
                {
                  ...selectedAnswers?.[question?.id],
                  notRelevant: !selectedAnswers?.[question?.id]?.notRelevant,
                  questionId: question?.id,
                },
                (data) => {
                  console.log(data);
                  setSelectedAnswers((prev) => {
                    return {
                      ...prev,
                      [question?.id]: {
                        ...prev?.[question?.id],
                        notRelevant: !selectedAnswers?.[question?.id]
                          ?.notRelevant,
                      },
                    };
                  });
                },
              ),
            );
          }}
        />
      </Box>
      {!editMode ? (
        <Typography
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {question?.type === 'text' || question?.type === 'textarea'
            ? answer
            : question?.type === 'select'
            ? question?.options?.find((op) => op?.id === selectedOptions?.[0])
                ?.text
            : question?.type === 'multiselect' || question?.type === 'checkbox'
            ? question?.options
                ?.filter((op) => selectedOptions?.includes(op?.id))
                ?.sort((a, b) => a?.order - b?.order)
                ?.map((op) => op?.text)
                ?.join('\n')
            : question?.type === 'radio'
            ? question?.options?.find((op) => op?.id === selectedOptions?.[0])
                ?.text
            : question?.type === 'date'
            ? moment(Number(answer))?.format('DD-MMMM-YYYY')
            : null}
        </Typography>
      ) : null}
      {editMode ? (
        question?.type === 'text' || question?.type === 'textarea' ? (
          <TextField
            placeholder={'Answer'}
            variant="outlined"
            name={'answerText'}
            fullWidth
            multiline={question?.type === 'textarea'}
            rows={question?.type === 'textarea' ? 4 : undefined}
            size="small"
            value={answer}
            onChange={(e) => {
              setAnswer(e.target.value);
            }}
            onBlur={(e) => {
              // console.log(e.target.value);
              if (e.target.value.trim()) {
                dispatch(
                  updateQuestionAnswers(
                    currentAccount?.id,
                    {
                      ...selectedAnswers?.[question?.id],
                      answerText: e.target.value.trim(),
                      questionId: question?.id,
                    },
                    (data) => {
                      console.log(data);
                      setSelectedAnswers((prev) => {
                        return {
                          ...prev,
                          [question?.id]: {
                            ...prev?.[question?.id],
                            answerText: e.target.value.trim(),
                          },
                        };
                      });
                    },
                  ),
                );
              }
            }}
            type={'text'}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: notRelevant ? '#aaa' : 'default',
              },
            }}
          />
        ) : question?.type === 'select' || question?.type === 'multiselect' ? (
          <FormControl
            size="small"
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                color: notRelevant ? '#aaa' : 'default',
              },
            }}
          >
            <Select
              size="small"
              labelId={'answer'}
              id={'answer'}
              name={'answerSelect'}
              MenuProps={{ disableScrollLock: true }}
              onChange={(e) => {
                // console.log(e.target.value);
                dispatch(
                  updateQuestionAnswers(
                    currentAccount?.id,
                    {
                      ...selectedAnswers?.[question?.id],
                      options:
                        question?.type === 'select'
                          ? [e.target.value]
                          : e.target.value,
                      questionId: question?.id,
                    },
                    (data) => {
                      setSelectedAnswers((prev) => {
                        return {
                          ...prev,
                          [question?.id]: {
                            ...prev?.[question?.id],
                            options:
                              question?.type === 'select'
                                ? [e.target.value]
                                : e.target.value,
                          },
                        };
                      });
                    },
                  ),
                );
              }}
              value={
                question?.type === 'select'
                  ? question?.options?.find(
                      (op) => op?.id === selectedOptions?.[0],
                    )?.id || ''
                  : question?.options
                      ?.filter((op) => selectedOptions?.includes(op?.id))
                      ?.map((op) => op?.id) || ['']
              }
              multiple={question?.type === 'multiselect'}
              renderValue={(selected) =>
                question?.type === 'multiselect'
                  ? selected
                      .map((value) => {
                        if (typeof value === 'object') {
                          return value.name;
                        }
                        return question?.options?.find((op) => op?.id === value)
                          ?.text;
                      })
                      .join(', ')
                  : question?.options?.find((op) => op?.id === selected)?.text
              }
            >
              {question?.options
                ?.sort((a, b) => a?.order - b?.order)
                ?.map((option) => (
                  <MenuItem value={option?.id} key={option?.id}>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      gap={2}
                      width={1}
                    >
                      <Typography variant="subtitle2">
                        {option?.text}
                      </Typography>
                      {question?.type === 'multiselect' &&
                      selectedOptions?.includes(option?.id) ? (
                        <Check color="primary" sx={{ fontSize: 18 }} />
                      ) : null}
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : question?.type === 'radio' ? (
          <FormControl
            sx={{
              width: '100%',
            }}
          >
            <RadioGroup
              sx={{ marginLeft: 2, gap: 1 }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={
                selectedOptions?.[0] ||
                question?.options?.find((op) => op?.selected)?.id
              }
              onChange={(e) => {
                dispatch(
                  updateQuestionAnswers(
                    currentAccount?.id,
                    {
                      ...selectedAnswers?.[question?.id],
                      options: [e.target.value],
                      questionId: question?.id,
                    },
                    (data) => {
                      setSelectedAnswers((prev) => {
                        return {
                          ...prev,
                          [question?.id]: {
                            ...prev?.[question?.id],
                            options: [e.target.value],
                          },
                        };
                      });
                      setOptions([e.target.value]);
                    },
                  ),
                );
              }}
            >
              {question?.options && question?.options?.length > 0
                ? question?.options
                    ?.sort((a, b) => a?.order - b?.order)
                    ?.map((option) => (
                      <FormControlLabel
                        key={option?.id}
                        value={option?.id}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: notRelevant ? '#aaa' : 'default',
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: notRelevant ? '#aaa' : 'default',
                            }}
                          >
                            {option?.text}
                          </Typography>
                        }
                        sx={{
                          border: '1px solid #d3d3d3',
                          paddingRight: 1,
                          borderRadius: 8,
                          '& .MuiButtonBase-root.MuiRadio-root.Mui-checked': {
                            color: notRelevant ? '#aaa' : 'default',
                          },
                        }}
                      />
                    ))
                : null}
            </RadioGroup>
          </FormControl>
        ) : question?.type === 'checkbox' ? (
          <FormControl component="fieldset" variant="standard">
            <FormGroup row sx={{ marginLeft: 2, gap: 1 }}>
              {question?.options && question?.options?.length > 0
                ? question?.options
                    ?.sort((a, b) => a?.order - b?.order)
                    ?.map((option) => (
                      <FormControlLabel
                        key={option?.id}
                        control={
                          <Checkbox
                            size="small"
                            value={option?.text}
                            defaultChecked={selectedOptions?.includes(
                              option?.id,
                            )}
                            sx={{
                              color: notRelevant ? '#aaa' : 'default',
                            }}
                            name="answers"
                          />
                        }
                        label={
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: notRelevant ? '#aaa' : 'default',
                            }}
                          >
                            {option?.text}
                          </Typography>
                        }
                        sx={{
                          border: '1px solid #d3d3d3',
                          paddingRight: 1,
                          borderRadius: 8,
                          '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
                            color: notRelevant ? '#aaa' : 'default',
                          },
                        }}
                        onChange={(e) => {
                          dispatch(
                            updateQuestionAnswers(
                              currentAccount?.id,
                              {
                                ...(selectedAnswers?.[question?.id] || {}),
                                options: selectedOptions?.includes(option?.id)
                                  ? selectedOptions?.filter(
                                      (id) => id !== option?.id,
                                    )
                                  : [...(selectedOptions || []), option?.id],
                                questionId: question?.id,
                              },
                              (data) => {
                                setSelectedAnswers((prev) => {
                                  return {
                                    ...prev,
                                    [question?.id]: {
                                      ...(prev?.[question?.id] || {}),
                                      options: selectedOptions?.includes(
                                        option?.id,
                                      )
                                        ? selectedOptions?.filter(
                                            (id) => id !== option?.id,
                                          )
                                        : [
                                            ...(selectedOptions || []),
                                            option?.id,
                                          ],
                                    },
                                  };
                                });
                              },
                            ),
                          );
                        }}
                      />
                    ))
                : null}
            </FormGroup>
          </FormControl>
        ) : question?.type === 'date' ? (
          <DatePicker
            value={answer ? dayjs(new Date(Number(answer))) : null}
            label="Start Date"
            format="MM-DD-YYYY"
            onChange={(date) => {
              let date_ = new Date(date); // some mock date
              // console.log(date);
              var milliseconds = date_.getTime();
              console.log(milliseconds);
              if (date) {
                dispatch(
                  updateQuestionAnswers(
                    currentAccount?.id,
                    {
                      ...selectedAnswers?.[question?.id],
                      answerText: milliseconds,
                      questionId: question?.id,
                    },
                    (data) => {
                      console.log(data);
                      setSelectedAnswers((prev) => {
                        return {
                          ...prev,
                          [question?.id]: {
                            ...prev?.[question?.id],
                            answerText: milliseconds,
                          },
                        };
                      });
                    },
                  ),
                );
              }
            }}
          />
        ) : null
      ) : null}
      {showAdditionalNotesBox ? (
        <TextField
          placeholder={'Additional Notes'}
          variant="outlined"
          name={'additionalNotes'}
          fullWidth
          multiline={true}
          rows={4}
          size="small"
          defaultValue={notes}
          // onChange={(e) => {}}
          onBlur={(e) => {
            if (e.target.value.trim()) {
              dispatch(
                updateQuestionAnswers(
                  currentAccount?.id,
                  {
                    ...selectedAnswers?.[question?.id],
                    notes: e.target.value.trim(),
                    questionId: question?.id,
                  },
                  (data) => {
                    console.log(data);
                    setSelectedAnswers((prev) => {
                      return {
                        ...prev,
                        [question?.id]: {
                          ...prev?.[question?.id],
                          notes: e.target.value.trim(),
                        },
                      };
                    });
                  },
                ),
              );
            }
          }}
          type={'text'}
        />
      ) : null}
      {editMode
        ? selectedOptions?.map((answer) => {
            return question?.options?.find((op) => op?.id === answer)
              ?.sectionReferences?.length > 0
              ? question?.options
                  ?.find((op) => op?.id === answer)
                  ?.sectionReferences?.map((ref) => (
                    <Box
                      key={ref?.id}
                      component={'a'}
                      href={`#${ref?.title}`}
                      dispaly={'flex'}
                      alignItems={'center'}
                      gap={1}
                      onClick={(e) => {
                        setHighlightedSectionId(ref?.id);
                        setBackReferenceSections((prev) =>
                          prev?.map((sc) => sc?.id)?.includes(section?.id)
                            ? prev
                            : [
                                ...prev,
                                {
                                  ...section,
                                  activeStep,
                                  activeQuestionFrameworkStep,
                                },
                              ],
                        );
                        setExpandedSections((prev) => {
                          return {
                            ...prev,
                            [ref?.id]: true,
                          };
                        });
                        dispatch(
                          getSectionParents(ref?.id, (data) => {
                            // console.log(data);
                            // console.log(
                            //   listOfGroups?.findIndex(
                            //     (group) =>
                            //       group?.id ===
                            //       data?.[0]?.parents?.[0]?.parents?.[0]?.id,
                            //   ),
                            // );
                            // console.log(
                            //   questionFramework?.[
                            //     listOfGroups?.findIndex(
                            //       (group) =>
                            //         group?.id ===
                            //         data?.[0]?.parents?.[0]?.parents?.[0]?.id,
                            //     )
                            //   ]?.sections?.findIndex(
                            //     (domain) =>
                            //       domain?.id === data?.[0]?.parents?.[0]?.id,
                            //   ),
                            // );
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'activeQuestionFrameworkStep',
                                value:
                                  listOfGroups?.findIndex(
                                    (group) =>
                                      group?.id ===
                                      data?.[0]?.parents?.[0]?.parents?.[0]?.id,
                                  ) >= 0
                                    ? listOfGroups?.findIndex(
                                        (group) =>
                                          group?.id ===
                                          data?.[0]?.parents?.[0]?.parents?.[0]
                                            ?.id,
                                      )
                                    : activeQuestionFrameworkStep,
                              },
                            });
                            setExpandedSections((prev) => {
                              return {
                                ...prev,
                                [data?.[0]?.id]: true,
                              };
                            });
                            setActiveStep((prev) => {
                              return questionFramework?.[
                                listOfGroups?.findIndex(
                                  (group) =>
                                    group?.id ===
                                    data?.[0]?.parents?.[0]?.parents?.[0]?.id,
                                )
                              ]?.sections?.findIndex(
                                (domain) =>
                                  domain?.id === data?.[0]?.parents?.[0]?.id,
                              ) >= 0
                                ? questionFramework?.[
                                    listOfGroups?.findIndex(
                                      (group) =>
                                        group?.id ===
                                        data?.[0]?.parents?.[0]?.parents?.[0]
                                          ?.id,
                                    )
                                  ]?.sections?.findIndex(
                                    (domain) =>
                                      domain?.id ===
                                      data?.[0]?.parents?.[0]?.id,
                                  )
                                : prev;
                            });
                          }),
                        );
                      }}
                    >
                      <ArrowForward
                        sx={{
                          fontSize: 18,
                          color: notRelevant ? '#aaa' : 'default',
                        }}
                      />
                      <span
                        style={{
                          fontSize: 16,
                          color: notRelevant ? '#aaa' : 'default',
                        }}
                      >
                        {capitalizeText(ref?.title?.split('_')?.join(' '))}
                      </span>
                    </Box>
                  ))
              : null;
          })
        : null}
      {question?.type === 'radio' ||
      question?.type === 'checkbox' ||
      question?.type === 'select' ||
      question?.type === 'multiselect'
        ? selectedOptions?.map((selectedAnswer) => (
            <>
              {question?.options
                ?.find((op) => op?.id === selectedAnswer)
                ?.questions?.map((qs, qi) => (
                  <QnAs
                    key={qs?.id}
                    question={qs}
                    questionIndex={qi}
                    currentAccount={currentAccount}
                    setQuestionFramework={setQuestionFramework}
                    selectedAnswers={selectedAnswers}
                    setSelectedAnswers={setSelectedAnswers}
                    selectedOptions={selectedAnswers?.[qs?.id]?.options}
                    selectedAnswerText={selectedAnswers?.[qs?.id]?.answerText}
                    tags={selectedAnswers?.[qs?.id]?.tags}
                    notes={selectedAnswers?.[qs?.id]?.notes}
                    editMode={editMode}
                    setHighlightedSectionId={setHighlightedSectionId}
                    section={section}
                    setBackReferenceSections={setBackReferenceSections}
                    activeStep={activeStep}
                    activeSecondaryStep={activeSecondaryStep}
                    activeQuestionFrameworkStep={activeQuestionFrameworkStep}
                    setActiveStep={setActiveStep}
                    listOfGroups={listOfGroups}
                    listOfPages={listOfPages}
                    setExpandedSections={setExpandedSections}
                    questionFramework={questionFramework}
                    showNotRelevantCheckbox={showNotRelevantCheckbox}
                    setShowNotRelevantCheckbox={setShowNotRelevantCheckbox}
                    notRelevant={
                      notRelevant || selectedAnswers?.[qs?.id]?.notRelevant
                    }
                    setShowHelpSidebar={setShowHelpSidebar}
                    helpSections={helpSections}
                  />
                ))}
            </>
          ))
        : null}
    </Box>
  );
};

const CompanyAccounts = ({ uId, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const {
    dashboardSidebarExpanded,
    questionFrameworkSteps,
    activeQuestionFrameworkStep,
  } = useSelector((state) => state.uiStates);
  const { uploadingFor, uploadPercentage } = useSelector(
    (state) => state.createSalesAnalysisThreadState,
  );
  const chatsScrollview = useRef(null);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const {
    accounts,
    fetching,
    fetchingPagination,
    lastSeen,
    emptyList,
  } = useSelector((state) => state.companyAccounts);
  const showControls =
    localStorage.getItem('showControls') === 'true' ? true : false;

  const { userDetails } = useSelector((state) => state.userDetails);
  const { members } = useSelector((state) => state.allTeamMembers);
  const { prospects } = useSelector((state) => state.companyProspects);
  const { membersByOrgId } = useSelector((state) => state.membersByOrgId);
  const allTeamMembers = useSelector((state) => state.allTeamMembers);
  const dealActivities = useSelector((state) => state.dealActivities);
  const {
    showCompetitionDetailsOnTopbar,
    currentContextData,
    showBackButtonOnTopbar,
    currentNavigationLevel,
    resetQuestionFrameworkStep,
  } = useSelector((state) => state.uiStates);
  const [loading, setLoading] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const browser = detect();

  const [showCreateAccount, setShowCreateAccount] = useState(null);
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);

  const [currentAccountIndex, setCurrentAccountIndex] = useState(null);

  const [viewType, setViewType] = useState('manage');
  const [currentAccount, setCurrentAccount] = useState(null);
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [activeSecondaryStep, setActiveSecondaryStep] = useState(0);

  const positionPoints = ['Profile', 'Power', 'Value', 'Process'];

  const [activePositionPoint, setActivePositionPoint] = useState(0);

  const velocityPoints = ['Deal Activities', 'Velocity Questions'];
  const [activeVelocityPoint, setActiveVelocityPoint] = useState(0);

  const timePoints = ['Time Factors'];
  const [activeTimePoint, setActiveTimePoint] = useState(0);
  const [dealCloseExpectedDate, setDealCloseExpectedDate] = useState(
    Date.now(),
  );
  const [solutionProvideDeadline, setSolutionProvideDeadline] = useState(
    Date.now(),
  );

  const [dealDueDateNotes, setDealDueDateNotes] = useState('');
  const [solutionDueDateNotes, setSolutionDueDateNotes] = useState('');

  const strategyPoints = [
    'Push Strategy (Direct Strategy)',
    'Pull Strategy (Indirect Strategy)',
    'Split Strategy (Land and Expand)',
    'Follow Strategy (Trail Strategy)',
    'Challenge Strategy (Containment Strategy)',
  ];
  const [activeStrategyPoint, setActiveStrategyPoint] = useState(0);
  const [prospectMembers, setProspectMembers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [processQA, setProcessQA] = useState(defaultProcessQA);

  const [currentValue, setCurrentValue] = useState([]);
  const [currentValueMap, setCurrentValueMap] = useState({
    Efficiency: '',
    Effectiveness: '',
    'Risk Mitigation': '',
    'Strategic Growth': '',
    'Customer Experience': '',
  });
  const [currentValues, setCurrentValues] = useState(null);

  const [showManagePermissions, setShowManagePermissions] = useState(null);
  const [userPermissionMap, setUserPermissionMap] = useState({});

  const [activeVelocityProspect, setActiveVelocityProspect] = useState(0);

  const [showWizard, setShowWizard] = useState(false);
  const wizardSteps = ['Account Details', 'Prospects', 'Deal Activities'];
  const [currentWizardStepIndex, setCurrentWizardStepIndex] = useState(0);
  const [completedWizardSteps, setCompletedWizardSteps] = useState({});
  const [updatingAccountDetails, setUpdatingAccountDetails] = useState(false);
  const [loadingProspects, setLoadingProspects] = useState(false);
  const [questionFramework, setQuestionFramework] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState(null);
  const [sectionsData, setSectionsData] = useState(null);
  const [listOfGroups, setListOfGroups] = useState([]);
  const [listOfPages, setListOfPages] = useState([]);

  const [backReferenceSections, setBackReferenceSections] = useState([]);
  const [highlightedSectionId, setHighlightedSectionId] = useState('');

  const [expandedSections, setExpandedSections] = useState(null);
  const [showNotRelevantCheckbox, setShowNotRelevantCheckbox] = useState(null);

  const [showHelpSidebar, setShowHelpSidebar] = useState(null);

  const [helpSections, setHelpSections] = useState([]);

  const [colorCodes] = useState({
    0: 'red',
    1: 'organge',
    2: '#ffe599',
    3: 'green',
    4: 'blue',
    5: 'blue',
  });

  const [
    fetchingQuestionFrameworkAndAnswers,
    setFetchingQuestionFrameworkAndAnswers,
  ] = useState(false);

  const initialValues = {
    title: '',
    description: '',
    companyName: '',
    primaryContactName: '',
    primaryContactEmail: '',
    primaryContactDesignation: '',
    primaryContactLinkedInurl: '',
    organizationLevel: '',
    additionalNotes: '',
  };

  const profileInitialValues = {
    industrySegment: '',
    size: '',
    location: '',
    fundingSources: [],
    lengthInBusiness: '',
    dealType: '',
    stageOfGrowth: '',
    technologyAdoptionProfile: '',
    hasManagementConsultant: false,
    hasInterimCLevelExecutive: false,
    hasPeOrVentureAdvisor: false,
    outsourceFunction: false,
    managementConsultantDetails: '',
    outsourcedFunctionDetails: '',
    peOrVentureAdvisorDetails: '',
    interimCLevelExecutiveDetails: '',
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentAccountIndex(null);
  };

  const handleFormReset = (values) => {
    // console.log(values);
  };

  const handleChange = (value) => {
    if (value) {
      setCurrentValue((prev) => [...prev, value]);
    } else {
      setCurrentValue((prev) => prev.filter((item) => item !== value));
    }
  };

  const handleNext = () => {};

  const onSubmit = (values) => {
    setCreatingAccount(true);
    // console.log(values);
    if (showCreateAccount === 'new' && !showWizard) {
      dispatch(
        createAccount(
          {
            companyName: values.companyName,
            description: '',
            createCompanyProfile: true,
            title: '',
            personRequestDto: {
              contacts: [
                {
                  type: 'email',
                  value: formik.values.primaryContactEmail,
                },
              ],
              designation: formik.values.primaryContactDesignation,
              links: [
                {
                  link: formik.values.primaryContactLinkedInurl,
                  type: 'linkedin',
                },
              ],
              name: formik.values.primaryContactName,
              notes: formik.values.additionalNotes,
              organizationLevel: formik.values.organizationLevel,
              relationships: [],
              type: 'prospect',
            },
          },
          (error) => {
            formik.resetForm();
            if (error) {
              dispatch(
                showAlert({
                  message: 'Failed to create account. Please try again later!',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            } else {
              dispatch(
                getAccounts(10, null, (companies) => {
                  setCreatingAccount(false);
                  setShowCreateAccount(null);
                  setShowWizard(companies[0]);
                  setCurrentWizardStepIndex((prev) => prev + 1);
                }),
              );
              dispatch(
                showAlert({
                  message: 'Account created successfully',
                  showCross: true,
                  title: null,
                  type: 'success',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            }
          },
        ),
      );
    } else {
      dispatch(
        updateAccountDetails(
          showCreateAccount?.id || showWizard?.id,
          {
            companyName: values.companyName,
            contactEmail: values.primaryContactEmail,
            contactName: values.primaryContactName,
            description: values.description,
            title: values.title,
          },
          (accountId) => {
            setCreatingAccount(false);
            setShowCreateAccount(null);
            // if (showWizard) {
            //   setCurrentWizardStepIndex((prev) => prev + 1);
            // }
            formik.resetForm();
            dispatch({
              type: 'UPDATE_ACCOUNT_DETAILS',
              payload: {
                id: showCreateAccount?.id,
                data: {
                  companyName: values.companyName,
                  contactEmail: values.primaryContactEmail,
                  contactName: values.primaryContactName,
                  description: values.description,
                  title: values.title,
                },
              },
            });
            dispatch(
              showAlert({
                message: 'Account details updated successfully',
                showCross: true,
                title: null,
                type: 'success',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          },
          () => {
            dispatch(
              showAlert({
                message:
                  'Failed to update account details. Please try again later!',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          },
        ),
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const profileFormSubmit = (values) => {
    console.log(values);
  };

  const formikProfile = useFormik({
    initialValues: profileInitialValues,
    validationSchema: profileValidationSchema,
    onSubmit: profileFormSubmit,
  });

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  // const handleListScroll = (e, paginate) => {
  //   if (
  //     (e.target.scrollTop + e.target.clientHeight + 1 >=
  //       e.target.scrollHeight &&
  //       !fetching &&
  //       !fetchingPagination &&
  //       accounts &&
  //       accounts.length % 10 === 0 &&
  //       emptyList === false) ||
  //     paginate
  //   ) {
  //     dispatch(getAccounts(10, lastSeen, () => {}));
  //   }
  // };

  const handleTeamMemberListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !allTeamMembers?.fetching &&
        !allTeamMembers?.fetchingPagination &&
        allTeamMembers?.members &&
        allTeamMembers?.members.length % 25 === 0 &&
        allTeamMembers?.emptyList === false) ||
      paginate
    ) {
      dispatch(
        getAllTeamMembers(
          null,
          null,
          false,
          0,
          25,
          allTeamMembers?.lastSeen,
          () => {},
        ),
      );
    }
  };

  useEffect(() => {
    if (prospects && prospects.length > 0) {
      setProspectMembers(
        prospects
          ?.filter((pr) => pr?.type === 'prospect')
          ?.sort((a, b) => {
            if (a?.createdOn > b?.createdOn) {
              return 1;
            } else if (a?.createdOn < b?.createdOn) {
              return -1;
            } else {
              return 0;
            }
          }),
      );
    } else {
      setProspectMembers([]);
    }
  }, [prospects]);

  useEffect(() => {
    if (showManagePermissions) {
      dispatch(getAllTeamMembers(null, null, false, 0, 25, null, (data) => {}));
    }
  }, [showManagePermissions]);

  useEffect(() => {
    if (showManagePermissions) {
      if (allTeamMembers?.members && allTeamMembers?.members.length > 0) {
        setUserPermissionMap(
          allTeamMembers?.members?.reduce((acc, curr) => {
            if (!acc[curr?.id])
              acc[curr?.id] = {
                read:
                  showManagePermissions?.userPermissionMap?.[curr?.id]?.read ||
                  false,
                write:
                  showManagePermissions?.userPermissionMap?.[curr?.id]?.write ||
                  false,
              };
            return acc;
          }, {}),
        );
      }
    }
  }, [allTeamMembers?.members, showManagePermissions]);

  useEffect(() => {
    setTeamMembers((prev) => {
      return [
        ...(membersByOrgId
          ?.filter(
            (m) =>
              m?.type === 'existing_team_member' ||
              m?.type === 'non_existing_team_member',
          )
          ?.map((m) => {
            return {
              name: m?.name,
              userId: m?.id,
              email:
                m?.contacts?.find((obj) => obj?.type === 'email')?.value || '',
              designation: m?.designation,
              linkedinUrl:
                m?.links?.find((obj) => obj?.type === 'linkedin')?.link || '',

              type: 'non_existing_team_member',
            };
          }) || []),
        ...(members
          ?.filter(
            (m) =>
              !membersByOrgId ||
              !membersByOrgId?.map((mm) => mm?.id)?.includes(m?.id),
          )
          ?.map((m) => {
            return {
              name: m?.fullName,
              userId: m?.id,
              email: m?.email,
              designation: '',
              linkedinUrl: '',
              type: 'existing_team_member',
            };
          }) || []),
      ];
    });
  }, [members, membersByOrgId]);

  useEffect(() => {
    if (!showBackButtonOnTopbar) {
      setViewType('list');
      setCurrentAccount(null);
      formikProfile.resetForm();
      setActivePositionPoint(0);
      setActiveStep(0);
    }
  }, [showBackButtonOnTopbar]);

  useEffect(() => {
    if (
      (viewType === 'manage' ||
        viewType === 'view' ||
        viewType === 'orgchart') &&
      currentAccount
    ) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: true,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            title: currentAccount?.companyName,
            tab: viewType,
          },
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    }
  }, [viewType, currentAccount]);

  useEffect(() => {
    if (currentContextData && currentContextData?.tab) {
      setViewType(currentContextData?.tab);
    } else {
      setCurrentAccount(null);
      setViewType('list');
    }
  }, [currentContextData]);

  useEffect(() => {
    if (showCreateAccount && showCreateAccount !== 'new') {
      formik.setValues({
        title: showCreateAccount?.title,
        description: showCreateAccount?.description,
        companyName: showCreateAccount?.companyName,
        primaryContactName:
          showCreateAccount?.customFields?.primaryContact?.name,
        primaryContactEmail: showCreateAccount?.customFields?.primaryContact?.contacts?.find(
          (c) => c?.type === 'email',
        )?.value,
        primaryContactDesignation:
          showCreateAccount?.customFields?.primaryContact?.designation,
        primaryContactLinkedInurl: showCreateAccount?.customFields?.primaryContact?.links?.find(
          (ln) => ln?.type === 'linkedin',
        )?.link,
        organizationLevel:
          showCreateAccount?.customFields?.primaryContact?.organizationLevel,
        additionalNotes: showCreateAccount?.customFields?.primaryContact?.notes,
      });
    }
    if (showWizard && showWizard !== 'new') {
      formik.setValues({
        title: showWizard?.title,
        description: showWizard?.description,
        companyName: showWizard?.companyName,
        primaryContactName: showWizard?.customFields?.primaryContact?.name,
        primaryContactEmail: showWizard?.customFields?.primaryContact?.contacts?.find(
          (c) => c?.type === 'email',
        )?.value,
        primaryContactDesignation:
          showWizard?.customFields?.primaryContact?.designation,
        primaryContactLinkedInurl: showWizard?.customFields?.primaryContact?.links?.find(
          (ln) => ln?.type === 'linkedin',
        )?.link,
        organizationLevel:
          showWizard?.customFields?.primaryContact?.organizationLevel,
        additionalNotes: showWizard?.customFields?.primaryContact?.notes,
      });
    }
  }, [showCreateAccount, showWizard]);

  // useEffect(() => {
  //   if (currentAccount && currentAccount?.id) {
  //     if (prospectMembers && prospectMembers.length > 0) {
  //       setVelocityQuestions(
  //         prospectMembers?.[activeVelocityProspect]
  //           ?.velocityQuestionAnswers || [
  //           {
  //             question: 'How regularly do you meet / speak with the user(s)?',
  //             answer: '',
  //           },
  //           {
  //             question: 'How regularly meetings get canceled?',
  //             answer: '',
  //           },
  //           {
  //             question:
  //               'Does the buyer most times provide required information?',
  //             answer: '',
  //           },
  //           {
  //             question:
  //               'Has the buying process been defined and agreed upon for completing the transaction?',
  //             answer: '',
  //           },
  //           {
  //             question:
  //               'Has the buying and selling process been aligned and agreed upon?',
  //             answer: '',
  //           },
  //         ],
  //       );
  //     }
  //   }
  // }, [currentAccount, prospectMembers, activeVelocityProspect]);

  // useEffect(() => {
  //   if (currentAccount && currentAccount?.id) {
  //     dispatch(
  //       getCompanyProfileById(currentAccount?.id, (data) => {
  //         setNumberOfProspects(data?.customFields?.persons?.length || 0);
  //         setNumberOfDeals(data?.customFields?.deals?.length || 0);
  //         setDealCloseExpectedDate(Number(data?.dealDueDate) || '');
  //         setDealDueDateNotes(data?.dealDueDateNotes || '');
  //         setSolutionProvideDeadline(Number(data?.solutionDueDate) || '');
  //         setSolutionDueDateNotes(data?.solutionDueDateNotes || '');
  //         formikProfile.setValues({
  //           industrySegment: data?.industrySegment || '',
  //           size: data?.size || '',
  //           location: data?.location || '',
  //           fundingSources: data?.fundingSource || [],
  //           lengthInBusiness: data?.lengthOfBusiness || '',
  //           dealType: data?.dealType || '',
  //           stageOfGrowth: data?.stageOfGrowth || '',
  //           technologyAdoptionProfile: data?.technologyAdoptionProfile || '',
  //           hasManagementConsultant: data?.hasManagementConsultant || false,
  //           hasInterimCLevelExecutive: data?.hasInterimCLevelExecutive || false,
  //           hasPeOrVentureAdvisor: data?.hasPeOrVentureAdvisor || false,
  //           outsourceFunction: data?.outsourceFunction || false,
  //           managementConsultantDetails:
  //             data?.managementConsultantDetails || '',
  //           outsourcedFunctionDetails: data?.outsourcedFunctionDetails || '',
  //           peOrVentureAdvisorDetails: data?.peOrVentureAdvisorDetails || '',
  //           interimCLevelExecutiveDetails:
  //             data?.interimCLevelExecutiveDetails || '',
  //         });
  //         setProcessQA(
  //           data?.questionAnswers
  //             ? data?.questionAnswers?.map((qa) => {
  //                 if (qa?.question === 'What is your buying process?') {
  //                   return {
  //                     ...qa,
  //                     type: 'select',
  //                     options: [
  //                       'Ad Hoc',
  //                       'Repeatable',
  //                       'Defined',
  //                       'Managed',
  //                       'Optimized',
  //                     ],
  //                   };
  //                 } else {
  //                   return qa;
  //                 }
  //               })
  //             : defaultProcessQA,
  //         );
  //         setCurrentValue(
  //           data?.companyValues
  //             ?.filter((value) => value?.checked)
  //             ?.map((value) => value?.value) || [],
  //         );
  //         setCurrentValueMap(
  //           data?.companyValues
  //             ? data?.companyValues?.reduce((acc, curr) => {
  //                 if (!acc[curr?.value]) {
  //                   acc[curr?.value] = curr?.notes;
  //                 }
  //                 return acc;
  //               }, {})
  //             : {
  //                 Efficiency: '',
  //                 Effectiveness: '',
  //                 'Risk Mitigation': '',
  //                 'Strategic Growth': '',
  //                 'Customer Experience': '',
  //               },
  //         );
  //         setCurrentValues(data?.companyValues);
  //       }),
  //     );
  //   }
  // }, [currentAccount]);

  // useEffect(() => {
  //   setActiveSecondaryStep(0);
  // }, [activeStep]);

  useEffect(() => {
    if (resetQuestionFrameworkStep) {
      setActiveStep(0);
    }
  }, [resetQuestionFrameworkStep]);

  useEffect(() => {
    if (activeStep === 0) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'resetQuestionFrameworkStep',
          value: false,
        },
      });
    }
  }, [activeStep]);

  useEffect(() => {
    if (
      currentAccount &&
      (viewType === 'manage' || viewType === 'view') &&
      questionFramework &&
      questionFramework.length > 0 &&
      questionFrameworkSteps &&
      questionFrameworkSteps.length > 0
    ) {
      setSteps(
        questionFramework?.find(
          (sc) =>
            sc?.sectionType ===
            questionFrameworkSteps?.[activeQuestionFrameworkStep]?.sectionType,
        )?.sections || [],
      );
    }
  }, [
    currentAccount,
    questionFramework,
    viewType,
    questionFrameworkSteps,
    activeQuestionFrameworkStep,
  ]);

  useEffect(() => {
    setListOfPages(questionFramework?.[activeQuestionFrameworkStep]?.sections);
  }, [activeQuestionFrameworkStep, questionFramework]);

  useEffect(() => {
    if (currentAccount && (viewType === 'view' || viewType === 'manage')) {
      setFetchingQuestionFrameworkAndAnswers(true);
      dispatch(
        getQuestionFramework(currentAccount?.id, (data) => {
          dispatch({
            type: 'UPDATE_UI_STATE',
            payload: {
              key: 'questionFrameworkSteps',
              value: data?.questions?.map((topSection) => {
                return {
                  id: topSection?.id,
                  sectionType: topSection?.sectionType,
                  title: topSection?.title,
                };
              }),
            },
          });
          setQuestionFramework(data?.questions);
          setListOfGroups(data?.questions);
          setSelectedAnswers(data?.selectedOptions);
          setSectionsData(data?.sectionData);
          setTimeout(() => {
            setFetchingQuestionFrameworkAndAnswers(false);
          }, 1000);
        }),
      );
    } else {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'activeQuestionFrameworkStep',
          value: 0,
        },
      });
      setShowHelpSidebar(null);
    }
  }, [currentAccount, viewType]);

  useEffect(() => {
    dispatch(getAccounts(10, null, (data) => {}));
    dispatch(
      getSectionsByLevel('null', (data) => {
        console.log(data);
        setExpandedSections(
          data?.reduce((acc, curr) => {
            acc[curr?.id] = false;
            return acc;
          }, {}),
        );
      }),
    );
    dispatch(
      getHelpSections(null, (data) => {
        // console.log(data);
        setHelpSections(data);
      }),
    );
    return () => {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    };
  }, []);

  // console.log(selectedAnswers);
  // console.log(listOfGroups);
  // console.log(listOfPages);
  // console.log(expandedSections);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight:
          isLoggedIn && !uId ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs: 'start',
            sm: 'start',
            md: 'start',
          }}
          justifyContent={{
            xs: 'start',
            sm: 'start',
            md: 'flex-start',
          }}
          height={1}
          gap={2}
          sx={{
            borderRight: '1px solid #f1f1f1',
            minHeight: isXs ? 'auto' : 'calc(100vh - 60px)',
            padding: viewType === 'list' ? 2 : 0,
            paddingTop: viewType === 'list' ? 2 : 0,
          }}
        >
          {viewType === 'list' ? (
            <Box display={'flex'} flexDirection={'column'} gap={1} width={1}>
              <Box display={'flex'} alignItems={'center'} gap={2} mb={2}>
                <Typography>All Accounts</Typography>
                {/* {accounts?.?.userPermissionMap?.[userInfo?.id]?.write ? ( */}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  size="small"
                  onClick={() => setShowCreateAccount('new')}
                >
                  Create Account
                </Button>
                {/* ) : null} */}
              </Box>
              {fetching ? (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                >
                  <CircularProgress size={20} />
                  <Typography variant="body1" color={'text.primary'} mt={4}>
                    Loading ...
                  </Typography>
                </Box>
              ) : accounts && accounts?.length > 0 ? (
                <Grid container spacing={2}>
                  {accounts?.map((account, index) => (
                    <Grid item xs={12} key={account?.id} sm={6} md={4} lg={4}>
                      <Card
                        sx={{
                          padding: 2,
                          position: 'relative',
                          paddingBottom: 12,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={'bold'}
                          color={'text.secondary'}
                        >
                          {account?.companyName}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.secondary'}
                        >
                          {account?.customFields?.primaryContact?.name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.secondary'}
                        >
                          {
                            account?.customFields?.primaryContact?.contacts?.find(
                              (obj) => obj?.type === 'email',
                            )?.value
                          }
                        </Typography>
                        <Box
                          sx={{
                            position: 'absolute',
                            bottom: 18,
                            left: 18,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            startIcon={<Explore sx={{ fontSize: 16 }} />}
                            sx={{
                              padding: '4px 8px',
                            }}
                            onClick={() => {
                              setViewType('manage');
                              setCurrentAccount(account);
                            }}
                          >
                            Overview
                          </Button>
                        </Box>
                        <Typography
                          variant="caption"
                          color={'text.secondary'}
                          sx={{
                            position: 'absolute',
                            bottom: 18,
                            right: 18,
                          }}
                        >
                          Created On{' '}
                          {moment(account?.createOn).format('DD MMM YYYY')}
                        </Typography>
                        {account?.userPermissionMap?.[userInfo?.id]?.write ? (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              setAnchorEl(e.target);
                              setCurrentAccountIndex(index);
                            }}
                            sx={{
                              position: 'absolute',
                              top: 5,
                              right: 5,
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                        ) : null}
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={
                            Boolean(anchorEl) && currentAccountIndex === index
                          }
                          onClose={handleMenuClose}
                          sx={{
                            '& .MuiMenu-paper': {
                              boxShadow:
                                '0 2px 2px 2px rgba(140, 152, 164, 0.2)',
                            },
                          }}
                        >
                          {/* <MenuItem
                            disabled
                            onClick={() => {
                              setShowCreateAccount(account);
                              handleMenuClose();
                            }}
                          >
                            Update Account Details
                          </MenuItem> */}
                          <MenuItem
                            onClick={() => {
                              setShowManagePermissions(account);
                              handleMenuClose();
                            }}
                          >
                            Manage Members Permissions
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setShowConfirmDeleteDialog(account?.id);
                              setAnchorEl(null);
                            }}
                          >
                            Delete Account
                          </MenuItem>
                        </Menu>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  gap={2}
                >
                  <Typography variant="body1" color={'text.secondary'} mt={4}>
                    No accounts to show here yet.
                  </Typography>
                </Box>
              )}
            </Box>
          ) : viewType === 'manage' ? (
            <Box display={'flex'} flexDirection={'column'} width={1}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  // justifyContent={'center'}
                  p={'8px 16px'}
                  borderBottom={'1px solid #d3d3d3'}
                  width={1}
                >
                  {steps && steps?.length > 0
                    ? steps.map((step, index) => {
                        return (
                          <Box
                            key={step?.title}
                            sx={{
                              padding: '8px 16px',
                              // border: '1px solid #d3d3d3',
                              borderTopLeftRadius: index === 0 ? 4 : 0,
                              borderBottomLeftRadius: index === 0 ? 4 : 0,
                              borderTopRightRadius:
                                index === steps?.length - 1 ? 4 : 0,
                              borderBottomRightRadius:
                                index === steps?.length - 1 ? 4 : 0,
                              background:
                                activeStep === index
                                  ? theme.palette.primary.main
                                  : '#f1f1f1',
                              color:
                                activeStep === index
                                  ? '#fff'
                                  : theme.palette.primary.main,
                              borderRight:
                                index !== steps?.length - 1
                                  ? '1px solid #d3d3d3'
                                  : 'none',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              if (step === 'Org Chart') {
                                setActiveStep(4);
                              } else {
                                setActiveStep(index);
                              }
                            }}
                          >
                            <Typography>
                              {capitalizeText(
                                step?.title?.split('_')?.join(' '),
                              )}
                            </Typography>
                          </Box>
                        );
                      })
                    : null}
                </Box>
              </Box>
              <Box display={'flex'}>
                <Box
                  flex={5}
                  sx={{
                    minHeight: 'calc(100vh - 120px)',
                    maxHeight: 'calc(100vh - 120px)',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: 2,
                      display: 'flex',
                      alignItems: 'stretch',
                      minHeight: 'calc(100vh - 180px)',
                      maxHeight: 'calc(100vh - 180px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                      scrollBehavior: 'smooth',
                      padding: 2,
                      flexDirection: 'column',
                      gap: 2,
                      paddingTop: 1,
                    }}
                  >
                    {fetchingQuestionFrameworkAndAnswers ? (
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        gap={2}
                        width={1}
                        height={1}
                      >
                        <CircularProgress size={20} />
                        <Typography>fetching ...</Typography>
                      </Box>
                    ) : (
                      <>
                        {questionFramework?.[activeQuestionFrameworkStep]
                          ?.sections?.[activeStep]?.sections &&
                        questionFramework?.[activeQuestionFrameworkStep]
                          ?.sections?.[activeStep]?.sections?.length > 0 ? (
                          questionFramework?.[
                            activeQuestionFrameworkStep
                          ]?.sections?.[activeStep]?.sections?.map(
                            (discipline, index) => (
                              <Box
                                key={discipline?.id}
                                sx={{
                                  position: 'relative',
                                  borderRadius: 1,
                                  padding: 1,
                                }}
                                id={discipline?.title}
                              >
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={1}
                                  onMouseEnter={() =>
                                    setShowNotRelevantCheckbox(discipline?.id)
                                  }
                                  onMouseLeave={() =>
                                    setShowNotRelevantCheckbox(null)
                                  }
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setExpandedSections((prev) => {
                                        return {
                                          ...prev,
                                          [discipline?.id]: !prev?.[
                                            discipline?.id
                                          ],
                                        };
                                      });
                                    }}
                                  >
                                    {expandedSections?.[discipline?.id] ? (
                                      <ArrowDropDown />
                                    ) : (
                                      <ArrowRight />
                                    )}
                                    <Typography
                                      variant="body1"
                                      fontWeight={'bold'}
                                      color={'text.secondary'}
                                      sx={{
                                        // background: '#fff',
                                        color: sectionsData?.[discipline?.id]
                                          ?.notRelevant
                                          ? '#aaa'
                                          : theme.palette.text.secondary,
                                      }}
                                    >
                                      {capitalizeText(
                                        discipline?.title
                                          ?.split('_')
                                          ?.join(' '),
                                      )}
                                    </Typography>
                                  </Box>
                                  {discipline?.helpSectionId ? (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title="Open Help Section"
                                    >
                                      <HelpOutline
                                        sx={{
                                          cursor: 'pointer',
                                          color: theme.palette.primary.main,
                                          fontSize: 20,
                                        }}
                                        onClick={() => {
                                          setShowHelpSidebar(
                                            helpSections?.find(
                                              (section) =>
                                                section?.id ===
                                                discipline?.helpSectionId,
                                            ),
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  ) : null}
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size="small"
                                        value={
                                          sectionsData?.[discipline?.id]
                                            ?.notRelevant
                                        }
                                        defaultChecked={
                                          sectionsData?.[discipline?.id]
                                            ?.notRelevant
                                        }
                                        name="notRelevant"
                                        icon={<CheckBoxOutlineBlank />}
                                        checkedIcon={<DisabledByDefault />}
                                        sx={{
                                          padding: '4px',
                                        }}
                                      />
                                    }
                                    sx={{
                                      border: '1px solid #d3d3d3',
                                      marginLeft: 1,
                                      paddingRight: 1,
                                      visibility:
                                        showNotRelevantCheckbox ===
                                          discipline?.id ||
                                        sectionsData?.[discipline?.id]
                                          ?.notRelevant
                                          ? 'visible'
                                          : 'hidden',
                                      '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
                                        color: theme.palette.text.secondary,
                                      },
                                    }}
                                    label={
                                      <Typography
                                        variant="caption"
                                        color={'text.secondary'}
                                      >
                                        Not relevant
                                      </Typography>
                                    }
                                    onChange={(e) => {
                                      dispatch(
                                        markSectionNonRelevant(
                                          currentAccount?.id,
                                          [
                                            {
                                              ...sectionsData?.[discipline?.id],
                                              notRelevant: !sectionsData?.[
                                                discipline?.id
                                              ]?.notRelevant,
                                              sectionId: discipline?.id,
                                            },
                                          ],
                                          (data) => {
                                            console.log(data);
                                            setSectionsData((prev) => {
                                              return {
                                                ...prev,
                                                [discipline?.id]: {
                                                  ...prev?.[discipline?.id],
                                                  notRelevant: !sectionsData?.[
                                                    discipline?.id
                                                  ]?.notRelevant,
                                                },
                                              };
                                            });
                                          },
                                        ),
                                      );
                                    }}
                                  />
                                </Box>
                                {expandedSections?.[discipline?.id] ? (
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                    paddingTop={1}
                                    marginLeft={1}
                                  >
                                    {discipline?.sectionType === 'power' ? (
                                      <ProspectMembers
                                        prospects={prospects}
                                        prospectMembers={prospectMembers}
                                        teamMembers={teamMembers}
                                        currentAccount={currentAccount}
                                        setTeamMembers={setTeamMembers}
                                        editMode={true}
                                      />
                                    ) : discipline?.sectionType ===
                                      'deal_activity' ? (
                                      <DealActivities
                                        currentAccount={currentAccount}
                                        editMode={true}
                                      />
                                    ) : null}
                                    <Box
                                      sx={{
                                        borderRadius: 1,
                                        marginLeft: 1,
                                      }}
                                    >
                                      {discipline?.sections &&
                                      discipline?.sections?.length > 0 ? (
                                        discipline?.sections?.map(
                                          (element, index) => {
                                            return (
                                              <Box
                                                key={element?.id}
                                                sx={{
                                                  position: 'relative',
                                                  borderRadius: 1,
                                                  padding: 1,

                                                  border:
                                                    highlightedSectionId ===
                                                    element?.id
                                                      ? `3px solid ${theme.palette.primary.main}`
                                                      : 'none',
                                                  // background:
                                                  //   highlightedSectionId ===
                                                  //   element?.id
                                                  //     ? '#f1f1f1'
                                                  //     : '#fff',
                                                }}
                                                id={element?.title}
                                              >
                                                <Box
                                                  display={'flex'}
                                                  alignItems={'center'}
                                                  gap={1}
                                                  onMouseEnter={() =>
                                                    setShowNotRelevantCheckbox(
                                                      element?.id,
                                                    )
                                                  }
                                                  onMouseLeave={() =>
                                                    setShowNotRelevantCheckbox(
                                                      null,
                                                    )
                                                  }
                                                >
                                                  <Box
                                                    sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                      setExpandedSections(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            [element?.id]: !prev?.[
                                                              element?.id
                                                            ],
                                                          };
                                                        },
                                                      );
                                                    }}
                                                  >
                                                    {expandedSections?.[
                                                      element?.id
                                                    ] ? (
                                                      <ArrowDropDown />
                                                    ) : (
                                                      <ArrowRight />
                                                    )}
                                                    <Typography
                                                      variant="body2"
                                                      fontWeight={'bold'}
                                                      color={'text.secondary'}
                                                      sx={{
                                                        background: '#fff',
                                                        color:
                                                          sectionsData?.[
                                                            element?.id
                                                          ]?.notRelevant ||
                                                          sectionsData?.[
                                                            discipline?.id
                                                          ]?.notRelevant
                                                            ? '#aaa'
                                                            : theme.palette.text
                                                                .secondary,
                                                      }}
                                                    >
                                                      {capitalizeText(
                                                        element?.title
                                                          ?.split('_')
                                                          ?.join(' '),
                                                      )}
                                                    </Typography>
                                                  </Box>
                                                  {element?.helpSectionId ? (
                                                    <Tooltip
                                                      arrow
                                                      placement="top"
                                                      title="Open Help Section"
                                                    >
                                                      <HelpOutline
                                                        sx={{
                                                          cursor: 'pointer',
                                                          color:
                                                            theme.palette
                                                              .primary.main,
                                                          fontSize: 20,
                                                        }}
                                                        onClick={() => {
                                                          setShowHelpSidebar(
                                                            helpSections?.find(
                                                              (section) =>
                                                                section?.id ===
                                                                element?.helpSectionId,
                                                            ),
                                                          );
                                                        }}
                                                      />
                                                    </Tooltip>
                                                  ) : null}
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        size="small"
                                                        value={
                                                          sectionsData?.[
                                                            element?.id
                                                          ]?.notRelevant
                                                        }
                                                        defaultChecked={
                                                          sectionsData?.[
                                                            element?.id
                                                          ]?.notRelevant
                                                        }
                                                        name="notRelevant"
                                                        icon={
                                                          <CheckBoxOutlineBlank />
                                                        }
                                                        checkedIcon={
                                                          <DisabledByDefault />
                                                        }
                                                        sx={{
                                                          padding: '4px',
                                                        }}
                                                      />
                                                    }
                                                    sx={{
                                                      border:
                                                        '1px solid #d3d3d3',
                                                      marginLeft: 1,
                                                      paddingRight: 1,
                                                      visibility:
                                                        showNotRelevantCheckbox ===
                                                          element?.id ||
                                                        sectionsData?.[
                                                          element?.id
                                                        ]?.notRelevant
                                                          ? 'visible'
                                                          : 'hidden',
                                                      '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
                                                        color:
                                                          theme.palette.text
                                                            .secondary,
                                                      },
                                                    }}
                                                    label={
                                                      <Typography
                                                        variant="caption"
                                                        color={'text.secondary'}
                                                      >
                                                        Not relevant
                                                      </Typography>
                                                    }
                                                    onChange={(e) => {
                                                      dispatch(
                                                        markSectionNonRelevant(
                                                          currentAccount?.id,
                                                          [
                                                            {
                                                              ...sectionsData?.[
                                                                element?.id
                                                              ],
                                                              notRelevant: !sectionsData?.[
                                                                element?.id
                                                              ]?.notRelevant,
                                                              sectionId:
                                                                element?.id,
                                                            },
                                                          ],
                                                          (data) => {
                                                            console.log(data);
                                                            setSectionsData(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  [element?.id]: {
                                                                    ...prev?.[
                                                                      element
                                                                        ?.id
                                                                    ],
                                                                    notRelevant: !sectionsData?.[
                                                                      element
                                                                        ?.id
                                                                    ]
                                                                      ?.notRelevant,
                                                                  },
                                                                };
                                                              },
                                                            );
                                                          },
                                                        ),
                                                      );
                                                    }}
                                                  />
                                                </Box>
                                                {expandedSections?.[
                                                  element?.id
                                                ] ? (
                                                  <Box
                                                    display={'flex'}
                                                    flexDirection={'column'}
                                                    gap={2}
                                                    paddingTop={1}
                                                    marginLeft={2}
                                                  >
                                                    {backReferenceSections &&
                                                    backReferenceSections?.length >
                                                      0 &&
                                                    highlightedSectionId ===
                                                      element?.id
                                                      ? backReferenceSections?.map(
                                                          (ref) => {
                                                            return (
                                                              <Box
                                                                key={ref?.id}
                                                                component={'a'}
                                                                href={`#${ref?.title}`}
                                                                dispaly={'flex'}
                                                                alignItems={
                                                                  'center'
                                                                }
                                                                gap={1}
                                                                onClick={(
                                                                  e,
                                                                ) => {
                                                                  setActiveStep(
                                                                    ref?.activeStep,
                                                                  );
                                                                  dispatch({
                                                                    type:
                                                                      'UPDATE_UI_STATE',
                                                                    payload: {
                                                                      key:
                                                                        'activeQuestionFrameworkStep',
                                                                      value:
                                                                        ref?.activeQuestionFrameworkStep,
                                                                    },
                                                                  });
                                                                  setTimeout(
                                                                    () => {
                                                                      setBackReferenceSections(
                                                                        (
                                                                          prev,
                                                                        ) =>
                                                                          prev?.filter(
                                                                            (
                                                                              br,
                                                                            ) =>
                                                                              br?.id !==
                                                                              ref?.id,
                                                                          ),
                                                                      );
                                                                      setHighlightedSectionId(
                                                                        ref?.id,
                                                                      );
                                                                    },
                                                                    200,
                                                                  );
                                                                }}
                                                              >
                                                                <ArrowBack
                                                                  sx={{
                                                                    fontSize: 18,
                                                                  }}
                                                                />
                                                                <span
                                                                  style={{
                                                                    fontSize: 16,
                                                                  }}
                                                                >
                                                                  {capitalizeText(
                                                                    ref?.title
                                                                      ?.split(
                                                                        '_',
                                                                      )
                                                                      ?.join(
                                                                        ' ',
                                                                      ),
                                                                  )}
                                                                </span>
                                                              </Box>
                                                            );
                                                          },
                                                        )
                                                      : null}
                                                    {element?.questions &&
                                                    element?.questions?.length >
                                                      0 ? (
                                                      element?.questions?.map(
                                                        (qa, index) => {
                                                          return (
                                                            <QnAs
                                                              key={qa?.id}
                                                              question={qa}
                                                              questionIndex={
                                                                index
                                                              }
                                                              currentAccount={
                                                                currentAccount
                                                              }
                                                              setQuestionFramework={
                                                                setQuestionFramework
                                                              }
                                                              selectedOptions={
                                                                selectedAnswers?.[
                                                                  qa?.id
                                                                ]?.options
                                                              }
                                                              selectedAnswerText={
                                                                selectedAnswers?.[
                                                                  qa?.id
                                                                ]?.answerText
                                                              }
                                                              tags={
                                                                selectedAnswers?.[
                                                                  qa?.id
                                                                ]?.tags
                                                              }
                                                              notes={
                                                                selectedAnswers?.[
                                                                  qa?.id
                                                                ]?.notes
                                                              }
                                                              selectedAnswers={
                                                                selectedAnswers
                                                              }
                                                              setSelectedAnswers={
                                                                setSelectedAnswers
                                                              }
                                                              editMode={
                                                                viewType ===
                                                                'view'
                                                                  ? false
                                                                  : true
                                                              }
                                                              setHighlightedSectionId={
                                                                setHighlightedSectionId
                                                              }
                                                              section={element}
                                                              setBackReferenceSections={
                                                                setBackReferenceSections
                                                              }
                                                              activeStep={
                                                                activeStep
                                                              }
                                                              activeSecondaryStep={
                                                                activeSecondaryStep
                                                              }
                                                              activeQuestionFrameworkStep={
                                                                activeQuestionFrameworkStep
                                                              }
                                                              setActiveStep={
                                                                setActiveStep
                                                              }
                                                              listOfGroups={
                                                                listOfGroups
                                                              }
                                                              listOfPages={
                                                                listOfPages
                                                              }
                                                              setExpandedSections={
                                                                setExpandedSections
                                                              }
                                                              questionFramework={
                                                                questionFramework
                                                              }
                                                              showNotRelevantCheckbox={
                                                                showNotRelevantCheckbox
                                                              }
                                                              setShowNotRelevantCheckbox={
                                                                setShowNotRelevantCheckbox
                                                              }
                                                              notRelevant={
                                                                sectionsData?.[
                                                                  element?.id
                                                                ]
                                                                  ?.notRelevant ||
                                                                sectionsData?.[
                                                                  discipline?.id
                                                                ]
                                                                  ?.notRelevant ||
                                                                selectedAnswers?.[
                                                                  qa?.id
                                                                ]?.notRelevant
                                                              }
                                                              setShowHelpSidebar={
                                                                setShowHelpSidebar
                                                              }
                                                              helpSections={
                                                                helpSections
                                                              }
                                                            />
                                                          );
                                                        },
                                                      )
                                                    ) : (
                                                      <Box
                                                        sx={{
                                                          width: 1,
                                                          display: 'flex',
                                                          flexDirection:
                                                            'column',
                                                          gap: 2,
                                                          justifyContent:
                                                            'center',
                                                          // alignItems: 'center',
                                                        }}
                                                      >
                                                        <Typography
                                                          variant="body2"
                                                          color={
                                                            'text.secondary'
                                                          }
                                                          fontWeight={700}
                                                        >
                                                          -- Nothing to report
                                                          --
                                                        </Typography>
                                                      </Box>
                                                    )}
                                                  </Box>
                                                ) : null}
                                              </Box>
                                            );
                                          },
                                        )
                                      ) : (
                                        <Box
                                          sx={{
                                            width: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                            justifyContent: 'center',
                                            // alignItems: 'center',
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            color={'text.secondary'}
                                            // fontWeight={700}
                                          >
                                            -- Nothing to report --
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Box>
                            ),
                          )
                        ) : (
                          <Box
                            sx={{
                              width: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 2,
                              justifyContent: 'center',
                              alignItems: 'center',
                              minHeight: 300,
                            }}
                          >
                            <Typography
                              variant="body2"
                              color={'text.secondary'}
                              fontWeight={700}
                            >
                              -- Nothing to report --
                            </Typography>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    padding={'8px 16px'}
                    justifyContent={'space-between'}
                    borderTop={'1px solid #d3d3d3'}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<ArrowBack />}
                      disabled={
                        activeQuestionFrameworkStep === 0 && activeStep === 0
                      }
                      onClick={() => {
                        if (activeStep === 0) {
                          dispatch({
                            type: 'UPDATE_UI_STATE',
                            payload: {
                              key: 'activeQuestionFrameworkStep',
                              value: activeQuestionFrameworkStep - 1,
                            },
                          });
                          setActiveStep(
                            questionFramework?.[activeQuestionFrameworkStep - 1]
                              ?.sections?.length - 1,
                          );
                        } else {
                          setActiveStep((prev) => prev - 1);
                        }
                      }}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={
                        activeQuestionFrameworkStep ===
                          listOfGroups?.length - 1 &&
                        activeStep === listOfPages?.length - 1
                      }
                      size="small"
                      endIcon={<ArrowForward />}
                      onClick={() => {
                        if (activeStep === listOfPages?.length - 1) {
                          setActiveStep(0);
                          dispatch({
                            type: 'UPDATE_UI_STATE',
                            payload: {
                              key: 'activeQuestionFrameworkStep',
                              value: activeQuestionFrameworkStep + 1,
                            },
                          });
                        } else {
                          setActiveStep((prev) => prev + 1);
                        }
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
                {showHelpSidebar ? (
                  <Box
                    flex={3}
                    sx={{
                      borderLeft: '1px solid #d3d3d3',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      minHeight: 'calc(100vh - 120px)',
                      maxHeight: 'calc(100vh - 120px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                    }}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      gap={2}
                      p={2}
                      borderBottom={'1px solid #d3d3d3'}
                    >
                      <KeyboardDoubleArrowRight
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setShowHelpSidebar(null)}
                      />
                      <Typography>{showHelpSidebar?.title}</Typography>
                    </Box>
                    <Box p={2}>
                      {showHelpSidebar?.customFields?.url ? (
                        <VideoPreview
                          video={showHelpSidebar?.customFields?.url}
                          onDurationChange={(e) => {}}
                        />
                      ) : null}
                      {showHelpSidebar?.text ? (
                        <RichTextEditor
                          value={showHelpSidebar?.text}
                          onChange={() => {}}
                          readOnly
                        />
                      ) : null}
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
          ) : viewType === 'view' ? (
            <Box display={'flex'} flexDirection={'column'} width={1}>
              <Box
                sx={{
                  height: '100%',
                  borderRadius: 2,
                  // paddingBottom: 2,
                  minHeight: 'calc(100vh - 80px)',
                  maxHeight: 'calc(100vh - 80px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                }}
              >
                <Box
                  sx={{
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    paddingTop: 1,
                  }}
                >
                  {fetchingQuestionFrameworkAndAnswers ? (
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}
                      gap={2}
                      width={1}
                      height={1}
                    >
                      <CircularProgress size={20} />
                      <Typography>fetching ...</Typography>
                    </Box>
                  ) : (
                    <>
                      {questionFramework && questionFramework?.length > 0 ? (
                        questionFramework?.map((level, index) => (
                          <Box
                            key={level?.id}
                            sx={{
                              position: 'relative',
                              borderRadius: 1,
                              padding: 1,
                            }}
                            id={level?.title}
                          >
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              {expandedSections?.[level?.id] ? (
                                <ArrowDropDown
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setExpandedSections((prev) => {
                                      return {
                                        ...prev,
                                        [level?.id]: false,
                                      };
                                    });
                                  }}
                                />
                              ) : (
                                <ArrowRight
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setExpandedSections((prev) => {
                                      return {
                                        ...prev,
                                        [level?.id]: true,
                                      };
                                    });
                                  }}
                                />
                              )}
                              <Flag
                                sx={{
                                  color:
                                    Math.round(
                                      Number(sectionsData?.[level?.id]?.score),
                                    ) === 0
                                      ? 'red'
                                      : Math.round(
                                          Number(
                                            sectionsData?.[level?.id]?.score,
                                          ),
                                        ) === 1
                                      ? 'orange'
                                      : Math.round(
                                          Number(
                                            sectionsData?.[level?.id]?.score,
                                          ),
                                        ) === 2
                                      ? '#ffe599'
                                      : Math.round(
                                          Number(
                                            sectionsData?.[level?.id]?.score,
                                          ),
                                        ) === 3
                                      ? 'green'
                                      : Math.round(
                                          Number(
                                            sectionsData?.[level?.id]?.score,
                                          ),
                                        ) === 4 ||
                                        Math.round(
                                          Number(
                                            sectionsData?.[level?.id]?.score,
                                          ),
                                        ) === 5
                                      ? 'blue'
                                      : 'blue',
                                }}
                              />
                              <Typography
                                variant="body1"
                                fontWeight={'bold'}
                                color={'text.secondary'}
                                sx={{
                                  background: '#fff',
                                }}
                              >
                                {capitalizeText(
                                  level?.title?.split('_')?.join(' '),
                                )}
                              </Typography>
                            </Box>
                            {expandedSections?.[level?.id] ? (
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                                paddingTop={2}
                                marginLeft={1}
                              >
                                <Box
                                  sx={{
                                    borderRadius: 1,
                                    marginLeft: 1,
                                  }}
                                >
                                  {level?.sections &&
                                  level?.sections?.length > 0 ? (
                                    level?.sections?.map((domain, index) => {
                                      return (
                                        <Box
                                          key={domain?.id}
                                          sx={{
                                            position: 'relative',
                                            // border: '1px solid #d3d3d3',
                                            borderRadius: 1,
                                            padding: 1,
                                          }}
                                          id={domain?.title}
                                        >
                                          <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            gap={1}
                                          >
                                            {expandedSections?.[domain?.id] ? (
                                              <ArrowDropDown
                                                sx={{
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                  setExpandedSections(
                                                    (prev) => {
                                                      return {
                                                        ...prev,
                                                        [domain?.id]: false,
                                                      };
                                                    },
                                                  );
                                                }}
                                              />
                                            ) : (
                                              <ArrowRight
                                                sx={{
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                  setExpandedSections(
                                                    (prev) => {
                                                      return {
                                                        ...prev,
                                                        [domain?.id]: true,
                                                      };
                                                    },
                                                  );
                                                }}
                                              />
                                            )}
                                            <Flag
                                              sx={{
                                                color:
                                                  Math.round(
                                                    Number(
                                                      sectionsData?.[domain?.id]
                                                        ?.score,
                                                    ),
                                                  ) === 0
                                                    ? 'red'
                                                    : Math.round(
                                                        Number(
                                                          sectionsData?.[
                                                            domain?.id
                                                          ]?.score,
                                                        ),
                                                      ) === 1
                                                    ? 'orange'
                                                    : Math.round(
                                                        Number(
                                                          sectionsData?.[
                                                            domain?.id
                                                          ]?.score,
                                                        ),
                                                      ) === 2
                                                    ? '#ffe599'
                                                    : Math.round(
                                                        Number(
                                                          sectionsData?.[
                                                            domain?.id
                                                          ]?.score,
                                                        ),
                                                      ) === 3
                                                    ? 'green'
                                                    : Math.round(
                                                        Number(
                                                          sectionsData?.[
                                                            domain?.id
                                                          ]?.score,
                                                        ),
                                                      ) === 4 ||
                                                      Math.round(
                                                        Number(
                                                          sectionsData?.[
                                                            domain?.id
                                                          ]?.score,
                                                        ),
                                                      ) === 5
                                                    ? 'blue'
                                                    : 'blue',
                                              }}
                                            />
                                            <Typography
                                              variant="body2"
                                              fontWeight={'bold'}
                                              color={'text.secondary'}
                                              sx={{
                                                background: '#fff',
                                              }}
                                            >
                                              {capitalizeText(
                                                domain?.title
                                                  ?.split('_')
                                                  ?.join(' '),
                                              )}
                                            </Typography>
                                          </Box>
                                          {expandedSections?.[domain?.id] ? (
                                            <Box
                                              display={'flex'}
                                              flexDirection={'column'}
                                              gap={1}
                                              paddingTop={2}
                                              marginLeft={1}
                                            >
                                              {domain?.sections &&
                                              domain?.sections?.length > 0 ? (
                                                domain?.sections?.map(
                                                  (discipline, index) => (
                                                    <Box
                                                      key={discipline?.id}
                                                      sx={{
                                                        position: 'relative',
                                                        // border: '1px solid #d3d3d3',
                                                        borderRadius: 1,
                                                        padding: 1,
                                                      }}
                                                      id={discipline?.title}
                                                    >
                                                      <Box
                                                        display={'flex'}
                                                        alignItems={'center'}
                                                        gap={1}
                                                      >
                                                        {expandedSections?.[
                                                          discipline?.id
                                                        ] ? (
                                                          <ArrowDropDown
                                                            sx={{
                                                              cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                              setExpandedSections(
                                                                (prev) => {
                                                                  return {
                                                                    ...prev,
                                                                    [discipline?.id]: false,
                                                                  };
                                                                },
                                                              );
                                                            }}
                                                          />
                                                        ) : (
                                                          <ArrowRight
                                                            sx={{
                                                              cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                              setExpandedSections(
                                                                (prev) => {
                                                                  return {
                                                                    ...prev,
                                                                    [discipline?.id]: true,
                                                                  };
                                                                },
                                                              );
                                                            }}
                                                          />
                                                        )}
                                                        <Flag
                                                          sx={{
                                                            color:
                                                              Math.round(
                                                                Number(
                                                                  sectionsData?.[
                                                                    discipline
                                                                      ?.id
                                                                  ]?.score,
                                                                ),
                                                              ) === 0
                                                                ? 'red'
                                                                : Math.round(
                                                                    Number(
                                                                      sectionsData?.[
                                                                        discipline
                                                                          ?.id
                                                                      ]?.score,
                                                                    ),
                                                                  ) === 1
                                                                ? 'orange'
                                                                : Math.round(
                                                                    Number(
                                                                      sectionsData?.[
                                                                        discipline
                                                                          ?.id
                                                                      ]?.score,
                                                                    ),
                                                                  ) === 2
                                                                ? '#ffe599'
                                                                : Math.round(
                                                                    Number(
                                                                      sectionsData?.[
                                                                        discipline
                                                                          ?.id
                                                                      ]?.score,
                                                                    ),
                                                                  ) === 3
                                                                ? 'green'
                                                                : Math.round(
                                                                    Number(
                                                                      sectionsData?.[
                                                                        discipline
                                                                          ?.id
                                                                      ]?.score,
                                                                    ),
                                                                  ) === 4 ||
                                                                  Math.round(
                                                                    Number(
                                                                      sectionsData?.[
                                                                        discipline
                                                                          ?.id
                                                                      ]?.score,
                                                                    ),
                                                                  ) === 5
                                                                ? 'blue'
                                                                : 'blue',
                                                          }}
                                                        />
                                                        <Typography
                                                          variant="body2"
                                                          fontWeight={'bold'}
                                                          color={
                                                            'text.secondary'
                                                          }
                                                          sx={{
                                                            background: '#fff',
                                                          }}
                                                        >
                                                          {capitalizeText(
                                                            discipline?.title
                                                              ?.split('_')
                                                              ?.join(' '),
                                                          )}
                                                        </Typography>
                                                      </Box>
                                                      {expandedSections?.[
                                                        discipline?.id
                                                      ] ? (
                                                        <Box
                                                          display={'flex'}
                                                          flexDirection={
                                                            'column'
                                                          }
                                                          gap={1}
                                                          paddingTop={2}
                                                          marginLeft={1}
                                                        >
                                                          {discipline?.sectionType ===
                                                          'power' ? (
                                                            <ProspectMembers
                                                              prospects={
                                                                prospects
                                                              }
                                                              prospectMembers={
                                                                prospectMembers
                                                              }
                                                              teamMembers={
                                                                teamMembers
                                                              }
                                                              currentAccount={
                                                                currentAccount
                                                              }
                                                              setTeamMembers={
                                                                setTeamMembers
                                                              }
                                                              editMode={false}
                                                            />
                                                          ) : discipline?.sectionType ===
                                                            'deal_activity' ? (
                                                            <DealActivities
                                                              currentAccount={
                                                                currentAccount
                                                              }
                                                              editMode={false}
                                                            />
                                                          ) : null}
                                                          <Box
                                                            sx={{
                                                              // border: '1px solid #d3d3d3',
                                                              borderRadius: 1,
                                                              // padding: 2,
                                                              marginLeft: 1,
                                                            }}
                                                          >
                                                            {discipline?.sections &&
                                                            discipline?.sections
                                                              ?.length > 0 ? (
                                                              discipline?.sections?.map(
                                                                (
                                                                  element,
                                                                  index,
                                                                ) => {
                                                                  return (
                                                                    <Box
                                                                      key={
                                                                        element?.id
                                                                      }
                                                                      sx={{
                                                                        position:
                                                                          'relative',
                                                                        // border: '1px solid #d3d3d3',
                                                                        borderRadius: 1,
                                                                        padding: 1,
                                                                      }}
                                                                      id={
                                                                        element?.title
                                                                      }
                                                                    >
                                                                      <Box
                                                                        display={
                                                                          'flex'
                                                                        }
                                                                        alignItems={
                                                                          'center'
                                                                        }
                                                                        gap={1}
                                                                      >
                                                                        {expandedSections?.[
                                                                          element
                                                                            ?.id
                                                                        ] ? (
                                                                          <ArrowDropDown
                                                                            sx={{
                                                                              cursor:
                                                                                'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                              setExpandedSections(
                                                                                (
                                                                                  prev,
                                                                                ) => {
                                                                                  return {
                                                                                    ...prev,
                                                                                    [element?.id]: false,
                                                                                  };
                                                                                },
                                                                              );
                                                                            }}
                                                                          />
                                                                        ) : (
                                                                          <ArrowRight
                                                                            sx={{
                                                                              cursor:
                                                                                'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                              setExpandedSections(
                                                                                (
                                                                                  prev,
                                                                                ) => {
                                                                                  return {
                                                                                    ...prev,
                                                                                    [element?.id]: true,
                                                                                  };
                                                                                },
                                                                              );
                                                                            }}
                                                                          />
                                                                        )}
                                                                        <Flag
                                                                          sx={{
                                                                            color:
                                                                              Math.round(
                                                                                Number(
                                                                                  sectionsData?.[
                                                                                    element
                                                                                      ?.id
                                                                                  ]
                                                                                    ?.score,
                                                                                ),
                                                                              ) ===
                                                                              0
                                                                                ? 'red'
                                                                                : Math.round(
                                                                                    Number(
                                                                                      sectionsData?.[
                                                                                        element
                                                                                          ?.id
                                                                                      ]
                                                                                        ?.score,
                                                                                    ),
                                                                                  ) ===
                                                                                  1
                                                                                ? 'orange'
                                                                                : Math.round(
                                                                                    Number(
                                                                                      sectionsData?.[
                                                                                        element
                                                                                          ?.id
                                                                                      ]
                                                                                        ?.score,
                                                                                    ),
                                                                                  ) ===
                                                                                  2
                                                                                ? '#ffe599'
                                                                                : Math.round(
                                                                                    Number(
                                                                                      sectionsData?.[
                                                                                        element
                                                                                          ?.id
                                                                                      ]
                                                                                        ?.score,
                                                                                    ),
                                                                                  ) ===
                                                                                  3
                                                                                ? 'green'
                                                                                : Math.round(
                                                                                    Number(
                                                                                      sectionsData?.[
                                                                                        element
                                                                                          ?.id
                                                                                      ]
                                                                                        ?.score,
                                                                                    ),
                                                                                  ) ===
                                                                                    4 ||
                                                                                  Math.round(
                                                                                    Number(
                                                                                      sectionsData?.[
                                                                                        element
                                                                                          ?.id
                                                                                      ]
                                                                                        ?.score,
                                                                                    ),
                                                                                  ) ===
                                                                                    5
                                                                                ? 'blue'
                                                                                : 'blue',
                                                                          }}
                                                                        />
                                                                        <Typography
                                                                          variant="body2"
                                                                          fontWeight={
                                                                            'bold'
                                                                          }
                                                                          color={
                                                                            'text.secondary'
                                                                          }
                                                                          sx={{
                                                                            background:
                                                                              '#fff',
                                                                          }}
                                                                        >
                                                                          {capitalizeText(
                                                                            element?.title
                                                                              ?.split(
                                                                                '_',
                                                                              )
                                                                              ?.join(
                                                                                ' ',
                                                                              ),
                                                                          )}
                                                                        </Typography>
                                                                      </Box>
                                                                      {expandedSections?.[
                                                                        element
                                                                          ?.id
                                                                      ] ? (
                                                                        <Box
                                                                          display={
                                                                            'flex'
                                                                          }
                                                                          flexDirection={
                                                                            'column'
                                                                          }
                                                                          gap={
                                                                            3
                                                                          }
                                                                          paddingTop={
                                                                            2
                                                                          }
                                                                          marginLeft={
                                                                            6
                                                                          }
                                                                        >
                                                                          {element?.questions?.filter(
                                                                            (
                                                                              question,
                                                                            ) =>
                                                                              Object.keys(
                                                                                selectedAnswers ||
                                                                                  {},
                                                                              )?.includes(
                                                                                question?.id,
                                                                              ),
                                                                          ) &&
                                                                          element?.questions?.filter(
                                                                            (
                                                                              question,
                                                                            ) =>
                                                                              Object.keys(
                                                                                selectedAnswers ||
                                                                                  {},
                                                                              )?.includes(
                                                                                question?.id,
                                                                              ),
                                                                          )
                                                                            ?.length >
                                                                            0 ? (
                                                                            element?.questions
                                                                              ?.filter(
                                                                                (
                                                                                  question,
                                                                                ) =>
                                                                                  Object.keys(
                                                                                    selectedAnswers ||
                                                                                      {},
                                                                                  )?.includes(
                                                                                    question?.id,
                                                                                  ),
                                                                              )
                                                                              ?.map(
                                                                                (
                                                                                  qa,
                                                                                  index,
                                                                                ) => {
                                                                                  return (
                                                                                    <QnAs
                                                                                      key={
                                                                                        qa?.id
                                                                                      }
                                                                                      question={
                                                                                        qa
                                                                                      }
                                                                                      questionIndex={
                                                                                        index
                                                                                      }
                                                                                      currentAccount={
                                                                                        currentAccount
                                                                                      }
                                                                                      setQuestionFramework={
                                                                                        setQuestionFramework
                                                                                      }
                                                                                      selectedOptions={
                                                                                        selectedAnswers?.[
                                                                                          qa
                                                                                            ?.id
                                                                                        ]
                                                                                          ?.options
                                                                                      }
                                                                                      selectedAnswerText={
                                                                                        selectedAnswers?.[
                                                                                          qa
                                                                                            ?.id
                                                                                        ]
                                                                                          ?.answerText
                                                                                      }
                                                                                      tags={
                                                                                        selectedAnswers?.[
                                                                                          qa
                                                                                            ?.id
                                                                                        ]
                                                                                          ?.tags
                                                                                      }
                                                                                      notes={
                                                                                        selectedAnswers?.[
                                                                                          qa
                                                                                            ?.id
                                                                                        ]
                                                                                          ?.notes
                                                                                      }
                                                                                      selectedAnswers={
                                                                                        selectedAnswers
                                                                                      }
                                                                                      setSelectedAnswers={
                                                                                        setSelectedAnswers
                                                                                      }
                                                                                      editMode={
                                                                                        viewType ===
                                                                                        'view'
                                                                                          ? false
                                                                                          : true
                                                                                      }
                                                                                      setHighlightedSectionId={
                                                                                        setHighlightedSectionId
                                                                                      }
                                                                                      section={
                                                                                        element
                                                                                      }
                                                                                      setBackReferenceSections={
                                                                                        setBackReferenceSections
                                                                                      }
                                                                                      activeStep={
                                                                                        activeStep
                                                                                      }
                                                                                      activeSecondaryStep={
                                                                                        activeSecondaryStep
                                                                                      }
                                                                                      activeQuestionFrameworkStep={
                                                                                        activeQuestionFrameworkStep
                                                                                      }
                                                                                      setActiveStep={
                                                                                        setActiveStep
                                                                                      }
                                                                                      listOfGroups={
                                                                                        listOfGroups
                                                                                      }
                                                                                      listOfPages={
                                                                                        listOfPages
                                                                                      }
                                                                                      setExpandedSections={
                                                                                        setExpandedSections
                                                                                      }
                                                                                      questionFramework={
                                                                                        questionFramework
                                                                                      }
                                                                                      notRelevant={
                                                                                        false
                                                                                      }
                                                                                      setShowHelpSidebar={
                                                                                        setShowHelpSidebar
                                                                                      }
                                                                                      helpSections={
                                                                                        helpSections
                                                                                      }
                                                                                    />
                                                                                  );
                                                                                },
                                                                              )
                                                                          ) : (
                                                                            <Box
                                                                              sx={{
                                                                                width: 1,
                                                                                display:
                                                                                  'flex',
                                                                                flexDirection:
                                                                                  'column',
                                                                                gap: 2,
                                                                                justifyContent:
                                                                                  'center',
                                                                                // alignItems: 'center',
                                                                              }}
                                                                            >
                                                                              <Typography
                                                                                variant="body2"
                                                                                color={
                                                                                  'text.secondary'
                                                                                }
                                                                              >
                                                                                -
                                                                                Nothing
                                                                                to
                                                                                report
                                                                                --
                                                                              </Typography>
                                                                            </Box>
                                                                          )}
                                                                        </Box>
                                                                      ) : null}
                                                                    </Box>
                                                                  );
                                                                },
                                                              )
                                                            ) : (
                                                              <Box
                                                                sx={{
                                                                  width: 1,
                                                                  display:
                                                                    'flex',
                                                                  flexDirection:
                                                                    'column',
                                                                  gap: 2,
                                                                  justifyContent:
                                                                    'center',
                                                                  // alignItems: 'center',
                                                                }}
                                                              >
                                                                <Typography
                                                                  variant="body2"
                                                                  color={
                                                                    'text.secondary'
                                                                  }
                                                                  // fontWeight={700}
                                                                >
                                                                  - Nothing to
                                                                  report --
                                                                </Typography>
                                                              </Box>
                                                            )}
                                                          </Box>
                                                        </Box>
                                                      ) : null}
                                                    </Box>
                                                  ),
                                                )
                                              ) : (
                                                <Box
                                                  sx={{
                                                    width: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 2,
                                                    justifyContent: 'center',
                                                    // alignItems: 'center',
                                                    // minHeight: 300,
                                                  }}
                                                >
                                                  <Typography
                                                    variant="body2"
                                                    color={'text.secondary'}
                                                    // fontWeight={700}
                                                  >
                                                    -- Nothing to report --
                                                  </Typography>
                                                </Box>
                                              )}
                                            </Box>
                                          ) : null}
                                        </Box>
                                      );
                                    })
                                  ) : (
                                    <Box
                                      sx={{
                                        width: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        justifyContent: 'center',
                                        // alignItems: 'center',
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        color={'text.secondary'}
                                        // fontWeight={700}
                                      >
                                        -- Nothing to report --
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            ) : null}
                          </Box>
                        ))
                      ) : (
                        <Box
                          sx={{
                            width: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: 300,
                          }}
                        >
                          <Typography
                            variant="body2"
                            color={'text.secondary'}
                            // fontWeight={700}
                          >
                            -- Nothing to report --
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          ) : viewType === 'orgchart' ? (
            <Box
              sx={{
                // border: '1px solid #d3d3d3',
                padding: 2,
                height: 'calc(100vh - 90px)',
                borderRadius: 2,
                paddingBottom: 2,
                width: '100%',
                maxHeight: 'calc(100vh - 90px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                position: 'relative',
              }}
            >
              <OrgChart
                loading={loadingProspects}
                currentAccount={currentAccount}
                prospects={prospects}
                teamMembers={teamMembers}
                editMode={
                  currentAccount?.userPermissionMap?.[userInfo?.id]?.write
                    ? true
                    : false
                }
              />
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
      </Box>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showCreateAccount) || Boolean(showWizard)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '90vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              if (showCreateAccount) {
                setShowCreateAccount(null);
              } else {
                setShowWizard(null);
              }
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box
            p={2}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color={'text.secondary'}>
              {showCreateAccount === 'new'
                ? 'Create Account'
                : 'Update Account Details'}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              // minHeight: 'calc(80vh - 58px)',
              maxHeight: 'calc(90vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              // background: '#343a40',
              margin: showWizard ? 0 : '0 auto',
              overflowX: 'hidden',
              paddingTop: 2,
            }}
          >
            {/* {showWizard ? ( */}
            <Stepper
              // nonLinear
              activeStep={currentWizardStepIndex}
              alternativeLabel
            >
              {wizardSteps.map((label, index) => (
                <Step key={label} completed={completedWizardSteps?.[index]}>
                  <StepButton
                    color="inherit"
                    onClick={() => {
                      setCurrentWizardStepIndex(index);
                    }}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {currentWizardStepIndex === 0 ? (
              <form
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
                style={{ width: '100%' }}
              >
                <Box display={'flex'} flexDirection={'column'} gap={2} p={2}>
                  <TextField
                    autoFocus
                    label="Company Name"
                    placeholder="Enter Company Name"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="companyName"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    sx={{ flex: 4 }}
                    error={
                      formik.touched.companyName &&
                      Boolean(formik.errors.companyName)
                    }
                    helperText={
                      formik.touched.companyName && formik.errors.companyName
                    }
                  />
                  <TextField
                    autoFocus
                    label="Primary Contact Name"
                    placeholder="Primary Contact Name"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="primaryContactName"
                    value={formik.values.primaryContactName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.primaryContactName &&
                      Boolean(formik.errors.primaryContactName)
                    }
                    helperText={
                      formik.touched.primaryContactName &&
                      formik.errors.primaryContactName
                    }
                  />
                  <TextField
                    autoFocus
                    label="Primary Contact Email"
                    placeholder="Primary Contact Email"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="primaryContactEmail"
                    value={formik.values.primaryContactEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.primaryContactEmail &&
                      Boolean(formik.errors.primaryContactEmail)
                    }
                    helperText={
                      formik.touched.primaryContactEmail &&
                      formik.errors.primaryContactEmail
                    }
                  />
                  <TextField
                    autoFocus
                    label="Primary Contact Designation"
                    placeholder="Primary Contact Designation"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="primaryContactDesignation"
                    value={formik.values.primaryContactDesignation}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.primaryContactDesignation &&
                      Boolean(formik.errors.primaryContactDesignation)
                    }
                    helperText={
                      formik.touched.primaryContactDesignation &&
                      formik.errors.primaryContactDesignation
                    }
                  />
                  <TextField
                    autoFocus
                    label="Primary Contact LinkedIn URL"
                    placeholder="Primary Contact LinkedIn URL"
                    variant="outlined"
                    color="primary"
                    size="small"
                    name="primaryContactLinkedInurl"
                    value={formik.values.primaryContactLinkedInurl}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.primaryContactLinkedInurl &&
                      Boolean(formik.errors.primaryContactLinkedInurl)
                    }
                    helperText={
                      formik.touched.primaryContactLinkedInurl &&
                      formik.errors.primaryContactLinkedInurl
                    }
                  />
                  <FormControl size="small" sx={{ width: '100%' }}>
                    <InputLabel id="organizationLevel">
                      Organization Level
                    </InputLabel>
                    <Select
                      size="small"
                      label="Organization Level"
                      labelId={'organizationLevel'}
                      id={'organizationLevel'}
                      name={'organizationLevel'}
                      MenuProps={{ disableScrollLock: true }}
                      onChange={formik.handleChange}
                      value={formik.values.organizationLevel}
                    >
                      {['C-Level', 'Users', 'Managers', 'Operations'].map(
                        (option) => (
                          <MenuItem value={option} key={option}>
                            <Typography variant="subtitle2">
                              {option}
                            </Typography>
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    autoFocus
                    label="Additional Notes"
                    placeholder="Additional Notes"
                    variant="outlined"
                    multiline
                    rows={4}
                    color="primary"
                    size="small"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                  <Box
                    display={'flex'}
                    gap={2}
                    sx={{ flex: 1, alignSelf: 'flex-end' }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      type="submit"
                      disabled={creatingAccount}
                      startIcon={
                        creatingAccount ? (
                          <CircularProgress size={20} />
                        ) : showCreateAccount === 'new' ? (
                          <Add />
                        ) : null
                      }
                    >
                      {showCreateAccount === 'new'
                        ? 'Create Account'
                        : 'Update'}
                    </Button>
                    {showWizard ? (
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                          setCurrentWizardStepIndex((prev) => prev + 1);
                        }}
                      >
                        Next
                      </Button>
                    ) : null}
                  </Box>
                </Box>
              </form>
            ) : currentWizardStepIndex === 1 ? (
              <Box p={2}>
                <ProspectMembers
                  prospects={prospects}
                  prospectMembers={prospectMembers}
                  teamMembers={teamMembers}
                  currentAccount={showWizard}
                  setTeamMembers={setTeamMembers}
                  editMode={true}
                />
              </Box>
            ) : currentWizardStepIndex === 2 ? (
              <Box p={2}>
                <DealActivities currentAccount={showWizard} editMode={true} />
              </Box>
            ) : null}
            {currentWizardStepIndex !== 0 ? (
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                mt={2}
                p={2}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setShowWizard(null);
                    setCurrentWizardStepIndex(0);
                  }}
                >
                  Skip and continue later
                </Button>
                <Box display={'flex'} gap={1} alignItems={'center'}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setCurrentWizardStepIndex((prev) => prev - 1);
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      if (currentWizardStepIndex === 2) {
                        // setShowWizard(false);
                        setShowCreateAccount(null);
                        setCurrentWizardStepIndex(0);
                        setViewType('manage');
                        setCurrentAccount(showWizard);
                      } else {
                        setCurrentWizardStepIndex((prev) => prev + 1);
                      }
                    }}
                  >
                    {currentWizardStepIndex === 2 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Modal>
      <Drawer
        open={Boolean(showManagePermissions)}
        anchor="right"
        onClose={() => setShowManagePermissions(null)}
      >
        <Box
          p={1}
          sx={{
            width: '40vw',
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => setShowManagePermissions(null)}>
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography color={'text.secondary'}>
            Manage Permissions and Visibility
          </Typography>
        </Box>
        <Box
          sx={{
            width: '40vw',
            // minHeight: 'calc(100vh - 58px)',
            // maxHeight: 'calc(100vh - 58px)',
            // overflowY: 'scroll',
            // '&::-webkit-scrollbar': {
            //   width: '5px',
            // },
            // '&::-webkit-scrollbar-track': {
            //   boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            //   webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            // },
            // '&::-webkit-scrollbar-thumb': {
            //   backgroundColor: theme.palette.primary.main,
            //   borderRadius: 2,
            //   // outline: '1px solid slategrey',
            // },
            // background: '#343a40',
            flex: 1,
          }}
        >
          {allTeamMembers?.fetching ? (
            <Box
              width={1}
              height={1}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <CircularProgress size={20} />
              <Typography variant="body1" color={'text.primary'} mt={4}>
                Loading team members ...
              </Typography>
            </Box>
          ) : allTeamMembers?.members && allTeamMembers?.members?.length > 0 ? (
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <TableContainer
                component={Paper}
                sx={{
                  minHeight: 'calc(100vh - 140px)',
                  maxHeight: 'calc(100vh - 140px)',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                    height: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                  },
                  boxShadow: 'none',
                }}
                onScroll={handleTeamMemberListScroll}
              >
                <Table
                  // sx={{ minWidth: 750 }}
                  stickyHeader
                  aria-label="simple table"
                >
                  <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                    <TableRow
                      sx={{
                        '& .MuiTableCell-root': {
                          // border: '1px solid #e1e1e1',
                        },
                      }}
                    >
                      <TableCell>
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{
                            textTransform: 'capitalize',
                          }}
                        >
                          Member
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant={'caption'}
                          fontWeight={700}
                          sx={{ textTransform: 'capitalize' }}
                        >
                          Permissions and Role
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allTeamMembers?.members.map((member, i) => (
                      <TableRow key={member?.id}>
                        <TableCell component="th" scope="row">
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            // gap={1}
                          >
                            <Typography
                              fontWeight={'bold'}
                              color={'text.secondary'}
                            >
                              {member?.fullName || ''}
                            </Typography>
                            <Typography variant="caption">
                              {member?.email}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <FormControl fullWidth size="small">
                            <Select
                              size="small"
                              labelId="role-select-label"
                              id="role-select"
                              name="teamRole"
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              onChange={(e) => {
                                if (e.target.value === 'write') {
                                  if (
                                    Object.keys(userPermissionMap || {}).find(
                                      (key) => userPermissionMap?.[key]?.write,
                                    )
                                  ) {
                                    setUserPermissionMap((prev) => {
                                      return {
                                        ...prev,
                                        [Object.keys(
                                          userPermissionMap || {},
                                        ).find(
                                          (key) =>
                                            userPermissionMap?.[key]?.write,
                                        )]: {
                                          ...prev?.[
                                            Object.keys(
                                              userPermissionMap || {},
                                            ).find(
                                              (key) =>
                                                userPermissionMap?.[key]?.write,
                                            )
                                          ],
                                          write: false,
                                          read: true,
                                        },
                                        [member?.id]: {
                                          ...prev?.[member?.id],
                                          write: e.target.value === 'write',
                                          read: e.target.value === 'read',
                                        },
                                      };
                                    });
                                  } else {
                                    setUserPermissionMap((prev) => {
                                      return {
                                        ...prev,
                                        [member?.id]: {
                                          ...prev?.[member?.id],
                                          write: e.target.value === 'write',
                                          read: e.target.value === 'read',
                                        },
                                      };
                                    });
                                  }
                                } else {
                                  setUserPermissionMap((prev) => {
                                    return {
                                      ...prev,
                                      [member?.id]: {
                                        ...prev?.[member?.id],
                                        write: e.target.value === 'write',
                                        read: e.target.value === 'read',
                                      },
                                    };
                                  });
                                }
                              }}
                              value={
                                userPermissionMap?.[member?.id]?.write
                                  ? 'write'
                                  : 'read'
                              }
                            >
                              <MenuItem key={'editor'} value="read">
                                VIEWER
                              </MenuItem>
                              <MenuItem key={'viewer'} value="write">
                                EDITOR
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        variant="footer"
                        colSpan={8}
                        sx={{
                          padding: 0,
                        }}
                      >
                        {allTeamMembers?.fetchingPagination ? (
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: 1,
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              // type="submit"
                              color="primary"
                              onClick={handleTeamMemberListScroll}
                              // className="searchSettings"
                              disabled
                              // fullWidth
                            >
                              <Typography color="textPrimary">
                                Loading more members ...{' '}
                              </Typography>
                              <CircularProgress size={20} />
                            </Button>
                          </Box>
                        ) : allTeamMembers?.emptyList === false &&
                          allTeamMembers?.members.length >= 25 ? (
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: 1,
                              textAlign: 'center',
                            }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              // type="submit"
                              // fullWidth
                              color="primary"
                              onClick={(e) =>
                                handleTeamMemberListScroll(e, true)
                              }
                              // className="searchSettings"
                            >
                              Load More
                            </Button>
                          </Box>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              <Box
                display={'flex'}
                justifyContent={'flex-end'}
                p={2}
                borderTop={'1px solid #d3d3d3'}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    dispatch(
                      assignAccountToTeamMember(
                        showManagePermissions?.id,
                        { userPermissionMap, accountPermissionMap: {} },
                        () => {
                          setShowManagePermissions(null);
                          dispatch(getAccounts(10, null, () => {}));
                          dispatch(
                            showAlert({
                              message: 'Permissions updated successfully.',
                              showCross: true,
                              title: null,
                              type: 'success',
                              autoHideDuration: 5000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                        },
                      ),
                    );
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              width={1}
              height={1}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              gap={2}
            >
              <Typography variant="body1" color={'text.secondary'} mt={4}>
                No team members found.
              </Typography>
            </Box>
          )}
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this account?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteAccount(showConfirmDeleteDialog, (data) => {
                  setShowConfirmDeleteDialog(false);
                  dispatch({
                    type: 'DELETE_ACCOUNT',
                    payload: showConfirmDeleteDialog,
                  });
                  dispatch(
                    showAlert({
                      message: 'Account deleted successfully.',
                      showCross: true,
                      title: null,
                      type: 'success',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

CompanyAccounts.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

QnAs.propTypes = {
  key: PropTypes.string,
  question: PropTypes.object,
  questionIndex: PropTypes.number,
  currentAccount: PropTypes.object,
  setQuestionFramework: PropTypes.func,
  selectedAnswers: PropTypes.object,
  setSelectedAnswers: PropTypes.func,
  selectedOptions: PropTypes.array,
  selectedAnswerText: PropTypes.string,
  tags: PropTypes.array,
  notes: PropTypes.string,
  editMode: PropTypes.bool,
  setHighlightedSectionId: PropTypes.func,
  section: PropTypes.object,
  setBackReferenceSections: PropTypes.func,
  activeStep: PropTypes.number,
  activeSecondaryStep: PropTypes.number,
  activeQuestionFrameworkStep: PropTypes.number,
  setActiveStep: PropTypes.func,
  listOfGroups: PropTypes.array,
  listOfPages: PropTypes.array,
  setExpandedSections: PropTypes.func,
  questionFramework: PropTypes.array,
  showNotRelevantCheckbox: PropTypes.string,
  setShowNotRelevantCheckbox: PropTypes.func,
  notRelevant: PropTypes.bool,
  setShowHelpSidebar: PropTypes.func,
  helpSections: PropTypes.array,
};

VideoPreview.propTypes = {
  video: PropTypes.any,
  onDurationChange: PropTypes.func,
};

export default CompanyAccounts;
