import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListSubheader,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Add, MoreVert } from '@mui/icons-material';
import moment from 'moment';
import ProspectMember from './ProspectMember';
import FormField from './FormField';
import { useFormik, withFormik } from 'formik';
import * as yup from 'yup';
import updateCompanyProspectDetails from 'redux/actions/Compass/updateCompanyProspectDetails';
import { useDispatch } from 'react-redux';
import getCompanyProspects from 'redux/actions/Compass/getCompanyProspects';
import addProspectToCompanyProfile from 'redux/actions/Compass/addProspectToCompanyProfile';
import deleteProspectFromCompanyProfile from 'redux/actions/Compass/deleteProspectFromCompanyProfile';
import getAllTeamMembers from 'redux/actions/Common/getAllTeamMembers';
import getMembersByOrgId from 'redux/actions/Compass/getMembersByOrgId';
import showAlert from 'redux/actions/Common/showAlert';

const prospectMemberValidationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .required('Name is required.'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address'),
  designation: yup.string().trim(),
  linkedinUrl: yup.string().trim(),
  orgLevel: yup.string(),
  relatedMembers: yup.array(),
  additionalNotes: yup.string().trim(),
  reportsTo: yup.string().trim(),
  keyStackHolder: yup.bool(),
});

const teamMemberValidationSchema = yup.object({
  userId: yup.string().trim(),
  name: yup
    .string()
    .trim()
    .required('Name is required.'),
  email: yup
    .string()
    .trim()
    .email()
    .required('Email is required.'),
  designation: yup
    .string()
    .trim()
    .required('Designation is required.'),
  linkedinUrl: yup
    .string()
    .trim()
    .required('LinkedIn URL is required.'),
});

const ProspectMembers = ({
  prospectMembers,
  prospects,
  teamMembers,
  currentAccount,
  setTeamMembers,
  editMode,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [creatingProspect, setCreatingProspect] = useState(false);
  const [updatingProspect, setUpdatingProspect] = useState(false);
  const [showAddNewTeamMember, setShowAddNewTeamMember] = useState(false);
  const [showAddNewMember, setShowAddNewMember] = useState(false);
  const [
    showConfirmDeleteProspectDialog,
    setShowConfirmDeleteProspectDialog,
  ] = useState(null);
  const [showMakeAsPrimaryDialog, setShowMakeAsPrimaryDialog] = useState(null);

  const prospectMemberInitialValues = {
    name: '',
    email: '',
    designation: '',
    linkedinUrl: '',
    orgLevel: '',
    relatedMembers: [],
    additionalNotes: '',
    reportsTo: '',
    keyStackHolder: false,
  };

  const teamMemberInitialValues = {
    userId: null,
    name: '',
    email: '',
    designation: '',
    linkedinUrl: '',
  };

  const resetMemberForm = () => {
    formikProspectMember.resetForm();
  };

  const handleRelatedMemberChange = (e) => {
    console.log(e.target.value);
    if (e.target.value[e.target.value.length - 1] === 'new') {
      setShowAddNewTeamMember(true);
    } else {
      setShowAddNewTeamMember(e.target.value[e.target.value.length - 1]);
    }
  };

  const prospectMemberFormSubmit = (values) => {
    console.log(values);
    if (typeof showAddNewMember === 'object') {
      setUpdatingProspect(true);
      dispatch(
        updateCompanyProspectDetails(
          showAddNewMember?.id,
          {
            contacts: values.email
              ? [
                  {
                    type: 'email',
                    value: values.email,
                  },
                ]
              : [],
            designation: values.designation,
            links: values.linkedinUrl
              ? [
                  {
                    link: values.linkedinUrl,
                    type: 'linkedin',
                  },
                ]
              : [],
            organizationLevel: values.orgLevel,
            name: values.name,
            notes: values.additionalNotes,
            keyStackHolder: values.keyStackHolder,
            reportsTo: values.reportsTo ? [values.reportsTo] : [],
            relationships: values.relatedMembers?.map((m) => {
              return {
                userId: m?.userId,
                contacts: m?.email
                  ? [
                      {
                        type: 'email',
                        value: m?.email,
                      },
                    ]
                  : [],
                designation: m?.designation,
                links: m?.linkedinUrl
                  ? [
                      {
                        link: m?.linkedinUrl,
                        type: 'linkedin',
                      },
                    ]
                  : [],
                name: m?.name,
                type: m?.type,
              };
            }),
            type: 'prospect',
          },
          () => {
            setUpdatingProspect(false);
            resetMemberForm();
            dispatch(
              showAlert({
                message: 'Prospect updated successfully',
                showCross: true,
                title: null,
                type: 'success',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
            setLoading(true);
            dispatch(
              getCompanyProspects(
                currentAccount?.id,
                10,
                null,
                (data) => {
                  console.log(data);
                  setShowAddNewMember(false);
                  setLoading(false);
                },
                () => {
                  setLoading(false);
                },
              ),
            );
          },
          () => {
            setUpdatingProspect(false);
            dispatch(
              showAlert({
                message: 'Oops, Something went wront! Please try again.',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          },
        ),
      );
      console.log('update member');
    } else {
      setCreatingProspect(true);
      // console.log(values.relatedMembers);
      dispatch(
        addProspectToCompanyProfile(
          currentAccount?.id,
          {
            contacts: values.email
              ? [
                  {
                    type: 'email',
                    value: values.email,
                  },
                ]
              : [],
            designation: values.designation,
            links: values.linkedinUrl
              ? [
                  {
                    link: values.linkedinUrl,
                    type: 'linkedin',
                  },
                ]
              : [],
            organizationLevel: values.orgLevel,
            name: values.name,
            notes: values.additionalNotes,
            keyStackHolder: values.keyStackHolder,
            reportsTo: values.reportsTo ? [values.reportsTo] : [],
            relationships: values.relatedMembers?.map((m) => {
              return {
                userId: m?.userId,
                contacts: m?.email
                  ? [
                      {
                        type: 'email',
                        value: m?.email,
                      },
                    ]
                  : [],
                designation: m?.designation,
                links: m?.linkedinUrl
                  ? [
                      {
                        link: m?.linkedinUrl,
                        type: 'linkedin',
                      },
                    ]
                  : [],
                name: m?.name,
                type: m?.type,
              };
            }),
            type: 'prospect',
          },
          (error) => {
            setCreatingProspect(false);
            if (error) {
              dispatch(
                showAlert({
                  message: 'Oops, Something went wront! Please try again.',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            } else {
              resetMemberForm();
              dispatch(
                showAlert({
                  message: 'Prospect added successfully',
                  showCross: true,
                  title: null,
                  type: 'success',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
              setLoading(true);
              dispatch(
                getCompanyProspects(
                  currentAccount?.id,
                  10,
                  null,
                  (data) => {
                    console.log(data);
                    setLoading(false);
                    setShowAddNewMember(false);
                  },
                  () => {
                    setLoading(false);
                  },
                ),
              );
            }
          },
        ),
      );
    }
  };

  const teamMemberFormSubmit = (values) => {
    console.log(values);
  };

  const formikProspectMember = useFormik({
    initialValues: prospectMemberInitialValues,
    validationSchema: prospectMemberValidationSchema,
    onSubmit: prospectMemberFormSubmit,
  });

  const formikTeamMember = useFormik({
    initialValues: teamMemberInitialValues,
    validationSchema: teamMemberValidationSchema,
    onSubmit: teamMemberFormSubmit,
  });

  useEffect(() => {
    console.log(typeof showAddNewTeamMember);
    if (typeof showAddNewTeamMember === 'object') {
      console.log(showAddNewTeamMember);
      formikTeamMember.setValues({
        name: showAddNewTeamMember?.name,
        email: showAddNewTeamMember?.email,
        designation: showAddNewTeamMember?.designation,
        linkedinUrl: showAddNewTeamMember?.linkedinUrl,
      });
    } else {
      formikTeamMember.resetForm();
    }
  }, [showAddNewTeamMember, showAddNewTeamMember?.userId]);

  useEffect(() => {
    if (typeof showAddNewMember === 'object' && showAddNewMember) {
      formikProspectMember.setValues({
        name: showAddNewMember?.name || '',
        email:
          showAddNewMember?.contacts?.find((obj) => obj.type === 'email')
            ?.value || '',
        designation: showAddNewMember?.designation || '',
        linkedinUrl:
          showAddNewMember?.links?.find((obj) => obj.type === 'linkedin')
            ?.link || '',
        orgLevel: showAddNewMember?.organizationLevel || '',
        keyStackHolder: showAddNewMember?.keyStackHolder || false,
        reportsTo: showAddNewMember?.reportsTo?.[0],
        relatedMembers: showAddNewMember?.customFields?.relationships?.map(
          (rel) => {
            return {
              name: rel?.name,
              email: rel?.contacts?.find((c) => c.type === 'email')?.email,
              designation: rel?.designation,
              linkedinUrl: rel?.links?.find((link) => link.type === 'linkedin')
                ?.link,
              userId: rel?.userId,
              type: rel?.type,
            };
          },
        ),
        // showAddNewMember?.customFields?.relationships
        additionalNotes: showAddNewMember?.notes,
      });
    } else {
      formikProspectMember.resetForm();
    }
    dispatch(getAllTeamMembers(null, null, false, 0, 25, null, (data) => {}));
    dispatch(getMembersByOrgId(25, null, (data) => {}));
  }, [showAddNewMember]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getCompanyProspects(
        currentAccount?.id,
        100,
        null,
        (data) => {
          console.log(data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      ),
    );
  }, []);

  console.log(formikProspectMember.values);
  console.log(formikProspectMember.errors);

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={1}
      >
        {editMode ? (
          <Typography variant="body2" color={'text.secondary'} fontWeight={700}>
            Prospects involved in the deal
          </Typography>
        ) : null}
        {prospectMembers && prospectMembers.length > 0 && editMode && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => setShowAddNewMember(true)}
            startIcon={<Add />}
          >
            Add new prospect
          </Button>
        )}
      </Box>
      <Grid container spacing={2} width={1}>
        {loading ? (
          <Box
            width={1}
            height={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <CircularProgress size={20} />
            <Typography variant="body1" color={'text.primary'} mt={4}>
              Loading ...
            </Typography>
          </Box>
        ) : prospectMembers && prospectMembers.length > 0 ? (
          prospectMembers?.map((member, index) => (
            <Grid
              item
              xs={12}
              key={member?.name}
              sm={editMode ? 6 : 4}
              md={editMode ? 6 : 3}
              lg={editMode ? 6 : 3}
            >
              <ProspectMember
                member={member}
                setShowAddNewMember={setShowAddNewMember}
                setShowConfirmDeleteProspectDialog={
                  setShowConfirmDeleteProspectDialog
                }
                setShowMakeAsPrimaryDialog={setShowMakeAsPrimaryDialog}
                editMode={editMode}
              />
            </Grid>
          ))
        ) : (
          <Box
            sx={{
              width: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              justifyContent: 'center',
              alignItems: 'center',
              pt: 4,
              pb: 4,
              minHeight: 150,
              // border: '1px solid #d3d3d3',
              mt: 2,
            }}
          >
            <Typography
              variant="body2"
              color={'text.secondary'}
              fontWeight={700}
            >
              No prospects added yet
            </Typography>
            {editMode ? (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => setShowAddNewMember(true)}
                startIcon={<Add />}
              >
                Add new prospect
              </Button>
            ) : null}
          </Box>
        )}
      </Grid>
      <Dialog
        open={Boolean(showAddNewMember)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <form
          noValidate
          autoComplete="off"
          onSubmit={formikProspectMember.handleSubmit}
          style={{ width: '100%' }}
        >
          <DialogContent>
            <Box display={'flex'} alignItems={'center'} gap={2}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Typography
                    variant="body2"
                    color={'text.secondary'}
                    fontWeight={700}
                  >
                    Prospect Name
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormField
                    name={'name'}
                    type={'text'}
                    multiple={false}
                    value={formikProspectMember.values.name}
                    onChange={formikProspectMember.handleChange}
                    options={[]}
                    customOptions={false}
                    placeholder={'Prospect Name'}
                    error={
                      formikProspectMember.touched.name &&
                      Boolean(formikProspectMember.errors.name)
                    }
                    helperText={
                      formikProspectMember.touched.name &&
                      formikProspectMember.errors.name
                    }
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="body2"
                    color={'text.secondary'}
                    fontWeight={700}
                  >
                    Prospect Email
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormField
                    name={'email'}
                    type={'email'}
                    multiple={false}
                    value={formikProspectMember.values.email}
                    onChange={formikProspectMember.handleChange}
                    options={[]}
                    customOptions={false}
                    placeholder={'Prospect Email'}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="body2"
                    color={'text.secondary'}
                    fontWeight={700}
                  >
                    Designation
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormField
                    name={'designation'}
                    type={'text'}
                    multiple={false}
                    value={formikProspectMember.values.designation}
                    onChange={formikProspectMember.handleChange}
                    options={[]}
                    customOptions={false}
                    placeholder={'Designation'}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="body2"
                    color={'text.secondary'}
                    fontWeight={700}
                  >
                    LinkedIn URL
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormField
                    name={'linkedinUrl'}
                    type={'text'}
                    multiple={false}
                    value={formikProspectMember.values.linkedinUrl}
                    onChange={formikProspectMember.handleChange}
                    options={[]}
                    customOptions={false}
                    placeholder={'LinkedIn URL'}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="body2"
                    color={'text.secondary'}
                    fontWeight={700}
                  >
                    Organization Level
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormField
                    name={'orgLevel'}
                    type={'select'}
                    multiple={false}
                    value={formikProspectMember.values.orgLevel}
                    onChange={formikProspectMember.handleChange}
                    options={['C-Level', 'Users', 'Managers', 'Operations']}
                    customOptions={false}
                    placeholder={'Organization Level'}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="body2"
                    color={'text.secondary'}
                    fontWeight={700}
                  >
                    Key Stakeholder
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormControlLabel
                    // checked={formikProspectMember.values.keyStackHolder}
                    control={
                      <Checkbox
                        size="small"
                        checked={formikProspectMember.values.keyStackHolder}
                        // value={formikProspectMember.values.keyStackHolder}
                        onChange={formikProspectMember.handleChange}
                        name="keyStackHolder"
                      />
                    }
                    // value={formikProspectMember.values.keyStackHolder}
                    // label="Key Stakeholder"
                    // onChange={formikProspectMember.handleChange}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="body2"
                    color={'text.secondary'}
                    fontWeight={700}
                  >
                    Reports to
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormControl size="small" sx={{ width: '100%' }}>
                    <InputLabel htmlFor="reportsTo">Reports to</InputLabel>
                    <Select
                      label="Reports to"
                      size="small"
                      onChange={formikProspectMember.handleChange}
                      value={formikProspectMember.values.reportsTo}
                      labelId={'reportsTo'}
                      id={'reportsTo'}
                      name={'reportsTo'}
                    >
                      <ListSubheader>
                        <Typography variant="subtitle2">C-Level</Typography>
                      </ListSubheader>
                      {prospects
                        ?.filter((pr) => pr?.organizationLevel === 'C-Level')
                        ?.map((prospect) => (
                          <MenuItem
                            sx={{ ml: 2 }}
                            key={prospect?.id}
                            value={prospect?.id}
                          >
                            <Typography variant="subtitle2">
                              {prospect?.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      <ListSubheader>
                        <Typography variant="subtitle2">Users</Typography>
                      </ListSubheader>
                      {prospects
                        ?.filter((pr) => pr?.organizationLevel === 'Users')
                        ?.map((prospect) => (
                          <MenuItem
                            sx={{ ml: 2 }}
                            key={prospect?.id}
                            value={prospect?.id}
                          >
                            <Typography variant="subtitle2">
                              {prospect?.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      <ListSubheader>
                        <Typography variant="subtitle2">Managers</Typography>
                      </ListSubheader>
                      {prospects
                        ?.filter((pr) => pr?.organizationLevel === 'Managers')
                        ?.map((prospect) => (
                          <MenuItem
                            sx={{ ml: 2 }}
                            key={prospect?.id}
                            value={prospect?.id}
                          >
                            <Typography variant="subtitle2">
                              {prospect?.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      <ListSubheader>
                        <Typography variant="subtitle2">Operations</Typography>
                      </ListSubheader>
                      {prospects
                        ?.filter((pr) => pr?.organizationLevel === 'Operations')
                        ?.map((prospect) => (
                          <MenuItem
                            sx={{ ml: 2 }}
                            key={prospect?.id}
                            value={prospect?.id}
                          >
                            <Typography variant="subtitle2">
                              {prospect?.name}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="body2"
                    color={'text.secondary'}
                    fontWeight={700}
                  >
                    Related Team Members
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormControl size="small" sx={{ width: '100%' }}>
                    <Select
                      size="small"
                      labelId={'relatedMembers'}
                      id={'relatedMembers'}
                      name={'relatedMembers'}
                      MenuProps={{ disableScrollLock: true }}
                      onChange={(e) => {
                        handleRelatedMemberChange(e);
                      }}
                      value={formikProspectMember.values.relatedMembers}
                      multiple={true}
                      renderValue={(selected) =>
                        selected
                          .map((value) => {
                            if (typeof value === 'object') {
                              return value?.name;
                            }
                            return value?.name;
                          })
                          .join(', ')
                      }
                    >
                      {teamMembers.map((option) => (
                        <MenuItem
                          value={option}
                          key={option?.userId || option?.linkedinUrl}
                        >
                          <Typography variant="subtitle2">
                            {option?.name}
                          </Typography>
                        </MenuItem>
                      ))}
                      <MenuItem value={'new'}>
                        <Add />
                        <Typography variant="subtitle2">Add new</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="body2"
                    color={'text.secondary'}
                    fontWeight={700}
                  >
                    Additional Notes
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormField
                    name={'additionalNotes'}
                    type={'textarea'}
                    multiple={false}
                    value={formikProspectMember.values.additionalNotes}
                    onChange={formikProspectMember.handleChange}
                    options={[]}
                    customOptions={false}
                    placeholder={'Additional Notes'}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowAddNewMember(null);
                // if (testMode) setShowTestDeviceDialog(null);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={creatingProspect || updatingProspect}
              startIcon={
                creatingProspect || updatingProspect ? (
                  <CircularProgress size={20} />
                ) : typeof showAddNewMember === 'object' ? null : (
                  <Add />
                )
              }
              color="primary"
              autoFocus
              type="submit"
            >
              {typeof showAddNewMember === 'object'
                ? 'Update Prospect'
                : 'Add Prospect'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteProspectDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this prospect?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDeleteProspectDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteProspectFromCompanyProfile(
                  showConfirmDeleteProspectDialog,
                  (data) => {
                    setLoading(true);
                    dispatch(
                      getCompanyProspects(
                        currentAccount?.id,
                        10,
                        null,
                        (data) => {
                          setLoading(false);
                          console.log(data);
                        },
                        () => {
                          setLoading(false);
                        },
                      ),
                    );
                    dispatch(
                      showAlert({
                        message: 'Prospect deleted successfully.',
                        showCross: true,
                        title: null,
                        type: 'success',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                    setShowConfirmDeleteProspectDialog(false);
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showMakeAsPrimaryDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to make this prospect as primary contact
              person for this account?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowMakeAsPrimaryDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                updateCompanyProspectDetails(
                  showMakeAsPrimaryDialog?.id,
                  {
                    primaryContact: true,
                  },
                  (data) => {
                    setLoading(true);
                    dispatch(
                      getCompanyProspects(
                        currentAccount?.id,
                        10,
                        null,
                        (data) => {
                          setLoading(false);
                          console.log(data);
                        },
                        () => {
                          setLoading(false);
                        },
                      ),
                    );
                    dispatch(
                      showAlert({
                        message: 'Prospect updated successfully.',
                        showCross: true,
                        title: null,
                        type: 'success',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                    setShowMakeAsPrimaryDialog(false);
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showAddNewTeamMember)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaperRootMuiDialogPaper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography
                  variant="body2"
                  color={'text.secondary'}
                  fontWeight={700}
                >
                  Member Name
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <FormField
                  name={'name'}
                  type={'text'}
                  multiple={false}
                  value={formikTeamMember.values.name}
                  onChange={formikTeamMember.handleChange}
                  options={[]}
                  customOptions={false}
                  placeholder={'Member Name'}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="body2"
                  color={'text.secondary'}
                  fontWeight={700}
                >
                  Member Email
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <FormField
                  name={'email'}
                  type={'email'}
                  multiple={false}
                  value={formikTeamMember.values.email}
                  onChange={formikTeamMember.handleChange}
                  options={[]}
                  customOptions={false}
                  placeholder={'Member Email'}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="body2"
                  color={'text.secondary'}
                  fontWeight={700}
                >
                  Designation
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <FormField
                  name={'designation'}
                  type={'text'}
                  multiple={false}
                  value={formikTeamMember.values.designation}
                  onChange={formikTeamMember.handleChange}
                  options={[]}
                  customOptions={false}
                  placeholder={'Designation'}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="body2"
                  color={'text.secondary'}
                  fontWeight={700}
                >
                  LinkedIn URL
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <FormField
                  name={'linkedinUrl'}
                  type={'text'}
                  multiple={false}
                  value={formikTeamMember.values.linkedinUrl}
                  onChange={formikTeamMember.handleChange}
                  options={[]}
                  customOptions={false}
                  placeholder={'LinkedIn URL'}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowAddNewTeamMember(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // startIcon={<Add />}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowAddNewTeamMember(false);
              if (typeof showAddNewTeamMember === 'object') {
                formikProspectMember.setFieldValue('relatedMembers', [
                  ...formikProspectMember?.values?.relatedMembers,
                  {
                    name: formikTeamMember.values.name,
                    email: formikTeamMember.values.email,
                    designation: formikTeamMember.values.designation,
                    linkedinUrl: formikTeamMember.values.linkedinUrl,
                    userId: showAddNewTeamMember?.userId,
                    type: showAddNewTeamMember?.type,
                  },
                ]);
                console.log('test');
              } else {
                setTeamMembers((prev) => {
                  return [
                    ...prev,
                    {
                      name: formikTeamMember.values.name,
                      email: formikTeamMember.values.email,
                      designation: formikTeamMember.values.designation,
                      linkedinUrl: formikTeamMember.values.linkedinUrl,
                      userId: null,
                      type: 'non_existing_team_member',
                    },
                  ];
                });
                formikProspectMember.setFieldValue('relatedMembers', [
                  ...formikProspectMember?.values?.relatedMembers,
                  {
                    name: formikTeamMember.values.name,
                    email: formikTeamMember.values.email,
                    designation: formikTeamMember.values.designation,
                    linkedinUrl: formikTeamMember.values.linkedinUrl,
                    userId: null,
                    type: 'non_existing_team_member',
                  },
                ]);
              }
            }}
            color="primary"
            autoFocus
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ProspectMembers.propTypes = {
  prospectMembers: PropTypes.object,
  prospects: PropTypes.array,
  currentAccount: PropTypes.object,
  teamMembers: PropTypes.array,
  setTeamMembers: PropTypes.func,
  editMode: PropTypes.bool,
};

export default ProspectMembers;
