import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Paper,
  Avatar,
  Tabs,
  Tab,
  useMediaQuery,
  TextField,
  InputAdornment,
  Alert,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  FormGroup,
  Checkbox,
  Select,
  MenuItem,
  Link,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
  FormLabel,
  Autocomplete,
  Chip,
  TableFooter,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { PublicFluid } from 'layouts';
import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import getAllCompetitions from 'redux/actions/Common/getAllCompetitions';
import { CompetitionCard } from 'components';
import {
  ArrowDownward,
  ArrowUpward,
  Article,
  AutoGraph,
  Check,
  ChevronRight,
  Close,
  Delete,
  DeleteOutline,
  Edit,
  EventAvailable,
  ExitToApp,
  Label,
  ManageAccounts,
  PersonRemove,
  Remove,
  Star,
  SupportAgent,
  TaskAlt,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import saveToCalendar from 'redux/actions/Candidate/saveToCalendar';
import fileDownload from 'js-file-download';
import axios from 'utils/axios';
import showAlert from 'redux/actions/Common/showAlert';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  EmailAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { auth } from './../../../firebase';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import getCandidateDetails from 'redux/actions/Candidate/getCandidateDetails';
import uploadResume from 'redux/actions/Candidate/uploadResume';
import updateProfile from 'redux/actions/Candidate/updateProfile';
import deleteResume from 'redux/actions/Candidate/deleteResume';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import {
  capitalizeText,
  checkIfNumberExistsBetweenRange,
  validateEmail,
} from 'utils';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import RichTextEditor from 'components/RichTextEditor';
import updatePlaybook from 'redux/actions/Common/updatePlaybook';
import getBots from 'redux/actions/Common/getBots';
import requestBot from 'redux/actions/Common/requestBot';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import getBotRequests from 'redux/actions/Common/getBotRequests';
import updateUserDetails from 'redux/actions/Common/updateUserDetails';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import getAllTeamInvitations from 'redux/actions/Common/getAllTeamInvitations';
import sendTeamInvite from 'redux/actions/Common/sendTeamInvite';
import getAllTeamMembers from 'redux/actions/Common/getAllTeamMembers';
import updateTeamMemberRole from 'redux/actions/Common/updateTeamMemberRole';
import PerformanceDashboard from 'views/Candidate/PerformanceDashboard';
import SalesCallAnalysis from 'views/Candidate/SaleCallAnalysis/SalesCallAnalysis';
import ConversationAIMain from 'views/Candidate/ConversationAIMain';
import { useHistory } from 'react-router-dom';
import removeUserFromOrg from 'redux/actions/Common/removeUserFromOrg';
import getLeaderboard from 'redux/actions/Common/getLeaderboard';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import { timeZones } from 'data';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ActionItems from 'views/Candidate/ActionItems';
import getAllHubspotUsers from 'redux/actions/Common/getAllHubspotUsers';
import mapHubspotUserToTeamMember from 'redux/actions/Common/mapHubspotUserToTeamMember';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import updateUserSettingsV2 from 'redux/actions/Common/updateUserSettingsV2';
import ProfileCompletion from 'components/ProfileCompletion';
import exportScoreDashboardData from 'redux/actions/Common/exportScoreDashboardData';
import { PropTypes } from 'prop-types';
import AccountSettings from '../AccountSettings';
import updateTeamPermissions from 'redux/actions/Common/updateTeamPermissions';
import addRemoveUserFromTeam from 'redux/actions/Common/addRemoveUserFromTeam';
import updateOrgRole from 'redux/actions/Common/updateOrgRole';
import actionCodeBaseUrl from 'config/actionCodeBaseUrl';
import { useParams } from 'react-router-dom';
import mapZohoUserToTeamMember from 'redux/actions/Common/mapZohoUserToTeamMember';
import getAllZohoUsers from 'redux/actions/Common/getAllZohoUsers';

const validationSchema = yup.object({
  currentPassword: yup
    .string()
    .trim()
    .required('Please enter your current password'),
  newPassword: yup
    .string()
    .trim()
    .required('Please enter new password')
    .min(5, 'New password should be atleast 5 character long'),
});

const validationSchemaPlaybook = yup.object({
  companyName: yup
    .string()
    .trim()
    .required('Please enter company name'),
  productDescription: yup
    .string()
    .trim()
    .required('Please enter product description'),
  keyFeatures: yup
    .string()
    .trim()
    .required('Please enter key features of the product or service'),
  secondaryFeatures: yup.string(),
  idealCustomerProfile: yup.string(),
  methodologies: yup.array(),
  customerQualificationQuestions: yup.string(),
  followUpQuestions: yup.string(),
  objectionsAndAnswers: yup.array(),
  expectedCallToAction: yup
    .string()
    .trim()
    .required('Please enter your call to action'),
  competingProducts: yup
    .string()
    .trim()
    .required('Please enter any of the competing product or service'),
});

const TeamSettings = ({ fullWidth, defaultTeamMembersTab }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: false,
  });
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const params = new URLSearchParams(window.location.search);
  const pathParams = useParams();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const { userDetails } = useSelector((state) => state.userDetails);
  const {
    dashboardSidebarExpanded,
    pinnedTeams,
    currentTeamToManage,
  } = useSelector((state) => state.uiStates);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);

  const {
    invitations,
    fetching,
    fetchingPagination,
    lastSeen,
    emptyList,
  } = useSelector((state) => state.allTeamInvitations);
  const allTeamMembers = useSelector((state) => state.allTeamMembers);

  const leaderboard = useSelector((state) => state.leaderboard);
  const {
    showCompetitionDetailsOnTopbar,
    showBackButtonOnTopbar,
    currentNavigationLevel,
    currentContextData,
    loggedInUserTeamDetails,
  } = useSelector((state) => state.uiStates);
  const teamId = localStorage.getItem('teamId') || pathParams?.teamId;
  const [currentUser, setCurrentUser] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [info, setInfo] = useState('');
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);

  const [currentTab, setCurrentTab] = useState('prospects');
  const [playbookTab, setPlaybookTab] = useState('current');
  const [companyName, setCompanyName] = useState(false);
  const [playbook, setPlaybook] = useState('');
  const [editPlaybook, setEditPlaybook] = useState(false);
  const [playbooks, setPlaybooks] = useState([]);

  const [showPlaybook, setShowPlaybook] = useState(null);

  const [publicBots, setPublicBots] = useState([]);
  const [privateBots, setPrivateBots] = useState([]);
  const [botDescription, setBotDescription] = useState('');
  const [vertical, setVertical] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [competitors, setCompetitors] = useState('');

  const [botVertical, setBotVertical] = useState('');
  const [botDesignation, setBotDesignation] = useState('');
  const [botVoice, setBotVoice] = useState('MALE');
  const [botTitle, setBotTitle] = useState('');
  const [botRole, setBotRole] = useState('');
  const [botCompany, setBotCompany] = useState('');
  const [botAboutCompany, setBotAboutCompany] = useState('');
  const [botExperience, setBotExperience] = useState(null);
  const [employeesCount, setEmployeesCount] = useState(null);
  const [topChallenge, setTopChallenge] = useState('');
  const [topCompetitors, setTopCompetitors] = useState('');
  const [otherInfo, setOtherInfo] = useState('');

  const [showBotRequest, setShowBotRequest] = useState(null);
  const [requestedBots, setRequestedBots] = useState([]);

  const [verticalOptions, setVerticalOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);

  const browser = detect();

  const [viewType, setViewType] = useState('team');

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [teamRole, setTeamRole] = useState('STANDARD');
  const [error, setError] = useState('');
  const [updatingRole, setUpdatingRole] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState('');

  const [removingUser, setRemovingUser] = useState(false);

  const [sendingCalendarInvite, setSendingCalendarInvite] = useState(false);
  const [showCalendarInviteModal, setShowCalendarInviteModal] = useState(null);

  const [timeZone, setTimeZone] = useState('select');

  const [startDate, setStartDate] = useState(new Date()?.getTime());
  const [endDate, setEndDate] = useState(
    new Date()?.getTime() + 15 * 60 * 1000,
  );
  const [frequency, setFrequency] = useState('norepeat');
  const [customFrequency, setCustomFrequency] = useState('DAILY');
  const [recurrenceCount, setRecurrenceCount] = useState(10);
  const [recurrenceInterval, setRecurrenceInterval] = useState(1);

  const [endType, setEndType] = useState('never');
  const [endRepeat, setEndRepeat] = useState(new Date()?.getTime());
  const [calendarInviteError, setCalendarInviteError] = useState(false);

  const [currentTeamsTab, setCurrentTeamsTab] = useState('members');
  const [hubspotUsers, setHubspotUsers] = useState([]);
  const [
    showHubspotUserMappingDialog,
    setShowHubspotUserMappingDialog,
  ] = useState(false);
  const [mappingUser, setMappinguser] = useState(false);
  const [hubspotEmail, setHubspotEmail] = useState('');

  const [zohoUsers, setZohoUsers] = useState([]);
  const [showZohoUserMappingDialog, setShowZohoUserMappingDialog] = useState(
    false,
  );
  const [mappingZohoUser, setMappingZohoUser] = useState(false);
  const [zohoEmail, setZohoEmail] = useState('');

  const [
    showManageDefaultConfigurations,
    setShowManageDefaultConfigurations,
  ] = useState(null);

  const [supportedAnalysisLanguages, setSupportedAnalysisLanguages] = useState([
    'english',
  ]);
  const [supportedRoleplayLanguages, setSupportedRoleplayLanguages] = useState([
    'english',
  ]);
  const [defaultAnalysisLanguages, setDefaultAnalysisLanguages] = useState([
    'english',
  ]);
  const [defaultRoleplayLanguages, setDefaultRoleplayLanguages] = useState([
    'english',
  ]);

  const [defaultCallType, setDefaultCallType] = useState('outbound');

  const [updatingUserConfigurations, setUpdatingUserConfigurations] = useState(
    false,
  );

  const [sortedOn, setSortedOn] = useState('');
  const [sortedOrder, setSortedOrder] = useState('neutral');
  const [currentScoreTab, setCurrentScoreTab] = useState('salescall');

  const [numberOfDays, setNumberOfDays] = useState(0);
  const [currentTeam, setCurrentTeam] = useState(null);

  const downloadFile = ({ link, fileName }) => {
    // Create a blob with the data we want to download as a file
    // const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a');
    a.download = fileName;
    a.href = link;
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const resetConfigurationFields = () => {
    if (
      orgConfigs &&
      orgConfigs?.languagesForAnalysis &&
      orgConfigs?.languagesForAnalysis?.length > 0
    ) {
      setSupportedAnalysisLanguages(orgConfigs?.languagesForAnalysis);
    } else {
      setSupportedAnalysisLanguages(['english']);
    }
    if (
      orgConfigs &&
      orgConfigs?.languagesForRolePlay &&
      orgConfigs?.languagesForRolePlay?.length > 0
    ) {
      setSupportedRoleplayLanguages(orgConfigs?.languagesForRolePlay);
    } else {
      setSupportedRoleplayLanguages(['english']);
    }
    if (orgConfigs?.defaultSalesCallType) {
      setDefaultCallType(orgConfigs?.defaultSalesCallType);
    } else {
      setDefaultCallType('outbound');
    }
    setDefaultAnalysisLanguages(
      orgConfigs?.defaultLanguageForAnalysis || ['english'],
    );
    setDefaultRoleplayLanguages(
      orgConfigs?.defaultLanguageForRolePlay || ['english'],
    );
  };

  const resetFields = () => {
    setBotAboutCompany('');
    setBotCompany('');
    setBotDescription('');
    setBotDesignation('');
    setBotExperience(null);
    setBotRole('');
    setBotTitle('');
    setBotVertical('');
    setBotVoice('MALE');
    setEmployeesCount(null);
    setTopChallenge('');
    setOtherInfo('');
    setTopCompetitors('');
  };

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };
  const initialValues = {
    currentPassword: '',
    newPassword: '',
  };

  const initialValuesPlaybook = {
    companyName: '',
    productDescription: '',
    keyFeatures: '',
    secondaryFeatures: '',
    idealCustomerProfile: '',
    methodologies: [],
    customerQualificationQuestions: '',
    followUpQuestions: '',
    objectionsAndAnswers: [
      {
        question: '',
        answer: '',
      },
    ],
    expectedCallToAction: '',
    competingProducts: '',
  };

  const onSubmit = (values) => {
    setProcessing(true);
    const credential = EmailAuthProvider.credential(
      userInfo?.email,
      values.currentPassword,
    );
    reauthenticateWithCredential(currentUser, credential)
      .then(function() {
        updatePassword(currentUser, values.newPassword)
          .then(function() {
            setProcessing(false);
            setInfo('Password updated successfully!');
            setError('');
            formik.setFieldValue('currentPassword', '');
            formik.setFieldValue('newPassword', '');
            formik.setErrors({});
            formik.setFieldTouched('currentPassword', false);
            formik.setFieldTouched('newPassword', false);
          })
          .catch(function(error) {
            setError(error.message);
            setProcessing(false);
            setInfo('');
          });
      })
      .catch(function(error) {
        dispatch(
          logError({
            message: error?.message,
            data: {
              formatted: {
                errorText: error?.message,
                errorType: error?.code,
                severity: 'error',
              },
              raw: error,
            },
          }),
        );
        setError('Invalid current password!');
        setProcessing(false);
        setInfo('');
      });
  };

  const onSubmitPlaybook = (values) => {
    console.log(values);
    handleTrackEvent(null, 'save_or_update_playbook');
    dispatch(
      updatePlaybook(
        null,
        null,
        {
          // playbook: playbook,
          organization: companyName,
          playbookStructure: {
            bant: formikPlaybook.values.methodologies.includes('bant'),
            companyName: formikPlaybook?.values?.companyName,
            competingProducts: formikPlaybook.values.competingProducts,
            customerQualificationQuestions:
              formikPlaybook.values.customerQualificationQuestions,
            customerQuestions: formikPlaybook.values.objectionsAndAnswers,
            expectedCallToAction: formikPlaybook.values.expectedCallToAction,
            idealCustomerProfile: formikPlaybook.values.idealCustomerProfile,
            meddpic: formikPlaybook.values.methodologies.includes('meddpic'),
            playbook: formikPlaybook.values.methodologies.includes('playbook'),
            // playbookText: '',
            productDescription: formikPlaybook.values.productDescription,
            productKeyFeatures: formikPlaybook.values.keyFeatures,
            secondaryFeatures: formikPlaybook.values.secondaryFeatures,
            spin: formikPlaybook.values.methodologies.includes('spin'),
            star: formikPlaybook.values.methodologies.includes('star'),
            followUpQuestions: formikPlaybook.values.followUpQuestions,
          },
        },
        () => {
          dispatch(
            getPlaybookHistory(teamId || null, (data) => {
              setPlaybooks(data?.history);
            }),
          );
          dispatch(getUserDetails(() => {}));
          dispatch(
            showAlert({
              message: 'Playbook updated successfully.',
              showCross: true,
              title: null,
              type: 'success',
              autoHideDuration: 2000,
              vertical: 'top',
              horizontal: 'center',
            }),
          );
        },
      ),
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const formikPlaybook = useFormik({
    initialValues: initialValuesPlaybook,
    validationSchema: validationSchemaPlaybook,
    onSubmit: onSubmitPlaybook,
  });

  const handleListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !allTeamMembers?.fetching &&
        !allTeamMembers?.fetchingPagination &&
        allTeamMembers?.members &&
        allTeamMembers?.members.length % 25 === 0 &&
        allTeamMembers?.emptyList === false) ||
      paginate
    ) {
      dispatch(
        getAllTeamMembers(
          teamId || null,
          null,
          true,
          numberOfDays,
          25,
          allTeamMembers?.lastSeen,
          () => {},
        ),
      );
    }
  };

  const handleInvitationsListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        invitations &&
        invitations.length % 25 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(
        getAllTeamInvitations(
          'team_invite',
          teamId || null,
          25,
          lastSeen,
          () => {},
        ),
      );
    }
  };

  useEffect(() => {
    if (currentTab === 'bots') {
      dispatch(
        getBots(null, (data) => {
          // console.log(data);
          setPublicBots(data?.public);
          setPrivateBots(data?.personal);
        }),
      );
      dispatch(
        getBotRequests((data) => {
          // console.log(data);
          setRequestedBots(data?.botRequests);
        }),
      );
    }
    if (currentTab === 'prospects') {
      dispatch(
        getDefaultPrompt((data) => {
          // console.log(data);
          setVerticalOptions(data?.verticals);
          setDesignationOptions(data?.designations);
        }),
      );
    }
  }, [currentTab]);

  useEffect(() => {
    setCompanyName(userDetails?.custom?.organization || '');
    formikPlaybook.setValues({
      companyName: userDetails?.custom?.playbookStructure?.companyName,
      productDescription:
        userDetails?.custom?.playbookStructure?.productDescription,
      keyFeatures: userDetails?.custom?.playbookStructure?.productKeyFeatures,
      secondaryFeatures:
        userDetails?.custom?.playbookStructure?.secondaryFeatures,
      idealCustomerProfile:
        userDetails?.custom?.playbookStructure?.idealCustomerProfile,
      methodologies: [
        userDetails?.custom?.playbookStructure?.bant && 'bant',
        userDetails?.custom?.playbookStructure?.star && 'star',
        userDetails?.custom?.playbookStructure?.spin && 'spin',
        userDetails?.custom?.playbookStructure?.meddpic && 'meddpic',
        userDetails?.custom?.playbookStructure?.playbook && 'playbook',
      ].filter((mthd) => mthd),
      competingProducts:
        userDetails?.custom?.playbookStructure?.competingProducts,
      expectedCallToAction:
        userDetails?.custom?.playbookStructure?.expectedCallToAction,
      customerQualificationQuestions:
        userDetails?.custom?.playbookStructure?.customerQualificationQuestions,
      followUpQuestions:
        userDetails?.custom?.playbookStructure?.followUpQuestions,
      objectionsAndAnswers:
        userDetails?.custom?.playbookStructure?.customerQuestions || [],
    });
    setDesignation(userDetails?.custom?.botDesignations || []);
    setVertical(userDetails?.custom?.botVerticals || []);
    setCompetitors(userDetails?.custom?.botCompetitors?.join(', ') || '');
  }, [userDetails]);

  useEffect(() => {
    console.log(currentContextData?.currentTeamTab);
    if (params && params.get('new') === 'true') {
      setCurrentTab('invite');
      setViewType('invite');
    } else if (
      (params && params.get('manageusers') === 'true') ||
      currentContextData?.currentTeamTab === 'manageusers'
    ) {
      setCurrentTab('team');
      setViewType('team');
    } else if (
      (params && params.get('scoresboard') === 'true') ||
      currentContextData?.currentTeamTab === 'scoresboard'
    ) {
      setCurrentTab('scoresboard');
      setViewType('scoresboard');
    } else if (
      (params && params.get('teamsettings') === 'true') ||
      currentContextData?.currentTeamTab === 'teamsettings'
    ) {
      setCurrentTab('teamsettings');
      setViewType('teamsettings');
    } else {
      setCurrentTab('leaderboard');
      setViewType('leaderboard');
    }
  }, [window.location.href, currentContextData?.currentTeamTab]);

  useEffect(() => {
    if (
      orgConfigs &&
      orgConfigs?.languagesForAnalysis &&
      orgConfigs?.languagesForAnalysis?.length > 0
    ) {
      setSupportedAnalysisLanguages(orgConfigs?.languagesForAnalysis);
    } else {
      setSupportedAnalysisLanguages(['english']);
    }
    if (
      orgConfigs &&
      orgConfigs?.languagesForRolePlay &&
      orgConfigs?.languagesForRolePlay?.length > 0
    ) {
      setSupportedRoleplayLanguages(orgConfigs?.languagesForRolePlay);
    } else {
      setSupportedRoleplayLanguages(['english']);
    }
    if (orgConfigs?.defaultSalesCallType) {
      setDefaultCallType(orgConfigs?.defaultSalesCallType);
    } else {
      setDefaultCallType('outbound');
    }
    setDefaultAnalysisLanguages(
      orgConfigs?.defaultLanguageForAnalysis || ['english'],
    );
    setDefaultRoleplayLanguages(
      orgConfigs?.defaultLanguageForRolePlay || ['english'],
    );
  }, [orgConfigs]);

  useEffect(() => {
    if (showManageDefaultConfigurations) {
      if (
        showManageDefaultConfigurations?.userSetting &&
        showManageDefaultConfigurations?.userSetting?.languagesForAnalysis &&
        showManageDefaultConfigurations?.userSetting?.languagesForAnalysis
          ?.length > 0
      ) {
        setSupportedAnalysisLanguages(
          showManageDefaultConfigurations?.userSetting?.languagesForAnalysis,
        );
      } else {
        setSupportedAnalysisLanguages(['english']);
      }
      if (
        showManageDefaultConfigurations?.userSetting &&
        showManageDefaultConfigurations?.userSetting?.languagesForRolePlay &&
        showManageDefaultConfigurations?.userSetting?.languagesForRolePlay
          ?.length > 0
      ) {
        setSupportedRoleplayLanguages(
          showManageDefaultConfigurations?.userSetting?.languagesForRolePlay,
        );
      } else {
        setSupportedRoleplayLanguages(['english']);
      }
      if (showManageDefaultConfigurations?.userSetting?.salesCallType) {
        setDefaultCallType(
          showManageDefaultConfigurations?.userSetting?.salesCallType,
        );
      } else {
        setDefaultCallType('outbound');
      }
      setDefaultAnalysisLanguages(
        showManageDefaultConfigurations?.userSetting
          ?.defaultLanguagesForAnalysis || ['english'],
      );
      setDefaultRoleplayLanguages(
        showManageDefaultConfigurations?.userSetting
          ?.defaultLanguagesForRolePlay || ['english'],
      );
    }
  }, [showManageDefaultConfigurations]);

  useEffect(() => {
    if (defaultTeamMembersTab) {
      setCurrentTeamsTab(defaultTeamMembersTab);
    } else {
      setCurrentTeamsTab('members');
      if (params && params.get('teaminvite') === 'true') {
        setCurrentTeamsTab('invite');
      } else {
        setCurrentTeamsTab('members');
      }
    }
  }, [defaultTeamMembersTab]);

  useEffect(() => {
    if (currentTab === 'invite' || currentTeamsTab === 'invitations') {
      dispatch(
        getAllTeamInvitations(
          'team_invite',
          teamId || null,
          25,
          null,
          (data) => {},
        ),
      );
    } else if (currentTab === 'team') {
      dispatch(
        getAllTeamMembers(
          teamId || null,
          null,
          true,
          numberOfDays,
          25,
          null,
          (data) => {},
        ),
      );
    }
  }, [currentTab, currentTeamsTab]);

  useEffect(() => {
    dispatch(
      getAllTeamMembers(
        teamId || null,
        null,
        true,
        numberOfDays,
        25,
        null,
        (data) => {},
      ),
    );
  }, [numberOfDays]);

  useEffect(() => {
    if (!showBackButtonOnTopbar) {
      setViewType('team');
    }
  }, [showBackButtonOnTopbar]);

  useEffect(() => {
    if (
      viewType === 'dashboard' ||
      viewType === 'practiceCalls' ||
      viewType === 'actionItems' ||
      viewType === 'salesCalls' ||
      fullWidth
    ) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: true,
        },
      });
    } else {
      if (currentNavigationLevel === 1) {
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'showBackButtonOnTopbar',
            value: false,
          },
        });
      }
    }
  }, [viewType, currentNavigationLevel]);

  useEffect(() => {
    if (
      viewType === 'leaderboard' ||
      currentTab === 'leaderboard' ||
      currentTab === 'scoresboard' ||
      viewType === 'scoresboard'
    ) {
      dispatch(getLeaderboard(teamId || null, (data) => {}));
    }
  }, [viewType, currentTab]);

  useEffect(() => {
    if (showHubspotUserMappingDialog) {
      setHubspotEmail(
        allTeamMembers?.members?.find(
          (member) => member?.id === showHubspotUserMappingDialog,
        )?.custom?.hubspotUser?.hubspotEmail,
      );
      dispatch(
        getAllHubspotUsers((data) => {
          // console.log(data);
          setHubspotUsers(data?.results);
        }),
      );
    }
  }, [showHubspotUserMappingDialog]);

  useEffect(() => {
    if (showZohoUserMappingDialog) {
      setZohoEmail(
        allTeamMembers?.members?.find(
          (member) => member?.id === showZohoUserMappingDialog,
        )?.custom?.zohoUser?.zohoEmail,
      );
      dispatch(
        getAllZohoUsers((data) => {
          // console.log(data);
          setZohoUsers(data?.users);
        }),
      );
    }
  }, [showZohoUserMappingDialog]);

  useEffect(() => {
    if (showCalendarInviteModal) {
      setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
  }, [showCalendarInviteModal]);

  useEffect(() => {
    if (
      userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
      userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN'
    ) {
      setCurrentTeam(
        teamId === userDetails?.organizationId
          ? pinnedTeams?.find((t) => t?.id === teamId)
          : currentTeamToManage,
      );
    } else if (userInfo?.teamRoles?.[0] === 'TEAM_ADMIN') {
      setCurrentTeam(loggedInUserTeamDetails);
    }
  }, [
    userDetails,
    pinnedTeams,
    currentTeamToManage,
    teamId,
    loggedInUserTeamDetails,
  ]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      unsubscribe();
    });
    dispatch(getUserDetails((data) => {}));
    dispatch(getOrganizationConfigs((data) => {}));
    return () => {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentNavigationLevel',
          value: 1,
        },
      });
      // dispatch({
      //   type: 'UPDATE_UI_STATE',
      //   payload: {
      //     key: 'showBackButtonOnTopbar',
      //     value: false,
      //   },
      // });
    };
  }, []);

  // console.log(formikPlaybook.values);
  // console.log(showPlaybook);

  // console.log(timeZones);
  // console.log(timeZone);
  // console.log(allTeamMembers);
  // console.log(currentNavigationLevel);
  // console.log(viewType);
  // console.log(currentNavigationLevel);
  // console.log(showBackButtonOnTopbar);
  // console.log(sortedOn);
  // console.log(sortedOrder);
  // console.log(leaderboard);
  // console.log('loggedInUserTeamDetails', loggedInUserTeamDetails);
  // console.log('currentTeam', currentTeam);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? (fullWidth ? 1 : 'calc(100% - 80px)') : 1,
          md: isLoggedIn
            ? fullWidth
              ? 1
              : dashboardSidebarExpanded
              ? 'calc(100% - 240px)'
              : 'calc(100% - 80px)'
            : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md: isLoggedIn
            ? fullWidth
              ? 0
              : dashboardSidebarExpanded
              ? '240px'
              : '80px'
            : 0,
        },
        height: 1,
        minHeight: isLoggedIn ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Container
        paddingX={
          isLoggedIn
            ? fullWidth
              ? 2
              : dashboardSidebarExpanded
              ? 2
              : { lg: 16, md: 8, sm: 4, xs: 1 }
            : 8
        }
        paddingY={fullWidth ? 2 : { lg: 2, md: 2, sm: 2, xs: 3 }}
        maxWidth={{ xs: 1, sm: 1, md: isLoggedIn ? 1 : 1236 }}
      >
        <Box
          sx={{
            minHeight: 400,
          }}
        >
          <Box>
            {isXs ? (
              <Tabs
                value={currentTab}
                onChange={(e, newValue) => setCurrentTab(newValue)}
                aria-label="Settings Tabs"
                orientation={'horizontal'}
                // indicatorColor={theme.palette.background.default}
                variant="scrollable"
                // scrollButtons={!isMd}
                allowScrollButtonsMobile
                sx={{
                  borderBottom: '1px solid #d3d3d3',
                }}
              >
                {userInfo?.designations?.includes('account_admin') ? (
                  <Tab
                    value={'prospects'}
                    label={<Typography variant="body1">Prospects</Typography>}
                    id={`simple-tab-${5}`}
                    aria-controls={`simple-tabpanel-${5}`}
                    sx={{}}
                  />
                ) : null}
                {userInfo?.designations?.includes('account_admin') ? (
                  <Tab
                    value={'playbook'}
                    label={<Typography variant="body1">Playbook</Typography>}
                    id={`simple-tab-${5}`}
                    aria-controls={`simple-tabpanel-${5}`}
                    sx={{}}
                  />
                ) : null}
                {userInfo?.designations?.includes('account_admin') ? (
                  <Tab
                    value={'bots'}
                    label={<Typography variant="body1">Bots</Typography>}
                    id={`simple-tab-${5}`}
                    aria-controls={`simple-tabpanel-${5}`}
                    sx={{}}
                  />
                ) : null}
                {userInfo?.designations?.includes('account_admin') ? (
                  <Tab
                    value={'requestbot'}
                    label={
                      <Typography variant="body1">Request New Bot</Typography>
                    }
                    id={`simple-tab-${5}`}
                    aria-controls={`simple-tabpanel-${5}`}
                    sx={{}}
                  />
                ) : null}
                <Tab
                  value={'profile'}
                  label={<Typography variant="body1">Profile</Typography>}
                  id={`simple-tab-${0}`}
                  aria-controls={`simple-tabpanel-${0}`}
                  sx={{}}
                />
              </Tabs>
            ) : null}
            {viewType === 'dashboard' ? (
              <PerformanceDashboard
                uId={currentMemberId}
                member={leaderboard?.leaderboard?.find(
                  (member) => member?.id === currentMemberId,
                )}
              />
            ) : viewType === 'practiceCalls' ? (
              <ConversationAIMain
                parentComponent="teamSettings"
                uId={currentMemberId}
                member={leaderboard?.leaderboard?.find(
                  (member) => member?.id === currentMemberId,
                )}
              />
            ) : viewType === 'salesCalls' ? (
              <SalesCallAnalysis
                parentComponent="teamSettings"
                uId={currentMemberId}
                member={leaderboard?.leaderboard?.find(
                  (member) => member?.id === currentMemberId,
                )}
                currentTeam={currentTeam}
              />
            ) : viewType === 'actionItems' ? (
              <ActionItems
                parentComponent="teamSettings"
                uId={currentMemberId}
                member={leaderboard?.leaderboard?.find(
                  (member) => member?.id === currentMemberId,
                )}
              />
            ) : (
              <>
                {currentTab === 'invite' ? (
                  <Box display={'flex'} gap={2} p={2}>
                    <Box flex={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle2"
                            color={'text.secondary'}
                          >
                            First Name
                          </Typography>
                          <TextField
                            placeholder="First Name"
                            variant="outlined"
                            name={'firstName'}
                            // fullWidth
                            // multiline
                            // rows={3}
                            size="small"
                            sx={{
                              width: '100%',
                            }}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle2"
                            color={'text.secondary'}
                          >
                            Last Name
                          </Typography>
                          <TextField
                            placeholder="Last Name"
                            variant="outlined"
                            name={'lastName'}
                            // fullWidth
                            // multiline
                            // rows={3}
                            size="small"
                            sx={{
                              width: '100%',
                            }}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle2"
                            color={'text.secondary'}
                          >
                            Email
                          </Typography>
                          <TextField
                            placeholder="Email"
                            variant="outlined"
                            name={'email'}
                            // fullWidth
                            // multiline
                            // rows={3}
                            size="small"
                            sx={{
                              width: '100%',
                            }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle2"
                            color={'text.secondary'}
                            // sx={{ marginBottom: 1 }}
                          >
                            Select Role
                          </Typography>
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              labelId="role-select-label"
                              id="role-select"
                              name="teamRole"
                              MenuProps={{ disableScrollLock: true }}
                              onChange={(e) => setTeamRole(e.target.value)}
                              value={teamRole}
                            >
                              <MenuItem key={'standard1'} value="STANDARD">
                                STANDARD
                              </MenuItem>
                              <MenuItem key={'ORG_ADMIN1'} value="TEAM_ADMIN">
                                ADMIN
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {error && (
                          <Grid item xs={12}>
                            <Alert severity="error">{error}</Alert>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            // sx={{ marginLeft: 'auto' }}
                            onClick={() => {
                              if (
                                email &&
                                email?.trim() &&
                                validateEmail(email?.trim())
                              ) {
                                if (
                                  userDetails?.plan?.trailEndsOn >
                                    new Date().getTime() ||
                                  userDetails?.plan?.currentPaymentStatus ===
                                    'paid'
                                ) {
                                  setLoading(true);
                                  dispatch(
                                    sendTeamInvite(
                                      {
                                        invitationType: 'team_invite',
                                        receiverDtls: [
                                          {
                                            designations: ['account_admin'],
                                            email,
                                            emailVerified: true,
                                            firstName,
                                            lastName,
                                            teamRole: teamRole,
                                            // orgRoles: [teamRole],
                                            teamId: teamId ? teamId : null,
                                            // teamRoles: [teamRole],
                                          },
                                        ],
                                      },
                                      (error) => {
                                        if (error) {
                                          console.log(error);
                                          setLoading(false);
                                          dispatch(
                                            showAlert({
                                              message: error?.includes(
                                                'already exists',
                                              )
                                                ? 'User already registered with the same email address'
                                                : 'Failed to send the invitation. Please try again!',
                                              showCross: true,
                                              title: null,
                                              type: 'error',
                                              autoHideDuration: 2000,
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }),
                                          );
                                        } else {
                                          dispatch(
                                            getAllTeamInvitations(
                                              'team_invite',
                                              teamId || null,
                                              25,
                                              null,
                                              () => {},
                                            ),
                                          );
                                          setLoading(false);
                                          dispatch(
                                            showAlert({
                                              message:
                                                'Invitation has been sent successfully.',
                                              showCross: true,
                                              title: null,
                                              type: 'success',
                                              autoHideDuration: 2000,
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }),
                                          );
                                        }
                                      },
                                    ),
                                  );
                                } else {
                                  dispatch({
                                    type: 'UPDATE_UI_STATE',
                                    payload: {
                                      key: 'showPlanExpired',
                                      value: true,
                                    },
                                  });
                                }
                              } else {
                                setError('Please enter a valid email.');
                              }
                            }}
                          >
                            Send Invite
                          </Button>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="subtitle2"
                        // color={'text.secondary'}
                        sx={{
                          marginTop: 2,
                          background: '#d3d3d3',
                          padding: 1,
                          textAlign: 'center',
                          color: '#343a40',
                        }}
                      >
                        Previous Invitations
                      </Typography>
                      {fetching ? (
                        <Box
                          width={1}
                          height={1}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          flexDirection={'column'}
                        >
                          <CircularProgress size={20} />
                          <Typography
                            variant="body1"
                            color={'text.primary'}
                            mt={4}
                          >
                            Loading all invitations
                          </Typography>
                        </Box>
                      ) : invitations && invitations?.length > 0 ? (
                        <TableContainer
                          component={Paper}
                          sx={{
                            minHeight: isXs
                              ? 'calc(100vh - 140px)'
                              : 'calc(100vh - 340px)',
                            maxHeight: isXs
                              ? 'calc(100vh - 140px)'
                              : 'calc(100vh - 340px)',
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                              width: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 2,
                            },
                            paddingBottom: 2,
                          }}
                          onScroll={handleInvitationsListScroll}
                        >
                          <Table
                            sx={{ minWidth: 750 }}
                            stickyHeader
                            aria-label="simple table"
                          >
                            <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                              <TableRow
                                sx={{
                                  '& .MuiTableCell-root': {
                                    // border: '1px solid #e1e1e1',
                                  },
                                }}
                              >
                                <TableCell>
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    Member
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    Invited On
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    Joined On
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'capitalize' }}
                                  >
                                    Role
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {invitations.map((invitation, i) => (
                                <TableRow key={invitation?.id}>
                                  <TableCell
                                    sx={{
                                      // border: '1px solid #e1e1e1',
                                      position: 'relative',
                                    }}
                                  >
                                    <Box
                                      display={'flex'}
                                      flexDirection={'column'}
                                      // gap={1}
                                    >
                                      <Typography
                                        fontWeight={'bold'}
                                        color={'text.secondary'}
                                      >
                                        {invitation?.receiverEmail}
                                      </Typography>
                                      <Typography variant="caption">{`${invitation?.firstName ||
                                        ''} ${invitation?.lastName ||
                                        ''}`}</Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    // sx={{ border: '1px solid #e1e1e1' }}
                                  >
                                    <Typography
                                      variant="caption"
                                      // fontWeight={700}
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      {moment(invitation.createdOn).format(
                                        'DD MMMM YY',
                                      )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    // sx={{ border: '1px solid #e1e1e1' }}
                                  >
                                    <Typography
                                      variant="caption"
                                      // fontWeight={700}
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      {invitation?.joined
                                        ? moment(invitation.joinedOn).format(
                                            'DD MMMM YY',
                                          )
                                        : 'Invitation Sent'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    // sx={{ border: '1px solid #e1e1e1' }}
                                  >
                                    <Typography
                                      variant="caption"
                                      // fontWeight={700}
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      {invitation?.teamRole === 'TEAM_ADMIN'
                                        ? 'ADMIN'
                                        : 'STANDARD'}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TableCell
                                  variant="footer"
                                  colSpan={8}
                                  sx={{
                                    padding: 0,
                                  }}
                                >
                                  {fetchingPagination ? (
                                    <Box
                                      sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: 1,
                                        textAlign: 'center',
                                      }}
                                    >
                                      <Button
                                        size="small"
                                        variant="contained"
                                        // type="submit"
                                        color="primary"
                                        onClick={handleListScroll}
                                        // className="searchSettings"
                                        disabled
                                        // fullWidth
                                      >
                                        <Typography color="textPrimary">
                                          Loading more invitations ...{' '}
                                        </Typography>
                                        <CircularProgress size={20} />
                                      </Button>
                                    </Box>
                                  ) : emptyList === false &&
                                    invitations.length >= 25 ? (
                                    <Box
                                      sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: 1,
                                        textAlign: 'center',
                                      }}
                                    >
                                      <Button
                                        size="small"
                                        variant="contained"
                                        // type="submit"
                                        // fullWidth
                                        color="primary"
                                        onClick={(e) =>
                                          handleListScroll(e, true)
                                        }
                                        // className="searchSettings"
                                      >
                                        Load More
                                      </Button>
                                    </Box>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Box
                          width={1}
                          height={1}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          flexDirection={'column'}
                          gap={2}
                        >
                          <Typography
                            variant="body1"
                            color={'text.secondary'}
                            mt={4}
                          >
                            No invites sent yet.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box flex={1}></Box>
                  </Box>
                ) : currentTab === 'team' ? (
                  <Box>
                    {currentTeamsTab === 'members' &&
                    pathname !== '/org-settings' ? (
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={numberOfDays}
                          onChange={(e) => {
                            setNumberOfDays(e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle2">
                                All time
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value={7}
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle2">
                                Last 7 days
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value={30}
                            control={<Radio size="small" />}
                            label={
                              <Typography variant="subtitle2">
                                Last 30 days
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : null}
                    <Box
                      sx={{
                        border:
                          pathname !== '/org-settings'
                            ? '1px solid #d3d3d3'
                            : 'none',
                        borderRadius: 2,
                        minHeight: 'calc(100vh - 140px)',
                      }}
                    >
                      {pathname !== '/org-settings' ? (
                        <Tabs
                          value={currentTeamsTab}
                          onChange={(e, newValue) =>
                            setCurrentTeamsTab(newValue)
                          }
                          aria-label="Teams Tabs"
                          orientation={'horizontal'}
                          // indicatorColor={theme.palette.background.default}
                          variant="scrollable"
                          // scrollButtons={!isMd}
                          allowScrollButtonsMobile
                          sx={{
                            borderBottom: '1px solid #d3d3d3',
                          }}
                        >
                          <Tab
                            value={'members'}
                            label={
                              <Typography variant="body1">
                                Manage Members
                              </Typography>
                            }
                            id={`simple-tab-${0}`}
                            aria-controls={`simple-tabpanel-${0}`}
                            sx={{}}
                          />
                          <Tab
                            value={'invite'}
                            label={
                              <Typography variant="body1">
                                Invite Members
                              </Typography>
                            }
                            id={`simple-tab-${0}`}
                            aria-controls={`simple-tabpanel-${0}`}
                            sx={{}}
                          />
                          <Tab
                            value={'invitations'}
                            label={
                              <Typography variant="body1">
                                Invitations
                              </Typography>
                            }
                            id={`simple-tab-${0}`}
                            aria-controls={`simple-tabpanel-${0}`}
                            sx={{}}
                          />
                        </Tabs>
                      ) : null}
                      {currentTeamsTab === 'members' ? (
                        <>
                          {allTeamMembers?.fetching ? (
                            <Box
                              width={1}
                              height={1}
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              flexDirection={'column'}
                            >
                              <CircularProgress size={20} />
                              <Typography
                                variant="body1"
                                color={'text.primary'}
                                mt={4}
                              >
                                Loading members ...
                              </Typography>
                            </Box>
                          ) : allTeamMembers?.members &&
                            allTeamMembers?.members?.length > 0 ? (
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={1}
                            >
                              <TableContainer
                                component={Paper}
                                sx={{
                                  minHeight: isXs
                                    ? 'calc(100vh - 190px)'
                                    : pathname !== '/org-settings'
                                    ? 'calc(100vh - 190px)'
                                    : 'calc(100vh - 92px)',
                                  maxHeight: isXs
                                    ? 'calc(100vh - 190px)'
                                    : pathname !== '/org-settings'
                                    ? 'calc(100vh - 190px)'
                                    : 'calc(100vh - 92px)',
                                  overflowY: 'auto',
                                  '&::-webkit-scrollbar': {
                                    width: '5px',
                                    height: '5px',
                                  },
                                  '&::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                    webkitBoxShadow:
                                      'inset 0 0 6px rgba(0,0,0,0.00)',
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: theme.palette.primary.main,
                                    borderRadius: 2,
                                  },
                                }}
                                onScroll={handleListScroll}
                              >
                                <Table
                                  sx={{ minWidth: 750 }}
                                  stickyHeader
                                  aria-label="simple table"
                                >
                                  <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                                    <TableRow
                                      sx={{
                                        '& .MuiTableCell-root': {
                                          // border: '1px solid #e1e1e1',
                                        },
                                      }}
                                    >
                                      <TableCell>
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{
                                            textTransform: 'capitalize',
                                          }}
                                        >
                                          Member
                                        </Typography>
                                      </TableCell>
                                      {pathname === '/org-settings' ? (
                                        <TableCell align="center">
                                          <Typography
                                            variant={'caption'}
                                            fontWeight={700}
                                            sx={{ textTransform: 'capitalize' }}
                                          >
                                            Joined On
                                          </Typography>
                                        </TableCell>
                                      ) : null}
                                      {pathname !== '/org-settings' ? (
                                        <>
                                          <TableCell align="center">
                                            <Typography
                                              variant={'caption'}
                                              fontWeight={700}
                                              sx={{
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {orgConfigs?.navBarTitles?.[
                                                'Sales Call Analysis'
                                              ]?.title || 'Sales Call Analysis'}
                                            </Typography>
                                          </TableCell>
                                          {orgConfigs?.rolePlayVisibilityControl &&
                                          currentTeam?.rolePlayVisibility ===
                                            'team' ? (
                                            <TableCell align="center">
                                              <Typography
                                                variant={'caption'}
                                                fontWeight={700}
                                                sx={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {orgConfigs?.navBarTitles?.[
                                                  'Private Roleplays'
                                                ]?.title || 'Private Roleplays'}
                                              </Typography>
                                            </TableCell>
                                          ) : null}
                                        </>
                                      ) : null}
                                      {pathname === '/org-settings' && (
                                        <TableCell align="center">
                                          <Typography
                                            variant={'caption'}
                                            fontWeight={700}
                                            sx={{ textTransform: 'capitalize' }}
                                          >
                                            Team
                                          </Typography>
                                        </TableCell>
                                      )}
                                      {/* {!userInfo?.orgRoles?.includes(
                                      'WL_ORG_ADMIN',
                                    ) ? ( */}
                                      <TableCell align="center">
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'capitalize' }}
                                        >
                                          Role
                                        </Typography>
                                      </TableCell>
                                      {/* ) : null} */}
                                      {/* {pathname !== '/org-settings' && ( */}
                                      <TableCell align="center">
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'capitalize' }}
                                          align="center"
                                        >
                                          Actions
                                        </Typography>
                                      </TableCell>
                                      {/* )} */}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {allTeamMembers?.members.map(
                                      (member, i) => (
                                        <TableRow key={member?.id}>
                                          <TableCell component="th" scope="row">
                                            <Box
                                              display={'flex'}
                                              flexDirection={'column'}
                                              // gap={1}
                                            >
                                              <Typography
                                                fontWeight={'bold'}
                                                color={'text.secondary'}
                                              >
                                                {member?.fullName || ''}
                                              </Typography>
                                              <Typography variant="caption">
                                                {member?.email}
                                              </Typography>
                                            </Box>
                                          </TableCell>
                                          {pathname !== '/org-settings' ? (
                                            <>
                                              <TableCell align="center" sx={{}}>
                                                {member?.custom?.counts
                                                  ?.salesCallAnalysed > 0 ? (
                                                  <Button
                                                    variant="text"
                                                    color="primary"
                                                    onClick={() => {
                                                      // if (
                                                      //   member?.id ===
                                                      //   userInfo?.id
                                                      // ) {
                                                      //   history.push(
                                                      //     '/sales-call-analysis',
                                                      //   );
                                                      // } else {
                                                      setViewType('salesCalls');
                                                      setCurrentMemberId(
                                                        member?.id,
                                                      );
                                                      dispatch({
                                                        type: 'UPDATE_UI_STATE',
                                                        payload: {
                                                          key:
                                                            'currentNavigationLevel',
                                                          value:
                                                            currentNavigationLevel +
                                                            1,
                                                        },
                                                      });
                                                      // }
                                                    }}
                                                  >
                                                    <Typography
                                                      variant="body2"
                                                      fontWeight={'bold'}
                                                      sx={{
                                                        textDecoration:
                                                          'underline',
                                                      }}
                                                    >
                                                      {member?.custom?.counts
                                                        ?.salesCallAnalysed ||
                                                        0}
                                                    </Typography>
                                                  </Button>
                                                ) : (
                                                  <Typography
                                                    // color={'text.secondary'}
                                                    variant={'caption'}
                                                  >
                                                    {member?.custom?.counts
                                                      ?.salesCallAnalysed || 0}
                                                  </Typography>
                                                )}
                                              </TableCell>
                                              {orgConfigs?.rolePlayVisibilityControl &&
                                              currentTeam?.rolePlayVisibility ===
                                                'team' ? (
                                                <TableCell
                                                  align="center"
                                                  sx={{}}
                                                >
                                                  {member?.custom?.counts
                                                    ?.practiceCallAnalysed >
                                                  0 ? (
                                                    <Button
                                                      size="small"
                                                      variant="text"
                                                      color="primary"
                                                      onClick={() => {
                                                        // if (
                                                        //   member?.id ===
                                                        //   userInfo?.id
                                                        // ) {
                                                        //   history.push(
                                                        //     '/conversation-ai',
                                                        //   );
                                                        // } else {
                                                        setViewType(
                                                          'practiceCalls',
                                                        );
                                                        setCurrentMemberId(
                                                          member?.id,
                                                        );
                                                        // if (uId) {
                                                        dispatch({
                                                          type:
                                                            'UPDATE_UI_STATE',
                                                          payload: {
                                                            key:
                                                              'currentNavigationLevel',
                                                            value:
                                                              currentNavigationLevel +
                                                              1,
                                                          },
                                                        });
                                                        // }
                                                        // }
                                                      }}
                                                    >
                                                      <Typography
                                                        variant="body2"
                                                        fontWeight={'bold'}
                                                        sx={{
                                                          textDecoration:
                                                            'underline',
                                                        }}
                                                      >
                                                        {member?.custom?.counts
                                                          ?.practiceCallAnalysed ||
                                                          0}
                                                      </Typography>
                                                    </Button>
                                                  ) : (
                                                    <Typography
                                                      // color={'text.secondary'}
                                                      variant={'caption'}
                                                    >
                                                      {member?.custom?.counts
                                                        ?.practiceCallAnalysed ||
                                                        0}
                                                    </Typography>
                                                  )}
                                                </TableCell>
                                              ) : null}
                                            </>
                                          ) : null}
                                          {pathname === '/org-settings' ? (
                                            <TableCell align="center">
                                              <Typography
                                                // color={'text.secondary'}
                                                variant={'subtitle2'}
                                              >
                                                {moment(
                                                  member?.createdOn,
                                                ).format('DD/MM/YYYY')}
                                              </Typography>
                                            </TableCell>
                                          ) : null}
                                          {pathname === '/org-settings' && (
                                            <TableCell align="center">
                                              <Typography
                                                variant={'caption'}
                                                fontWeight={700}
                                                sx={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {member?.custom?.teams?.length >
                                                1
                                                  ? member?.custom?.teams?.find(
                                                      (team) =>
                                                        team?.id !==
                                                        member?.organizationId,
                                                    )?.name || '--'
                                                  : member?.custom?.teams?.[0]
                                                      ?.name || '--'}
                                              </Typography>
                                            </TableCell>
                                          )}
                                          {/* {!userInfo?.orgRoles?.includes(
                                        'WL_ORG_ADMIN',
                                      ) ? ( */}
                                          <TableCell align="center">
                                            {member?.custom?.teams?.[0]
                                              ?.name ? (
                                              <FormControl
                                                fullWidth
                                                size="small"
                                              >
                                                <Select
                                                  disabled={
                                                    member?.email ===
                                                      userInfo?.email ||
                                                    updatingRole
                                                  }
                                                  size="small"
                                                  labelId="role-select-label"
                                                  id="role-select"
                                                  name="teamRole"
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                  }}
                                                  onChange={(e) => {
                                                    setUpdatingRole(true);
                                                    dispatch(
                                                      updateTeamPermissions(
                                                        teamId ||
                                                          member?.custom
                                                            ?.teams?.[0]?.id,
                                                        {
                                                          usersPermissions: {
                                                            ...(Object.keys(
                                                              currentTeam?.usersPermissions ||
                                                                {},
                                                            )?.reduce(
                                                              (acc, curr) => {
                                                                acc[curr] = {
                                                                  write: true,
                                                                  read: true,
                                                                  teamRole: allTeamMembers?.members
                                                                    ?.find(
                                                                      (
                                                                        member,
                                                                      ) =>
                                                                        curr ===
                                                                        member?.id,
                                                                    )
                                                                    ?.teamRoles?.includes(
                                                                      'TEAM_ADMIN',
                                                                    )
                                                                    ? 'TEAM_ADMIN'
                                                                    : 'STANDARD',
                                                                };
                                                                return acc;
                                                              },
                                                              {},
                                                            ) || []),
                                                            [member?.id]: {
                                                              write: true,
                                                              read: true,
                                                              teamRole:
                                                                e.target.value,
                                                            },
                                                          },
                                                        },
                                                        (error) => {
                                                          if (error) {
                                                            dispatch(
                                                              showAlert({
                                                                message:
                                                                  'Failed to update role',
                                                                showCross: true,
                                                                title: null,
                                                                type: 'error',
                                                                autoHideDuration: 2000,
                                                                vertical: 'top',
                                                                horizontal:
                                                                  'center',
                                                              }),
                                                            );
                                                          } else {
                                                            dispatch(
                                                              getAllTeamMembers(
                                                                teamId || null,
                                                                null,
                                                                true,
                                                                numberOfDays,
                                                                25,
                                                                null,
                                                                () => {},
                                                              ),
                                                            );
                                                            dispatch(
                                                              showAlert({
                                                                message:
                                                                  'Role updated successfully.',
                                                                showCross: true,
                                                                title: null,
                                                                type: 'success',
                                                                autoHideDuration: 2000,
                                                                vertical: 'top',
                                                                horizontal:
                                                                  'center',
                                                              }),
                                                            );
                                                          }
                                                          setUpdatingRole(
                                                            false,
                                                          );
                                                        },
                                                      ),
                                                    );
                                                  }}
                                                  value={
                                                    member?.teamRoles?.includes(
                                                      'TEAM_ADMIN',
                                                    )
                                                      ? 'TEAM_ADMIN'
                                                      : member?.teamRoles?.includes(
                                                          'STANDARD',
                                                        )
                                                      ? 'STANDARD'
                                                      : 'STANDARD'
                                                  }
                                                >
                                                  <MenuItem
                                                    key={'standard2'}
                                                    value="STANDARD"
                                                  >
                                                    STANDARD
                                                  </MenuItem>
                                                  <MenuItem
                                                    key={'team-admin2'}
                                                    value="TEAM_ADMIN"
                                                  >
                                                    ADMIN
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            ) : (
                                              '--'
                                            )}
                                          </TableCell>
                                          {/* ) : null} */}
                                          {/* {pathname !== '/org-settings' ? ( */}
                                          <TableCell align="center">
                                            <Box
                                              display={'flex'}
                                              alignItems={'center'}
                                              justifyContent={'flex-end'}
                                              gap={1}
                                              marginLeft={'-40px'}
                                            >
                                              {member?.id !== userInfo?.id &&
                                              pathname !== '/org-settings' ? (
                                                <Tooltip
                                                  title={'Remove User'}
                                                  placement="top"
                                                  arrow
                                                >
                                                  <IconButton
                                                    onClick={(e) => {
                                                      setShowConfirmDeleteDialog(
                                                        member?.id,
                                                      );
                                                    }}
                                                  >
                                                    <PersonRemove color="primary" />
                                                  </IconButton>
                                                </Tooltip>
                                              ) : null}
                                              {pathname !== '/org-settings' ? (
                                                <>
                                                  <Tooltip
                                                    title={
                                                      'View performance graph'
                                                    }
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <IconButton
                                                      onClick={(e) => {
                                                        if (
                                                          member?.id ===
                                                          userInfo?.id
                                                        ) {
                                                          history.push(
                                                            '/performance',
                                                          );
                                                        } else {
                                                          setViewType(
                                                            'dashboard',
                                                          );
                                                          setCurrentMemberId(
                                                            member?.id,
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <AutoGraph color="primary" />
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Tooltip
                                                    title={'View Action Items'}
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <IconButton
                                                      onClick={(e) => {
                                                        if (
                                                          member?.id ===
                                                          userInfo?.id
                                                        ) {
                                                          history.push(
                                                            '/tasks',
                                                          );
                                                        } else {
                                                          setViewType(
                                                            'actionItems',
                                                          );
                                                          setCurrentMemberId(
                                                            member?.id,
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <TaskAlt color="primary" />
                                                    </IconButton>
                                                  </Tooltip>
                                                  {/* {member?.id !== userInfo?.id ? ( */}
                                                  <Tooltip
                                                    title={
                                                      'Schedule a roleplay reminder'
                                                    }
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <IconButton
                                                      onClick={(e) => {
                                                        if (
                                                          userDetails?.plan
                                                            ?.trailEndsOn >
                                                            new Date().getTime() ||
                                                          userDetails?.plan
                                                            ?.currentPaymentStatus ===
                                                            'paid'
                                                        ) {
                                                          setShowCalendarInviteModal(
                                                            member,
                                                          );
                                                        } else {
                                                          dispatch({
                                                            type:
                                                              'UPDATE_UI_STATE',
                                                            payload: {
                                                              key:
                                                                'showPlanExpired',
                                                              value: true,
                                                            },
                                                          });
                                                        }
                                                      }}
                                                    >
                                                      <EventAvailable color="primary" />
                                                    </IconButton>
                                                  </Tooltip>
                                                  {/* ) : null} */}
                                                </>
                                              ) : null}
                                              {userDetails?.custom?.integration
                                                ?.hubSpot ? (
                                                <Tooltip
                                                  title={
                                                    member?.custom?.hubspotUser
                                                      ?.hubspotEmail
                                                      ? `Linked to ${member?.custom?.hubspotUser?.hubspotEmail}`
                                                      : 'Link to hubspot user'
                                                  }
                                                  placement="top"
                                                  arrow
                                                >
                                                  <IconButton
                                                    onClick={(e) => {
                                                      setShowHubspotUserMappingDialog(
                                                        member?.id,
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src="/hubspot-icon.webp"
                                                      width={'20px'}
                                                      height={'20px'}
                                                      style={{
                                                        filter: member?.custom
                                                          ?.hubspotUser
                                                          ? 'none'
                                                          : 'grayscale(100%)',
                                                      }}
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                              ) : null}
                                              {userDetails?.custom?.integration
                                                ?.zohoCrm ? (
                                                <Tooltip
                                                  title={
                                                    member?.custom?.zohoUser
                                                      ?.zohoEmail
                                                      ? `Linked to ${member?.custom?.zohoUser?.zohoEmail}`
                                                      : 'Link to Zoho user'
                                                  }
                                                  placement="top"
                                                  arrow
                                                >
                                                  <IconButton
                                                    onClick={(e) => {
                                                      setShowZohoUserMappingDialog(
                                                        member?.id,
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src="/zoho.png"
                                                      width={'20px'}
                                                      // height={'20px'}
                                                      style={{
                                                        filter: member?.custom
                                                          ?.zohoUser
                                                          ? 'none'
                                                          : 'grayscale(100%) opacity(0.7)',
                                                      }}
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                              ) : null}
                                            </Box>
                                          </TableCell>
                                          {/* ) : null} */}
                                        </TableRow>
                                      ),
                                    )}
                                  </TableBody>
                                  <TableFooter>
                                    <TableRow>
                                      <TableCell
                                        variant="footer"
                                        colSpan={8}
                                        sx={{
                                          padding: 0,
                                        }}
                                      >
                                        {allTeamMembers?.fetchingPagination ? (
                                          <Box
                                            sx={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              padding: 1,
                                              textAlign: 'center',
                                            }}
                                          >
                                            <Button
                                              size="small"
                                              variant="contained"
                                              // type="submit"
                                              color="primary"
                                              onClick={handleListScroll}
                                              // className="searchSettings"
                                              disabled
                                              // fullWidth
                                            >
                                              <Typography color="textPrimary">
                                                Loading more members ...{' '}
                                              </Typography>
                                              <CircularProgress size={20} />
                                            </Button>
                                          </Box>
                                        ) : allTeamMembers?.emptyList ===
                                            false &&
                                          allTeamMembers?.members.length >=
                                            25 ? (
                                          <Box
                                            sx={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              padding: 1,
                                              textAlign: 'center',
                                            }}
                                          >
                                            <Button
                                              size="small"
                                              variant="contained"
                                              // type="submit"
                                              // fullWidth
                                              color="primary"
                                              onClick={(e) =>
                                                handleListScroll(e, true)
                                              }
                                              // className="searchSettings"
                                            >
                                              Load More
                                            </Button>
                                          </Box>
                                        ) : null}
                                      </TableCell>
                                    </TableRow>
                                  </TableFooter>
                                </Table>
                              </TableContainer>
                            </Box>
                          ) : (
                            <Box
                              width={1}
                              height={1}
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              flexDirection={'column'}
                              gap={2}
                            >
                              <Typography
                                variant="body1"
                                color={'text.secondary'}
                                mt={4}
                              >
                                No invites sent yet.
                              </Typography>
                            </Box>
                          )}
                        </>
                      ) : currentTeamsTab === 'invite' ? (
                        <Box display={'flex'} gap={2} p={2}>
                          <Box
                            flex={1}
                            sx={{
                              maxWidth: 500,
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  First Name
                                </Typography>
                                <TextField
                                  placeholder="First Name"
                                  variant="outlined"
                                  name={'firstName'}
                                  // fullWidth
                                  // multiline
                                  // rows={3}
                                  size="small"
                                  sx={{
                                    width: '100%',
                                  }}
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  type="text"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  Last Name
                                </Typography>
                                <TextField
                                  placeholder="Last Name"
                                  variant="outlined"
                                  name={'lastName'}
                                  // fullWidth
                                  // multiline
                                  // rows={3}
                                  size="small"
                                  sx={{
                                    width: '100%',
                                  }}
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  type="text"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  Email
                                </Typography>
                                <TextField
                                  placeholder="Email"
                                  variant="outlined"
                                  name={'email'}
                                  // fullWidth
                                  // multiline
                                  // rows={3}
                                  size="small"
                                  sx={{
                                    width: '100%',
                                  }}
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  type="email"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                  // sx={{ marginBottom: 1 }}
                                >
                                  Select Role
                                </Typography>
                                <FormControl fullWidth>
                                  <Select
                                    size="small"
                                    labelId="role-select-label"
                                    id="role-select"
                                    name="teamRole"
                                    MenuProps={{ disableScrollLock: true }}
                                    onChange={(e) =>
                                      setTeamRole(e.target.value)
                                    }
                                    value={teamRole}
                                  >
                                    <MenuItem key={'standard'} value="STANDARD">
                                      STANDARD
                                    </MenuItem>
                                    <MenuItem
                                      key={'TEAM_ADMIN'}
                                      value="TEAM_ADMIN"
                                    >
                                      ADMIN
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {error && (
                                <Grid item xs={12}>
                                  <Alert severity="error">{error}</Alert>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  // sx={{ marginLeft: 'auto' }}
                                  onClick={() => {
                                    if (
                                      email &&
                                      email?.trim() &&
                                      validateEmail(email?.trim())
                                    ) {
                                      if (
                                        userDetails?.plan?.trailEndsOn >
                                          new Date().getTime() ||
                                        userDetails?.plan
                                          ?.currentPaymentStatus === 'paid'
                                      ) {
                                        setLoading(true);
                                        dispatch(
                                          sendTeamInvite(
                                            {
                                              invitationType: 'team_invite',
                                              receiverDtls: [
                                                {
                                                  designations: [
                                                    'account_admin',
                                                  ],
                                                  email,
                                                  emailVerified: true,
                                                  firstName,
                                                  lastName,
                                                  teamRole: teamRole,
                                                  teamId: teamId
                                                    ? teamId
                                                    : null,
                                                  // teamRoles: [teamRole],
                                                },
                                              ],
                                            },
                                            (error) => {
                                              if (error) {
                                                setLoading(false);
                                                dispatch(
                                                  showAlert({
                                                    message: error?.includes(
                                                      'already exists',
                                                    )
                                                      ? 'User already registered with the same email address'
                                                      : 'Failed to send the invitation. Please try again!',
                                                    showCross: true,
                                                    title: null,
                                                    type: 'error',
                                                    autoHideDuration: 2000,
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  }),
                                                );
                                              } else {
                                                dispatch(
                                                  getAllTeamInvitations(
                                                    'team_invite',

                                                    teamId || null,
                                                    25,
                                                    null,
                                                    () => {},
                                                  ),
                                                );
                                                setLoading(false);
                                                dispatch(
                                                  showAlert({
                                                    message:
                                                      'Invitation has been sent successfully.',
                                                    showCross: true,
                                                    title: null,
                                                    type: 'success',
                                                    autoHideDuration: 2000,
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  }),
                                                );
                                              }
                                            },
                                          ),
                                        );
                                      } else {
                                        dispatch({
                                          type: 'UPDATE_UI_STATE',
                                          payload: {
                                            key: 'showPlanExpired',
                                            value: true,
                                          },
                                        });
                                      }
                                    } else {
                                      setError('Please enter a valid email.');
                                    }
                                  }}
                                >
                                  Send Invite
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      ) : currentTeamsTab === 'invitations' ? (
                        <>
                          {fetching ? (
                            <Box
                              width={1}
                              height={1}
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              flexDirection={'column'}
                            >
                              <CircularProgress size={20} />
                              <Typography
                                variant="body1"
                                color={'text.primary'}
                                mt={4}
                              >
                                Loading all invitations
                              </Typography>
                            </Box>
                          ) : invitations && invitations?.length > 0 ? (
                            <TableContainer
                              component={Paper}
                              sx={{
                                minHeight: isXs
                                  ? 'calc(100vh - 190px)'
                                  : 'calc(100vh - 190px)',
                                maxHeight: isXs
                                  ? 'calc(100vh - 190px)'
                                  : 'calc(100vh - 190px)',
                                overflowY: 'scroll',
                                '&::-webkit-scrollbar': {
                                  width: '5px',
                                },
                                '&::-webkit-scrollbar-track': {
                                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                  webkitBoxShadow:
                                    'inset 0 0 6px rgba(0,0,0,0.00)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: 2,
                                },
                                paddingBottom: 2,
                              }}
                              onScroll={handleInvitationsListScroll}
                            >
                              <Table
                                sx={{ minWidth: 750 }}
                                stickyHeader
                                aria-label="simple table"
                              >
                                <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                                  <TableRow
                                    sx={{
                                      '& .MuiTableCell-root': {
                                        // border: '1px solid #e1e1e1',
                                      },
                                    }}
                                  >
                                    <TableCell>
                                      <Typography
                                        variant={'caption'}
                                        fontWeight={700}
                                        sx={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        Member
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography
                                        variant={'caption'}
                                        fontWeight={700}
                                        sx={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        Invited On
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography
                                        variant={'caption'}
                                        fontWeight={700}
                                        sx={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        Joined On
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography
                                        variant={'caption'}
                                        fontWeight={700}
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        Role
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography
                                        variant={'caption'}
                                        fontWeight={700}
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        Invitation Link
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {invitations.map((invitation, i) => (
                                    <TableRow key={invitation?.id}>
                                      <TableCell
                                        sx={{
                                          // border: '1px solid #e1e1e1',
                                          position: 'relative',
                                        }}
                                      >
                                        <Box
                                          display={'flex'}
                                          flexDirection={'column'}
                                          // gap={1}
                                        >
                                          <Typography
                                            fontWeight={'bold'}
                                            color={'text.secondary'}
                                          >
                                            {invitation?.receiverEmail}
                                          </Typography>
                                          <Typography variant="caption">{`${invitation?.firstName ||
                                            ''} ${invitation?.lastName ||
                                            ''}`}</Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        // sx={{ border: '1px solid #e1e1e1' }}
                                      >
                                        <Typography
                                          variant="caption"
                                          // fontWeight={700}
                                          sx={{ textTransform: 'capitalize' }}
                                        >
                                          {moment(invitation.createdOn).format(
                                            'DD MMMM YY',
                                          )}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        // sx={{ border: '1px solid #e1e1e1' }}
                                      >
                                        <Typography
                                          variant="caption"
                                          // fontWeight={700}
                                          sx={{ textTransform: 'capitalize' }}
                                        >
                                          {invitation?.joined
                                            ? moment(
                                                invitation.joinedOn,
                                              ).format('DD MMMM YY')
                                            : 'Invitation Sent'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        // sx={{ border: '1px solid #e1e1e1' }}
                                      >
                                        <Typography
                                          variant="caption"
                                          // fontWeight={700}
                                          sx={{ textTransform: 'capitalize' }}
                                        >
                                          {invitation?.teamRole === 'TEAM_ADMIN'
                                            ? 'ADMIN'
                                            : 'STANDARD'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        // sx={{ border: '1px solid #e1e1e1' }}
                                      >
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          onClick={async () => {
                                            try {
                                              await navigator.clipboard.writeText(
                                                `${actionCodeBaseUrl}signup?invitationId=${invitation?.id}`,
                                              );
                                              alert('Link Copied.');
                                            } catch (err) {
                                              alert('Failed to copy link.');
                                              console.error(
                                                'Failed to copy: ',
                                                err,
                                              );
                                            }
                                          }}
                                        >
                                          Copy Link
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                                <TableFooter>
                                  <TableRow>
                                    <TableCell
                                      variant="footer"
                                      colSpan={8}
                                      sx={{
                                        padding: 0,
                                      }}
                                    >
                                      {fetchingPagination ? (
                                        <Box
                                          sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: 1,
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Button
                                            size="small"
                                            variant="contained"
                                            // type="submit"
                                            color="primary"
                                            onClick={handleListScroll}
                                            // className="searchSettings"
                                            disabled
                                            // fullWidth
                                          >
                                            <Typography color="textPrimary">
                                              Loading more invitations ...{' '}
                                            </Typography>
                                            <CircularProgress size={20} />
                                          </Button>
                                        </Box>
                                      ) : emptyList === false &&
                                        invitations.length >= 25 ? (
                                        <Box
                                          sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: 1,
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Button
                                            size="small"
                                            variant="contained"
                                            // type="submit"
                                            // fullWidth
                                            color="primary"
                                            onClick={(e) =>
                                              handleListScroll(e, true)
                                            }
                                            // className="searchSettings"
                                          >
                                            Load More
                                          </Button>
                                        </Box>
                                      ) : null}
                                    </TableCell>
                                  </TableRow>
                                </TableFooter>
                              </Table>
                            </TableContainer>
                          ) : (
                            <Box
                              width={1}
                              height={1}
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              flexDirection={'column'}
                              gap={2}
                            >
                              <Typography
                                variant="body1"
                                color={'text.secondary'}
                                mt={4}
                              >
                                No invites sent yet.
                              </Typography>
                            </Box>
                          )}
                        </>
                      ) : null}
                    </Box>
                  </Box>
                ) : currentTab === 'leaderboard' ? (
                  <>
                    {leaderboard?.fetching ? (
                      <Box
                        width={1}
                        height={1}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                      >
                        <CircularProgress size={20} />
                        <Typography
                          variant="body1"
                          color={'text.primary'}
                          mt={4}
                        >
                          Loading leaderboard ...
                        </Typography>
                      </Box>
                    ) : leaderboard?.leaderboard &&
                      leaderboard?.leaderboard?.length > 0 ? (
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        <TableContainer
                          component={Paper}
                          sx={{
                            minHeight: isXs
                              ? 'calc(100vh - 140px)'
                              : 'calc(100vh - 110px)',
                            maxHeight: isXs
                              ? 'calc(100vh - 140px)'
                              : 'calc(100vh - 110px)',
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                              width: '5px',
                              height: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 2,
                            },
                          }}
                          // onScroll={handleListScroll}
                        >
                          <Table
                            sx={{ minWidth: 750 }}
                            stickyHeader
                            aria-label="simple table"
                          >
                            <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                              <TableRow
                                sx={{
                                  '& .MuiTableCell-root': {
                                    border: '1px solid #e1e1e1',
                                  },
                                }}
                              >
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    #
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    Member
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'capitalize' }}
                                  >
                                    {orgConfigs?.navBarTitles?.[
                                      'Sales Call Analysis'
                                    ]?.title || 'Sales Call Analysis'}
                                  </Typography>
                                </TableCell>
                                {orgConfigs?.rolePlayVisibilityControl &&
                                currentTeam?.rolePlayVisibility === 'team' ? (
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      {orgConfigs?.navBarTitles?.[
                                        'Private Roleplays'
                                      ]?.title || 'Private Roleplays'}
                                    </Typography>
                                  </TableCell>
                                ) : null}
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    Actions
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {leaderboard?.leaderboard.map((member, i) => (
                                <TableRow key={`${member?.id}`}>
                                  <TableCell align="center" sx={{}}>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {i + 1}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    // rowSpan={2}
                                    sx={{
                                      position: 'relative',
                                    }}
                                  >
                                    <Box
                                      display={'flex'}
                                      flexDirection={'column'}
                                      // gap={1}
                                    >
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={2}
                                      >
                                        <Typography
                                          fontWeight={'bold'}
                                          color={'text.secondary'}
                                        >
                                          {member?.fullName || ''}
                                        </Typography>
                                      </Box>
                                      <Typography variant="caption">
                                        {member?.teamRoles?.includes(
                                          'TEAM_ADMIN',
                                        )
                                          ? 'ADMIN'
                                          : member?.teamRoles?.includes(
                                              'STANDARD',
                                            )
                                          ? 'STANDARD'
                                          : 'STANDARD'}
                                      </Typography>
                                      <Typography variant="caption">
                                        {member?.email}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="center" sx={{}}>
                                    {member?.custom?.counts?.salesCallAnalysed >
                                    0 ? (
                                      <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => {
                                          // if (member?.id === userInfo?.id) {
                                          //   history.push(
                                          //     '/sales-call-analysis',
                                          //   );
                                          // } else {
                                          setViewType('salesCalls');
                                          setCurrentMemberId(member?.id);
                                          dispatch({
                                            type: 'UPDATE_UI_STATE',
                                            payload: {
                                              key: 'currentNavigationLevel',
                                              value: currentNavigationLevel + 1,
                                            },
                                          });
                                          // }
                                        }}
                                      >
                                        <Typography
                                          variant="body2"
                                          fontWeight={'bold'}
                                          sx={{
                                            textDecoration: 'underline',
                                          }}
                                        >
                                          {member?.custom?.counts
                                            ?.salesCallAnalysed || 0}
                                        </Typography>
                                      </Button>
                                    ) : (
                                      <Typography
                                        // color={'text.secondary'}
                                        variant={'caption'}
                                      >
                                        {member?.custom?.counts
                                          ?.salesCallAnalysed || 0}
                                      </Typography>
                                    )}
                                  </TableCell>
                                  {orgConfigs?.rolePlayVisibilityControl &&
                                  currentTeam?.rolePlayVisibility === 'team' ? (
                                    <TableCell align="center" sx={{}}>
                                      {member?.custom?.counts
                                        ?.practiceCallAnalysed > 0 ? (
                                        <Button
                                          size="small"
                                          variant="text"
                                          color="primary"
                                          onClick={() => {
                                            // if (member?.id === userInfo?.id) {
                                            //   history.push('/conversation-ai');
                                            // } else {
                                            setViewType('practiceCalls');
                                            setCurrentMemberId(member?.id);
                                            // if (uId) {
                                            dispatch({
                                              type: 'UPDATE_UI_STATE',
                                              payload: {
                                                key: 'currentNavigationLevel',
                                                value:
                                                  currentNavigationLevel + 1,
                                              },
                                            });
                                            // }
                                            // }
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            fontWeight={'bold'}
                                            sx={{
                                              textDecoration: 'underline',
                                            }}
                                          >
                                            {member?.custom?.counts
                                              ?.practiceCallAnalysed || 0}
                                          </Typography>
                                        </Button>
                                      ) : (
                                        <Typography
                                          // color={'text.secondary'}
                                          variant={'caption'}
                                        >
                                          {member?.custom?.counts
                                            ?.practiceCallAnalysed || 0}
                                        </Typography>
                                      )}
                                    </TableCell>
                                  ) : null}
                                  <TableCell align="center">
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      // mt={2}
                                      justifyContent={'center'}
                                      // gap={1}
                                    >
                                      <Tooltip
                                        title={'View performance graph'}
                                        placement="top"
                                        arrow
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            if (member?.id === userInfo?.id) {
                                              history.push('/performance');
                                            } else {
                                              setViewType('dashboard');
                                              setCurrentMemberId(member?.id);
                                            }
                                          }}
                                        >
                                          <AutoGraph color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title={'View Action Items'}
                                        placement="top"
                                        arrow
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            if (member?.id === userInfo?.id) {
                                              history.push('/tasks');
                                            } else {
                                              setViewType('actionItems');
                                              setCurrentMemberId(member?.id);
                                            }
                                          }}
                                        >
                                          <TaskAlt color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                      {/* {member?.id !== userInfo?.id ? ( */}
                                      <Tooltip
                                        title={'Schedule a roleplay reminder'}
                                        placement="top"
                                        arrow
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            if (
                                              userDetails?.plan?.trailEndsOn >
                                                new Date().getTime() ||
                                              userDetails?.plan
                                                ?.currentPaymentStatus ===
                                                'paid'
                                            ) {
                                              setShowCalendarInviteModal(
                                                member,
                                              );
                                            } else {
                                              dispatch({
                                                type: 'UPDATE_UI_STATE',
                                                payload: {
                                                  key: 'showPlanExpired',
                                                  value: true,
                                                },
                                              });
                                            }
                                          }}
                                        >
                                          <EventAvailable color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                      {/* ) : null} */}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    ) : (
                      <Box
                        width={1}
                        height={1}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        gap={2}
                      >
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          mt={4}
                        >
                          No dashboard data
                        </Typography>
                      </Box>
                    )}
                  </>
                ) : currentTab === 'scoresboard' ? (
                  <Box
                    sx={{
                      border:
                        pathname !== '/org-settings'
                          ? '1px solid #d3d3d3'
                          : 'none',
                      borderRadius: 2,
                      minHeight: 'calc(100vh - 90px)',
                    }}
                  >
                    {leaderboard?.fetching ? (
                      <Box
                        width={1}
                        height={1}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                      >
                        <CircularProgress size={20} />
                        <Typography
                          variant="body1"
                          color={'text.primary'}
                          mt={4}
                        >
                          Loading leaderboard ...
                        </Typography>
                      </Box>
                    ) : leaderboard?.leaderboard &&
                      leaderboard?.leaderboard?.length > 0 ? (
                      <Box display={'flex'} flexDirection={'column'}>
                        {/* { ? ( */}
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent:
                              orgConfigs?.rolePlayVisibilityControl &&
                              currentTeam?.rolePlayVisibility === 'team'
                                ? 'space-between'
                                : 'flex-end',
                            paddingY:
                              orgConfigs?.rolePlayVisibilityControl &&
                              currentTeam?.rolePlayVisibility === 'team'
                                ? 0
                                : 1,
                          }}
                        >
                          {orgConfigs?.rolePlayVisibilityControl &&
                          currentTeam?.rolePlayVisibility === 'team' ? (
                            <Tabs
                              value={currentScoreTab}
                              onChange={(e, newValue) =>
                                setCurrentScoreTab(newValue)
                              }
                              aria-label="Score Tabs"
                              orientation={'horizontal'}
                              // indicatorColor={theme.palette.background.default}
                              variant="scrollable"
                              // scrollButtons={!isMd}
                              allowScrollButtonsMobile
                            >
                              <Tab
                                value={'salescall'}
                                label={
                                  <Typography variant="body1">
                                    {orgConfigs?.navBarTitles?.[
                                      'Sales Call Analysis'
                                    ]?.title || 'Sales Call Analysis'}
                                  </Typography>
                                }
                                id={`simple-tab-${1}`}
                                aria-controls={`simple-tabpanel-${5}`}
                                sx={{}}
                              />
                              <Tab
                                value={'roleplays'}
                                label={
                                  <Typography variant="body1">
                                    {orgConfigs?.navBarTitles?.[
                                      'Private Roleplays'
                                    ]?.title || 'Private Roleplays'}
                                  </Typography>
                                }
                                id={`simple-tab-${1}`}
                                aria-controls={`simple-tabpanel-${5}`}
                                sx={{}}
                              />
                            </Tabs>
                          ) : null}
                          {process.env.REACT_APP_TARGET_ENV == 'local' ||
                          userInfo?.email?.includes('spectra.co') ||
                          userInfo?.email === 'spectra-cs@qualification.ai' ||
                          userInfo?.email === 'gaurav+e05@qualification.ai' ||
                          userInfo?.email ===
                            'gaurav+e05-su@qualification.ai' ? (
                            <Button
                              startIcon={<ExitToApp />}
                              variant="contained"
                              size="small"
                              sx={{
                                marginRight: 1,
                              }}
                              onClick={() => {
                                dispatch(
                                  exportScoreDashboardData(
                                    (link) => {
                                      if (link) {
                                        downloadFile({
                                          link: link,
                                          fileName: `score-dashboard-${new Date()}`,
                                        });
                                        dispatch(
                                          showAlert({
                                            message:
                                              'Data is exported successfully.',
                                            showCross: true,
                                            title: null,
                                            type: 'success',
                                            autoHideDuration: 2000,
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }),
                                        );
                                      } else {
                                        dispatch(
                                          showAlert({
                                            message:
                                              'Failed to export data. Please try again later!',
                                            showCross: true,
                                            title: null,
                                            type: 'error',
                                            autoHideDuration: 2000,
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }),
                                        );
                                      }
                                      console.log(link);
                                    },
                                    () => {
                                      dispatch(
                                        showAlert({
                                          message:
                                            'Failed to export data. Please try again later!',
                                          showCross: true,
                                          title: null,
                                          type: 'error',
                                          autoHideDuration: 2000,
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }),
                                      );
                                    },
                                  ),
                                );
                              }}
                            >
                              Export
                            </Button>
                          ) : null}
                        </Box>
                        {/* ) : null} */}
                        <TableContainer
                          component={Paper}
                          sx={{
                            minHeight: isXs
                              ? 'calc(100vh - 140px)'
                              : process.env.REACT_APP_TARGET_ENV == 'local' ||
                                userInfo?.email?.includes('spectra.co') ||
                                userInfo?.email ===
                                  'spectra-cs@qualification.ai' ||
                                userInfo?.email ===
                                  'gaurav+e05@qualification.ai' ||
                                userInfo?.email ===
                                  'gaurav+e05-su@qualification.ai'
                              ? 'calc(100vh - 140px)'
                              : 'calc(100vh - 110px)',
                            maxHeight: isXs
                              ? 'calc(100vh - 140px)'
                              : process.env.REACT_APP_TARGET_ENV == 'local' ||
                                userInfo?.email?.includes('spectra.co') ||
                                userInfo?.email ===
                                  'spectra-cs@qualification.ai' ||
                                userInfo?.email ===
                                  'gaurav+e05@qualification.ai' ||
                                userInfo?.email ===
                                  'gaurav+e05-su@qualification.ai'
                              ? 'calc(100vh - 140px)'
                              : 'calc(100vh - 110px)',
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                              width: '5px',
                              height: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 2,
                            },
                          }}
                          // onScroll={handleListScroll}
                        >
                          <Table
                            sx={{ minWidth: 750 }}
                            stickyHeader
                            aria-label="simple table"
                          >
                            <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                              <TableRow
                                sx={{
                                  '& .MuiTableCell-root': {
                                    border: '1px solid #e1e1e1',
                                  },
                                }}
                              >
                                <TableCell align="center">
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    gap={1}
                                  >
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      #
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'flex-start'}
                                    gap={1}
                                  >
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      Member
                                    </Typography>
                                    <IconButton
                                      onClick={() => {
                                        setSortedOn('membername');
                                        setSortedOrder((prev) =>
                                          prev === 'neutral'
                                            ? 'descending'
                                            : prev === 'descending'
                                            ? 'ascending'
                                            : 'neutral',
                                        );
                                      }}
                                    >
                                      {sortedOn === 'membername' ? (
                                        sortedOrder === 'neutral' ? (
                                          <ArrowUpward
                                            sx={{
                                              fontSize: 18,
                                              color: '#aaa',
                                            }}
                                          />
                                        ) : sortedOrder === 'ascending' ? (
                                          <ArrowUpward
                                            sx={{
                                              fontSize: 18,
                                              color: 'black',
                                            }}
                                          />
                                        ) : (
                                          <ArrowDownward
                                            sx={{
                                              fontSize: 18,
                                              color: 'black',
                                            }}
                                          />
                                        )
                                      ) : (
                                        <ArrowUpward
                                          sx={{
                                            fontSize: 18,
                                            color: '#aaa',
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    gap={1}
                                  >
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      Calls Scored
                                    </Typography>
                                    <IconButton
                                      onClick={() => {
                                        setSortedOn('callsscored');
                                        setSortedOrder((prev) =>
                                          prev === 'neutral'
                                            ? 'descending'
                                            : prev === 'descending'
                                            ? 'ascending'
                                            : 'neutral',
                                        );
                                      }}
                                    >
                                      {sortedOn === 'callsscored' ? (
                                        sortedOrder === 'neutral' ? (
                                          <ArrowUpward
                                            sx={{
                                              fontSize: 18,
                                              color: '#aaa',
                                            }}
                                          />
                                        ) : sortedOrder === 'ascending' ? (
                                          <ArrowUpward
                                            sx={{
                                              fontSize: 18,
                                              color: 'black',
                                            }}
                                          />
                                        ) : (
                                          <ArrowDownward
                                            sx={{
                                              fontSize: 18,
                                              color: 'black',
                                            }}
                                          />
                                        )
                                      ) : (
                                        <ArrowUpward
                                          sx={{
                                            fontSize: 18,
                                            color: '#aaa',
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </Box>
                                </TableCell>
                                <TableCell align="center" width={150}>
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    gap={1}
                                  >
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      Overall
                                    </Typography>
                                    <IconButton
                                      onClick={() => {
                                        setSortedOn('overall');
                                        setSortedOrder((prev) =>
                                          prev === 'neutral'
                                            ? 'descending'
                                            : prev === 'descending'
                                            ? 'ascending'
                                            : 'neutral',
                                        );
                                      }}
                                    >
                                      {sortedOn === 'overall' ? (
                                        sortedOrder === 'neutral' ? (
                                          <ArrowUpward
                                            sx={{
                                              fontSize: 18,
                                              color: '#aaa',
                                            }}
                                          />
                                        ) : sortedOrder === 'ascending' ? (
                                          <ArrowUpward
                                            sx={{
                                              fontSize: 18,
                                              color:
                                                sortedOn === 'overall'
                                                  ? 'black'
                                                  : '#aaa',
                                            }}
                                          />
                                        ) : (
                                          <ArrowDownward
                                            sx={{
                                              fontSize: 18,
                                              color:
                                                sortedOn === 'overall'
                                                  ? 'black'
                                                  : '#aaa',
                                            }}
                                          />
                                        )
                                      ) : (
                                        <ArrowUpward
                                          sx={{
                                            fontSize: 18,
                                            color: '#aaa',
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </Box>
                                </TableCell>
                                {orgConfigs?.contentScoringConfigKeys?.map(
                                  (configKey) => (
                                    <TableCell
                                      key={configKey}
                                      align="center"
                                      width={150}
                                    >
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        gap={1}
                                      >
                                        <Typography
                                          variant={'caption'}
                                          fontWeight={700}
                                          sx={{ textTransform: 'capitalize' }}
                                        >
                                          {configKey}
                                        </Typography>
                                        <IconButton
                                          onClick={() => {
                                            setSortedOn(
                                              configKey.toLowerCase(),
                                            );
                                            setSortedOrder((prev) =>
                                              prev === 'neutral'
                                                ? 'descending'
                                                : prev === 'descending'
                                                ? 'ascending'
                                                : 'neutral',
                                            );
                                          }}
                                        >
                                          {sortedOn ===
                                          configKey.toLowerCase() ? (
                                            sortedOrder === 'neutral' ? (
                                              <ArrowUpward
                                                sx={{
                                                  fontSize: 18,
                                                  color: '#aaa',
                                                }}
                                              />
                                            ) : sortedOrder === 'ascending' ? (
                                              <ArrowUpward
                                                sx={{
                                                  fontSize: 18,
                                                  color:
                                                    sortedOn ===
                                                    configKey.toLowerCase()
                                                      ? 'black'
                                                      : '#aaa',
                                                }}
                                              />
                                            ) : (
                                              <ArrowDownward
                                                sx={{
                                                  fontSize: 18,
                                                  color:
                                                    sortedOn ===
                                                    configKey.toLowerCase()
                                                      ? 'black'
                                                      : '#aaa',
                                                }}
                                              />
                                            )
                                          ) : (
                                            <ArrowUpward
                                              sx={{
                                                fontSize: 18,
                                                color: '#aaa',
                                              }}
                                            />
                                          )}
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  ),
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {leaderboard?.leaderboard
                                ?.sort((a, b) => {
                                  if (
                                    sortedOn === '' ||
                                    sortedOrder === 'neutral'
                                  ) {
                                    return 0;
                                  } else {
                                    if (sortedOn === 'membername') {
                                      if (sortedOrder === 'ascending') {
                                        if (a?.fullName < b?.fullName) {
                                          return -1;
                                        } else if (a?.fullName > b?.fullName) {
                                          return 1;
                                        } else {
                                          return 0;
                                        }
                                      } else {
                                        if (a?.fullName > b?.fullName) {
                                          return -1;
                                        } else if (a?.fullName < b?.fullName) {
                                          return 1;
                                        } else {
                                          return 0;
                                        }
                                      }
                                    }
                                    if (sortedOn === 'callsscored') {
                                      if (sortedOrder === 'ascending') {
                                        if (
                                          a?.custom?.counts
                                            ?.callScoredAnalysis <
                                          b?.custom?.counts?.callScoredAnalysis
                                        ) {
                                          return -1;
                                        } else if (
                                          a?.custom?.counts
                                            ?.callScoredAnalysis >
                                          b?.custom?.counts?.callScoredAnalysis
                                        ) {
                                          return 1;
                                        } else {
                                          return 0;
                                        }
                                      } else {
                                        if (
                                          a?.custom?.counts
                                            ?.callScoredAnalysis >
                                          b?.custom?.counts?.callScoredAnalysis
                                        ) {
                                          return -1;
                                        } else if (
                                          a?.custom?.counts
                                            ?.callScoredAnalysis <
                                          b?.custom?.counts?.callScoredAnalysis
                                        ) {
                                          return 1;
                                        } else {
                                          return 0;
                                        }
                                      }
                                    }
                                    if (sortedOn === 'overall') {
                                      if (sortedOrder === 'ascending') {
                                        if (
                                          a?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.['overall'] <
                                          b?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.['overall']
                                        ) {
                                          return -1;
                                        } else if (
                                          a?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.['overall'] >
                                          b?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.['overall']
                                        ) {
                                          return 1;
                                        } else {
                                          return 0;
                                        }
                                      } else {
                                        if (
                                          a?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.['overall'] >
                                          b?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.['overall']
                                        ) {
                                          return -1;
                                        } else if (
                                          a?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.['overall'] <
                                          b?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.['overall']
                                        ) {
                                          return 1;
                                        } else {
                                          return 0;
                                        }
                                      }
                                    } else {
                                      if (sortedOrder === 'ascending') {
                                        if (
                                          a?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.[sortedOn] <
                                          b?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.[sortedOn]
                                        ) {
                                          return -1;
                                        } else if (
                                          a?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.[sortedOn] >
                                          b?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.[sortedOn]
                                        ) {
                                          return 1;
                                        } else {
                                          return 0;
                                        }
                                      } else {
                                        if (
                                          a?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.[sortedOn] >
                                          b?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.[sortedOn]
                                        ) {
                                          return -1;
                                        } else if (
                                          a?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.[sortedOn] <
                                          b?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.[sortedOn]
                                        ) {
                                          return 1;
                                        } else {
                                          return 0;
                                        }
                                      }
                                    }
                                  }
                                })
                                ?.map((member, i) => (
                                  <TableRow key={`${member?.id}`}>
                                    <TableCell align="center" sx={{}}>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {i + 1}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      // rowSpan={2}
                                      sx={{
                                        position: 'relative',
                                      }}
                                    >
                                      <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        // gap={1}
                                      >
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          gap={2}
                                        >
                                          <Typography
                                            fontWeight={'bold'}
                                            color={'text.secondary'}
                                          >
                                            {member?.fullName || ''}
                                          </Typography>
                                        </Box>
                                        <Typography variant="caption">
                                          {member?.teamRoles?.includes(
                                            'TEAM_ADMIN',
                                          )
                                            ? 'ADMIN'
                                            : member?.teamRoles?.includes(
                                                'STANDARD',
                                              )
                                            ? 'STANDARD'
                                            : 'STANDARD'}
                                        </Typography>
                                        <Typography variant="caption">
                                          {member?.email}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell align="center" sx={{}}>
                                      {member?.custom?.counts?.[
                                        currentScoreTab === 'salescall'
                                          ? 'callScoredAnalysis'
                                          : 'conversationScoredAnalysis'
                                      ] > 0 ? (
                                        <Button
                                          variant="text"
                                          color="primary"
                                          onClick={() => {
                                            // if (member?.id === userInfo?.id) {
                                            //   history.push(
                                            //     '/sales-call-analysis',
                                            //   );
                                            // } else {
                                            if (
                                              currentScoreTab === 'salescall'
                                            ) {
                                              setViewType('salesCalls');
                                            } else {
                                              setViewType('practiceCalls');
                                            }
                                            setCurrentMemberId(member?.id);
                                            dispatch({
                                              type: 'UPDATE_UI_STATE',
                                              payload: {
                                                key: 'currentNavigationLevel',
                                                value:
                                                  currentNavigationLevel + 1,
                                              },
                                            });
                                            // }
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            fontWeight={'bold'}
                                            sx={{
                                              textDecoration: 'underline',
                                            }}
                                          >
                                            {member?.custom?.counts?.[
                                              currentScoreTab === 'salescall'
                                                ? 'callScoredAnalysis'
                                                : 'conversationScoredAnalysis'
                                            ] || 0}
                                          </Typography>
                                        </Button>
                                      ) : (
                                        <Typography
                                          // color={'text.secondary'}
                                          variant={'caption'}
                                        >
                                          {member?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoredAnalysis'
                                              : 'conversationScoredAnalysis'
                                          ] || 0}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" sx={{}}>
                                      <ProfileCompletion
                                        percentCompleted={Math.round(
                                          member?.custom?.counts?.[
                                            currentScoreTab === 'salescall'
                                              ? 'callScoreCounts'
                                              : 'conversationScoreCounts'
                                          ]?.['overall'] || 0,
                                        )}
                                        circleSize={45}
                                        circleTextSize={12}
                                        fontSize={12}
                                        thickness={5}
                                        dontShowExtraText={true}
                                      />
                                    </TableCell>
                                    {orgConfigs?.contentScoringConfigKeys?.map(
                                      (configKey) => (
                                        <TableCell
                                          key={configKey}
                                          align="center"
                                          sx={{}}
                                        >
                                          <ProfileCompletion
                                            percentCompleted={Math.round(
                                              member?.custom?.counts?.[
                                                currentScoreTab === 'salescall'
                                                  ? 'callScoreCounts'
                                                  : 'conversationScoreCounts'
                                              ]?.[
                                                currentScoreTab === 'salescall'
                                                  ? configKey?.toLowerCase()
                                                  : configKey
                                              ] || 0,
                                            )}
                                            circleSize={45}
                                            circleTextSize={12}
                                            fontSize={12}
                                            thickness={5}
                                            dontShowExtraText={true}
                                          />
                                        </TableCell>
                                      ),
                                    )}
                                    {/* <TableCell align="center">
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      // mt={2}
                                      justifyContent={'center'}
                                      // gap={1}
                                    >
                                      <Tooltip
                                        title={'View performance graph'}
                                        placement="top"
                                        arrow
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            if (member?.id === userInfo?.id) {
                                              history.push('/performance');
                                            } else {
                                              setViewType('dashboard');
                                              setCurrentMemberId(member?.id);
                                            }
                                          }}
                                        >
                                          <AutoGraph color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title={'View Action Items'}
                                        placement="top"
                                        arrow
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            if (member?.id === userInfo?.id) {
                                              history.push('/tasks');
                                            } else {
                                              setViewType('actionItems');
                                              setCurrentMemberId(member?.id);
                                            }
                                          }}
                                        >
                                          <TaskAlt color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title={'Schedule a roleplay reminder'}
                                        placement="top"
                                        arrow
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            if (
                                              userDetails?.plan?.trailEndsOn >
                                                new Date().getTime() ||
                                              userDetails?.plan
                                                ?.currentPaymentStatus ===
                                                'paid'
                                            ) {
                                              setShowCalendarInviteModal(
                                                member,
                                              );
                                            } else {
                                              dispatch({
                                                type: 'UPDATE_UI_STATE',
                                                payload: {
                                                  key: 'showPlanExpired',
                                                  value: true,
                                                },
                                              });
                                            }
                                          }}
                                        >
                                          <EventAvailable color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </TableCell> */}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    ) : (
                      <Box
                        width={1}
                        height={1}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        gap={2}
                      >
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          mt={4}
                        >
                          No dashboard data
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ) : currentTab === 'teamsettings' ? (
                  <AccountSettings
                    defaultTab="playbook"
                    page="teamsettings"
                    currentTeam={currentTeam || loggedInUserTeamDetails}
                    setCurrentTeam={setCurrentTeam}
                  />
                ) : null}
              </>
            )}
          </Box>
        </Box>
      </Container>
      <Drawer
        open={Boolean(showBotRequest)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_bot_drawer');
          setShowBotRequest(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
        >
          <IconButton
            onClick={(e) => {
              handleTrackEvent(e, 'hide_bot_drawer');
              setShowBotRequest(null);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
        </Box>
        <Box
          p={2}
          sx={{
            minWidth: 500,
            maxWidth: 500,
            minHeight: 'calc(100vh - 60px)',
            maxHeight: 'calc(100vh - 60px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            flex: 1,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body2" color={'text.secondary'}>
            Bot Details
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Do you sell to a specific Vertical?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.domain}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Do you sell to a specific Designation?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.designation}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Name
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.botName}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Voice
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.gender}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Role
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.role}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Experience (in Years)
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.experience}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Company Name
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.companyName}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            About Company
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.aboutCompany}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Company Size
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.companySize}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            What might be the top challenge for buyer?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.challenges}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Products the compete with yours
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.competitors?.map((cmpt) => cmpt?.name)?.join(',')}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Other information
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.newInfo}
          </Typography>
        </Box>
      </Drawer>
      <Drawer
        open={Boolean(showPlaybook)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_playbook_drawer');
          setShowPlaybook(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
        >
          <IconButton
            onClick={(e) => {
              handleTrackEvent(e, 'hide_playbook_drawer');
              setShowPlaybook(null);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
        </Box>
        <Box
          p={2}
          sx={{
            minWidth: 500,
            maxWidth: 500,
            minHeight: 'calc(100vh - 60px)',
            maxHeight: 'calc(100vh - 60px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            flex: 1,
            gap: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body2" color={'text.secondary'}>
            Playbook
          </Typography>
          {typeof showPlaybook === 'object' ? (
            <>
              {' '}
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Company Name
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['companyName'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Product Description
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['productDescription'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Product Key Features
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['productKeyFeatures'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Product Secondary Features
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['secondaryFeatures'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Customer Qualification Questions
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['customerQualificationQuestions'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Any follow up questions
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['followUpQuestions'] || ''}
                onChange={() => {}}
                style={{
                  whiteSpace: 'pre-wrap',
                }}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Customer Objections and Answers
              </Typography>
              {showPlaybook?.customerQuestions?.map((qa) => (
                <>
                  <Typography>Question: {qa.question}</Typography>
                  <Typography>Answer: {qa.answer}</Typography>
                </>
              ))}
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Expected Call To Action
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['expectedCallToAction'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Competing Products and Companies
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['competingProducts'] || ''}
                onChange={() => {}}
                // readOnly
              />
            </>
          ) : (
            <RichTextEditor
              value={showPlaybook || ''}
              onChange={() => {}}
              readOnly
            />
          )}
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to remove this user from the team?
            </DialogContentText>
          </Box>
          <Typography variant="caption">
            This will remove user from the team. You can still add this member
            again in the same team or new team.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={removingUser}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setRemovingUser(true);
              dispatch(
                addRemoveUserFromTeam(
                  'remove',
                  teamId,
                  {
                    usersPermissions: {
                      [showConfirmDeleteDialog]: {
                        read: true,
                        write: true,
                        teamRole: 'TEAM_ADMIN',
                      },
                    },
                  },
                  () => {
                    dispatch(
                      getAllTeamMembers(
                        teamId || null,
                        null,
                        true,
                        numberOfDays,
                        25,
                        null,
                        (data) => {
                          setRemovingUser(false);
                          setShowConfirmDeleteDialog(null);
                        },
                      ),
                    );
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showManageDefaultConfigurations)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display={'flex'}
            flexDirection={'column'}
            // gap={1}
          >
            <Typography fontWeight={'bold'} color={'text.secondary'}>
              {showManageDefaultConfigurations?.fullName}
            </Typography>
            <Typography variant="caption">
              {showManageDefaultConfigurations?.email}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText
              variant="subtitle2"
              id="alert-dialog-description"
              flex={1}
            >
              Change these default language and call settings and hit save
            </DialogContentText>
          </Box>
          {orgConfigs?.languagesForAnalysis?.length > 1 ? (
            <Box
              display={'flex'}
              alignItems={'center'}
              position={'relative'}
              sx={{
                border: '1px solid #d3d3d3',
                width: 1,
                borderRadius: 1,
                padding: 2,
                minHeight: 20,
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: -12,
                  left: 10,
                  background: '#fff',
                }}
                variant="subtitle2"
                color={'text.secondary'}
              >
                Multi Language Analysis
              </Typography>
              {/* {multiLanguageAnalysisSupport ? ( */}
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Box display={'flex'} alignItems={'center'} gap={1} width={1}>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ flex: 1 }}
                  >
                    Supported Languages
                  </Typography>
                  <FormControl size="small">
                    <Select
                      size="small"
                      multiple
                      labelId="analysis-lang-support-select-label"
                      id="analysis-lang-support-select"
                      name="supportedAnalysisLanguages"
                      MenuProps={{ disableScrollLock: true }}
                      onChange={(e) => {
                        setSupportedAnalysisLanguages(e.target.value);
                      }}
                      value={supportedAnalysisLanguages}
                    >
                      {orgConfigs?.languagesForAnalysis?.map((lang) => (
                        <MenuItem key={lang} value={lang}>
                          <Typography variant="caption">
                            {capitalizeText(lang)}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={1} width={1}>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    // sx={{ marginBottom: 1 }}
                    sx={{ flex: 1 }}
                  >
                    Default Languages
                  </Typography>
                  <FormControl size="small">
                    <Select
                      size="small"
                      multiple
                      labelId="analysis-default-language-select-label"
                      id="analysis-default-language-select"
                      name="defaultAnalysisLanguages"
                      MenuProps={{ disableScrollLock: true }}
                      onChange={(e) => {
                        setDefaultAnalysisLanguages(e.target.value);
                      }}
                      value={defaultAnalysisLanguages}
                    >
                      {orgConfigs?.languagesForAnalysis?.map((lang) => (
                        <MenuItem key={lang} value={lang}>
                          <Typography variant="caption">
                            {capitalizeText(lang)}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              {/* ) : null} */}
            </Box>
          ) : null}
          {orgConfigs?.languagesForRolePlay?.length > 1 ? (
            <Box
              display={'flex'}
              alignItems={'center'}
              position={'relative'}
              sx={{
                border: '1px solid #d3d3d3',
                width: 1,
                borderRadius: 1,
                padding: 2,
                minHeight: 20,
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: -12,
                  left: 10,
                  background: '#fff',
                }}
                variant="subtitle2"
                color={'text.secondary'}
              >
                Multi Language Roleplay
              </Typography>
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Box display={'flex'} alignItems={'center'} gap={1} width={1}>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ flex: 1 }}
                  >
                    Supported Languages
                  </Typography>
                  <FormControl size="small">
                    <Select
                      size="small"
                      multiple
                      labelId="roleplay-lang-support-select-label"
                      id="roleplay-lang-support-select"
                      name="supportedRoleplayLanguages"
                      MenuProps={{ disableScrollLock: true }}
                      onChange={(e) => {
                        setSupportedRoleplayLanguages(e.target.value);
                      }}
                      value={supportedRoleplayLanguages}
                    >
                      <MenuItem value={'english'}>
                        <Typography variant="caption">English</Typography>
                      </MenuItem>
                      <MenuItem value={'hindi'}>
                        <Typography variant="caption">Hindi</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={1} width={1}>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    // sx={{ marginBottom: 1 }}
                    sx={{ flex: 1 }}
                  >
                    Default Languages
                  </Typography>
                  <FormControl size="small">
                    <Select
                      size="small"
                      multiple
                      labelId="roleplay-default-language-select-label"
                      id="roleplay-default-language-select"
                      name="defaultRoleplayLanguages"
                      MenuProps={{ disableScrollLock: true }}
                      onChange={(e) => {
                        setDefaultAnalysisLanguages(e.target.value);
                      }}
                      value={defaultRoleplayLanguages}
                    >
                      <MenuItem value={'english'}>
                        <Typography variant="caption">English</Typography>
                      </MenuItem>
                      <MenuItem value={'hindi'}>
                        <Typography variant="caption">Hindi</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          ) : null}
          {/* <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              <Typography variant="caption" color={'text.secondary'}>
                Default Call Type
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={defaultCallType}
              onChange={(e) => setDefaultCallType(e.target.value)}
            >
              <FormControlLabel
                value={'outbound'}
                control={<Radio size="small" />}
                label={'Outbound'}
              />
              <FormControlLabel
                value={'inbound'}
                control={<Radio size="small" />}
                label={'Inbound'}
              />
            </RadioGroup>
          </FormControl> */}
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowManageDefaultConfigurations(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={updatingUserConfigurations}
            startIcon={
              updatingUserConfigurations ? <CircularProgress size={20} /> : null
            }
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setUpdatingUserConfigurations(true);
              dispatch(
                updateUserSettingsV2(
                  showManageDefaultConfigurations?.id,
                  {
                    userSetting: {
                      defaultLanguagesForAnalysis: defaultAnalysisLanguages,
                      defaultLanguagesForRolePlay: defaultRoleplayLanguages,
                      languagesForAnalysis: supportedAnalysisLanguages,
                      languagesForRolePlay: supportedRoleplayLanguages,
                      salesCallType: defaultCallType,
                    },
                  },
                  () => {
                    setUpdatingUserConfigurations(false);
                    resetConfigurationFields();
                    setShowManageDefaultConfigurations(null);
                    dispatch(
                      showAlert({
                        message: 'Default configurations updated successfully',
                        showCross: true,
                        title: null,
                        type: 'success',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showHubspotUserMappingDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display={'flex'}
            flexDirection={'column'}
            // gap={1}
          >
            <Typography fontWeight={'bold'} color={'text.secondary'}>
              {
                allTeamMembers?.members?.find(
                  (member) => member?.id === showHubspotUserMappingDialog,
                )?.fullName
              }
            </Typography>
            <Typography variant="caption">
              {
                allTeamMembers?.members?.find(
                  (member) => member?.id === showHubspotUserMappingDialog,
                )?.email
              }
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {allTeamMembers?.members?.find(
            (member) => member?.id === showHubspotUserMappingDialog,
          )?.custom?.hubspotUser ? (
            <>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <DialogContentText id="alert-dialog-description" flex={1}>
                  Selected account is already linked to a hubspot user.
                </DialogContentText>
              </Box>
              <Typography variant="caption">
                If you want to change the linked hubspot user, select the
                associated hubspot email from the dropdown and hit save.
              </Typography>
            </>
          ) : (
            <>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <DialogContentText id="alert-dialog-description" flex={1}>
                  Pick a hubspot user email that you want to map with the
                  selected user.
                </DialogContentText>
              </Box>
              <Typography variant="caption">
                This will link the hubspot user to your team member
              </Typography>
            </>
          )}
          <FormControl fullWidth size="small">
            <Select
              size="small"
              labelId="user-select-label"
              id="user-select"
              name="userSelect"
              MenuProps={{
                disableScrollLock: true,
              }}
              onChange={(e) => {
                setHubspotEmail(e.target.value);
              }}
              value={hubspotEmail}
            >
              {hubspotUsers.map((u) => (
                <MenuItem key={u?.id} value={u?.email}>
                  {u?.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowHubspotUserMappingDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={mappingUser || !hubspotEmail}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setMappinguser(true);
              dispatch(
                mapHubspotUserToTeamMember(
                  {
                    [showHubspotUserMappingDialog]: hubspotEmail,
                  },
                  () => {
                    dispatch(
                      getAllTeamMembers(
                        teamId || null,
                        null,
                        true,
                        numberOfDays,
                        25,
                        null,
                        (data) => {
                          setMappinguser(false);
                          setShowHubspotUserMappingDialog(null);
                        },
                      ),
                    );
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showZohoUserMappingDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display={'flex'}
            flexDirection={'column'}
            // gap={1}
          >
            <Typography fontWeight={'bold'} color={'text.secondary'}>
              {
                allTeamMembers?.members?.find(
                  (member) => member?.id === showZohoUserMappingDialog,
                )?.fullName
              }
            </Typography>
            <Typography variant="caption">
              {
                allTeamMembers?.members?.find(
                  (member) => member?.id === showZohoUserMappingDialog,
                )?.email
              }
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {allTeamMembers?.members?.find(
            (member) => member?.id === showZohoUserMappingDialog,
          )?.custom?.zohoUser ? (
            <>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <DialogContentText id="alert-dialog-description" flex={1}>
                  Selected account is already linked to a Zoho user.
                </DialogContentText>
              </Box>
              <Typography variant="caption">
                If you want to change the linked Zoho user, select the
                associated Zoho email from the dropdown and hit save.
              </Typography>
            </>
          ) : (
            <>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <DialogContentText id="alert-dialog-description" flex={1}>
                  Pick a Zoho user email that you want to map with the selected
                  user.
                </DialogContentText>
              </Box>
              <Typography variant="caption">
                This will link the Zoho user to your team member
              </Typography>
            </>
          )}
          <FormControl fullWidth size="small">
            <Select
              size="small"
              labelId="user-select-label"
              id="user-select"
              name="userSelect"
              MenuProps={{
                disableScrollLock: true,
              }}
              onChange={(e) => {
                setZohoEmail(e.target.value);
              }}
              value={zohoEmail}
            >
              {zohoUsers.map((u) => (
                <MenuItem key={u?.id} value={u?.email}>
                  {u?.email} ({u?.first_name} {u?.last_name})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowZohoUserMappingDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={mappingZohoUser || !zohoEmail}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setMappingZohoUser(true);
              dispatch(
                mapZohoUserToTeamMember(
                  {
                    [showZohoUserMappingDialog]: zohoEmail,
                  },
                  () => {
                    dispatch(
                      getAllTeamMembers(
                        teamId || null,
                        null,
                        true,
                        numberOfDays,
                        25,
                        null,
                        (data) => {
                          setMappingZohoUser(false);
                          setShowZohoUserMappingDialog(null);
                        },
                      ),
                    );
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={Boolean(showCalendarInviteModal)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowCalendarInviteModal(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            maxHeight: '90vh',
            maxWidth: '60vw',
            minWidth: '500px',
            outline: 'none',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              background: '#d3d3d3',
              borderRadius: 1,
              padding: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body1">Schedule Roleplay</Typography>
            <IconButton onClick={() => setShowCalendarInviteModal(null)}>
              <Close sx={{ fontSize: 20 }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              padding: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'bold'}
                  color={'text.secondary'}
                >
                  Select timezone
                </Typography>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    size="small"
                    labelId="select-timezone-label"
                    id="select-timezone"
                    name="selectTimeZone"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => setTimeZone(e.target.value)}
                    value={timeZone}
                  >
                    <MenuItem value={'select'}>
                      <Typography>Select time zone</Typography>
                    </MenuItem>
                    {timeZones && timeZones?.length > 0
                      ? timeZones?.map((tm) => (
                          <MenuItem key={tm?.value} value={tm?.utc?.[0]}>
                            <Typography>{tm?.text}</Typography>
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'bold'}
                  color={'text.secondary'}
                >
                  Start Time
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateTimePicker
                    sx={{
                      width: 1,
                    }}
                    // label="Meeting Time"
                    value={dayjs(startDate)}
                    onChange={(newValue) => {
                      console.log(new Date(newValue).getTime());
                      setStartDate(new Date(newValue).getTime());
                      setEndDate(new Date(newValue).getTime() + 15 * 60 * 1000);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'bold'}
                  color={'text.secondary'}
                >
                  Select Duration
                </Typography>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    size="small"
                    labelId="select-duration-label"
                    id="select-duration"
                    name="selectDuration"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => {
                      setEndDate(startDate + e.target.value);
                    }}
                    value={endDate - startDate}
                  >
                    <MenuItem value={15 * 60 * 1000}>
                      <Typography>15 Minutes</Typography>
                    </MenuItem>
                    <MenuItem value={30 * 60 * 1000}>
                      <Typography>30 Minutes</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'bold'}
                  color={'text.secondary'}
                >
                  Repeats
                </Typography>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    size="small"
                    labelId="select-repeat-label"
                    id="select-repeat"
                    name="selectRepeat"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => setFrequency(e.target.value)}
                    value={frequency}
                  >
                    <MenuItem value={'norepeat'}>
                      <Typography>Do not repeat</Typography>
                    </MenuItem>
                    <MenuItem value={'DAILY'}>
                      <Typography>Daily</Typography>
                    </MenuItem>
                    <MenuItem value={'WEEKLY'}>
                      <Typography>Weekly</Typography>
                    </MenuItem>
                    <MenuItem value={'MONTHLY'}>
                      <Typography>Monthly</Typography>
                    </MenuItem>
                    <MenuItem value={'YEARLY'}>
                      <Typography>Yearly</Typography>
                    </MenuItem>
                    <MenuItem value={'CUSTOM'}>
                      <Typography>Custom</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}></Grid>
              {frequency !== 'norepeat' && (
                <>
                  {frequency === 'CUSTOM' && (
                    <Grid item xs={12}>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        <Typography
                          variant="subtitle2"
                          fontWeight={'bold'}
                          color={'text.secondary'}
                        >
                          Repeat Every
                        </Typography>
                      </Box>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        <TextField
                          placeholder="Repeat Every"
                          variant="outlined"
                          name={'recurrenceInterval'}
                          size="small"
                          value={recurrenceInterval}
                          onChange={(e) =>
                            setRecurrenceInterval(e.target.value)
                          }
                          type="number"
                        />
                        <FormControl>
                          <Select
                            fullWidth
                            size="small"
                            labelId="select-repeat-label"
                            id="select-repeat"
                            name="selectRepeat"
                            MenuProps={{ disableScrollLock: true }}
                            onChange={(e) => setCustomFrequency(e.target.value)}
                            value={customFrequency}
                          >
                            <MenuItem value={'DAILY'}>
                              <Typography>Day</Typography>
                            </MenuItem>
                            <MenuItem value={'WEEKLY'}>
                              <Typography>Week</Typography>
                            </MenuItem>
                            <MenuItem value={'MONTHLY'}>
                              <Typography>Month</Typography>
                            </MenuItem>
                            <MenuItem value={'YEARLY'}>
                              <Typography>Year</Typography>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      fontWeight={'bold'}
                      color={'text.secondary'}
                    >
                      Ends
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        // row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={endType}
                        onChange={(e) => {
                          // handleTrackEvent(e, `selected_bot_change`);
                          setEndType(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value={'never'}
                          control={<Radio />}
                          label={'Never'}
                        />
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'flex-start'}
                          gap={3}
                          mt={2}
                        >
                          <FormControlLabel
                            value={'date'}
                            control={<Radio />}
                            label={'On'}
                          />
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                width: 1,
                              }}
                              disabled={endType !== 'date'}
                              value={dayjs(endRepeat)}
                              onChange={(newValue) => {
                                console.log(new Date(newValue).getTime());
                                setEndRepeat(new Date(newValue).getTime());
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'flex-start'}
                          gap={3}
                          mt={2}
                        >
                          <FormControlLabel
                            value={'recurrences'}
                            control={<Radio />}
                            label={'After'}
                          />
                          <TextField
                            // placeholder=""
                            disabled={endType !== 'recurrences'}
                            variant="outlined"
                            name={'recurrenceCount'}
                            size="small"
                            value={recurrenceCount}
                            onChange={(e) => setRecurrenceCount(e.target.value)}
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography
                                    variant="subtitle2"
                                    color={'text.secondary'}
                                  >
                                    occurrences
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={2}
            justifyContent={'space-between'}
            p={1}
            borderTop={'1px solid #d3d3d3'}
          >
            <Box>
              {calendarInviteError && (
                <Alert severity="error">{calendarInviteError}</Alert>
              )}
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              gap={1}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => setShowCalendarInviteModal(null)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={sendingCalendarInvite}
                startIcon={
                  sendingCalendarInvite ? <CircularProgress size={14} /> : null
                }
                onClick={() => {
                  if (endDate < startDate) {
                    setCalendarInviteError(
                      'End time should be after the start time',
                    );
                  } else {
                    setCalendarInviteError(false);
                    setSendingCalendarInvite(true);
                    dispatch(
                      sendTeamInvite(
                        {
                          invitationType: 'practice_call',
                          receiverDtls: [
                            {
                              email: showCalendarInviteModal?.email,
                              firstName: showCalendarInviteModal?.firstName,
                              icsFileRequestDto: {
                                endDate: endDate,
                                endRepeat: endType === 'date' ? endRepeat : 0,
                                frequency:
                                  frequency === 'CUSTOM'
                                    ? customFrequency
                                    : frequency !== 'norepeat'
                                    ? frequency
                                    : null,
                                recurrenceCount:
                                  endType === 'recurrences'
                                    ? recurrenceCount
                                    : null,
                                recurrenceInterval:
                                  endType === 'never'
                                    ? -1
                                    : frequency !== 'norepeat'
                                    ? Number(recurrenceInterval)
                                    : 1,
                                startDate: startDate,
                                timeZoneId: timeZone,
                                teamId: teamId ? teamId : null,
                              },
                            },
                          ],
                        },
                        (error) => {
                          if (error) {
                            setSendingCalendarInvite(false);
                            dispatch(
                              showAlert({
                                message:
                                  'Failed to schedule roleplay. Please try again!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            setSendingCalendarInvite(false);
                            setShowCalendarInviteModal(null);
                            dispatch(
                              showAlert({
                                message:
                                  '"Roleplay schedule email sent successfully".',
                                showCross: true,
                                title: null,
                                type: 'success',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          }
                        },
                      ),
                    );
                  }
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

TeamSettings.propTypes = {
  fullWidth: PropTypes.bool,
  defaultTeamMembersTab: PropTypes.string,
};

export default TeamSettings;
