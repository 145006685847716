import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const updateUserDetails = (userRequest, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'UPDATING_USER_DETAILS' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.put(`/us/users/${userId}`, userRequest, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'UPDATING_USER_DETAILS_DONE',
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'UPDATING_USER_DETAILS_FAILED',
              payload: `Failed to update user details`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'UPDATING_USER_DETAILS_FAILED',
            payload: `Failed to update user details`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'UPDATING_USER_DETAILS_FAILED',
        payload: `Failed to update user details`,
      });
    }
  };
};

export default updateUserDetails;
