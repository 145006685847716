import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const syncDataWithZoho = (payload, callback, errCb) => {
  return async (dispatch) => {
    dispatch({ type: 'DATA_SYNCING_WITH_ZOHO' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/us/zh/summary/export`, payload, {
            params: {
              'request-id': reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'DATA_SYNCING_WITH_ZOHO_DONE',
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'DATA_SYNCING_WITH_ZOHO_FAILED',
              payload: `Failed to sync data with zoho`,
            });
            if (typeof errCb === 'function') {
              errCb(response?.response?.data);
            }
          }
        } catch (e) {
          dispatch({
            type: 'DATA_SYNCING_WITH_ZOHO_FAILED',
            payload: `Failed to sync data with zoho`,
          });
          if (typeof errCb === 'function') {
            errCb(e?.response?.data);
          }
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'DATA_SYNCING_WITH_ZOHO_FAILED',
        payload: `Failed to sync data with zoho`,
      });
      if (typeof errCb === 'function') {
        errCb(e?.response?.data);
      }
    }
  };
};

export default syncDataWithZoho;
