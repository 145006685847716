import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const validateZohoCrmOAuthUrl = (
  orgId,
  code,
  error,
  location,
  accountsServer,
  callback,
  errCallback,
) => {
  return async (dispatch) => {
    dispatch({ type: 'VALIDATING_ZOHO_CRM_OAUTH_URL' });
    try {
      // let unsubscribe = onAuthStateChanged(auth, async (user) => {
      //   const token = await user?.getIdToken();
      //   const userInfo = localStorage.getItem('userInfo');
      //   const userId = JSON.parse(userInfo)?.id;
      const reqId = uuidv4();
      try {
        const response = await axios.get(`/us/zh/oauth/zoho-crm`, {
          params: {
            'request-id': reqId,
            orgId,
            code,
            error,
            location,
            'accounts-server': accountsServer,
          },
          headers: {
            // Authorization: `Bearer ${token}`,
          },
        });
        //   console.log(response.data);
        if (response.status === 200) {
          await dispatch({
            type: 'VALIDATING_ZOHO_CRM_OAUTH_URL_DONE',
            payload: response?.data,
          });
          if (typeof callback === 'function') {
            callback(response?.data);
          }
        } else {
          dispatch({
            type: 'VALIDATING_ZOHO_CRM_OAUTH_URL_FAILED',
            payload: `Failed to validate zoho crm oauth url`,
          });
          if (typeof errCallback === 'function') {
            errCallback();
          }
        }
      } catch (e) {
        dispatch({
          type: 'VALIDATING_ZOHO_CRM_OAUTH_URL_FAILED',
          payload: `Failed to validate zoho crm oauth url`,
        });
        if (typeof errCallback === 'function') {
          errCallback();
        }
      }
      //   unsubscribe();
      // });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'VALIDATING_ZOHO_CRM_OAUTH_URL_FAILED',
        payload: `Failed to validate zoho crm oauth url`,
      });
      if (typeof errCallback === 'function') {
        errCallback();
      }
    }
  };
};

export default validateZohoCrmOAuthUrl;
