import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const getMessageResponse = (
  conversationId,
  message,
  speaker,
  status,
  videoId,
  version,
  wordsLimit,
  callback,
  errCb,
) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCHING_MESSAGE_RESPONSE' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(
            `/cs/conversation/${conversationId}`,
            {
              message,
              status: status || null,
              speaker: speaker,
              videoId,
              wordsLimit,
            },
            {
              params: {
                'request-id': reqId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
                'x-api-version': version || 'v1',
              },
            },
          );
          if (response.status === 200) {
            await dispatch({
              type: 'FETCHING_MESSAGE_RESPONSE_DONE',
              payload: {
                response: response?.data,
              },
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            console.log('comng here');
            console.log(response);
            // console.log(message);
            dispatch({
              type: 'FETCHING_MESSAGE_RESPONSE_FAILED',
              payload: `Failed to get gpt response`,
            });
            if (typeof errCb === 'function') {
              errCb(response?.response?.data?.message);
            }
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_MESSAGE_RESPONSE_FAILED',
            payload: `Failed to get gpt response`,
          });
          if (typeof errCb === 'function') {
            errCb();
          }
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'FETCHING_MESSAGE_RESPONSE_FAILED',
        payload: `Failed to get gpt response`,
      });
      if (typeof errCb === 'function') {
        errCb();
      }
    }
  };
};

export default getMessageResponse;
