import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Avatar,
  Alert,
  ListItemText,
  OutlinedInput,
  Fab,
} from '@mui/material';
import { keyframes } from '@mui/system';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  ArrowBack,
  Article,
  Audiotrack,
  Call,
  CallEnd,
  Chat,
  ChevronRight,
  Circle,
  Close,
  CloudUpload,
  Delete,
  Edit,
  Info,
  Lock,
  Mic,
  MicOff,
  Pause,
  PauseCircle,
  PlayArrow,
  RecordVoiceOver,
  RecordVoiceOverOutlined,
  Replay,
  Send,
  Stop,
  StopCircle,
  StopOutlined,
  SupportAgent,
  Troubleshoot,
  Undo,
  VoiceOverOffOutlined,
  VolumeUp,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import getAllConversationsMain from 'redux/actions/Candidate/getAllConversationsMain';

import parse from 'html-react-parser';
import createNewConversation from 'redux/actions/Candidate/createNewConversation';
import getConversationById from 'redux/actions/Candidate/getConversationById';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import { RecordRTCPromisesHandler } from 'recordrtc';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import getMessageResponse from 'redux/actions/Candidate/getMessageResponse';
import uploadAudio from 'redux/actions/Common/uploadAudio';
import verifyAudioUpload from 'redux/actions/Common/verifyAudioUpload';
import getMergedConversation from 'redux/actions/Candidate/getMergedConversation';
import deleteConversation from 'redux/actions/Candidate/deleteConversation';
import updateConversationStatus from 'redux/actions/Candidate/updateConversationStatus';
import checkMergedAudioStatus from 'redux/actions/Common/checkMergedAudioStatus';
import logError from 'redux/actions/Common/logError';
import getBots from 'redux/actions/Common/getBots';
import analyzeConversation from 'redux/actions/Common/analyzeConversation';
import RichTextEditor from 'components/RichTextEditor';
import checkConversationTranscriptStatus from 'redux/actions/Common/checkConversationTranscriptStatus';
import { PropTypes } from 'prop-types';

import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';
import showAlert from 'redux/actions/Common/showAlert';
import checkPracticeCallScoreStatus from 'redux/actions/Common/checkPracticeCallScoreStatus';
import trackEvent from 'redux/actions/Common/trackEvent';
import { capitalizeText, formatSeconds } from 'utils';
import getAllConversationsMainByUserId from 'redux/actions/Candidate/getAllConversationsMainByUserId';
import getFeedbacksOnEntity from 'redux/actions/Candidate/getFeedbacksOnEntity';
import postFeedbackOnEntity from 'redux/actions/Candidate/postFeedbackOnEntity';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import syncDataWithHubspot from 'redux/actions/Common/syncDataWithHubspot';
import ReportPage from 'pages/ReportPage';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import MediaPreview from 'components/@2024/MediaPreview';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import Lottie from 'react-lottie';
import speakingAnimation from '../../../data/lotties/speakingAnimation.json';
import getTrainingData from 'redux/actions/Candidate/getTrainingData';
import addExpertBotTraningData from 'redux/actions/Candidate/addExpertBotTraningData';
import createHelpSection from 'redux/actions/Compass/createHelpSection';

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import updateExpertBotTrainingData from 'redux/actions/Candidate/updateExpertBotTrainingData';
import deleteTrainingData from 'redux/actions/Candidate/deleteTrainingData';
import uploadHelpVideo from 'redux/actions/Common/uploadHelpVideo';
import verifyHelpVideoUpload from 'redux/actions/Common/verifyHelpVideoUpload';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    // borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const VideoPreview = memo(
  function VideoPreview({ video, onDurationChange }) {
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        {video ? (
          <Box width={'90%'} display={'flex'} flexDirection={'column'} gap={1}>
            <video
              style={{ width: '100%' }}
              preload={false}
              src={
                typeof video === 'string'
                  ? video
                  : window.URL.createObjectURL(video)
              }
              controls
              controlsList="nodownload"
              disablePictureInPicture
              onDurationChange={onDurationChange}
            />
          </Box>
        ) : null}
      </Box>
    );
  },
  (pre, post) => {
    return pre?.video === post?.video;
  },
);

const ExpertConversation = ({ uId, member, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const browser = detect();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { userDetails } = useSelector((state) => state.userDetails);
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const {
    conversations,
    fetching,
    fetchingPagination,
    lastSeen,
    emptyList,
  } = useSelector((state) =>
    uId ? state.allConversationsByUserId : state.allConversationsMain,
  );
  const feedbacksOnEntity = useSelector((state) => state.feedbacksOnEntity);

  const {
    showCompetitionDetailsOnTopbar,
    showBackButtonOnTopbar,
    currentContextData,
    currentNavigationLevel,
  } = useSelector((state) => state.uiStates);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const { progressIds } = useSelector((state) => state.mergedAudioProgressIds);

  const [currentTab, setCurrentTab] = useState('create');

  const [conversationTitle, setConversationTitle] = useState('');

  const [viewType, setViewType] = useState('list');

  const [gender, setGender] = useState('MALE');
  const [creatingConversation, setCreatingConversation] = useState(false);
  const [currentConversation, setCurrentConversation] = useState(null);

  const [promptType, setPromptType] = useState('default_with_name');
  const [companyName, setCompanyName] = useState('');
  const [aboutCompany, setAboutCompany] = useState('');
  const [context, setContext] = useState('');

  const [recorder, setRecorder] = useState(null);
  const [showPermissionDeniedError, setShowPermissionDeniedError] = useState(
    false,
  );
  const [permissionDenied, setPermissionDenied] = useState(null);
  const [permissionPersisting, setPermissionPersisting] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const [recording, setRecording] = useState(null);
  const streamRef = useRef();
  const playBackRef = useRef();
  const timer = useRef(null);
  const timer2 = useRef(null);
  const [timerSs, setTimerSs] = useState(0);
  const [timerMs, setTimerMs] = useState(0);
  const [mediaStream, setMediaStream] = useState(null);
  const [sampleRate, setSampleRate] = useState(48000);
  const [availableAudioDevices, setAvailableAudioDevices] = useState(null);
  const [firstRenderAudio, setFirstRenderAudio] = useState(false);
  const [audioSource, setAudioSource] = useState('');
  const [autoPlay, setAutoPlay] = useState(false);
  const [autoRecord, setAutoRecord] = useState(true);
  const [autoStart, setAutoStart] = useState(false);
  const [message, setMessage] = useState('');
  const [microphoneLevel, setMicrophoneLevel] = useState(0);

  const [conversationStarted, setConversationStarted] = useState(false);
  const [numberOfDots, setNumberOfDots] = useState(0);
  const [numberOfAltDots, setNumberOfAltDots] = useState(0);
  const dotsTimerRef = useRef(null);
  const dots2TimerRef = useRef(null);
  const [playingResponse, setPlayingResponse] = useState(false);
  const [responseAudioUrl, setResponseAudioUrl] = useState('');

  const [gettingResponse, setGettingResponse] = useState(false);
  const [
    generatingMergedConversation,
    setGeneratingMergedConversation,
  ] = useState([]);

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);

  const [showEmptySpeech, setShowEmptySpeech] = useState(false);

  const responseAudioRef = useRef(null);

  const checkPauseTimerRef = useRef(null);
  const [pauseTimer, setPauseTimer] = useState(0);
  const [pauseTimeLimit, setPauseTimeLimit] = useState(2500);

  const [analysisInProgress, setAnalysisInProgress] = useState([]);
  const [endConversationAlert, setEndConversationAlert] = useState(false);

  const [publicBots, setPublicBots] = useState([]);
  const [privateBots, setPrivateBots] = useState([]);
  const [botId, setBotId] = useState('');

  const [showReAnalyzeDrawer, setShowReAnalyzeDrawer] = useState(null);
  const [methods, setMethods] = useState(['meddpic']);
  const [playbook, setPlaybook] = useState('');
  const [speaker, setSpeaker] = useState('spk_0');
  const [speakers, setSpeakers] = useState({});
  const checkTranscriptionProgressTimer = useRef(null);
  const [transcriptReady, setTranscriptReady] = useState(false);
  const [transcriptionFailed, setTranscriptionFailed] = useState(false);
  const [salesReview, setSalesReview] = useState(null);

  const [scriptReadingFillerWords, setScriptReadingFillerWords] = useState([]);
  const [scriptRepeatWords, setScriptRepeatWords] = useState([]);
  const [fillerWordsRegexp, setFillerWordsRegexp] = useState(null);
  const [repeatWordsRegexp, setRepeatWordRegexp] = useState(null);

  const [currentParam, setCurrentParam] = useState('transcript');
  const [showPlaybook, setShowPlaybook] = useState(false);
  const [currentMethod, setCurrentMethod] = useState('meddpic');
  const checkMergedAudioProgressTimerRef = useRef(null);

  const checkPracticeCallScoreStatusTimer = useRef(null);

  const scrollDivRef = useRef(null);
  const scrollDiv1Ref = useRef(null);
  const [scoreCalculationInProcess, setScoreCalculationInProcess] = useState(
    true,
  );

  const [vertical, setVertical] = useState('surprise');
  const [designation, setDesignation] = useState('surprise');
  const [feedbackOnEntity, setFeedbackOnEntity] = useState('');
  const [postingFeedback, setPostingFeedback] = useState(false);

  const [fieldsRequired, setFieldsRequired] = useState([]);
  const [showLimitExhausted, setShowLimitExhausted] = useState('');
  const [showSyncHubspotDialog, setShowSyncHubspotDialog] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactFirstName, setContactFirstName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [dateTime, setDateTime] = useState(new Date().getTime());
  const [syncingWithHubspot, setSyncingWithHubspot] = useState(false);

  const [playbooks, setPlaybooks] = useState([]);
  const [selectedPlaybook, setSelectedPlaybook] = useState('select');
  const [playbookTitle, setPlaybookTitle] = useState('');

  const [showPreviewSalesCall, setShowPreviewSalesCall] = useState(false);
  const [selectedBotId, setSelectedBotId] = useState('');
  const chatsScrollview = useRef(null);

  const [currentBot, setCurrentBot] = useState(null);
  const [conversationType, setConversationType] = useState('talk');
  const [muted, setMuted] = useState(false);
  const [finalMessage, setFinalMessage] = useState('');

  const [showAddHelpVideo, setShowAddHelpVideo] = useState(false);

  const helpVideoRef = useRef(null);
  const [helpVideo, setHelpVideo] = useState(null);
  const [invalidVideoFile, setInvalidVideoFile] = useState(false);
  const [helpVideoDuration, setHelpVideoDuration] = useState(0);

  const [deletingTrainingData, setDeletingTrainingData] = useState(false);
  const [
    showConfirmDeleteTrainingData,
    setShowConfirmDeleteTrainingData,
  ] = useState(null);
  const [showAddTrainingData, setShowAddTrainingData] = useState(null);
  const [saveAsHelpTopic, setSaveAsHelpTopic] = useState(false);
  const [trainingTopic, setTrainingTopic] = useState('');
  const [trainingText, setTrainingText] = useState('');
  const [expanded, setExpanded] = useState(null);

  const [
    fetchingPreviousTrainingData,
    setFetchingPreviousTrainingData,
  ] = useState(false);
  const [previousTrainingData, setPreviousTrainingData] = useState([]);
  const [training, setTraining] = useState(false);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition();

  const handleVideoFilePicker = (event) => {
    handleTrackEvent(event, 'help_video_selected');
    if (
      event.target.files[0] &&
      event.target.files[0].type.includes('video') &&
      event.target.files[0]?.size <= 300 * 1000 * 1000
    ) {
      setHelpVideo(event.target.files[0]);
      setInvalidVideoFile(false);
    } else {
      helpVideoRef.current.value = null;
      setInvalidVideoFile(
        'Please select a valid video file of size not exceeding 300 MB.',
      );
    }
  };

  const handleTrackEvent = (e, type) => {
    // console.log(e.target);
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const getRecordingPermission = async () => {
    try {
      const timeStampBeforePermission = Date.now();
      let stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      });
      const timeStampAfterPermission = Date.now();
      setPermissionDenied(false);
      if (timeStampAfterPermission - timeStampBeforePermission < 1500) {
        setPermissionPersisting(true);
      } else {
        setPermissionPersisting(false);
      }
      streamRef.current = stream;
      const sampleRateBlock = stream.getAudioTracks()[0].getSettings()
        .sampleRate;
      setSampleRate(sampleRateBlock);
      // console.log('sampleRateBlock', sampleRateBlock);
      setMediaStream(stream);
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log('enumerateDevices() not supported.');
        return;
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      setAvailableAudioDevices(
        devices.filter((device) => device.kind === 'audioinput'),
      );
    } catch (e) {
      console.log(e?.name);
      if (e?.name === 'NotAllowedError') {
        setShowPermissionDeniedError(true);
        setPermissionDenied(true);
        setError(true);
        setErrorMessage(
          'Camera and microphone are blocked. Please allow the permissions and start recording.',
        );
      } else {
        setError(true);
        setErrorMessage(
          'Oops we are unable to detect your camera. Please refresh this page and try again.',
        );
      }
    }
  };

  const handleRecordClick = async () => {
    setConversationStarted(true);
    setAutoStart(false);
    setMuted(false);
    if (permissionDenied) {
      setShowPermissionDeniedError(true);
    } else {
      try {
        setSelectedAudio(null);
        setRecordedBlobs([]);
        setRecording(true);
        // startTimer();
        if (playBackRef && playBackRef.current) {
          playBackRef.current.src = null;
        }
        SpeechRecognition.startListening({ continuous: true });
      } catch (error) {
        handleRecordStopClick();
        alert('An error occurred while recording: ' + error.message);
      }
    }
  };

  const handleRecordStopClick = async (end) => {
    // setConversationStarted(false);
    try {
      if (recording) {
        await recorder.stopRecording();
        let blob = await recorder.getBlob();
        // console.log('blob', blob);
        setSelectedAudio(blob);
        stopTimer();
        setRecording(false);
        SpeechRecognition.stopListening();
        formik.handleSubmit();
      } else {
        if (end) {
          if (autoRecord) {
            setAutoStart(false);
          }
          dispatch(
            updateConversationStatus(
              currentConversation?.id,
              'completed',
              () => {
                setCurrentConversation((prev) => {
                  return {
                    ...prev,
                    status: 'completed',
                  };
                });
              },
            ),
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClearRecordingClick = () => {
    setSelectedAudio(null);
    setRecordedBlobs([]);
    setRecording(null);
    setMessage('');
    resetTranscript();
    if (selectedAudio) {
      playBackRef.current.src = null;
    }
    if (streamRef && streamRef.current)
      streamRef.current.getTracks().forEach((track) => track.stop());
    getRecordingPermission();
  };

  const startTimer = () => {
    timer.current = setInterval(() => {
      setTimerSs((prev) => {
        if (prev === 59) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 1000);
    timer2.current = setInterval(() => {
      setTimerMs((prev) => prev + 1);
    }, 60000);
  };

  const pauseRecordingTimer = () => {
    console.log('calling this');
    clearInterval(timer.current);
    clearInterval(timer2.current);
  };

  const stopTimer = () => {
    // console.log(timer.current, timer2.current);
    clearInterval(timer.current);
    clearInterval(timer2.current);
    setTimerSs(0);
    setTimerMs(0);
  };

  // const handleGetResponse = () => {};

  const handleGetResponse = (audio, end, conId, convData) => {
    if (finalMessage.trim()) {
      dispatch(
        getMessageResponse(
          conId || currentConversation?.id,
          finalMessage,
          null,
          end ? 'completed' : null,
          null,
          null,
          null,
          async (data) => {
            if (data?.userAudioUploadLink && data?.userAudioId && audio) {
              dispatch(
                uploadAudio(data?.userAudioUploadLink, audio, () => {
                  dispatch(
                    verifyAudioUpload(
                      data?.userAudioUploadLink && data?.userAudioId,
                      null,
                      () => {
                        setSelectedAudio(null);
                      },
                    ),
                  );
                }),
              );
            }
            setGettingResponse(false);
            setFinalMessage('');
            resetTranscript();
            if (conversationType === 'talk') {
              if (streamRef && streamRef.current)
                streamRef.current.getTracks().forEach((track) => track.stop());
              getRecordingPermission();
            }
            setCurrentConversation((prev) => {
              return {
                ...convData,
                messages: [
                  ...(prev.messages || []),
                  {
                    gptMessage: {
                      role: 'assistant',
                      content: data?.gptResponse,
                    },
                    id: uuidv4(),
                    createdOn: Date.now(),
                    audioId: uuidv4(),
                    userId: null,
                    customFields: data?.elevenLabsAudioLink
                      ? {
                          elevenLabsAudioLink: data?.elevenLabsAudioLink,
                        }
                      : data?.gptTTsLink
                      ? {
                          gptTTsLink: data?.gptTTsLink,
                        }
                      : data?.gcpAudioLink
                      ? {
                          gcpAudioLink: data?.gcpAudioLink,
                        }
                      : null,
                  },
                ],
              };
            });
            if (end) {
              if (autoRecord) {
                setAutoStart(false);
              }
              dispatch(
                updateConversationStatus(
                  currentConversation?.id,
                  'completed',
                  () => {
                    setCurrentConversation((prev) => {
                      return {
                        ...prev,
                        status: 'completed',
                      };
                    });
                  },
                ),
              );
            } else {
              setAutoPlay(true);
              setPlayingResponse(true);
              if (autoRecord) {
                setAutoStart(true);
              }
            }
            if (convData?.audioService?.includes('ELEVEN_LABS')) {
              setResponseAudioUrl(data?.elevenLabsAudioLink);
            } else if (convData?.audioService?.includes('CHAT_GPT')) {
              setResponseAudioUrl(data?.gptTTsLink);
            } else {
              setResponseAudioUrl(data?.gcpAudioLink);
            }
          },
          (errorCode) => {
            if (errorCode === 'quota exceeded') {
              setShowLimitExhausted(errorCode);
            }
            // console.log('coming here');
            setGettingResponse(false);
            setFinalMessage('');
            resetTranscript();
          },
        ),
      );
    } else {
      setShowEmptySpeech(true);
      dispatch(
        updateConversationStatus(currentConversation?.id, 'in_process', () => {
          setCurrentConversation((prev) => {
            return {
              ...prev,
              status: 'in_process',
            };
          });
        }),
      );
      if (end) {
        if (autoRecord) {
          setAutoStart(false);
        }
      }
    }
  };

  const handleSubmit = (values) => {
    setFinalMessage(message);
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: null,
    onSubmit: handleSubmit,
  });

  const handleChange = (e) => {
    if (e.target.checked) {
      setMethods((prev) => [...prev, e.target.value]);
    } else {
      setMethods((prev) => prev.filter((item) => item !== e.target.value));
    }
  };

  const handleListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        conversations &&
        conversations.length % 10 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      if (uId) {
        dispatch(getAllConversationsMainByUserId(uId, 10, lastSeen, () => {}));
      } else {
        dispatch(
          getAllConversationsMain(
            null,
            'expert_conversation',
            10,
            lastSeen,
            () => {},
          ),
        );
      }
    }
  };

  useEffect(() => {
    if (showAddTrainingData && showAddTrainingData !== 'new') {
      setTrainingTopic(showAddTrainingData?.topic);
      setTrainingText(showAddTrainingData?.content);
      setSaveAsHelpTopic(showAddTrainingData?.asHelpSection);
    }
  }, [showAddTrainingData]);

  useEffect(() => {
    if (finalMessage?.trim()) {
      setMessage('');
      if (!gettingResponse && !creatingConversation) {
        if (currentConversation) {
          if (finalMessage?.trim()) {
            setGettingResponse(true);
            setCurrentConversation((prev) => {
              return {
                ...prev,
                status: 'in_process',
                messages: [
                  ...(prev ? prev.messages || [] : []),
                  {
                    gptMessage: {
                      role: 'user',
                      content: finalMessage?.trim(),
                    },
                    id: uuidv4(),
                    createdOn: Date.now(),
                    audioId: uuidv4(),
                    userId: null,
                    customFields: {
                      gcpAudioLink: selectedAudio
                        ? URL.createObjectURL(selectedAudio)
                        : null,
                    },
                  },
                ],
              };
            });
          } else {
            setGettingResponse(false);
          }
          handleGetResponse(null, false, null, currentConversation);
        } else {
          if (finalMessage?.trim()) {
            setCreatingConversation(true);
            setCurrentConversation((prev) => {
              return {
                ...prev,
                status: 'in_process',
                messages: [
                  ...(prev ? prev.messages || [] : []),
                  {
                    gptMessage: {
                      role: 'user',
                      content: finalMessage?.trim(),
                    },
                    id: uuidv4(),
                    createdOn: Date.now(),
                    audioId: uuidv4(),
                    userId: null,
                    customFields: {
                      gcpAudioLink: selectedAudio
                        ? URL.createObjectURL(selectedAudio)
                        : null,
                    },
                  },
                ],
              };
            });
            dispatch(
              createNewConversation(
                {
                  // description: '',
                  title: finalMessage?.trim(),
                  botId: currentBot?.id,
                  conversationMod: conversationType,
                },
                (conId) => {
                  setGettingResponse(true);
                  dispatch(
                    getConversationById(conId, null, (data) => {
                      dispatch(
                        updateConversationStatus(conId, 'in_process', () => {
                          handleGetResponse(null, false, conId, data);
                        }),
                      );
                    }),
                  );
                  setCreatingConversation(false);
                },
              ),
            );
          }
        }
      }
    }
  }, [finalMessage]);

  useEffect(() => {
    if (transcript) {
      if (recording && !muted) {
        checkPauseTimerRef.current = setInterval(() => {
          if (recording && !muted) {
            handleRecordStopClick();
          }
        }, pauseTimeLimit + 200);
      } else {
        if (checkPauseTimerRef.current) {
          clearInterval(checkPauseTimerRef.current);
          setPauseTimer(0);
        }
      }
    }
    return () => {
      if (checkPauseTimerRef.current) {
        clearInterval(checkPauseTimerRef.current);
        setPauseTimer(0);
      }
    };
  }, [transcript, recording]);

  // useEffect(() => {
  //   if (pauseTimer > pauseTimeLimit) {

  //   }
  // }, [pauseTimer]);

  useEffect(() => {
    if (transcript) {
      setMessage(transcript);
    } else {
      setMessage('');
    }
  }, [transcript]);

  useEffect(() => {
    if (selectedAudio && recording === false) {
      let recordedVideoUrl = window.URL.createObjectURL(selectedAudio);
      // setRecording(false);
      if (playBackRef.current) {
        playBackRef.current.src = recordedVideoUrl;
        // playBackRef.current.play();
      }
    }
    // if (streamRef && streamRef.current)
    //   streamRef.current.getTracks().forEach((track) => track.stop());
  }, [selectedAudio, recording, playBackRef?.current]);

  useEffect(() => {
    let options = {
      mimeType: 'audio/webm',
    };
    try {
      if (mediaStream !== null) {
        setRecorder(new RecordRTCPromisesHandler(mediaStream, options));
      }
    } catch (e) {
      console.error(e);
      setError(true);
      setErrorMessage('Failed to get media device');
    }
  }, [mediaStream]);

  useEffect(() => {
    if (
      recording === true &&
      recordedBlobs.length === 0 &&
      selectedAudio === null
    ) {
      try {
        recorder.onerror = (e) => {
          console.log(e);
          setError(true);
          setErrorMessage(
            'Oops! Something went wrong. Please refresh this page and try again. ',
          );
        };
        recorder.startRecording();
      } catch (e) {
        console.log(e);
        console.log('mediaRecorder API not supported for this browser');
        setError(true);
        setErrorMessage(
          'Oops! Something went wrong. Please refresh this page and try again. ',
        );
      }
    }
  }, [selectedAudio, recordedBlobs, recording]);

  useEffect(() => {
    if (
      timerSs % 15 === 0 &&
      (timerSs !== 0 || (timerSs === 0 && timerMs !== 0))
    ) {
      (async function() {
        await SpeechRecognition.stopListening();
        await SpeechRecognition.startListening({ continuous: true });
      })();
      // console.log('restart');
    }
  }, [timerSs, timerMs]);

  useEffect(() => {
    if (!firstRenderAudio) {
      if (availableAudioDevices && availableAudioDevices.length > 0) {
        setAudioSource(availableAudioDevices[0].deviceId);
        setFirstRenderAudio(true);
      }
    }
  }, [availableAudioDevices]);

  useEffect(() => {
    if (viewType === 'conversation' && conversationType === 'talk') {
      // setAutoPlay(false);
      getRecordingPermission();
      if (chatsScrollview?.current) {
        chatsScrollview?.current?.scroll({
          top: chatsScrollview?.current?.scrollHeight,
          behaviour: 'smooth',
        });
      }
    }
    return () => {
      if (streamRef && streamRef.current)
        streamRef.current.getTracks().forEach((track) => track.stop());
    };
  }, [viewType, conversationType]);

  useEffect(() => {
    if (!params?.get('type')) {
      dispatch(
        getAllConversationsMain(
          null,
          'expert_conversation',
          10,
          null,
          () => {},
        ),
      );
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    }
  }, [params?.get('type')]);

  useEffect(() => {
    if (selectedPlaybook === 'select') {
      if (orgConfigs?.playbook) {
        setPlaybook(orgConfigs?.playbook);
      }
    } else {
      setPlaybook(
        playbooks?.find((pb) => pb?.id === selectedPlaybook)?.playbook || '',
      );
    }
  }, [orgConfigs, selectedPlaybook]);

  useEffect(() => {
    setMethods(orgConfigs?.selectedMethodologies || []);
  }, [orgConfigs]);

  useEffect(() => {
    if (viewType === 'conversation' || viewType === 'training') {
      dispatch(
        getBots('digital_twin', (data) => {
          // console.log(data);
          setPublicBots(data?.public);
          setPrivateBots(data?.personal);
          setBotId(data?.public?.[0]?.id || data?.personal?.[0]?.id || '');
          // setCurrentBot(
          //   data?.public?.find((b) => {
          //     // if (process.env.REACT_APP_TARGET_ENV === 'local') {
          //     return b?.id === data?.defaultExpertBot;
          //     // } else if (process.env.REACT_APP_TARGET_ENV === 'development') {
          //     //   return b?.id === 'f3945290-e884-4e8c-9d68-b59d32d69d83';
          //     // } else if (process.env.REACT_APP_TARGET_ENV === 'production') {
          //     //   return b?.id === 'f3945290-e884-4e8c-9d68-b59d32d69d83';
          //     // }
          //   }) || null,
          // );
        }),
      );
    }
  }, [viewType]);

  useEffect(() => {
    if (viewType === 'training' && currentBot) {
      setFetchingPreviousTrainingData(true);
      dispatch(
        getTrainingData(currentBot?.id, (data) => {
          console.log(data);
          setPreviousTrainingData(data || []);
          setFetchingPreviousTrainingData(false);
        }),
      );
    }
  }, [viewType, currentBot]);

  useEffect(() => {
    if (recording) {
      dotsTimerRef.current = setInterval(() => {
        setNumberOfDots((prev) => (prev >= 3 ? 0 : prev + 1));
      }, 500);
    } else {
      clearInterval(dotsTimerRef.current);
    }
  }, [recording]);

  useEffect(() => {
    if (playingResponse) {
      dots2TimerRef.current = setInterval(() => {
        setNumberOfAltDots((prev) => (prev >= 3 ? 0 : prev + 1));
      }, 500);
    } else {
      clearInterval(dots2TimerRef.current);
    }
  }, [playingResponse]);

  useEffect(() => {
    if (message) {
      setShowEmptySpeech(false);
    }
  }, [message]);

  useEffect(() => {
    if (salesReview) {
      setMeetingTitle(salesReview?.title);
      setSpeakers(salesReview?.speakers);
      if (salesReview?.analysis?.summary) {
        setCurrentMethod('summary');
      } else if (salesReview?.playbook) {
        setCurrentMethod('playbook');
      }
    }
  }, [salesReview]);

  useEffect(() => {
    if (
      salesReview?.analysis?.fillerInfo?.fillers &&
      Object.keys(salesReview?.analysis?.fillerInfo?.fillers) &&
      Object.keys(salesReview?.analysis?.fillerInfo?.fillers).length > 0
    ) {
      setScriptReadingFillerWords(
        Object.keys(salesReview?.analysis?.fillerInfo?.fillers),
      );
    }
    if (
      salesReview?.analysis?.repetitionInfo?.repetitions &&
      Object.keys(salesReview?.analysis?.repetitionInfo?.repetitions) &&
      Object.keys(salesReview?.analysis?.repetitionInfo?.repetitions).length > 0
    ) {
      setScriptRepeatWords(
        Object.keys(salesReview?.analysis?.repetitionInfo?.repetitions),
      );
    }
  }, [salesReview]);

  useEffect(() => {
    if (
      scriptReadingFillerWords &&
      Array.isArray(scriptReadingFillerWords) &&
      scriptReadingFillerWords.length > 0
    ) {
      setFillerWordsRegexp(
        new RegExp(
          scriptReadingFillerWords.map((w) => `{${w}}`).join('|'),
          'ig',
        ),
      );
    }
  }, [scriptReadingFillerWords]);

  useEffect(() => {
    if (
      scriptRepeatWords &&
      Array.isArray(scriptRepeatWords) &&
      scriptRepeatWords.length > 0
    ) {
      setRepeatWordRegexp(
        new RegExp(scriptRepeatWords.map((w) => `{${w}}`).join('|'), 'ig'),
      );
    }
  }, [scriptRepeatWords]);

  useEffect(() => {
    if (!showBackButtonOnTopbar) {
      if (conversationStarted || gettingResponse) {
        setEndConversationAlert(true);
      } else {
        setViewType('list');
        setCurrentConversation(null);
      }
    }
  }, [showBackButtonOnTopbar]);

  useEffect(() => {
    if (viewType === 'report' || viewType === 'conversation') {
      if (!(params && params.get('type'))) {
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'showBackButtonOnTopbar',
            value: true,
          },
        });
      }
      // dispatch({
      //   type: 'UPDATE_UI_STATE',
      //   payload: {
      //     key: 'currentContextData',
      //     value: {
      //       ...currentContextData,
      //       title: salesReview?.title || currentConversation?.title || '',
      //     },
      //   },
      // });
    } else {
      if (!uId) {
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'showBackButtonOnTopbar',
            value: false,
          },
        });
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'currentContextData',
            value: {
              ...currentContextData,
              title: '',
            },
          },
        });
      }
    }
  }, [viewType]);

  useEffect(() => {
    // if (currentConversation?.messages) {
    if (chatsScrollview?.current) {
      chatsScrollview?.current?.scroll({
        top: chatsScrollview?.current?.scrollHeight,
        behaviour: 'smooth',
      });
    }
    // }
  }, [currentConversation]);

  useEffect(() => {
    if (
      params &&
      (params.get('type') === 'talk' || params.get('type') === 'chat')
    ) {
      setCurrentTab('list');
      setViewType('conversation');
      if (params.get('type') === 'talk') {
        setConversationType((prev) => {
          if (prev === 'chat') {
            setCurrentConversation(null);
            stopTimer();
            return 'talk';
          } else {
            return 'talk';
          }
        });
      } else if (params.get('type') === 'chat') {
        setConversationType((prev) => {
          if (prev === 'talk') {
            setCurrentConversation(null);
            stopTimer();
            return 'chat';
          } else {
            return 'chat';
          }
        });
      }
    } else if (params && params.get('type') === 'training') {
      setCurrentTab('training');
      setViewType('training');
      setCurrentConversation(null);
      stopTimer();
      setConversationType('');
    } else {
      setCurrentTab('list');
      setViewType('list');
      setCurrentConversation(null);
      stopTimer();
      setConversationType('');
    }
  }, [window.location.href, params]);

  useEffect(() => {
    if (playbooks && playbooks.length > 0) {
      setSelectedPlaybook(
        currentConversation?.playbookHistoryId ||
          playbooks?.find((pb) => pb?.selected)?.id ||
          'select',
      );
    }
  }, [playbooks, currentConversation]);

  useEffect(() => {
    if (selectedPlaybook) {
      setPlaybookTitle(
        playbooks?.find((pb) => pb?.id === selectedPlaybook)?.tags?.[0] || '',
      );
    }
  }, [selectedPlaybook]);

  useEffect(() => {
    if (showReAnalyzeDrawer || currentTab === 'create') {
      dispatch(
        getPlaybookHistory(null, (data) => {
          if (data?.selectedPlaybook) {
            setPlaybooks([data?.selectedPlaybook, ...(data?.history || [])]);
          } else {
            setPlaybooks([...(data?.history || [])]);
          }
        }),
      );
    }
  }, [showReAnalyzeDrawer, currentTab]);

  useEffect(() => {
    if (botId === 'surprise') {
      setSelectedBotId(
        [...(publicBots || []), ...(privateBots || [])][
          Math.floor(
            Math.random() *
              [...(publicBots || []), ...(privateBots || [])].length,
          )
        ]?.id,
      );
    } else {
      setSelectedBotId(botId);
    }
  }, [botId]);

  useEffect(() => {
    if (muted) {
      pauseRecordingTimer();
      if (checkPauseTimerRef.current) {
        clearInterval(checkPauseTimerRef.current);
        setPauseTimer(0);
      }
      if (recorder) {
        recorder.pauseRecording();
      }
      if (recording) {
        SpeechRecognition.stopListening();
      }
    } else {
      if (recorder) {
        recorder.resumeRecording();
      }
      if (recording) {
        startTimer();
        SpeechRecognition.startListening({ continuous: true });
      }
    }
  }, [muted, recorder, recording]);

  useEffect(() => {
    setAutoPlay(false);
    dispatch(getOrganizationConfigs((data) => {}));
    dispatch(
      getUserDetails((data) => {
        setCurrentBot(data?.custom?.expertBot || null);
      }),
    );
    return () => {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    };
  }, []);

  console.log(currentBot);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight: isLoggedIn ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
        marginTop: {
          xs: 1,
          sm: 0,
          md: 0,
        },
      }}
    >
      {/* <Container maxWidth={{ sm: 1, md: 1 }} paddingX={2} paddingY={2}> */}
      {viewType === 'list' ? (
        <></>
      ) : viewType === 'report' ||
        member ||
        isXs ||
        viewType === 'conversation' ? (
        <Box
          p={'8px 16px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={
            viewType === 'report'
              ? 'flex-start'
              : isXs
              ? 'space-between'
              : 'space-between'
          }
          sx={{
            boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.1)',
          }}
        >
          {isXs ? (
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={() => {
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'showBackButtonOnTopbar',
                    value: false,
                  },
                });
              }}
              color="primary"
              size="small"
            >
              Go Back
            </Button>
          ) : null}
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Avatar
              variant="circle"
              alt={
                userInfo?.orgRoles?.includes('SS_ADMIN')
                  ? 'John Stopper'
                  : userInfo?.email === 'jbsales@qualification.ai' ||
                    userInfo?.email === 'vijay+laa1@list2shop.com'
                  ? 'John Barrows'
                  : 'Brynne Tillman'
              }
              src={
                userInfo?.orgRoles?.includes('SS_ADMIN')
                  ? '/js.jpeg'
                  : userInfo?.email === 'jbsales@qualification.ai' ||
                    userInfo?.email === 'vijay+laa1@list2shop.com'
                  ? '/jb.jpeg'
                  : '/rohn.jpeg'
              }
              sx={{
                width: 40,
                height: 40,
              }}
            >
              {userInfo?.orgRoles?.includes('SS_ADMIN')
                ? 'John Stopper'
                : userInfo?.email === 'jbsales@qualification.ai' ||
                  userInfo?.email === 'vijay+laa1@list2shop.com'
                ? 'John Barrows'
                : 'R'}
            </Avatar>
            <Box>
              <Typography variant="body1" color="text.primary">
                {currentBot?.title}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {currentBot?.description}
              </Typography>
            </Box>
          </Box>
          {currentConversation &&
          currentConversation?.status === 'in_process' ? (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              startIcon={<RecordVoiceOverOutlined />}
              onClick={() => {
                setCurrentConversation(null);
              }}
            >
              Start New Conversation
            </Button>
          ) : null}
        </Box>
      ) : null}
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs:
              (currentTab === 'create' || fetching) &&
              viewType !== 'conversation'
                ? 'center'
                : 'start',
            sm: 'start',
            md:
              (currentTab === 'create' || fetching) &&
              viewType !== 'conversation'
                ? 'center'
                : 'start',
          }}
          justifyContent={{
            xs:
              (currentTab === 'create' || fetching) &&
              viewType !== 'conversation'
                ? 'center'
                : 'start',
            sm: 'start',
            md:
              (currentTab === 'create' || fetching) &&
              viewType !== 'conversation'
                ? 'center'
                : 'flex-start',
          }}
          height={1}
          gap={1}
          sx={{
            borderRight: '1px solid #f1f1f1',
            minHeight: 'calc(100vh - 130px)',
            padding:
              uId || viewType === 'conversation' || viewType === 'training'
                ? 0
                : 2,
            paddingTop:
              uId || viewType === 'conversation' || viewType === 'training'
                ? 0
                : viewType === 'report'
                ? 0
                : 2,
          }}
          position={'relative'}
        >
          {viewType === 'list' && currentTab === 'create' ? (
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                padding: 2,
                minWidth: '50%',
                // minHeight: '60vh',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                },
              }}
            >
              <Typography variant="subtitle2" color={'text.secondary'}>
                Conversation Title *
              </Typography>
              <TextField
                autoFocus
                placeholder="Title*"
                variant="outlined"
                color="primary"
                size="small"
                name="title"
                value={conversationTitle}
                onChange={(e) => setConversationTitle(e.target.value)}
                fullWidth
              />
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  AI Persona
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={botId}
                  onChange={(e) => {
                    handleTrackEvent(e, `selected_bot_change`);
                    setBotId(e.target.value);
                  }}
                >
                  {publicBots && publicBots?.length > 0
                    ? publicBots?.map((pb) => (
                        <FormControlLabel
                          key={pb?.id}
                          value={pb?.id}
                          control={<Radio />}
                          label={
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              justifyContent={'center'}
                              sx={{
                                border: '1px solid #d3d3d3',
                                borderRadius: 1,
                                padding: 1,
                                minWidth: 160,
                                maxWidth: 160,
                                minHeight: 80,
                              }}
                              p={1}
                            >
                              <Typography variant="body2">
                                {pb?.title}
                              </Typography>
                              {pb?.description ? (
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                  sx={{}}
                                >
                                  {pb?.description}
                                </Typography>
                              ) : null}
                            </Box>
                          }
                        />
                      ))
                    : null}
                  {privateBots && privateBots?.length > 0
                    ? privateBots?.map((pb) => (
                        <FormControlLabel
                          key={pb?.id}
                          value={pb?.id}
                          control={<Radio />}
                          label={
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              justifyContent={'center'}
                              sx={{
                                border: '1px solid #d3d3d3',
                                borderRadius: 1,
                                padding: 1,
                                minWidth: 160,
                                maxWidth: 160,
                                minHeight: 80,
                              }}
                              p={1}
                            >
                              <Typography variant="body2">
                                {pb?.title}
                              </Typography>
                              {pb?.description ? (
                                <Typography
                                  variant="caption"
                                  color={'text.secondary'}
                                  sx={{}}
                                >
                                  {pb?.description}
                                </Typography>
                              ) : null}
                            </Box>
                          }
                        />
                      ))
                    : null}
                </RadioGroup>
              </FormControl>
              {/* {orgConfigs?.botVerticals &&
              orgConfigs?.botVerticals?.length > 0 ? (
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Pick a vertical
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={vertical}
                    onChange={(e) => {
                      handleTrackEvent(e, `selected_vertical_change`);
                      setVertical(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={'surprise'}
                      control={<Radio />}
                      label={'Surprise Me'}
                    />
                    {orgConfigs?.botVerticals &&
                    orgConfigs?.botVerticals?.length > 0
                      ? orgConfigs?.botVerticals?.map((vertical, index) => (
                          <FormControlLabel
                            key={index}
                            value={vertical}
                            control={<Radio />}
                            label={vertical}
                          />
                        ))
                      : null}
                  </RadioGroup>
                </FormControl>
              ) : null} */}
              {/* {orgConfigs?.botDesignations &&
              orgConfigs?.botDesignations?.length > 0 ? (
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Pick a designation
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={designation}
                    onChange={(e) => {
                      handleTrackEvent(e, `selected_designation_change`);
                      setDesignation(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={'surprise'}
                      control={<Radio />}
                      label={'Surprise Me'}
                    />
                    {orgConfigs?.botDesignations &&
                    orgConfigs?.botDesignations?.length > 0
                      ? orgConfigs?.botDesignations?.map(
                          (designation, index) => (
                            <FormControlLabel
                              key={index}
                              value={designation}
                              control={<Radio />}
                              label={designation}
                            />
                          ),
                        )
                      : null}
                  </RadioGroup>
                </FormControl>
              ) : null} */}
              {/* {fieldsRequired && fieldsRequired?.length > 0 ? (
                <Box>
                  <Typography
                    variant="body1"
                    style={{ color: theme.palette.error.main }}
                  >
                    This bot requires additional prospect details :{' '}
                    {fieldsRequired?.join(', ')}
                  </Typography>
                  <Button
                    onClick={() => history.push('/org/settings?t=playbook')}
                  >
                    Go to playbook page
                  </Button>
                </Box>
              ) : null} */}
              {/* <FormControl fullWidth>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Pick a playbook
                </FormLabel>
                <Box display={'flex'} alignItems={'center'} gap={2}>
                  <Select
                    size="small"
                    labelId="playbook-select-label"
                    id="playbook-select"
                    name="selectedPlaybook"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => setSelectedPlaybook(e.target.value)}
                    value={selectedPlaybook}
                    sx={{
                      mt: 1,
                    }}
                  >
                    <MenuItem value={'select'}>
                      <Typography>Select from available playbooks</Typography>
                    </MenuItem>
                    {playbooks && playbooks?.length > 0
                      ? playbooks?.map((pb) => (
                          <MenuItem key={pb?.id} value={pb?.id}>
                            <Typography>
                              {pb?.tags?.join(', ')}{' '}
                              {moment(pb?.createdOn).format('DD MMM YYYY')}
                              {pb?.selected ? '(Default)' : ''}
                            </Typography>
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  <Tooltip title="show playbook" placement="top" arrow>
                    <Article
                      sx={{
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                        marginRight: 2,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTrackEvent(e, 'open_playbook_dialog');
                        setShowPlaybook((prev) => !prev);
                      }}
                    />
                  </Tooltip>
                </Box>
                <Drawer
                  open={showPlaybook}
                  anchor="right"
                  onClose={() => setShowPlaybook(false)}
                >
                  <Box
                    p={1}
                    sx={{
                      borderBottom: '1px solid #d3d3d3',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton onClick={() => setShowPlaybook(false)}>
                      <ChevronRight sx={{}} />
                    </IconButton>
                    <Typography variant="body2" color={'text.secondary'}>
                      Playbook
                    </Typography>
                  </Box>
                  <Box
                    flex={1}
                    padding={2}
                    sx={{
                      width: '40vw',
                      // border: '1px solid #d3d3d3',
                      borderRadius: 2,
                      minHeight: isXs ? 'auto' : 'calc(100vh - 70px)',
                      maxHeight: isXs ? 'auto' : 'calc(100vh - 70px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                      gap: { xs: 1, sm: 2, md: 2 },
                      position: 'relative',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        flex: 1,
                      }}
                    >
                      {parse(
                        playbooks?.find((p) => p?.id === selectedPlaybook)
                          ?.playbook || '',
                      )}
                    </Typography>
                  </Box>
                </Drawer>
              </FormControl> */}
              <Button
                variant="contained"
                size="small"
                sx={{ alignSelf: 'end' }}
                disabled={!conversationTitle?.trim() || creatingConversation}
                onClick={(e) => {
                  // if (
                  //   userDetails?.custom?.userUsage?.currentUsage
                  //     ?.number_of_practice_call >=
                  //   Object.values(
                  //     Object.values(
                  //       userDetails?.custom?.plan?.features || {},
                  //     )?.[0]?.restrictions || {},
                  //   )?.[1]?.limit
                  // ) {
                  //   setShowLimitExhausted('conversation_exhausted');
                  // } else {
                  handleTrackEvent(e, `create_conversation`);
                  const selectedBot = [...publicBots, privateBots]?.find(
                    (bot) => bot?.id === selectedBotId,
                  );
                  // if (
                  //   selectedBot?.mandatoryFields &&
                  //   Array.isArray(selectedBot?.mandatoryFields) &&
                  //   selectedBot?.mandatoryFields?.length > 0 &&
                  //   ((selectedBot?.mandatoryFields?.find(
                  //     (field) => field === '<%>vertical<%>',
                  //   ) &&
                  //     !(
                  //       orgConfigs?.botVerticals &&
                  //       orgConfigs?.botVerticals?.length > 0
                  //     )) ||
                  //     (selectedBot?.mandatoryFields?.find(
                  //       (field) => field === '<%>designation<%>',
                  //     ) &&
                  //       !(
                  //         orgConfigs?.botDesignations &&
                  //         orgConfigs?.botDesignations?.length > 0
                  //       )) ||
                  //     (selectedBot?.mandatoryFields?.find(
                  //       (field) => field === '<%>competitors<%>',
                  //     ) &&
                  //       !(
                  //         orgConfigs?.botCompetitors &&
                  //         orgConfigs?.botCompetitors?.length > 0
                  //       )))
                  // ) {
                  //   const requiredFields = [];
                  //   if (
                  //     selectedBot?.mandatoryFields?.find(
                  //       (field) => field === '<%>vertical<%>',
                  //     ) &&
                  //     !(
                  //       orgConfigs?.botVerticals &&
                  //       orgConfigs?.botVerticals?.length > 0
                  //     )
                  //   ) {
                  //     requiredFields.push('vertical');
                  //   } else {
                  //     requiredFields.filter((field) => field === 'vertical');
                  //   }
                  //   if (
                  //     selectedBot?.mandatoryFields?.find(
                  //       (field) => field === '<%>designation<%>',
                  //     ) &&
                  //     !(
                  //       orgConfigs?.botVerticals &&
                  //       orgConfigs?.botVerticals?.length > 0
                  //     )
                  //   ) {
                  //     requiredFields.push('designation');
                  //   } else {
                  //     requiredFields.filter(
                  //       (field) => field === 'designation',
                  //     );
                  //   }
                  //   if (
                  //     selectedBot?.mandatoryFields?.find(
                  //       (field) => field === '<%>competitors<%>',
                  //     ) &&
                  //     !(
                  //       orgConfigs?.botVerticals &&
                  //       orgConfigs?.botVerticals?.length > 0
                  //     )
                  //   ) {
                  //     requiredFields.push('competitors');
                  //   } else {
                  //     requiredFields.filter(
                  //       (field) => field === 'competitors',
                  //     );
                  //   }
                  //   setFieldsRequired(requiredFields);
                  // } else {
                  setCreatingConversation(true);
                  dispatch(
                    createNewConversation(
                      {
                        description: '',
                        title: conversationTitle?.trim(),
                        botId: selectedBotId,
                        // playbookHistoryId: selectedPlaybook,
                        // configs:
                        //   (orgConfigs?.botVerticals &&
                        //     orgConfigs?.botVerticals?.length > 0) ||
                        //   (orgConfigs?.botDesignations &&
                        //     orgConfigs?.botDesignations?.length > 0) ||
                        //   (playbooks?.find(
                        //     (pb) => pb?.id === selectedPlaybook,
                        //   ) &&
                        //     playbooks?.find(
                        //       (pb) => pb?.id === selectedPlaybook,
                        //     )?.playbookStructure &&
                        //     playbooks?.find(
                        //       (pb) => pb?.id === selectedPlaybook,
                        //     )?.playbookStructure?.competingProducts)
                        //     ? {
                        //         VERTICAL: {
                        //           '<%>vertical<%>':
                        //             vertical === 'surprise'
                        //               ? orgConfigs?.botVerticals?.[
                        //                   Math.floor(
                        //                     Math.random() *
                        //                       [
                        //                         ...(orgConfigs?.botVerticals ||
                        //                           []),
                        //                       ].length,
                        //                   )
                        //                 ]
                        //               : vertical,
                        //         },
                        //         DESIGNATION: {
                        //           '<%>designation<%>':
                        //             designation === 'surprise'
                        //               ? orgConfigs?.botDesignations?.[
                        //                   Math.floor(
                        //                     Math.random() *
                        //                       [
                        //                         ...(orgConfigs?.botDesignations ||
                        //                           []),
                        //                       ].length,
                        //                   )
                        //                 ]
                        //               : designation,
                        //         },
                        //         COMPETITORS: {
                        //           '<%>competitors<%>':
                        //             (playbooks?.find(
                        //               (pb) => pb?.id === selectedPlaybook,
                        //             ) &&
                        //               playbooks?.find(
                        //                 (pb) => pb?.id === selectedPlaybook,
                        //               )?.playbookStructure &&
                        //               playbooks?.find(
                        //                 (pb) => pb?.id === selectedPlaybook,
                        //               )?.playbookStructure
                        //                 ?.competingProducts) ||
                        //             '',
                        //         },
                        //         Objections:
                        //           process.env.REACT_APP_TARGET_ENV ===
                        //             'development' ||
                        //           process.env.REACT_APP_TARGET_ENV ===
                        //             'local'
                        //             ? {
                        //                 '<%>questions_list<%>':
                        //                   playbooks?.find(
                        //                     (pb) =>
                        //                       pb?.id === selectedPlaybook,
                        //                   ) &&
                        //                   playbooks?.find(
                        //                     (pb) =>
                        //                       pb?.id === selectedPlaybook,
                        //                   )?.playbookStructure
                        //                     ? playbooks
                        //                         ?.find(
                        //                           (pb) =>
                        //                             pb?.id ===
                        //                             selectedPlaybook,
                        //                         )
                        //                         ?.playbookStructure?.customerQuestions?.map(
                        //                           (q, index) =>
                        //                             `${index + 1}.${
                        //                               q.question
                        //                             }`,
                        //                         )
                        //                         ?.join('\n') || null
                        //                     : null,
                        //               }
                        //             : null,
                        //         Customer_Challenges:
                        //           process.env.REACT_APP_TARGET_ENV ===
                        //             'development' ||
                        //           process.env.REACT_APP_TARGET_ENV ===
                        //             'local'
                        //             ? {
                        //                 '<%>customer_challenges<%>':
                        //                   playbooks?.find(
                        //                     (pb) =>
                        //                       pb?.id === selectedPlaybook,
                        //                   ) &&
                        //                   playbooks?.find(
                        //                     (pb) =>
                        //                       pb?.id === selectedPlaybook,
                        //                   )?.playbookStructure
                        //                     ? playbooks
                        //                         ?.find(
                        //                           (pb) =>
                        //                             pb?.id ===
                        //                             selectedPlaybook,
                        //                         )
                        //                         ?.playbookStructure?.challengesForCustomer?.map(
                        //                           (q, index) =>
                        //                             `${index + 1}.${
                        //                               q.question
                        //                             }`,
                        //                         )
                        //                         ?.join('\n') || null
                        //                     : null,
                        //               }
                        //             : null,
                        //       }
                        //     : {
                        //         Objections:
                        //           process.env.REACT_APP_TARGET_ENV ===
                        //             'development' ||
                        //           process.env.REACT_APP_TARGET_ENV ===
                        //             'local'
                        //             ? {
                        //                 '<%>questions_list<%>':
                        //                   playbooks?.find(
                        //                     (pb) =>
                        //                       pb?.id === selectedPlaybook,
                        //                   ) &&
                        //                   playbooks?.find(
                        //                     (pb) =>
                        //                       pb?.id === selectedPlaybook,
                        //                   )?.playbookStructure
                        //                     ? playbooks
                        //                         ?.find(
                        //                           (pb) =>
                        //                             pb?.id ===
                        //                             selectedPlaybook,
                        //                         )
                        //                         ?.playbookStructure?.customerQuestions?.map(
                        //                           (q, index) =>
                        //                             `${index + 1}.${
                        //                               q.question
                        //                             }`,
                        //                         )
                        //                         ?.join('\n') || null
                        //                     : null,
                        //               }
                        //             : null,
                        //         Customer_Challenges:
                        //           process.env.REACT_APP_TARGET_ENV ===
                        //             'development' ||
                        //           process.env.REACT_APP_TARGET_ENV ===
                        //             'local'
                        //             ? {
                        //                 '<%>customer_challenges<%>':
                        //                   playbooks?.find(
                        //                     (pb) =>
                        //                       pb?.id === selectedPlaybook,
                        //                   ) &&
                        //                   playbooks?.find(
                        //                     (pb) =>
                        //                       pb?.id === selectedPlaybook,
                        //                   )?.playbookStructure
                        //                     ? playbooks
                        //                         ?.find(
                        //                           (pb) =>
                        //                             pb?.id ===
                        //                             selectedPlaybook,
                        //                         )
                        //                         ?.playbookStructure?.challengesForCustomer?.map(
                        //                           (q, index) =>
                        //                             `${index + 1}.${
                        //                               q.question
                        //                             }`,
                        //                         )
                        //                         ?.join('\n') || null
                        //                     : null,
                        //               }
                        //             : null,
                        //       },
                      },
                      (data) => {
                        dispatch(
                          getConversationById(data, null, (data) => {
                            setCurrentConversation(data);
                          }),
                        );
                        setCreatingConversation(false);
                        setConversationTitle('');
                        setGender('MALE');
                        setViewType('conversation');
                        setResponseAudioUrl('');
                        setPlayingResponse(false);
                        setConversationStarted(false);
                        dispatch(getUserDetails(() => {}));
                      },
                    ),
                  );
                  // }
                  // }
                }}
              >
                Start Conversation
              </Button>
            </Card>
          ) : viewType === 'list' && currentTab === 'list' ? (
            <Box display={'flex'} flexDirection={'column'} gap={1} width={1}>
              {fetching ? (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                >
                  <CircularProgress size={20} />
                  <Typography variant="body1" color={'text.primary'} mt={4}>
                    Loading all conversations ...
                  </Typography>
                </Box>
              ) : conversations && conversations?.length > 0 ? (
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  {isXs ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ marginLeft: 'auto' }}
                      onClick={() => history.push(`digital-twin?new=true`)}
                    >
                      New Conversation
                    </Button>
                  ) : null}
                  <TableContainer
                    component={Paper}
                    sx={{
                      minHeight: uId
                        ? 'calc(100vh - 110px)'
                        : isXs
                        ? 'calc(100vh - 140px)'
                        : 'calc(100vh - 110px)',
                      maxHeight: uId
                        ? 'calc(100vh - 110px)'
                        : isXs
                        ? 'calc(100vh - 140px)'
                        : 'calc(100vh - 110px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                    }}
                    onScroll={handleListScroll}
                  >
                    <Table
                      sx={{ minWidth: 750 }}
                      stickyHeader
                      aria-label="simple table"
                    >
                      <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Title
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Date
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              align="center"
                            >
                              Type
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              align="center"
                            >
                              Conversation
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Designation
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Vertical
                            </Typography>
                          </TableCell> */}
                          {/* <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              align="center"
                            >
                              Playbook
                            </Typography>
                          </TableCell> */}
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              align="center"
                            >
                              Actions
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {conversations.map((cs, i) => (
                          <TableRow
                            key={i}
                            // sx={{
                            //   '&:last-child td, &:last-child th': {
                            //     border: 0,
                            //   },
                            // }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                maxWidth: 400,
                              }}
                            >
                              <Link
                                href={null}
                                onClick={(e) => {
                                  handleTrackEvent(
                                    e,
                                    `start_conversation_from_list`,
                                  );
                                  setConversationStarted(false);
                                  setResponseAudioUrl('');
                                  setPlayingResponse(false);
                                  setCurrentConversation(cs);
                                  setViewType('conversation');
                                  history.push(
                                    `/digital-twin?type=${cs?.conversationMod ||
                                      'talk'}`,
                                  );
                                }}
                                sx={{
                                  textDecoration: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                {`${cs?.title?.[0].toUpperCase()}${cs?.title?.slice(
                                  1,
                                )}`}{' '}
                                ({`${Math.floor(cs?.messages?.length / 2)}`})
                              </Link>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                variant={'caption'}
                                // fontWeight={700}
                                // sx={{
                                //   color: theme.palette.success.main,
                                // }}
                              >
                                {moment(cs?.createdOn).format('DD MMMM YYYY')}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                variant={'caption'}
                                // fontWeight={700}
                                // sx={{
                                //   color: theme.palette.success.main,
                                // }}
                              >
                                {capitalizeText(cs?.conversationMod) || 'Talk'}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {cs?.customFields?.audioLink &&
                              cs?.mergedAudioStatus === 'completed' ? (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  gap={1}
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setShowPreviewSalesCall(cs)}
                                >
                                  <Typography variant={'subtitle2'}>
                                    {cs?.duration
                                      ? formatSeconds(
                                          Math.round(cs?.duration / 1000000),
                                        )
                                      : '-'}
                                  </Typography>
                                  <IconButton
                                    sx={{
                                      background: '#d3d3d3',
                                      padding: '2px',
                                    }}
                                    onClick={() => setShowPreviewSalesCall(cs)}
                                  >
                                    <PlayArrow
                                      sx={{
                                        fontSize: 16,
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              ) : cs?.status === 'completed' ? (
                                <Typography>-</Typography>
                              ) : !uId ? (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={`Continue ${
                                    cs?.conversationMod === 'talk'
                                      ? 'Talk'
                                      : 'Chat'
                                  }`}
                                >
                                  <IconButton
                                    onClick={(e) => {
                                      handleTrackEvent(
                                        e,
                                        `start_conversation_from_list`,
                                      );
                                      setConversationStarted(false);
                                      setResponseAudioUrl('');
                                      setPlayingResponse(false);
                                      setCurrentConversation(cs);
                                      setViewType('conversation');
                                      history.push(
                                        `/digital-twin?type=${cs?.conversationMod ||
                                          'talk'}`,
                                      );
                                    }}
                                  >
                                    {cs?.conversationMod === 'talk' ? (
                                      <RecordVoiceOverOutlined />
                                    ) : (
                                      <Chat />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Typography>-</Typography>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                gap={1}
                              >
                                {/* {!uId ? (
                                  <Tooltip
                                    title={'Analyze Roleplay'}
                                    placement="top"
                                    arrow
                                  >
                                    {analysisInProgress.includes(cs?.id) ? (
                                      <CircularProgress size={20} />
                                    ) : (
                                      <IconButton
                                        onClick={(e) => {
                                          handleTrackEvent(
                                            e,
                                            `open_analyze_conversation_dialog`,
                                          );
                                          setShowReAnalyzeDrawer(cs?.id);
                                        }}
                                      >
                                        <Troubleshoot color="primary" />
                                      </IconButton>
                                    )}
                                  </Tooltip>
                                ) : null}
                                <Tooltip
                                  title={
                                    cs?.analysis
                                      ? 'View Report'
                                      : 'Report is not available'
                                  }
                                  placement="top"
                                  arrow
                                >
                                  <IconButton
                                    onClick={(e) => {
                                      if (cs?.analysis) {
                                        handleTrackEvent(
                                          e,
                                          `open_analysis_report`,
                                        );
                                        console.log('view report');
                                        setViewType('report');
                                        setSalesReview(cs);
                                        if (uId) {
                                          dispatch({
                                            type: 'UPDATE_UI_STATE',
                                            payload: {
                                              key: 'currentNavigationLevel',
                                              value: currentNavigationLevel + 1,
                                            },
                                          });
                                        }
                                      } else {
                                        return;
                                      }
                                    }}
                                  >
                                    <Article
                                      color={
                                        cs?.analysis ? 'primary' : 'default'
                                      }
                                    />
                                  </IconButton>
                                </Tooltip> */}
                                {!uId ? (
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={'Delete Conversation'}
                                  >
                                    <IconButton
                                      onClick={(e) => {
                                        handleTrackEvent(
                                          e,
                                          `open_confirm_delete_conversation_dialog`,
                                        );

                                        setShowConfirmDeleteDialog(cs?.id);
                                      }}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell
                            variant="footer"
                            colSpan={8}
                            sx={{
                              padding: 0,
                            }}
                          >
                            {fetchingPagination ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  // type="submit"
                                  color="primary"
                                  onClick={handleListScroll}
                                  // className="searchSettings"
                                  disabled
                                  // fullWidth
                                >
                                  <Typography color="textPrimary">
                                    Loading more conversations ...{' '}
                                  </Typography>
                                  <CircularProgress size={20} />
                                </Button>
                              </Box>
                            ) : emptyList === false &&
                              conversations.length >= 10 ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  // type="submit"
                                  // fullWidth
                                  color="primary"
                                  onClick={(e) => handleListScroll(e, true)}
                                  // className="searchSettings"
                                >
                                  Load More
                                </Button>
                              </Box>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                  {/* <Card
                    key={ar?.id}
                    sx={{ width: 1, height: 'auto', padding: 2 }}
                  ></Card>
                  {conversations.map((ar) => (
                    <Card
                      key={ar?.id}
                      sx={{ width: 1, height: 'auto', padding: 2 }}
                    >
                      <Typography>{ar.title}</Typography>
                    </Card>
                  ))} */}
                </Box>
              ) : (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  gap={2}
                >
                  <Typography variant="body1" color={'text.secondary'} mt={4}>
                    No conversations to show here yet.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    // sx={{ marginLeft: 'auto' }}
                    onClick={() => history.push(`digital-twin?type=chat`)}
                  >
                    New Conversation
                  </Button>
                </Box>
              )}
            </Box>
          ) : viewType === 'conversation' ? (
            <Box
              width={1}
              display={'flex'}
              flexDirection={'column'}
              gap={1}
              position={'relative'}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={2}
                justifyContent={'space-between'}
                p={2}
                sx={{
                  borderTop: '1px solid #d3d3d3',
                  minHeight:
                    isXs || isSm
                      ? 'calc(100vh - 130px)'
                      : 'calc(100vh - 140px)',
                  maxHeight:
                    isXs || isSm
                      ? 'calc(100vh - 130px)'
                      : 'calc(100vh - 140px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                  paddingBottom:
                    isXs || isSm
                      ? '120px'
                      : conversationType === 'talk'
                      ? '120px'
                      : '70px',
                }}
                ref={chatsScrollview}
              >
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                  {currentConversation?.messages?.map((msg, index) => (
                    <Box
                      key={msg?.id}
                      sx={{
                        borderTopLeftRadius:
                          msg?.gptMessage?.role === 'user' ? 16 : 0,
                        borderTopRightRadius:
                          msg?.gptMessage?.role === 'user' ? 0 : 16,
                        borderBottomLeftRadius:
                          msg?.gptMessage?.role === 'user' ? 8 : 16,
                        borderBottomRightRadius:
                          msg?.gptMessage?.role === 'user' ? 16 : 8,
                        maxWidth: '80%',
                        alignSelf:
                          msg?.gptMessage?.role === 'user' ? 'end' : 'start',
                        display: 'flex',
                        flexDirection: 'column',
                        // gap: 1,
                        boxShadow: theme.shadows[8],
                        background:
                          msg?.gptMessage?.role === 'user'
                            ? theme.palette.primary.main
                            : '#fff',
                      }}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        sx={{
                          padding: '8px 16px',
                          gap: 4,
                        }}
                      >
                        {msg?.gptMessage?.role === 'user' ? (
                          <Typography
                            variant="caption"
                            sx={{
                              color:
                                msg?.gptMessage?.role === 'user'
                                  ? '#f1f1f1'
                                  : theme.palette.text.secondary,
                            }}
                          >
                            You
                          </Typography>
                        ) : (
                          <Typography
                            variant="caption"
                            sx={{
                              color:
                                msg?.gptMessage?.role === 'user'
                                  ? '#f1f1f1'
                                  : theme.palette.text.secondary,
                            }}
                          >
                            {currentConversation?.customFields?.botTitle}
                          </Typography>
                        )}
                        {/* {index === 0 ? null : ( */}
                        <Typography
                          variant="caption"
                          sx={{
                            color:
                              msg?.gptMessage?.role === 'user'
                                ? '#f1f1f1'
                                : theme.palette.text.secondary,
                          }}
                        >
                          {moment(msg?.createdOn).format(
                            'DD MMMM YYYY hh:mm:ss A',
                          )}
                        </Typography>
                        {/* )} */}
                      </Box>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-wrap',
                          color:
                            msg?.gptMessage?.role === 'user'
                              ? '#fff'
                              : theme.palette.text.primary,
                          padding: '0px 16px 16px 16px',
                        }}
                      >
                        {msg?.gptMessage?.content}
                      </Typography>
                      {msg?.gptMessage?.role === 'assistant' &&
                      (msg?.customFields?.gcpAudioLink ||
                        msg?.customFields?.elevenLabsAudioLink ||
                        responseAudioUrl) ? (
                        <>
                          <Divider />
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={1}
                            flexDirection={{
                              xs: 'column',
                              sm: 'column',
                              md: 'row',
                            }}
                          >
                            {index ===
                              currentConversation?.messages?.length - 1 &&
                            responseAudioUrl &&
                            conversationType === 'talk' ? (
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={1}
                              >
                                <audio
                                  controls
                                  autoPlay={autoPlay && playingResponse}
                                  preload={'true'}
                                  ref={responseAudioRef}
                                  src={responseAudioUrl}
                                  style={{
                                    maxWidth: '220px',
                                    padding: '10px 20px',
                                  }}
                                  onLoadedMetadata={() =>
                                    responseAudioRef.current.play()
                                  }
                                  onPause={() => {
                                    setPlayingResponse(false);
                                  }}
                                  onPlay={() => {
                                    if (recording) {
                                      setMuted(true);
                                    }
                                  }}
                                  onEnded={() => {
                                    // console.log('audio played');
                                    if (
                                      autoStart &&
                                      autoRecord &&
                                      conversationStarted &&
                                      !muted
                                    ) {
                                      handleRecordClick();
                                    }
                                    setPlayingResponse(false);
                                    setResponseAudioUrl('');
                                  }}
                                  onError={(e) => {
                                    console.log(e);
                                    setPlayingResponse(false);
                                    dispatch(
                                      logError({
                                        message: e?.message,
                                        data: {
                                          formatted: {
                                            errorText: e?.message,
                                            severity: 'error',
                                          },
                                          raw: e,
                                        },
                                      }),
                                    );
                                  }}
                                />
                              </Box>
                            ) : (
                              <>
                                {msg?.customFields?.elevenLabsAudioLink ? (
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                  >
                                    {msg?.customFields?.elevenLabsAudioLink ? (
                                      <audio
                                        // autoPlay={
                                        //   index ===
                                        //     currentConversation?.messages
                                        //       ?.length -
                                        //       1 &&
                                        //   currentConversation?.audioService?.includes(
                                        //     'ELEVEN_LABS',
                                        //   ) &&
                                        //   autoPlay &&
                                        //   conversationType === 'talk'
                                        // }
                                        preload
                                        controls
                                        src={
                                          msg?.customFields?.elevenLabsAudioLink
                                        }
                                        style={{
                                          maxWidth: '220px',
                                          padding: '10px 20px',
                                        }}
                                        controlsList="nodownload"
                                        onPlay={() => {
                                          if (recording) {
                                            setMuted(true);
                                          }
                                        }}
                                      />
                                    ) : null}
                                  </Box>
                                ) : null}
                                {msg?.customFields?.gcpAudioLink ? (
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                  >
                                    {msg?.customFields?.gcpAudioLink ? (
                                      <audio
                                        // autoPlay={
                                        //   index ===
                                        //     currentConversation?.messages
                                        //       ?.length -
                                        //       1 &&
                                        //   currentConversation?.audioService?.includes(
                                        //     'GCP',
                                        //   ) &&
                                        //   autoPlay &&
                                        //   conversationType === 'talk'
                                        // }
                                        preload
                                        controls
                                        src={msg?.customFields?.gcpAudioLink}
                                        style={{
                                          maxWidth: '220px',
                                          padding: '10px 20px',
                                        }}
                                        controlsList="nodownload"
                                        onPlay={() => {
                                          if (recording) {
                                            setMuted(true);
                                          }
                                        }}
                                      />
                                    ) : null}
                                  </Box>
                                ) : null}
                              </>
                            )}
                          </Box>
                        </>
                      ) : null}
                    </Box>
                  ))}
                </Box>
                {gettingResponse || creatingConversation ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      gap: 1,
                      paddingLeft: 1,
                      marginBottom: 1,
                      alignSelf: 'start',
                    }}
                  >
                    <RecordVoiceOver
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Box
                      sx={{
                        boxShadow: theme.shadows[8],
                        borderRadius: '16px',
                        overflow: 'hidden',
                        objectFit: 'cover',
                        width: 60,
                        height: 30,
                        position: 'relative',
                      }}
                    >
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: speakingAnimation,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={50}
                        width={50}
                        style={{
                          margin: 0,
                          position: 'absolute',
                          top: '-30%',
                          left: 5,
                        }}
                      />
                    </Box>
                  </Box>
                ) : null}
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  padding: '0 16px',
                  background: '#fff',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  // alignItems: 'center',
                }}
              >
                {conversationType === 'talk' ? (
                  <Button
                    variant={recording && !muted ? 'outlined' : 'contained'}
                    // onClick={() => {}}
                    disabled={gettingResponse || creatingConversation}
                    color={recording && !muted ? 'warning' : 'primary'}
                    size="small"
                    startIcon={
                      recording && !muted ? (
                        <PauseCircle />
                      ) : (
                        <RecordVoiceOver />
                      )
                    }
                    sx={{
                      alignSelf: 'center',
                    }}
                    // disabled={playingResponse}
                    onClick={() => {
                      if (playingResponse) {
                        if (responseAudioRef && responseAudioRef.current) {
                          console.log('coming here');
                          responseAudioRef.current.pause();
                        }
                        setPlayingResponse(false);
                      }
                      if (recording) {
                        setMuted((prev) => !prev);
                      } else {
                        handleRecordClick();
                      }
                    }}
                  >
                    {recording && !muted
                      ? 'Pause'
                      : currentConversation?.status === 'in_process'
                      ? 'Continue'
                      : 'Start'}{' '}
                    Talking
                  </Button>
                ) : null}
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    autoFocus
                    placeholder="Ask any question (max 1000 characters)"
                    variant="outlined"
                    color="primary"
                    size="large"
                    name="message"
                    value={message}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        formik.handleSubmit();
                      }
                    }}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      console.log('coming here');
                      if (conversationType === 'talk') {
                        resetTranscript();
                      }
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={2}
                          >
                            {recording && conversationType === 'talk' && (
                              <Box sx={{}}>
                                <Typography>
                                  {`0${timerMs} `}:
                                  {timerSs > 9
                                    ? timerSs < 0
                                      ? '00'
                                      : ` ${timerSs}`
                                    : ` 0${timerSs}`}
                                </Typography>
                              </Box>
                            )}
                            <IconButton
                              size="small"
                              disabled={
                                !message.trim() ||
                                gettingResponse ||
                                message?.trim()?.length > 1000
                              }
                              type="submit"
                              // onClick={() => handleGetResponse(false)}
                            >
                              {/* {gettingResponse ? (
                                  <CircularProgress size={20} />
                                ) : ( */}
                              <Send
                                fontSize="small"
                                color={
                                  !message?.trim() ||
                                  gettingResponse ||
                                  message?.trim()?.length > 1000
                                    ? 'default'
                                    : 'primary'
                                }
                              />
                              {/* )} */}
                            </IconButton>
                            {/* {conversationType === 'talk' ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: 2,
                                }}
                              >
                                <IconButton
                                  size="small"
                                  // disabled={!message.trim()}
                                  onClick={() => {
                                    setMuted((prev) => !prev);
                                  }}
                                >
                                  {muted ? (
                                    <Tooltip
                                      title="Unmute"
                                      arrow
                                      placement="top"
                                    >
                                      <MicOff
                                        fontSize="medium"
                                        color="primary"
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Mute" arrow placement="top">
                                      <Mic
                                        fontSize="medium"
                                        color={'primary'}
                                      />
                                    </Tooltip>
                                  )}
                                </IconButton>
                              </Box>
                            ) : null} */}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    error={message?.trim()?.length > 1000}
                    helperText={
                      message?.trim()?.length > 1000
                        ? 'Please enter less than 1000 characters'
                        : null
                    }
                  />
                </form>
              </Box>
            </Box>
          ) : viewType === 'report' ? (
            <Box width={1}>
              <ReportPage
                type="practiceCall"
                report={salesReview}
                setSalesReview={setSalesReview}
              />
            </Box>
          ) : viewType === 'training' ? (
            <Box display={'flex'} gap={2} width={1}>
              <Box
                flex={1}
                borderLeft={'1px solid #d3d3d3'}
                sx={{
                  minHeight: 'calc(100vh - 60px)',
                  maxHeight: 'calc(100vh - 60px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                }}
                padding={2}
              >
                <Typography
                  variant="body1"
                  sx={{ border: '1px solid #d3d3d3', padding: 2 }}
                >
                  Previous Trainings
                </Typography>
                {fetchingPreviousTrainingData ? (
                  <Box
                    width={1}
                    minHeight={'calc(100vh - 200px)'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                  >
                    <CircularProgress size={20} />
                    <Typography variant="body1" color={'text.primary'} mt={4}>
                      Loading previous training data ....
                    </Typography>
                  </Box>
                ) : previousTrainingData?.length > 0 ? (
                  <>
                    {previousTrainingData?.map((data) => (
                      <Accordion
                        key={data?.id}
                        expanded={expanded === data?.id}
                        onChange={() => {
                          if (expanded === data?.id) setExpanded(null);
                          else setExpanded(data?.id);
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography>{data?.topic}</Typography>
                            <Box display={'flex'} gap={1}>
                              <Tooltip
                                title="Update training data"
                                arrow
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => {
                                    setShowAddTrainingData(data);
                                  }}
                                >
                                  <Edit sx={{ fontSize: 16 }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Delete training data"
                                arrow
                                placement="top"
                              >
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowConfirmDeleteTrainingData(data?.id);
                                  }}
                                >
                                  <Delete sx={{ fontSize: 16 }} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <RichTextEditor
                              value={data?.content}
                              onChange={() => {}}
                              readOnly
                            />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </>
                ) : (
                  <Box
                    width={1}
                    minHeight={'calc(100vh - 200px)'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                    gap={2}
                  >
                    <Typography variant="body1" color={'text.secondary'} mt={4}>
                      previous training data not present
                    </Typography>
                  </Box>
                )}
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  sx={{
                    position: 'absolute',
                    bottom: 10,
                    right: 20,
                  }}
                >
                  <Tooltip arrow placement="top" title="Add new training data">
                    <Fab
                      size="small"
                      color="primary"
                      aria-label="add"
                      // sx={{ position: 'absolute', bottom: 10, right: 10 }}
                      onClick={() => setShowAddTrainingData('new')}
                      // sx={{ padding: 1 }}
                    >
                      <Add sx={{ fontSize: 20 }} />
                    </Fab>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Drawer
        open={Boolean(showReAnalyzeDrawer)}
        anchor="right"
        onClose={() => setShowReAnalyzeDrawer(null)}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => setShowReAnalyzeDrawer(null)}>
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography color={'text.secondary'}>
            {conversations?.find((cs) => cs?.id === showReAnalyzeDrawer)?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '40vw',
            minHeight: 'calc(100vh - 58px)',
            maxHeight: 'calc(100vh - 58px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            // background: '#343a40',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box p={2} display={'flex'} flexDirection={'column'} gap={1}>
            {conversations?.find((cs) => cs?.id === showReAnalyzeDrawer)
              ?.transcript ? (
              <Box
                sx={{
                  borderRadius: 3,
                  border: '1px solid #e3e3e3',
                }}
              >
                <Box
                  sx={{
                    background: '#e3e3e3',
                    padding: 2,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                  }}
                >
                  <Typography color={'text.secondary'} fontWeight={'bold'}>
                    Transcript
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    minHeight: '260px',
                    maxHeight: '260px',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                    },
                    whiteSpace: 'pre-wrap',
                    padding: 2,
                  }}
                >
                  {conversations?.find((cs) => cs?.id === showReAnalyzeDrawer)
                    ?.transcript || 'Transcript is not available yet'}
                </Typography>
              </Box>
            ) : null}
            {userInfo?.orgRoles?.includes('ORG_ADMIN') ? (
              <FormControl
                component="fieldset"
                variant="standard"
                // sx={{ marginTop: 2 }}
              >
                <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                  Methodologies
                </FormLabel>
                <FormGroup row>
                  {orgConfigs?.selectedMethodologies?.map((mthd) => (
                    <FormControlLabel
                      key={mthd}
                      checked={methods?.includes(mthd)}
                      control={
                        <Checkbox size="small" value={mthd} name="methods" />
                      }
                      label={
                        mthd === 'meddpic' ? 'MEDDICC' : mthd.toUpperCase()
                      }
                      onChange={(e) => handleChange(e)}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            ) : (
              <>
                <Typography
                  variant={'body1'}
                  sx={{ marginTop: 1 }}
                  color={'text.secondary'}
                  // fontWeight={'bold'}
                >
                  Methodologies
                </Typography>
                <Typography
                  variant={'body1'}
                  // sx={{ marginBottom: 1 }}
                  // color={'text.secondary'}
                  // fontWeight={'bold'}
                >
                  {methods
                    ?.map((mthd) =>
                      mthd === 'meddpic' ? 'MEDDICC' : capitalizeText(mthd),
                    )
                    ?.join(', ')}
                </Typography>
              </>
            )}
            <Box>
              <Typography variant="subtitle2" color={'text.secondary'}>
                Select from available playbooks
              </Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  labelId="playbook-select-label"
                  id="playbook-select"
                  name="selectedPlaybook"
                  MenuProps={{ disableScrollLock: true }}
                  onChange={(e) => setSelectedPlaybook(e.target.value)}
                  value={selectedPlaybook}
                >
                  <MenuItem value={'select'}>
                    <Typography>Select from available playbooks</Typography>
                  </MenuItem>
                  {playbooks && playbooks?.length > 0
                    ? playbooks?.map((pb) => (
                        <MenuItem key={pb?.id} value={pb?.id}>
                          <Typography>
                            {pb?.tags?.join(', ')}{' '}
                            {moment(pb?.createdOn).format('DD MMM YYYY')}
                          </Typography>
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                borderRadius: 3,
                border: '1px solid #e3e3e3',
              }}
            >
              <Box
                sx={{
                  background: '#e3e3e3',
                  padding: 2,
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                }}
              >
                <Typography color={'text.secondary'} fontWeight={'bold'}>
                  Playbook
                </Typography>
              </Box>
              <Box
                sx={{
                  // minHeight: '260px',
                  maxHeight: '260px',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                  },
                  whiteSpace: 'pre-wrap',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: 'pre-wrap',
                    flex: 1,
                    // borderRight: '1px solid #d3d3d3',
                    p: 2,
                  }}
                >
                  {parse(playbook)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              p={2}
              width={1}
              borderTop={'1px solid #f1f1f1'}
            >
              <Button
                variant="contained"
                size="small"
                disabled={
                  analysisInProgress.includes(showReAnalyzeDrawer)
                    ? true
                    : false
                }
                startIcon={
                  analysisInProgress.includes(showReAnalyzeDrawer) ? (
                    <CircularProgress size={20} />
                  ) : null
                }
                onClick={(e) => {
                  handleTrackEvent(e, `analyze_conversation`);
                  // handleAnalyze(showReAnalyzeDrawer, 'list');
                }}
              >
                Analyze
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={showPermissionDeniedError}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Camera and microphone are blocked
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Box flex={1}>
              <img src="/camerahelp.png" width={'100%'} height={'100%'} />
            </Box>
            <DialogContentText id="alert-dialog-description" flex={1}>
              To record your video we need permission to use your camera and
              microphone. Click the <Lock /> Lock Icon (usually in your
              browser&apos;s address bar) to allow permissions and start
              recording.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowPermissionDeniedError(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showLimitExhausted)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Sorry, your plan&apos;s limit has been exhausted.
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              {showLimitExhausted === 'quota exceeded'
                ? 'Your current plan has reached its usage limit for this feature. To continue, consider upgrading your plan or wait until the limit resets at the beginning of the next cycle. If you have any questions or need assistance, please contact our support team.'
                : 'Your current plan has reached its usage limit for this feature. To continue, consider upgrading your plan or wait until the limit resets at the beginning of the next cycle. If you have any questions or need assistance, please contact our support team.'}
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                // handleTrackEvent(e, `cta_permission_denied_dialog_close`);

                history.push('/pricing');
                // if (testMode) setShowTestDeviceDialog(false);
              }}
              color="primary"
              // autoFocus
            >
              Go to Pricing Page
            </Button>
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                if (showLimitExhausted === 'quota exceeded') {
                  setShowLimitExhausted(false);
                  dispatch(
                    updateConversationStatus(
                      currentConversation?.id,
                      'completed',
                      () => {
                        setCurrentConversation((prev) => {
                          return {
                            ...prev,
                            status: 'completed',
                          };
                        });
                      },
                    ),
                  );
                } else {
                  setShowLimitExhausted(false);
                }
                // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
                // if (testMode) setShowTestDeviceDialog(false);
              }}
              color="primary"
              // autoFocus
            >
              {showLimitExhausted === 'quota exceeded'
                ? 'End Conversation'
                : 'Dismiss'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this conversation?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `confirm_delete_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `delete_conversation`);
              dispatch(
                deleteConversation(showConfirmDeleteDialog, () => {
                  dispatch({
                    type: 'UPDATE_ALL_CONVERSATIONS_AFTER_DELETE',
                    payload: {
                      id: showConfirmDeleteDialog,
                    },
                  });
                  setShowConfirmDeleteDialog(null);
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteTrainingData)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this training data?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `confirm_delete_training_data_dialog_close`);
              setShowConfirmDeleteTrainingData(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={deletingTrainingData}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `delete_training_data`);
              setDeletingTrainingData(true);
              dispatch(
                deleteTrainingData(showConfirmDeleteTrainingData, () => {
                  setFetchingPreviousTrainingData(true);
                  dispatch(
                    getTrainingData(currentBot?.id, (data) => {
                      console.log(data);
                      setPreviousTrainingData(data || []);
                      setFetchingPreviousTrainingData(false);
                    }),
                  );
                  setDeletingTrainingData(false);
                  setShowConfirmDeleteTrainingData(null);
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={endConversationAlert}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              This will end up the conversation. Do you want to continue?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `end_conversation_dialog_close`);
              setEndConversationAlert(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `end_conversation`);
              dispatch(
                updateConversationStatus(
                  currentConversation?.id,
                  'completed',
                  () => {
                    setConversationStarted(false);
                    setEndConversationAlert(false);
                    setCurrentConversation((prev) => {
                      return {
                        ...prev,
                        status: 'completed',
                      };
                    });
                    setViewType('list');
                    if (recording) {
                      handleRecordStopClick(true);
                    }
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={Boolean(showAddTrainingData)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowAddTrainingData(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // maxHeight: '30vh',
            maxWidth: '50vw',
            minWidth: '300px',
            outline: 'none',
            borderRadius: 1,
            // padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
              padding: '4px 16px',
            }}
          >
            <Typography variant="body2">
              {showAddTrainingData === 'new' ? 'Add' : 'Update'} Training Data
            </Typography>
            <IconButton
              data-trackid={'close_add_training_data'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_add_training_data');
                setShowAddTrainingData(null);
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 20 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box
            flex={1}
            sx={{
              minHeight: 'calc(100vh - 60px)',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              gap: 2,
              // justifyContent: 'space-between',
              padding: 2,
            }}
          >
            <TextField
              placeholder="Topic*"
              variant="outlined"
              name={'trainingTopic'}
              // fullWidth
              // multiline
              // rows={3}
              size="small"
              sx={{
                width: '100%',
              }}
              value={trainingTopic}
              onChange={(e) => setTrainingTopic(e.target.value)}
              type="text"
            />
            <Box
              sx={{
                height: 'calc(100vh - 230px)',
              }}
            >
              <RichTextEditor
                value={trainingText}
                onChange={setTrainingText}
                height="calc(100vh - 300px)"
                // readOnly
              />
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              {/* {showAddTrainingData === 'new' ? ( */}
              <FormControlLabel
                disabled={showAddTrainingData?.asHelpSection}
                // checked={formikProspectMember.values.keyStackHolder}
                control={
                  <Checkbox
                    size="small"
                    checked={saveAsHelpTopic}
                    // value={formikProspectMember.values.keyStackHolder}
                    onChange={(e) => setSaveAsHelpTopic(e.target.checked)}
                    name="saveAsHelpTopic"
                  />
                }
                // value={formikProspectMember.values.keyStackHolder}
                label="Also save as compass help"
                // onChange={formikProspectMember.handleChange}
              />
              {/* ) : null} */}
              <Button
                disabled={!trainingText || !trainingTopic || training}
                variant="contained"
                color="primary"
                startIcon={training ? <CircularProgress size={20} /> : null}
                onClick={() => {
                  if (showAddTrainingData === 'new') {
                    if (saveAsHelpTopic) {
                      setShowAddHelpVideo(true);
                    } else {
                      setTraining(true);
                      dispatch(
                        addExpertBotTraningData(
                          currentBot?.id,
                          {
                            asHelpSection: false,
                            content: trainingText,
                            format: 'html',
                            topic: trainingTopic,
                          },
                          () => {
                            // if(saveAsHelpTopic){
                            //   dispatch(
                            //     createHelpSection(
                            //       {
                            //         contentType: helpVideo?.type?.split(';')?.[0],
                            //         duration: Math.round(helpVideoDuration * 1000),
                            //         sizeInBytes: helpVideo?.size,
                            //         text: helpText,
                            //         title: helpSectionTitle,
                            //       },
                            //       (data) => {
                            //         if (helpVideo && typeof helpVideo !== 'string') {
                            //           dispatch(
                            //             uploadHelpVideo(data?.uploadUrlRes?.url, helpVideo, () => {
                            //               dispatch(
                            //                 verifyHelpVideoUpload(
                            //                   data?.uploadUrlRes?.url && data?.uploadUrlRes?.videoId,
                            //                   null,
                            //                   () => {
                            //                     dispatch(
                            //                       updateQuestion(
                            //                         showAddHelpReference?.data?.id,
                            //                         {
                            //                           helpSectionId: data?.helpSectionId,
                            //                         },
                            //                         () => {
                            //                           setHelpText('');
                            //                           setHelpSectionTitle('');
                            //                           setHelpVideo(null);
                            //                           setShowAddHelpReference(null);
                            //                           setSavingOrUpdatingHelpReference(false);
                            //                           setFetchingQuestionFramework(true);
                            //                           dispatch(
                            //                             getQuestionFramework(null, (data) => {
                            //                               console.log(data);
                            //                               setFetchingQuestionFramework(false);
                            //                               setQuestionFramework(data?.questions);
                            //                             }),
                            //                           );
                            //                         },
                            //                         () => {
                            //                           setSavingOrUpdatingHelpReference(false);
                            //                           dispatch(
                            //                             showAlert({
                            //                               message: 'Failed to update help reference.',
                            //                               showCross: true,
                            //                               title: null,
                            //                               type: 'error',
                            //                               autoHideDuration: 2000,
                            //                               vertical: 'top',
                            //                               horizontal: 'center',
                            //                             }),
                            //                           );
                            //                         },
                            //                       ),
                            //                     );
                            //                   },
                            //                 ),
                            //               );
                            //             }),
                            //           );
                            //         } else {
                            //           dispatch(
                            //             updateQuestion(
                            //               showAddHelpReference?.data?.id,
                            //               {
                            //                 helpSectionId: data?.helpSectionId,
                            //               },
                            //               () => {
                            //                 setHelpText('');
                            //                 setHelpSectionTitle('');
                            //                 setHelpVideo(null);
                            //                 setShowAddHelpReference(null);
                            //                 setSavingOrUpdatingHelpReference(false);
                            //                 setFetchingQuestionFramework(true);
                            //                 dispatch(
                            //                   getQuestionFramework(null, (data) => {
                            //                     console.log(data);
                            //                     setFetchingQuestionFramework(false);
                            //                     setQuestionFramework(data?.questions);
                            //                   }),
                            //                 );
                            //               },
                            //               () => {
                            //                 setSavingOrUpdatingHelpReference(false);
                            //                 dispatch(
                            //                   showAlert({
                            //                     message: 'Failed to update help reference.',
                            //                     showCross: true,
                            //                     title: null,
                            //                     type: 'error',
                            //                     autoHideDuration: 2000,
                            //                     vertical: 'top',
                            //                     horizontal: 'center',
                            //                   }),
                            //                 );
                            //               },
                            //             ),
                            //           );
                            //         }
                            //       },
                            //       () => {
                            //         setSavingOrUpdatingHelpReference(false);
                            //         setShowAddHelpReference(null);
                            //         dispatch(
                            //           showAlert({
                            //             message: 'Failed to update help reference.',
                            //             showCross: true,
                            //             title: null,
                            //             type: 'error',
                            //             autoHideDuration: 2000,
                            //             vertical: 'top',
                            //             horizontal: 'center',
                            //           }),
                            //         );
                            //       },
                            //     ),
                            //   );
                            // }
                            setFetchingPreviousTrainingData(true);
                            dispatch(
                              getTrainingData(currentBot?.id, (data) => {
                                console.log(data);
                                setPreviousTrainingData(data || []);
                                setTraining(false);
                                setTrainingText('');
                                setFetchingPreviousTrainingData(false);
                                setShowAddTrainingData(null);
                              }),
                            );
                          },
                        ),
                      );
                    }
                  } else {
                    setTraining(true);
                    dispatch(
                      updateExpertBotTrainingData(
                        showAddTrainingData?.id,
                        {
                          content: trainingText,
                          format: 'html',
                          topic: trainingTopic,
                        },
                        () => {
                          setFetchingPreviousTrainingData(true);
                          dispatch(
                            getTrainingData(currentBot?.id, (data) => {
                              console.log(data);
                              setPreviousTrainingData(data || []);
                              setTraining(false);
                              setTrainingText('');
                              setFetchingPreviousTrainingData(false);
                              setShowAddTrainingData(null);
                            }),
                          );
                        },
                      ),
                    );
                  }
                }}
              >
                Submit Training Data
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={Boolean(showPreviewSalesCall)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowPreviewSalesCall(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // maxHeight: '30vh',
            maxWidth: '50vw',
            minWidth: '300px',
            outline: 'none',
            borderRadius: 1,
            // padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
              padding: '4px 16px',
            }}
          >
            <Typography variant="body2">Conversation Media</Typography>
            <IconButton
              data-trackid={'close_preview_sales_call'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_preview_sales_call');
                setShowPreviewSalesCall(null);
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 20 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={1}>
            <MediaPreview
              styles={{
                flex: 1,
              }}
              url={showPreviewSalesCall?.customFields?.audioLink}
            />
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={showAddHelpVideo}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
            maxHeight: '70vh',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Help Reference Video(Optional)
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              If you want to upload a video for this help reference. Please
              select and proceed.
            </DialogContentText>
          </Box>
          <Box flex={1} mt={2}>
            <InputLabel htmlFor="videoFile" style={{ cursor: 'pointer' }}>
              <input
                style={{ display: 'none' }}
                id="videoFile"
                ref={helpVideoRef}
                type="file"
                accept="video/*"
                onChange={(e) => {
                  handleVideoFilePicker(e);
                }}
              />
              <Box
                sx={{
                  border: '1px dashed #d3d3d3',
                  borderRadius: 4,
                  p: 2,
                  textAlign: 'center',
                }}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={1}
              >
                {helpVideo ? (
                  <Box>
                    <Typography
                      color={'#677788'}
                      variant="caption"
                      sx={{ whiteSpace: 'wrap' }}
                    >
                      {helpVideo?.name}
                    </Typography>
                    <VideoPreview
                      video={helpVideo}
                      onDurationChange={(e) => {
                        setHelpVideoDuration(e.target.duration);
                      }}
                    />
                  </Box>
                ) : (
                  <CloudUpload
                    sx={{
                      fontSize: 20,
                      color: theme.palette.primary.main,
                    }}
                  />
                )}
                <Box
                  sx={{
                    background: theme.palette.primary.main,
                    borderRadius: 1,
                    padding: '4px 8px',
                  }}
                >
                  <Typography variant="subtitle2" color={'#fff'}>
                    {helpVideo ? 'Change Help Video' : 'Choose Help Video'}
                  </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  {invalidVideoFile ? (
                    <Alert severity="error">
                      <Typography
                        variant="caption"
                        sx={{
                          whiteSpace: 'pre-wrap',
                          color: theme.palette.error.main,
                        }}
                      >
                        {invalidVideoFile}
                      </Typography>
                    </Alert>
                  ) : null}
                  <Typography
                    variant="caption"
                    sx={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    (Common video formats supported)(Optional)
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    Upload upto 300 MB of video
                  </Typography>
                </Box>
              </Box>
            </InputLabel>
            {/* )} */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowAddHelpVideo(false);
              }}
              color="primary"
              // autoFocus
            >
              Dismiss
            </Button>
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <Button
                variant="outlined"
                size="small"
                disabled={training}
                // data-trackid={'cta_permission_denied_dialog_close'}
                onClick={(e) => {
                  setTraining(true);
                  dispatch(
                    addExpertBotTraningData(
                      currentBot?.id,
                      {
                        asHelpSection: true,
                        content: trainingText,
                        format: 'html',
                        topic: trainingTopic,
                      },
                      () => {
                        dispatch(
                          createHelpSection(
                            {
                              contentType: helpVideo?.type?.split(';')?.[0],
                              duration: Math.round(helpVideoDuration * 1000),
                              sizeInBytes: helpVideo?.size,
                              text: trainingText,
                              title: trainingTopic,
                            },
                            (data) => {
                              setTrainingText('');
                              setTrainingTopic('');
                              setHelpVideo(null);
                              setShowAddHelpVideo(null);
                              setShowAddTrainingData(null);
                              setFetchingPreviousTrainingData(true);
                              dispatch(
                                getTrainingData(currentBot?.id, (data) => {
                                  console.log(data);
                                  setPreviousTrainingData(data || []);
                                  setTraining(false);
                                  setTrainingText('');
                                  setFetchingPreviousTrainingData(false);
                                  setShowAddTrainingData(null);
                                }),
                              );
                            },
                            () => {
                              dispatch(
                                showAlert({
                                  message: 'Failed to add help reference.',
                                  showCross: true,
                                  title: null,
                                  type: 'error',
                                  autoHideDuration: 2000,
                                  vertical: 'top',
                                  horizontal: 'center',
                                }),
                              );
                            },
                          ),
                        );
                        setFetchingPreviousTrainingData(true);
                        dispatch(
                          getTrainingData(currentBot?.id, (data) => {
                            console.log(data);
                            setPreviousTrainingData(data || []);
                            setTraining(false);
                            setTrainingText('');
                            setFetchingPreviousTrainingData(false);
                            setShowAddTrainingData(null);
                          }),
                        );
                      },
                    ),
                  );
                }}
                color="primary"
                // autoFocus
              >
                Proceed Without Video
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={training || !helpVideo}
                // data-trackid={'cta_permission_denied_dialog_close'}
                onClick={(e) => {
                  setTraining(true);
                  dispatch(
                    addExpertBotTraningData(
                      currentBot?.id,
                      {
                        asHelpSection: true,
                        content: trainingText,
                        format: 'html',
                        topic: trainingTopic,
                      },
                      () => {
                        dispatch(
                          createHelpSection(
                            {
                              contentType: helpVideo?.type?.split(';')?.[0],
                              duration: Math.round(helpVideoDuration * 1000),
                              sizeInBytes: helpVideo?.size,
                              text: trainingText,
                              title: trainingTopic,
                            },
                            (data) => {
                              dispatch(
                                uploadHelpVideo(
                                  data?.uploadUrlRes?.url,
                                  helpVideo,
                                  () => {
                                    dispatch(
                                      verifyHelpVideoUpload(
                                        data?.uploadUrlRes?.url &&
                                          data?.uploadUrlRes?.videoId,
                                        null,
                                        () => {
                                          setTrainingText('');
                                          setTrainingTopic('');
                                          setHelpVideo(null);
                                          setShowAddHelpVideo(null);
                                          setShowAddTrainingData(null);
                                          setFetchingPreviousTrainingData(true);
                                          dispatch(
                                            getTrainingData(
                                              currentBot?.id,
                                              (data) => {
                                                console.log(data);
                                                setPreviousTrainingData(
                                                  data || [],
                                                );
                                                setTraining(false);
                                                setTrainingText('');
                                                setFetchingPreviousTrainingData(
                                                  false,
                                                );
                                                setShowAddTrainingData(null);
                                              },
                                            ),
                                          );
                                        },
                                      ),
                                    );
                                  },
                                ),
                              );
                            },
                            () => {
                              dispatch(
                                showAlert({
                                  message: 'Failed to add help reference.',
                                  showCross: true,
                                  title: null,
                                  type: 'error',
                                  autoHideDuration: 2000,
                                  vertical: 'top',
                                  horizontal: 'center',
                                }),
                              );
                            },
                          ),
                        );
                      },
                    ),
                  );
                }}
                color="primary"
                // autoFocus
              >
                Upload and Proceed
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showSyncHubspotDialog}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Sync the recording and call summary to HubSpot
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Please provide the hubspot contact details you wanted this
              recording to be uploaded on.
            </DialogContentText>
          </Box>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption" color={'text.secondary'}>
                  Meeting Title*
                </Typography>
                <TextField
                  placeholder="Meeting Title*"
                  variant="outlined"
                  name={'meetingTitle'}
                  // fullWidth
                  // multiline
                  // rows={3}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={meetingTitle}
                  onChange={(e) => setMeetingTitle(e.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color={'text.secondary'}>
                  Contact Email(HubSpot)*
                </Typography>
                <TextField
                  placeholder="Contact Email*"
                  variant="outlined"
                  name={'contactEmail'}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  type="email"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color={'text.secondary'}>
                  Contact First Name
                </Typography>
                <TextField
                  placeholder="Contact First Name"
                  variant="outlined"
                  name={'contactFirstName'}
                  // fullWidth
                  // multiline
                  // rows={3}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={contactFirstName}
                  onChange={(e) => setContactFirstName(e.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color={'text.secondary'}>
                  Contact Last Name
                </Typography>
                <TextField
                  placeholder="Contact Last Name"
                  variant="outlined"
                  name={'contactLastName'}
                  // fullWidth
                  // multiline
                  // rows={3}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={contactLastName}
                  onChange={(e) => setContactLastName(e.target.value)}
                  type="text"
                />
              </Grid>
              {/*                         
                        {error && (
                          <Grid item xs={12}>
                            <Alert severity="error">{error}</Alert>
                          </Grid>
                        )} */}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowSyncHubspotDialog(false);
              }}
              color="primary"
              // autoFocus
            >
              Dismiss
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={syncingWithHubspot}
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setSyncingWithHubspot(true);
                dispatch(
                  syncDataWithHubspot(
                    salesReview?.id,
                    {
                      email: contactEmail,
                      fname: contactFirstName,
                      hubSpot: true,
                      lname: contactLastName,
                      meetingTitle: meetingTitle,
                      startTime: 1704274078176,
                    },
                    () => {
                      setShowSyncHubspotDialog(false);
                      setSyncingWithHubspot(false);
                      dispatch(
                        showAlert({
                          message:
                            'Summary is successfully exported to HubSpot.',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 5000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                    () => {
                      setSyncingWithHubspot(false);
                      // setShowSyncHubspotDialog(false)
                      dispatch(
                        showAlert({
                          message:
                            'Failed to export summary. Please try again.',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 5000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                  ),
                );
              }}
              color="primary"
              // autoFocus
            >
              Sync
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ExpertConversation.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

VideoPreview.propTypes = {
  video: PropTypes.any,
  onDurationChange: PropTypes.func,
};

export default ExpertConversation;
