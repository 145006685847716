import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const validateZoomOAuthCallback = (code, callback, errCallback) => {
  return async (dispatch) => {
    dispatch({ type: 'VALIDATING_ZOOM_OAUTH_CALLBACK' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.put(`/us/zoom/users/oauth-cred`, null, {
            params: {
              'request-id': reqId,
              code,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          //   console.log(response.data);
          if (response.status === 200) {
            await dispatch({
              type: 'VALIDATING_ZOOM_OAUTH_CALLBACK_DONE',
              payload: response?.data,
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'VALIDATING_ZOOM_OAUTH_CALLBACK_FAILED',
              payload: `Failed to validate google oauth url`,
            });
            if (typeof errCallback === 'function') {
              errCallback();
            }
          }
        } catch (e) {
          dispatch({
            type: 'VALIDATING_ZOOM_OAUTH_CALLBACK_FAILED',
            payload: `Failed to validate google oauth url`,
          });
          if (typeof errCallback === 'function') {
            errCallback();
          }
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'VALIDATING_ZOOM_OAUTH_CALLBACK_FAILED',
        payload: `Failed to validate google oauth url`,
      });
      if (typeof errCallback === 'function') {
        errCallback();
      }
    }
  };
};

export default validateZoomOAuthCallback;
