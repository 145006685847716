import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Alert,
  Autocomplete,
  Badge,
  Popper,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  ArrowBack,
  Article,
  Assistant,
  AudioFile,
  Audiotrack,
  AutoAwesome,
  Cached,
  Chat,
  ChevronRight,
  Close,
  CloudUpload,
  Delete,
  ErrorOutline,
  FindReplace,
  Info,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Lock,
  Loop,
  Mic,
  PlayArrow,
  Redo,
  Refresh,
  Search,
  Send,
  StopCircle,
  Subject,
  TaskAlt,
  Timeline,
  Troubleshoot,
  Warning,
  WarningAmber,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import createSalesAnalysisThread from 'redux/actions/Candidate/createSalesAnalysisThread';
import showAlert from 'redux/actions/Common/showAlert';
import checkSalesCallTranscriptStatus from 'redux/actions/Common/checkSalesCallTranscriptStatus';
import getSalesCallReviews from 'redux/actions/Common/getSalesCallReviews';
import analyzeSalesCall from 'redux/actions/Common/analyzeSalesCall';

import { PropTypes } from 'prop-types';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';
import parse from 'html-react-parser';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import checkSalesCallScoreStatus from 'redux/actions/Common/checkSalesCallScoreStatus';
import actionCodeBaseUrl from 'config/actionCodeBaseUrl';
import { capitalizeText, formatSeconds, getFileType } from 'utils';
import getSalesCallReviewsByUserId from 'redux/actions/Common/getSalesCallReviewsByUserId';
import getFeedbacksOnEntity from 'redux/actions/Candidate/getFeedbacksOnEntity';
import postFeedbackOnEntity from 'redux/actions/Candidate/postFeedbackOnEntity';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import syncDataWithHubspot from 'redux/actions/Common/syncDataWithHubspot';
import MediaPreview from 'components/@2024/MediaPreview';
import ParticipationPieChart from 'components/@2024/ParticipationPieChart';
import ReportPage from 'pages/ReportPage';
import getSalesCallReviewByToken from 'redux/actions/Common/getSalesCallReviewByToken';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import reAnalyzeSalesCall from 'redux/actions/Common/reAnalyzeSalesCall';
import SkillTagInput from './components/skillTagInput';
import getConversationById from 'redux/actions/Candidate/getConversationById';
import getMessageResponse from 'redux/actions/Candidate/getMessageResponse';
import ProfileCompletion from 'components/ProfileCompletion';
import updateSalesReviewData from 'redux/actions/Common/updateSalesReviewData';
import CRODetails from 'pages/CRODetails';
import getCROInterviews from 'redux/actions/Compass/getCROInterviews';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const VideoPreview = memo(
  function VideoPreview({ selectedAudio, onDurationChange }) {
    return (
      <Box
        // flex={1}
        display={'flex'}
        flexDirection={'column'}
        sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        {selectedAudio ? (
          selectedAudio?.type?.split('/')?.[0] === 'audio' ? (
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Typography
                variant="body2"
                color={'text.secondary'}
                textAlign={'center'}
              >
                Selected File
              </Typography>
              <audio
                preload
                src={window.URL.createObjectURL(selectedAudio)}
                controls
                onDurationChange={onDurationChange}
              />
            </Box>
          ) : (
            <Box
              width={'90%'}
              display={'flex'}
              flexDirection={'column'}
              gap={1}
            >
              <Typography
                variant="body2"
                color={'text.secondary'}
                textAlign={'center'}
              >
                Selected File
              </Typography>
              <video
                style={{ width: '100%' }}
                preload={false}
                src={window.URL.createObjectURL(selectedAudio)}
                controls
                controlsList="nodownload"
                disablePictureInPicture
                onDurationChange={onDurationChange}
              />
            </Box>
          )
        ) : null}
      </Box>
    );
  },
  (pre, post) => {
    return pre?.selectedAudio === post?.selectedAudio;
  },
);

const CROInterviews = ({ uId, member, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const { uploadingFor, uploadPercentage } = useSelector(
    (state) => state.createSalesAnalysisThreadState,
  );
  const chatsScrollview = useRef(null);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const {
    interviews,
    fetching,
    fetchingPagination,
    lastSeen,
    emptyList,
  } = useSelector((state) => state.croInterviews);
  const conversationDetailsById = useSelector(
    (state) => state.conversationDetailsById,
  );

  const { conversationDetails } = conversationDetailsById;

  const { userDetails } = useSelector((state) => state.userDetails);
  const {
    showCompetitionDetailsOnTopbar,
    currentContextData,
    showBackButtonOnTopbar,
    currentNavigationLevel,
  } = useSelector((state) => state.uiStates);
  const [loading, setLoading] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const [currentTab, setCurrentTab] = useState('create');

  // const [autoAnalysis, setAutoAnalysis] = useState(false);
  // const [searchKeywords, setSearchKeywords] = useState([]);
  // const [showAskDonna, setShowAskDonna] = useState(null);
  // const [askAIQuestion, setAskAIQuestion] = useState('');
  // const [generatingResponse, setGeneratingResponse] = useState(false);
  // const [anchorElDetails, setAnchorElDetails] = useState(null);
  // const [popperDetails, setPopperDetails] = useState(null);
  // const [languages, setLanguages] = useState(['english']);

  const [interview, setInterview] = useState(null);

  const [viewType, setViewType] = useState('analysis');
  const [showPreviewSalesCall, setShowPreviewSalesCall] = useState(null);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [showAskDonna, setShowAskDonna] = useState(null);
  const [askAIQuestion, setAskAIQuestion] = useState('');
  const [generatingResponse, setGeneratingResponse] = useState(false);
  const browser = detect();

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  // const validateFile = (event) => {
  //   if (activeStep === 0) {
  //     // if (activeStep === 0) {
  //     if (
  //       // (process.env.REACT_APP_TARGET_ENV === 'development' ||
  //       //   process.env.REACT_APP_TARGET_ENV === 'local') &&
  //       Math.round(event.target.duration / 60) >
  //       Object.values(
  //         Object.values(userDetails?.custom?.plan?.features || {})?.[1]
  //           ?.restrictions || {},
  //       )?.[0]?.limit /
  //         1000
  //     ) {
  //       setInvalidFile(
  //         `You are only alowed to upload a file of upto ${Math.round(
  //           Object.values(
  //             Object.values(
  //               userDetails?.custom?.plan?.features || {},
  //             )?.sort()?.[1]?.restrictions || {},
  //           )?.sort()?.[0]?.limit /
  //             (1000 * 60),
  //         )} minutes duration`,
  //       );
  //       setActiveStep(0);
  //     } else {
  //       setInvalidFile(false);
  //       setActiveStep(1);
  //     }
  //   }
  //   // }
  // };

  // const onFileChange = (event) => {
  //   console.log(event.target.files[0]?.size > 200 * 1024 * 1024);
  //   handleTrackEvent(event, 'recording_file_selected_or_changed');
  //   if (
  //     event.target.files[0] &&
  //     (event.target.files[0].type.includes('audio') ||
  //       event.target.files[0].type.includes('video'))
  //     //   &&
  //     // (!(
  //     //   event.target.files[0]?.size > 200 * 1024 * 1024 &&
  //     //   !(
  //     //     process.env.REACT_APP_TARGET_ENV === 'local' ||
  //     //     process.env.REACT_APP_TARGET_ENV === 'development'
  //     //   )
  //     // ) ||
  //     //   userInfo?.id === 'p1NmkUlUCabB0OJIs2UnEapBEJy2')
  //   ) {
  //     setSelectedAudio(event.target.files[0]);
  //     // setActiveStep(1);
  //   } else {
  //     // if (
  //     //   event.target.files[0]?.size > 200 * 1024 * 1024 &&
  //     //   !(
  //     //     process.env.REACT_APP_TARGET_ENV === 'local' ||
  //     //     process.env.REACT_APP_TARGET_ENV === 'development'
  //     //   )
  //     // ) {
  //     //   dispatch(
  //     //     logError({
  //     //       message: `User with userId ${userInfo?.id} tried to upload more than 200 MB file`,
  //     //       data: {
  //     //         formatted: {
  //     //           errorText: 'User tried to upload more than 200 MB file',
  //     //           severity: 'error',
  //     //         },
  //     //         raw: {
  //     //           errorText: 'User tried to upload more than 200 MB file',
  //     //           severity: 'error',
  //     //         },
  //     //       },
  //     //     }),
  //     //   );
  //     //   setInvalidFile(
  //     //     'The video you are trying to analyze is larger than the limit supported in this plan. Please get in touch with your Qualification AI success manager or write to support@qualification.ai to get it analyzed. ',
  //     //   );
  //     // } else {
  //     inputImage.current.value = null;
  //     setInvalidAudioFile(true);
  //     // }
  //   }
  // };

  // const handleChange = (e, update) => {
  //   if (e.target.checked) {
  //     setMethods((prev) => [...prev, e.target.value]);
  //   } else {
  //     setMethods((prev) => prev.filter((item) => item !== e.target.value));
  //   }
  // };

  // const handleLanguageChange = (e) => {
  //   if (e.target.checked) {
  //     setLanguages((prev) => [...prev, e.target.value]);
  //   } else {
  //     setLanguages((prev) => prev.filter((item) => item !== e.target.value));
  //   }
  // };

  // const resetThread = () => {
  //   setActiveStep(0);
  //   setSelectedAudio(null);
  //   setInterview(null);
  //   setSaleReviewId('');
  //   setProgressValue(0);
  //   setTranscriptReady(false);
  //   setTranscriptionFailed(false);
  //   setConversationTitle('');
  //   setInvalidFile(false);
  // };

  // const skillsTags = (value) => {
  //   const keywords = value.map((skils) => skils.value);
  //   setSearchKeywords(keywords);
  // };

  // const resetSearch = () => {
  //   dispatch(
  //     getSalesCallReviews(
  //       {
  //         userId: uId || null,
  //         keywords: '',
  //         lastSeen: null,
  //         lastTime: null,
  //         size: 10,
  //       },
  //       (data) => {
  //         setSearchKeywords([]);
  //       },
  //     ),
  //   );
  // };

  // const handleUpload = () => {
  //   if (
  //     userDetails?.custom?.userUsage?.currentUsage?.[
  //       'number_of_sale_call_recording_analysis'
  //     ] >=
  //     Object.values(
  //       Object.values(userDetails?.custom?.plan?.features || {})?.[1]
  //         ?.restrictions || {},
  //     )?.[1]?.limit
  //   ) {
  //     setShowLimitExhausted(true);
  //   } else {
  //     setUploadingCall(true);
  //     handleTrackEvent(null, 'upload_recording');
  //     dispatch(
  //       createSalesAnalysisThread(
  //         {
  //           contentType: selectedAudio?.type?.split(';')?.[0],
  //           mediaType: 'audio',
  //           playbook: playbook?.trim() ? true : false,
  //           playbookText: playbook,
  //           title: conversationTitle?.trim(),
  //           playbookTitle: playbookTitle,
  //           playbookHistoryId: selectedPlaybook,
  //           analysisMethods: methods,
  //           salesCallType,
  //           fileSize: selectedAudio?.size,
  //           languages: orgConfigs?.multiLanguageSupport ? languages : null,
  //         },
  //         selectedAudio,
  //         (data) => {
  //           if (autoAnalysis) {
  //             setCurrentTab('list');
  //             dispatch(
  //               getUserDetails((data) => {
  //                 history.push('/sales-call-analysis');
  //               }),
  //             );
  //             setViewType('interviews');
  //             resetThread();
  //             setUploadingCall(false);
  //             dispatch(
  //               showAlert({
  //                 message:
  //                   'File is uploaded successfully. We will notify when it is analyzed.',
  //                 showCross: true,
  //                 title: null,
  //                 type: 'success',
  //                 autoHideDuration: 5000,
  //                 vertical: 'top',
  //                 horizontal: 'center',
  //               }),
  //             );
  //           } else {
  //             dispatch(getUserDetails((data) => {}));
  //             setUploadingCall(false);
  //             dispatch(
  //               showAlert({
  //                 message: 'File is uploaded successfully.',
  //                 showCross: true,
  //                 title: null,
  //                 type: 'success',
  //                 autoHideDuration: 5000,
  //                 vertical: 'top',
  //                 horizontal: 'center',
  //               }),
  //             );
  //             setActiveStep(2);
  //             setSaleReviewId(data?.id);
  //           }
  //         },
  //         (audioId) => {
  //           setUploadingAudioId(audioId);
  //         },
  //         () => {
  //           setUploadingCall(false);
  //           dispatch(
  //             showAlert({
  //               message: 'Oops something went wrong. Please try again later!',
  //               showCross: true,
  //               title: null,
  //               type: 'error',
  //               autoHideDuration: 5000,
  //               vertical: 'top',
  //               horizontal: 'center',
  //             }),
  //           );
  //         },
  //       ),
  //     );
  //   }
  // };

  // const handleAnalyze = (srId, mode, ar, spks, spk, cb) => {
  //   if (
  //     userDetails?.custom?.userUsage?.currentUsage?.[
  //       'number_of_sale_call_recording_analysis'
  //     ] >=
  //     Object.values(
  //       Object.values(userDetails?.custom?.plan?.features || {})?.[1]
  //         ?.restrictions || {},
  //     )?.[1]?.limit
  //   ) {
  //     setShowLimitExhausted(true);
  //   } else {
  //     if (mode === 'create') {
  //       handleTrackEvent(null, 'new_analyze_call_started');
  //     } else {
  //       handleTrackEvent(null, 'reanalyze_call_started');
  //     }
  //     setAnalysisInProgress((prev) => [...prev, srId]);
  //     dispatch(
  //       analyzeSalesCall(
  //         srId,
  //         {
  //           playbookText: playbook,
  //           speakers: spks ? spks : speakers,
  //           analysisMethods: methods,
  //           playbookTitle: playbookTitle,
  //           playbookHistoryId: selectedPlaybook,
  //           salesCallType,
  //         },
  //         spk ? spk : speaker,
  //         (data) => {
  //           dispatch(getUserDetails(() => {}));
  //           const updatedAnalysisInProgress = analysisInProgress.filter(
  //             (ap) => ap !== srId,
  //           );
  //           setAnalysisInProgress(updatedAnalysisInProgress);
  //           if (showTranscriptionFailed) {
  //             setShowTranscriptionFailed(null);
  //           }
  //           if (mode === 'create') {
  //             setInterview(data);
  //           } else {
  //             setShowReAnalyzeDrawer(null);
  //             setInterview(data);
  //             dispatch({
  //               type: 'UPDATE_SALES_CALL_ANALYSIS',
  //               payload: {
  //                 id: srId,
  //                 data: data,
  //               },
  //             });
  //           }
  //           setViewType('report');
  //           setSaleReviewId(srId);
  //           if (userDetails?.custom?.integration?.hubSpot) {
  //             setShowSyncHubspotDialog(true);
  //           }
  //           if (typeof cb === 'function') {
  //             cb();
  //           }
  //         },
  //         () => {
  //           const updatedAnalysisInProgress = analysisInProgress.filter(
  //             (ap) => ap !== srId,
  //           );
  //           setAnalysisInProgress(updatedAnalysisInProgress);
  //           dispatch(
  //             showAlert({
  //               message: 'Something went wrong. Please try again!',
  //               showCross: true,
  //               title: null,
  //               type: 'error',
  //               autoHideDuration: 2000,
  //               vertical: 'top',
  //               horizontal: 'center',
  //             }),
  //           );
  //           if (typeof cb === 'function') {
  //             cb();
  //           }
  //         },
  //       ),
  //     );
  //   }
  // };

  // const handleReAnalyze = (mediaId) => {
  //   if (
  //     userDetails?.custom?.userUsage?.currentUsage?.[
  //       'number_of_sale_call_recording_analysis'
  //     ] >=
  //     Object.values(
  //       Object.values(userDetails?.custom?.plan?.features || {})?.[1]
  //         ?.restrictions || {},
  //     )?.[1]?.limit
  //   ) {
  //     setShowLimitExhausted(true);
  //   } else {
  //     setRetriggeringCall(true);
  //     dispatch(
  //       reAnalyzeSalesCall(
  //         mediaId,
  //         (data) => {
  //           setRetriggeringCall(false);
  //           setSaleReviewId(showTranscriptionFailed?.id);

  //           // setShowTranscriptionFailed(null);
  //         },
  //         () => {
  //           setRetriggeringCall(false);
  //         },
  //       ),
  //     );
  //   }
  // };

  const handleListScroll = (e, paginate) => {
    // console.log(e.target.scrollTop + e.target.clientHeight);
    // console.log(e.target.clientHeight);
    // console.log(e.target.scrollHeight);
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight + 1 >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        interviews &&
        interviews.length % 10 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      // if (uId) {
      //   dispatch(
      //     getSalesCallReviews(
      //       {
      //         userId: uId,
      //         keywords: searchKeywords?.join(','),
      //         lastSeen: lastSeen,
      //         lastTime: lastTime,
      //         size: 10,
      //       },
      //       () => {},
      //     ),
      //   );
      // } else {
      dispatch(getCROInterviews(10, lastSeen, () => {}));
      // }
    }
  };

  // useEffect(() => {
  //   if (selectedPlaybook === 'select') {
  //     if (
  //       showReAnalyzeDrawer &&
  //       interviews?.find((ar) => ar?.id === showReAnalyzeDrawer)?.playbook
  //     ) {
  //       setPlaybook(
  //         interviews?.find((ar) => ar?.id === showReAnalyzeDrawer)?.playbook,
  //       );
  //     } else {
  //       if (orgConfigs?.playbook) {
  //         setPlaybook(orgConfigs?.playbook);
  //       }
  //     }
  //   } else {
  //     setPlaybook(
  //       playbooks?.find((pb) => pb?.id === selectedPlaybook)?.playbook || '',
  //     );
  //   }
  // }, [orgConfigs, showReAnalyzeDrawer, selectedPlaybook]);

  // useEffect(() => {
  //   if (
  //     uploadingFor &&
  //     Object.keys(uploadingFor).length > 0 &&
  //     uploadingFor?.[uploadingAudioId]
  //   ) {
  //     setProgressValue(uploadPercentage?.[uploadingAudioId]);
  //   }
  // }, [uploadingFor, uploadPercentage, uploadingAudioId]);

  // useEffect(() => {
  //   if ((activeStep === 2 || showTranscriptionFailed) && saleReviewId) {
  //     dispatch(
  //       checkSalesCallTranscriptStatus(saleReviewId, (data) => {
  //         // console.log(data);
  //         if (data?.status === 'failed') {
  //           setTranscriptReady(false);
  //           setTranscriptionFailed(true);
  //           dispatch(
  //             showAlert({
  //               message: 'Something went wrong. Please try again!',
  //               showCross: true,
  //               title: null,
  //               type: 'error',
  //               autoHideDuration: 2000,
  //               vertical: 'top',
  //               horizontal: 'center',
  //             }),
  //           );
  //         } else if (data?.status !== 'failed' && data?.status !== 'success') {
  //           checkProgressTimer.current = setInterval(() => {
  //             dispatch(
  //               checkSalesCallTranscriptStatus(saleReviewId, (data) => {
  //                 if (data?.status === 'success') {
  //                   clearInterval(checkProgressTimer.current);
  //                   setTranscriptionFailed(false);
  //                   setTranscriptReady(true);
  //                   setInterview(data?.saleReview);
  //                   if (showTranscriptionFailed) {
  //                     setShowTranscriptionFailed(data?.saleReview);
  //                   }
  //                 } else if (data?.status === 'failed') {
  //                   clearInterval(checkProgressTimer.current);
  //                   setTranscriptReady(false);
  //                   setTranscriptionFailed(true);
  //                   dispatch(
  //                     showAlert({
  //                       message: 'Something went wrong. Please try again!',
  //                       showCross: true,
  //                       title: null,
  //                       type: 'error',
  //                       autoHideDuration: 2000,
  //                       vertical: 'top',
  //                       horizontal: 'center',
  //                     }),
  //                   );
  //                 }
  //               }),
  //             );
  //           }, 5000);
  //         }
  //       }),
  //     );
  //   }
  //   if (activeStep === 1) {
  //     setConversationTitle(
  //       selectedAudio?.name
  //         ?.split('.')
  //         .slice(0, selectedAudio?.name.split('.')?.length - 1)
  //         ?.join('.') || '',
  //     );
  //   }
  // }, [activeStep, saleReviewId, showTranscriptionFailed]);

  // useEffect(() => {
  //   if (interview) {
  //     setSpeakers(interview?.speakers);
  //     setMethods(interview?.analysisMethodologies || []);
  //   }
  // }, [interview]);

  // useEffect(() => {
  //   if (showReAnalyzeDrawer) {
  //     // setActiveStep(2);
  //     setSpeakers(interview?.speakers);
  //     setSpeaker(interview?.speaker || 'spk_0');
  //     setSalesCallType(interview?.salesCallType || 'outbound');
  //     setMethods([...(interview?.analysisMethodologies || [])]);
  //   }
  // }, [showReAnalyzeDrawer]);

  useEffect(() => {
    if (currentTab === 'list' && viewType === 'interviews') {
      // if (uId) {
      //   dispatch(
      //     getSalesCallReviews(
      //       {
      //         userId: uId,
      //         keywords: searchKeywords?.join(','),
      //         lastSeen: null,
      //         lastTime: null,
      //         size: 10,
      //       },
      //       () => {},
      //     ),
      //   );
      // } else {
      dispatch(getCROInterviews(10, null, (data) => {}));
      // }
    }
  }, [currentTab, viewType]);

  // useEffect(() => {
  //   // if (currentTab === 'create') {
  //   if (orgConfigs) {
  //     setMethods(orgConfigs?.selectedMethodologies || []);
  //     setAutoAnalysis(orgConfigs?.isGeneralAutoAnalysis);
  //     setSearchKeywordOptions(
  //       [
  //         ...(orgConfigs?.tags || []),
  //         ...(orgConfigs?.saleReviewFileNames || []),
  //       ]?.sort((a, b) => {
  //         if (orgConfigs?.tags?.includes(a) && !orgConfigs?.tags?.includes(b)) {
  //           return -1;
  //         } else {
  //           return 1;
  //         }
  //       }),
  //     );
  //   }
  //   // }
  // }, [orgConfigs]);

  useEffect(() => {
    if (params && params.get('new') === 'true') {
      setCurrentTab('create');
      setViewType('interviews');
      dispatch(getOrganizationConfigs((data) => {}));
    } else {
      setCurrentTab('list');
      setViewType('interviews');
    }
  }, [window.location.href, currentTab]);

  // useEffect(() => {
  //   if (params && params.get('rtid')) {
  //     dispatch(
  //       getSalesCallReviewByToken(params.get('rtid'), (data) => {
  //         console.log(data);
  //         setInterview(data);
  //         setViewType('report');
  //         setSaleReviewId(params.get('rtid'));
  //       }),
  //     );
  //   }
  // }, [params.get('rtid')]);

  useEffect(() => {
    if (!showBackButtonOnTopbar) {
      setViewType('interviews');
      setCurrentTab('list');
      // resetThread();
    }
  }, [showBackButtonOnTopbar]);

  useEffect(() => {
    if (viewType === 'report') {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: true,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            ...currentContextData,
            title: interview?.title || '',
          },
        },
      });
    } else {
      if (!uId) {
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'showBackButtonOnTopbar',
            value: false,
          },
        });
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'currentContextData',
            value: {
              ...currentContextData,
              title: '',
            },
          },
        });
      }
    }
  }, [viewType]);

  // useEffect(() => {
  //   if (member && uId) {
  //     dispatch({
  //       type: 'UPDATE_UI_STATE',
  //       payload: {
  //         key: 'currentContextData',
  //         value: {
  //           ...currentContextData,
  //           name: member?.fullName,
  //           email: member?.email,
  //         },
  //       },
  //     });
  //   }
  // }, [member, uId]);

  // useEffect(() => {
  //   if (playbooks && playbooks.length > 0) {
  //     if (showReAnalyzeDrawer) {
  //       setSelectedPlaybook(
  //         interviews?.find((ar) => ar?.id === showReAnalyzeDrawer)
  //           ?.playbookHistoryId || playbooks?.find((pb) => pb?.selected)?.id,
  //       );
  //     } else {
  //       setSelectedPlaybook(playbooks?.find((pb) => pb?.selected)?.id);
  //     }
  //   }
  // }, [playbooks]);

  // useEffect(() => {
  //   if (selectedPlaybook) {
  //     setPlaybookTitle(
  //       playbooks?.find((pb) => pb?.id === selectedPlaybook)?.tags?.[0] || '',
  //     );
  //   }
  // }, [selectedPlaybook]);

  // useEffect(() => {
  //   if (activeStep === 1 || showReAnalyzeDrawer) {
  //     dispatch(
  //       getPlaybookHistory((data) => {
  //         setPlaybooks([data?.selectedPlaybook, ...(data?.history || [])]);
  //       }),
  //     );
  //   }
  // }, [activeStep, showReAnalyzeDrawer]);

  // useEffect(() => {
  //   if (
  //     searchKeywords &&
  //     searchKeywords.length > 0 &&
  //     interview?.transcript
  //   ) {
  //     setSearchKeywordsMap(
  //       searchKeywords.reduce((acc, curr) => {
  //         acc[curr] =
  //           interview?.transcript?.match(new RegExp(curr, 'ig'))?.length || 0;
  //         return acc;
  //       }, {}),
  //     );
  //   }
  // }, [searchKeywords, interview]);

  // useEffect(() => {
  //   if (
  //     (parentComponent === 'teamSettings' && currentNavigationLevel > 2) ||
  //     (parentComponent === 'customerSettings' && currentNavigationLevel > 3)
  //   ) {
  //     setViewType('report');
  //   } else {
  //     setViewType('interviews');
  //   }
  // }, [currentNavigationLevel]);

  useEffect(() => {
    if (chatsScrollview?.current) {
      chatsScrollview?.current?.scroll({
        top: chatsScrollview?.current?.scrollHeight,
        behaviour: 'smooth',
      });
    }
  }, [conversationDetails]);

  useEffect(() => {
    if (showAskDonna) {
      dispatch(
        getConversationById(
          showAskDonna === 'global'
            ? orgConfigs?.globalSalesContentConversationId
            : showAskDonna?.conversationId,
          showAskDonna === 'global' ? null : showAskDonna?.speaker,
          (data) => {},
        ),
      );
    }
  }, [showAskDonna]);

  useEffect(() => {
    dispatch(getOrganizationConfigs((data) => {}));
    dispatch(getUserDetails((data) => {}));
    dispatch(getCROInterviews(10, null, (data) => {}));
    return () => {
      // dispatch({
      //   type: 'UPDATE_UI_STATE',
      //   payload: {
      //     key: 'showBackButtonOnTopbar',
      //     value: false,
      //   },
      // });
      // dispatch({
      //   type: 'UPDATE_UI_STATE',
      //   payload: {
      //     key: 'currentContextData',
      //     value: null,
      //   },
      // });
    };
  }, []);

  // console.log(selectedAudio);
  // console.log(saleReviewId);
  // console.log(viewType);
  // console.log(interview);
  // console.log(invalidFile);
  // console.log(playbooks);
  // console.log(currentNavigationLevel);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight:
          isLoggedIn && !uId ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs: 'start',
            sm: 'start',
            md: currentTab === 'create' ? 'center' : 'start',
          }}
          justifyContent={{
            xs: 'start',
            sm: 'start',
            md: currentTab === 'create' ? 'center' : 'flex-start',
          }}
          height={1}
          gap={2}
          sx={{
            borderRight: '1px solid #f1f1f1',
            minHeight: isXs
              ? 'auto'
              : viewType === 'report'
              ? 'calc(100vh - 130px)'
              : 'calc(100vh - 60px)',
            padding: uId ? 0 : 2,
            paddingTop: viewType === 'report' || uId ? 0 : 2,
          }}
        >
          {viewType === 'interviews' && !fetching ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ marginLeft: 'auto' }}
              startIcon={<AutoAwesome />}
              onClick={() => {
                setShowAskDonna('global');
              }}
            >
              Ask Donna
            </Button>
          ) : null}
          {viewType === 'interviews' && currentTab === 'list' ? (
            <Box display={'flex'} flexDirection={'column'} gap={1} width={1}>
              {fetching ? (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                >
                  <CircularProgress size={20} />
                  <Typography variant="body1" color={'text.primary'} mt={4}>
                    Loading ...
                  </Typography>
                </Box>
              ) : interviews && interviews?.length > 0 ? (
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      minHeight: uId
                        ? 'calc(100vh - 160px)'
                        : isXs
                        ? 'calc(100vh - 100px)'
                        : 'calc(100vh - 150px)',
                      maxHeight: uId
                        ? 'calc(100vh - 160px)'
                        : isXs
                        ? 'calc(100vh - 100px)'
                        : 'calc(100vh - 150px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                    }}
                    onScroll={handleListScroll}
                  >
                    <Table
                      sx={{ minWidth: 750 }}
                      stickyHeader
                      aria-label="simple table"
                    >
                      <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Title
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Date
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              textAlign={'center'}
                              // align="center"
                            >
                              Media
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Actions
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {interviews.map((ar, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell component="th" scope="row">
                                <Typography
                                  variant={'subtitle2'}
                                  fontWeight={700}
                                >
                                  {ar?.title}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography variant={'caption'}>
                                  {moment(ar?.createdOn).format('DD MMMM YYYY')}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  gap={1}
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setShowPreviewSalesCall(ar)}
                                >
                                  <Typography variant={'subtitle2'}>
                                    {ar?.customFields?.duration
                                      ? formatSeconds(
                                          ar?.customFields?.duration,
                                        )
                                      : '--:--'}
                                  </Typography>
                                  <IconButton
                                    sx={{
                                      background: '#d3d3d3',
                                      padding: '2px',
                                    }}
                                    onClick={() => setShowPreviewSalesCall(ar)}
                                  >
                                    <PlayArrow
                                      sx={{
                                        fontSize: 16,
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              </TableCell>
                              <TableCell align="center">
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={1}
                                  justifyContent={'center'}
                                >
                                  <Tooltip
                                    title={
                                      ar?.transcript ? 'View Details' : '-'
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      onClick={(e) => {
                                        if (ar?.transcript) {
                                          handleTrackEvent(e, 'view_details');
                                          setViewType('report');
                                          setInterview(ar);
                                        }
                                      }}
                                    >
                                      {ar?.transcript ? (
                                        <Article
                                          color={
                                            ar?.transcript
                                              ? 'primary'
                                              : 'default'
                                          }
                                        />
                                      ) : (
                                        <Cached
                                          sx={{
                                            color: '#d3d3d3',
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                  {/* <Tooltip
                                    title={
                                      (ar?.counts?.totalTask || 0) -
                                        (ar?.counts?.completedTask || 0) >
                                        0 || ar?.counts?.totalTask > 0
                                        ? 'Open tasks associated with the sales call'
                                        : 'No associated tasks found'
                                    }
                                    arrow
                                    placement="top"
                                  >
                                    <IconButton
                                      onClick={() => {
                                        if (
                                          (ar?.counts?.totalTask || 0) -
                                            (ar?.counts?.completedTask || 0) >
                                            0 ||
                                          ar?.counts?.totalTask > 0
                                        ) {
                                          history.push({
                                            pathname: '/tasks',
                                            state: {
                                              id: ar?.id,
                                              type: 'saleReviewAnalysis',
                                              title: ar?.title,
                                            },
                                          });
                                        }
                                      }}
                                    >
                                      <Badge
                                        badgeContent={
                                          (ar?.counts?.totalTask || 0) -
                                          (ar?.counts?.completedTask || 0)
                                        }
                                        sx={{
                                          '& .MuiBadge-badge': {
                                            border: '1px solid #d3d3d3',
                                            background: '#fff',
                                            // color: '#fff',
                                            fontSize: '8px',
                                            fontWeight: 'bold',
                                            padding: '0px',
                                          },
                                        }}
                                      >
                                        <TaskAlt
                                          sx={{
                                            color:
                                              (ar?.counts?.totalTask || 0) -
                                                (ar?.counts?.completedTask ||
                                                  0) >
                                              0
                                                ? theme.palette.primary.main
                                                : theme.palette.grey[400],
                                          }}
                                        />
                                      </Badge>
                                    </IconButton>
                                  </Tooltip> */}
                                  <Tooltip
                                    title={
                                      ar?.transcript
                                        ? 'Ask Donna'
                                        : `Donna will be available once transcript is ready`
                                    }
                                    arrow
                                    placement="top"
                                  >
                                    <IconButton
                                      onClick={() => {
                                        if (ar?.transcript) {
                                          setShowAskDonna(ar);
                                        }
                                      }}
                                    >
                                      <AutoAwesome
                                        sx={{
                                          color: ar?.transcript
                                            ? theme.palette.primary.main
                                            : theme.palette.grey[500],
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  {!uId ? (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title={'Delete Interview'}
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          setShowConfirmDeleteDialog(ar?.id);
                                        }}
                                      >
                                        <Delete
                                          sx={{
                                            color: '#a3a3a3',
                                            fontSize: 20,
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  ) : null}
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell
                            variant="footer"
                            colSpan={6}
                            sx={{
                              padding: 0,
                            }}
                          >
                            {fetchingPagination ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  // type="submit"
                                  color="primary"
                                  onClick={handleListScroll}
                                  // className="searchSettings"
                                  disabled
                                  // fullWidth
                                >
                                  <Typography color="textPrimary">
                                    Loading ...{' '}
                                  </Typography>
                                  <CircularProgress size={20} />
                                </Button>
                              </Box>
                            ) : emptyList === false &&
                              interviews.length >= 10 ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  onClick={(e) => handleListScroll(e, true)}
                                >
                                  Load More
                                </Button>
                              </Box>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Box>
              ) : (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  gap={2}
                >
                  <Typography variant="body1" color={'text.secondary'} mt={4}>
                    No interviews to show here yet.
                  </Typography>
                </Box>
              )}
            </Box>
          ) : viewType === 'report' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: 1,
                paddingTop: uId ? 0 : 2,
              }}
            >
              <CRODetails
                type="croInterviews"
                interview={interview}
                setInterview={setInterview}
                // handleReanalyze={handleAnalyze}
                // searchKeywords={searchKeywords}
                // searchKeywordsMap={searchKeywordsMap}
                // setShowReAnalyzeDrawer={setShowReAnalyzeDrawer}
                // uId={uId}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Modal
        open={Boolean(showPreviewSalesCall)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowPreviewSalesCall(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // maxHeight: '30vh',
            maxWidth: '50vw',
            minWidth: '300px',
            outline: 'none',
            borderRadius: 1,
            // padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
              padding: '4px 16px',
            }}
          >
            <Typography variant="body2">Interview Media</Typography>
            <IconButton
              data-trackid={'close_preview_sales_call'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_preview_sales_call');
                setShowPreviewSalesCall(null);
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 20 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={1}>
            <MediaPreview
              styles={{
                flex: 1,
              }}
              url={showPreviewSalesCall?.customFields?.videoUrl || ''}
              mediaType={showPreviewSalesCall?.saleReviewMediaContentType || ''}
              setDuration={(duration) => {
                if (!showPreviewSalesCall?.duration) {
                  dispatch({
                    type: 'UPDATE_SALES_CALL_ANALYSIS',
                    payload: {
                      id: showPreviewSalesCall?.id,
                      data: {
                        ...showPreviewSalesCall,
                        customFields: {
                          ...(showPreviewSalesCall.customFields || {}),
                          duration: Math.round(duration),
                        },
                      },
                    },
                  });
                }
              }}
            />
          </Box>
          {/* <Box
              sx={{
                width: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              mt={2}
            >
              <Button
                data-trackid={'send_calendar_event_to_email'}
                variant="text"
                color="primary"
                onClick={(e) => {
                  handleTrackEvent(e, 'send_calendar_event_to_email');
                  handleCalendarActions('email');
                }}
              >
                Send me via email
              </Button>
              <Button
                variant="contained"
                data-trackid={'download_event_ics_file'}
                color="primary"
                onClick={(e) => {
                  handleTrackEvent(e, 'send_calendar_event_to_email');
                  handleCalendarActions('download');
                }}
              >
                Download .ics
              </Button>
            </Box> */}
        </Box>
      </Modal>
      <Drawer
        open={Boolean(showAskDonna)}
        anchor="right"
        onClose={() => setShowAskDonna(null)}
      >
        <Box
          p={1}
          sx={{
            width: '40vw',
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => setShowAskDonna(null)}>
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography color={'text.secondary'}>
            {showAskDonna?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '40vw',
            minHeight: 'calc(100vh - 58px)',
            maxHeight: 'calc(100vh - 58px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            // background: '#343a40',
            flex: 1,
          }}
        >
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            {conversationDetailsById?.fetching ? (
              <Box
                width={1}
                height={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
              >
                <CircularProgress size={20} />
                <Typography variant="body1" color={'text.primary'} mt={4}>
                  Loading all chats ...
                </Typography>
              </Box>
            ) : conversationDetails ? (
              <>
                {conversationDetails?.presetMessageMap ||
                (conversationDetails?.messages &&
                  conversationDetails?.messages?.length > 0) ? (
                  <Box
                    flex={1}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                  >
                    <Box
                      ref={chatsScrollview}
                      sx={{
                        minHeight: 'calc(100vh - 115px)',
                        maxHeight: 'calc(100vh - 115px)',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                        },
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      {Object.values(
                        conversationDetails?.presetMessageMap || {},
                      )?.map(({ question, answer }, index) => (
                        <>
                          {question ? (
                            <Box
                              key={question?.id}
                              sx={{
                                borderTopLeftRadius:
                                  question?.gptMessage?.role === 'user'
                                    ? 16
                                    : 0,
                                borderTopRightRadius:
                                  question?.gptMessage?.role === 'user'
                                    ? 0
                                    : 16,
                                borderBottomLeftRadius:
                                  question?.gptMessage?.role === 'user'
                                    ? 8
                                    : 16,
                                borderBottomRightRadius:
                                  question?.gptMessage?.role === 'user'
                                    ? 16
                                    : 8,
                                maxWidth: '80%',
                                alignSelf:
                                  question?.gptMessage?.role === 'user'
                                    ? 'end'
                                    : 'start',
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: 1,
                                boxShadow: theme.shadows[8],
                                background:
                                  question?.gptMessage?.role === 'user'
                                    ? theme.palette.primary.main
                                    : '#fff',
                              }}
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                sx={{
                                  padding: '8px 16px',
                                  gap: 4,
                                }}
                              >
                                {question?.gptMessage?.role === 'user' ? (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        question?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    System
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        question?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    Donna
                                  </Typography>
                                )}
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color:
                                      question?.gptMessage?.role === 'user'
                                        ? '#f1f1f1'
                                        : theme.palette.text.secondary,
                                  }}
                                >
                                  {moment(question?.createdOn).format(
                                    'DD MMMM YYYY hh:mm:ss A',
                                  )}
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  color:
                                    question?.gptMessage?.role === 'user'
                                      ? '#fff'
                                      : theme.palette.text.primary,
                                  padding: '0px 16px 16px 16px',
                                }}
                              >
                                {question?.gptMessage?.content}
                              </Typography>
                            </Box>
                          ) : null}
                          {answer ? (
                            <Box
                              key={answer?.id}
                              sx={{
                                borderTopLeftRadius:
                                  answer?.gptMessage?.role === 'user' ? 16 : 0,
                                borderTopRightRadius:
                                  answer?.gptMessage?.role === 'user' ? 0 : 16,
                                borderBottomLeftRadius:
                                  answer?.gptMessage?.role === 'user' ? 8 : 16,
                                borderBottomRightRadius:
                                  answer?.gptMessage?.role === 'user' ? 16 : 8,
                                maxWidth: '80%',
                                alignSelf:
                                  answer?.gptMessage?.role === 'user'
                                    ? 'end'
                                    : 'start',
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: 1,
                                boxShadow: theme.shadows[8],
                                background:
                                  answer?.gptMessage?.role === 'user'
                                    ? theme.palette.primary.main
                                    : '#fff',
                              }}
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                sx={{
                                  padding: '8px 16px',
                                  gap: 4,
                                }}
                              >
                                {answer?.gptMessage?.role === 'user' ? (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        answer?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    You
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        answer?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    Donna
                                  </Typography>
                                )}
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color:
                                      answer?.gptMessage?.role === 'user'
                                        ? '#f1f1f1'
                                        : theme.palette.text.secondary,
                                  }}
                                >
                                  {moment(answer?.createdOn).format(
                                    'DD MMMM YYYY hh:mm:ss A',
                                  )}
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  color:
                                    answer?.gptMessage?.role === 'user'
                                      ? '#fff'
                                      : theme.palette.text.primary,
                                  padding: '0px 16px 16px 16px',
                                }}
                              >
                                {answer?.gptMessage?.content}
                              </Typography>
                            </Box>
                          ) : (
                            <Box
                              width={1}
                              height={1}
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              flexDirection={'column'}
                              p={2}
                              sx={{
                                borderTopLeftRadius:
                                  answer?.gptMessage?.role === 'user' ? 16 : 0,
                                borderTopRightRadius:
                                  answer?.gptMessage?.role === 'user' ? 0 : 16,
                                borderBottomLeftRadius:
                                  answer?.gptMessage?.role === 'user' ? 8 : 16,
                                borderBottomRightRadius:
                                  answer?.gptMessage?.role === 'user' ? 16 : 8,
                                maxWidth: '80%',
                                alignSelf:
                                  answer?.gptMessage?.role === 'user'
                                    ? 'end'
                                    : 'start',
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: 1,
                                boxShadow: theme.shadows[8],
                                background:
                                  answer?.gptMessage?.role === 'user'
                                    ? theme.palette.primary.main
                                    : '#fff',
                              }}
                            >
                              <CircularProgress size={20} />
                              <Typography
                                variant="body1"
                                color={'text.primary'}
                                mt={4}
                              >
                                This will take a few seconds. Please check back
                                after a short while.
                              </Typography>
                            </Box>
                          )}
                        </>
                      ))}
                      {conversationDetails?.messages?.map((msg, index) => (
                        <>
                          {msg ? (
                            <Box
                              key={msg?.id}
                              sx={{
                                borderTopLeftRadius:
                                  msg?.gptMessage?.role === 'user' ? 16 : 0,
                                borderTopRightRadius:
                                  msg?.gptMessage?.role === 'user' ? 0 : 16,
                                borderBottomLeftRadius:
                                  msg?.gptMessage?.role === 'user' ? 8 : 16,
                                borderBottomRightRadius:
                                  msg?.gptMessage?.role === 'user' ? 16 : 8,
                                maxWidth: '80%',
                                alignSelf:
                                  msg?.gptMessage?.role === 'user'
                                    ? 'end'
                                    : 'start',
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: 1,
                                boxShadow: theme.shadows[8],
                                background:
                                  msg?.gptMessage?.role === 'user'
                                    ? theme.palette.primary.main
                                    : '#fff',
                              }}
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                sx={{
                                  padding: '8px 16px',
                                  gap: 4,
                                }}
                              >
                                {msg?.gptMessage?.role === 'user' ? (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        msg?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    You
                                    {/* {`${msg?.customFields?.userDetails?.firstName} ${msg?.customFields?.userDetails?.lastName}` !==
                                    userInfo?.fullName
                                      ? `${msg?.customFields?.userDetails?.firstName} ${msg?.customFields?.userDetails?.lastName}`
                                      : 'You'} */}
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        msg?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    Donna
                                  </Typography>
                                )}
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color:
                                      msg?.gptMessage?.role === 'user'
                                        ? '#f1f1f1'
                                        : theme.palette.text.secondary,
                                  }}
                                >
                                  {moment(msg?.createdOn).format(
                                    'DD MMMM YYYY hh:mm:ss A',
                                  )}
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  color:
                                    msg?.gptMessage?.role === 'user'
                                      ? '#fff'
                                      : theme.palette.text.primary,
                                  padding: '0px 16px 16px 16px',
                                }}
                              >
                                {msg?.gptMessage?.content}
                              </Typography>
                            </Box>
                          ) : null}
                        </>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    width={1}
                    height={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    p={2}
                    flexDirection={'column'}
                  >
                    <Typography
                      variant="body1"
                      color={'text.secondary'}
                      mt={4}
                      textAlign={'center'}
                    >
                      No conversation done yet.
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box
                flex={3}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                gap={2}
              >
                <Typography variant="body1" color={'text.secondary'} mt={4}>
                  No conversation done yet.
                </Typography>
              </Box>
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setGeneratingResponse(true);
                dispatch(
                  getMessageResponse(
                    showAskDonna === 'global'
                      ? orgConfigs?.globalSalesContentConversationId
                      : showAskDonna?.conversationId,
                    askAIQuestion,
                    null,
                    null,
                    showAskDonna === 'global' ? null : showAskDonna?.videoId,
                    'v2',
                    null,
                    async (data) => {
                      // console.log(data);
                      setGeneratingResponse(false);
                      setAskAIQuestion('');
                      dispatch({
                        type: 'UPDATE_CONVERSATION_MESSAGES',
                        payload: data,
                      });
                    },
                    (errorCode) => {
                      setGeneratingResponse(false);
                      if (errorCode === 'quota exceeded') {
                        // setShowLimitExhausted(errorCode);
                      }
                    },
                  ),
                );
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gap={1}
                borderTop={'1px solid #d3d3d3'}
                p={1}
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  zIndex: 11,
                  width: 1,
                  background: '#fff',
                }}
              >
                <TextField
                  autoFocus
                  placeholder="Ask any question"
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="askAIQuestion"
                  value={askAIQuestion}
                  onChange={(e) => setAskAIQuestion(e.target.value)}
                  sx={{ flex: 4 }}
                  autoComplete="off"
                />
                <Button
                  sx={{ flex: 1 }}
                  variant="contained"
                  size="small"
                  color="primary"
                  type="submit"
                  disabled={
                    generatingResponse || !askAIQuestion.trim()
                    // conversationDetails?.presetQuestionStatus !== 'completed'
                  }
                  startIcon={
                    generatingResponse ? (
                      <CircularProgress size={20} />
                    ) : (
                      <AutoAwesome />
                    )
                  }
                >
                  Ask Donna
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this sales call?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                updateSalesReviewData(
                  showConfirmDeleteDialog,
                  {
                    hidden: true,
                  },
                  (data) => {
                    setShowConfirmDeleteDialog(false);
                    dispatch(getCROInterviews(10, null, (data) => {}));
                  },
                  () => {
                    setShowConfirmDeleteDialog(false);
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

VideoPreview.propTypes = {
  selectedAudio: PropTypes.object,
  onDurationChange: PropTypes.func,
};

CROInterviews.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

export default CROInterviews;
