import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import { NavItem } from './components';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import {
  AutoGraph,
  Logout,
  ManageAccounts,
  RecordVoiceOver,
  Troubleshoot,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import updateNotificationPreferences from 'redux/actions/Common/updateNotificationPreferences.js';

const BottomNavbarItems = ({ pages, onClose, expanded }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState('');
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { userDetails } = useSelector((state) => state.userDetails);
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const { fetching, domainDetails } = useSelector(
    (state) => state.whitelabelDetails,
  );

  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  const handleLogoutClick = () => {
    if (localStorage.getItem('notificationToken')) {
      dispatch(
        updateNotificationPreferences(
          {
            pushnotification: false,
          },
          () => {
            localStorage.removeItem('notificationToken');
            dispatch(
              firebaseLogout(() => {
                dispatch(firebaseCheckSession());
                history.go();
              }),
            );
          },
        ),
      );
    } else {
      dispatch(
        firebaseLogout(() => {
          dispatch(firebaseCheckSession());
          history.go();
        }),
      );
    }
  };
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  return (
    <Box
      height={1}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      // justifyContent={'space-between'}
    >
      <Box height={1} display={'flex'} alignItems={'center'}>
        <Box
          display={'flex'}
          component="a"
          href={
            domainDetails?.organizationWebsite
              ? `https://${domainDetails?.organizationWebsite}`
              : 'https://qualification.ai'
          }
          title={domainDetails?.appTitle || 'Qualification AI'}
          width={34}
          height={34}
        >
          <Box
            component={'img'}
            src={
              theme.palette.mode === 'dark'
                ? '/dark_theme_logo.svg'
                : domainDetails?.logoWithoutText || '/light_theme_logo.svg'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} ml={'auto'}>
        {/* <Box>
          <NavItem
            title={'Contests'}
            items={[]}
            href={'/contests'}
            startIcon={'/contest.svg'}
          />
        </Box> */}
        {/* <Box>
          <NavItem
            title={'Practice'}
            items={[]}
            href={'/practice'}
            startIcon={'/practice1.svg'}
          />
        </Box> */}
        {userInfo?.designations?.includes('account_admin') ? (
          <>
            <Box>
              <NavItem
                title={'Sales Call Analysis'}
                items={[]}
                href={'/sales-call-analysis'}
                startIcon={
                  <Troubleshoot
                    style={{
                      color: new RegExp(`/sales-call-analysis`, 'i').test(
                        pathname,
                      )
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    }}
                  />
                }
              />
            </Box>
            <Box>
              <NavItem
                title={'Roleplays'}
                items={[]}
                href={'/conversation-ai'}
                startIcon={
                  <RecordVoiceOver
                    style={{
                      color: new RegExp(`/conversation-ai`, 'i').test(pathname)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    }}
                  />
                }
              />
            </Box>
            <Box>
              <NavItem
                title={'Performance'}
                items={[]}
                href={'/performance'}
                startIcon={
                  <AutoGraph
                    style={{
                      color: new RegExp(`/performance`, 'i').test(pathname)
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    }}
                  />
                }
              />
            </Box>
          </>
        ) : null}
        {userInfo?.designations?.includes('candidate') ? (
          <Box>
            <NavItem
              title={'Profile'}
              items={[]}
              href={'/profile/settings'}
              startIcon={
                <Avatar
                  variant="square"
                  alt={userInfo?.firstName?.toUpperCase()}
                  src={userDetails?.profilePicture?.url}
                  sx={{
                    width: 20,
                    height: 20,
                  }}
                >
                  {userInfo?.firstName?.[0]?.toUpperCase()}
                </Avatar>
              }
            />
          </Box>
        ) : null}
        <Box>
          <NavItem
            title={'Settings'}
            items={[]}
            href={'/org/settings'}
            startIcon={
              <ManageAccounts
                sx={{
                  color: new RegExp(`/org/settings`, 'i').test(pathname)
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                }}
              />
            }
          />
        </Box>
        <Box>
          <NavItem
            title={'Logout'}
            items={[]}
            // href={'/account'}
            startIcon={
              <Logout style={{ color: theme.palette.text.secondary }} />
            }
            action={handleLogoutClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

BottomNavbarItems.propTypes = {
  pages: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  expanded: PropTypes.bool,
};

export default BottomNavbarItems;
