const initialstate = {
  pendoInstance: null,
};

const pendoReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'UPDATE_PENDO_STATE':
      return Object.assign({}, state, {
        ...state,
        [action.payload.key]: action.payload.value,
      });
    default:
      return state;
  }
};

export default pendoReducer;
