import React, { useEffect } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const PublicRoutes = (props) => {
  const {
    layout: Layout,
    component: Component,
    noFooter,
    noTopbar,
    leftSidebar,
    competeSidebar,
    ...rest
  } = props;
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === '/' && isLoggedIn) {
      console.log(userInfo);
      if (userInfo?.designations?.includes('account_admin')) {
        if (userInfo?.custom?.orgType === 'demo_org') {
          history.push(`/conversation-ai`);
        } else if (userInfo?.orgRoles?.[0] === 'ORG_ADMIN') {
          history.push(`/all-teams`);
        } else if (userInfo?.orgRoles?.[0] === 'WL_ORG_ADMIN') {
          history.push(`/customer/settings`);
        } else if (userInfo?.teamRoles?.[0] === 'TEAM_ADMIN') {
          history.push(`/team-settings?scoresboard=true`);
        } else {
          history.push(`/sales-call-analysis`);
        }
      } else {
        history.push('/all-teams');
      }
    }
  }, [userInfo]);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout
          noTopbar={noTopbar}
          noFooter={noFooter}
          leftSidebar={leftSidebar}
          competeSidebar={competeSidebar}
        >
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

PublicRoutes.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  noFooter: PropTypes.bool,
  noTopbar: PropTypes.bool,
  path: PropTypes.string,
  leftSidebar: PropTypes.bool,
  competeSidebar: PropTypes.bool,
};

export default PublicRoutes;
