import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  Menu,
  MenuItem,
  Typography,
  Modal,
  IconButton,
  Divider,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Tooltip,
  Link,
  Chip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  Circle,
  ExitToApp,
  Home,
  Close,
  ArrowBack,
  HelpOutline,
  Inbox,
  Drafts,
  Send,
  ExpandLess,
  ExpandMore,
  StarBorder,
  ArrowForward,
  Payments,
  Person,
  PriorityHigh,
} from '@mui/icons-material';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import { useHistory } from 'react-router-dom';
import registerForCompetition from 'redux/actions/Candidate/registerForCompetition';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import parse from 'html-react-parser';
import updateNotificationPreferences from 'redux/actions/Common/updateNotificationPreferences.js';
import ProfileCompletion from 'components/ProfileCompletion';
import { capitalizeText } from 'utils';

// import { NavItem } from './components';

const Topbar = ({
  onSidebarOpen,
  //  pages, colorInvert = false
  setPage,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const competitionType =
    location?.pathname?.split('/')?.[1] === 'contests'
      ? 'real_competition'
      : location?.pathname?.split('/')?.[1] === 'practice'
      ? 'practice_competition'
      : null;
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [anchorEl, setAnchorEl] = useState(null);
  const competitionDetailsById = useSelector(
    (state) => state.competitionDetailsById,
  );
  const { competitionDetails } = competitionDetailsById;
  const {
    showCompetitionDetailsOnTopbar,
    currentContextData,
    showBackButtonOnTopbar,
    currentNavigationLevel,
    showPlanExpired,
    showFeatureRestricted,
    onBoardingCompletionPercentage,
    questionFrameworkSteps,
    activeQuestionFrameworkStep,
    creditsRemaining,
    currentTeamToManage,
    showCreditsRequired,
  } = useSelector((state) => state.uiStates);
  const [showRegistrationClosed, setShowRegistrationClosed] = useState(false);
  const [showContestInactive, setShowContestInactive] = useState(false);

  const openRegistrationClosed = Boolean(showRegistrationClosed);
  const openContestInactive = Boolean(showContestInactive);

  const { userDetails, fetching } = useSelector((state) => state.userDetails);
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const { pathname } = useLocation();
  const browser = detect();

  const [showProductHelp, setShowProductHelp] = useState(false);

  const [activeDemo, setActiveDemo] = useState(0);
  const [activeStep, setActiveStep] = useState('');
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [demos, setDemos] = useState([
    {
      title: 'Account Setup',
      expanded: true,
      activeStep: 0,
      description:
        'To derive the maximum advantages from your account, you need to set up a few things.<br /><br />Click <strong>Next</strong> to learn more.',
      imageFolder: 'accountsettings',
      numberOfSteps: 8,
      subSections: [
        'Settings - Roleplays',
        'Settings - Sales Call Analysis',
        'Settings - Playbook',
      ],
      steps: [
        {
          title: 'Account Settings > Playbook',
          subText:
            'Go to <strong>Account Settings > Playbook</strong> from the navigation panel. On this page you can configure settings for roleplays and sales call analysis.',
        },
        {
          title: 'Settings - Roleplays',
          subText:
            'Provide one or more Target Verticals for your sales prospecting.',
        },
        {
          title: 'Settings - Roleplays',
          subText: 'Provide one or more Designations of prospects you target.',
        },
        {
          title: 'Settings - Sales Call Analysis',
          subText:
            'Select the applicable sales methodologies for analysis. These will be used in addition to the playbook.',
        },
        {
          title: 'Settings - Sales Call Analysis',
          subText:
            'We recommend you leave Auto Analysis enabled so that call analysis can happen in the background for uploaded recordings.',
        },
        {
          title: 'Settings - Playbook',
          subText:
            'Click the <strong>Create Playbook</strong> button (at the bottom right) to create your sales playbook.',
        },
        {
          title: 'Settings - Playbook',
          subText:
            'Fill out the sections as per your sales process. This information will be used to analyze sales call recordings uploaded to your account.',
        },
      ],
    },
    {
      title: 'Team Management',
      expanded: true,
      activeStep: 0,
      description:
        'Team admins can invite team members to the platform, ensure central management of playbooks, sales methodologies, call analysis, roleplays, automations and reports so that all pieces are aligned for best team performance.<br /><br />Click <strong>Next</strong> to learn more.',
      imageFolder: 'manageteam',
      numberOfSteps: 6,
      subSections: ['Invite a Team Member', 'Manage Invitations'],
      steps: [
        {
          title: 'My Team > Manage Members',
          subText:
            'Go to <strong>My Team > Manage Members</strong> from the navigation panel. From this page you can add members to your team and manage them.',
        },
        {
          title: 'Invite a Team Member',
          subText: 'Go to <strong>Invite Members</strong> tab.',
        },
        {
          title: 'Invite a Team Member',
          subText: 'Provide name & email of the member and select their role.',
        },
        {
          title: 'Invite a Team Member',
          subText:
            'Click <strong>Send Invite</strong>. An email with the sign up link will be sent to the invited member.',
        },
        {
          title: 'Manage Invitations',
          subText:
            'Track sent invitations from the <strong>Invitations</strong> tab.',
        },
      ],
    },
    {
      title: 'Sales Call Analysis',
      expanded: true,
      activeStep: 0,
      description:
        'Sales call analysis highlights selling strengths and identifies areas of improvement.<br />A detailed report is created for each call recording.<br /><br />Click <strong>Next</strong> to learn more.',
      imageFolder: 'salescall',
      numberOfSteps: 15,
      subSections: ['Analyze a Sales Call', 'View Analysis Report'],
      steps: [
        {
          title: 'Sales Call Analysis > Upload Recording',
          subText:
            'Go to <strong>Sales Call Analysis > Upload Recording</strong> from the navigation panel. From this page you can upload call recordings and have them analyzed',
        },
        {
          title: 'Analyze a Sales Call',
          subText: 'Upload a call recording',
        },
        {
          title: 'Analyze a Sales Call',
          subText: 'Start Analysis - Click Next button',
        },
        {
          title: 'Analyze a Sales Call',
          subText: 'Analysis in Progress - In progess icon in Actions column',
        },
        {
          title: 'Analyze a Sales Call',
          subText: 'Analysis Complete - Report icon visible in Actions column',
        },
        {
          title: 'View Analysis Report',
          subText:
            'View Analysis - Click the Report icon in the Actions column',
        },
        {
          title: 'View Analysis Report',
          subText: 'Call Summary - Default Tab',
        },
        {
          title: 'View Analysis Report',
          subText: 'Deal Risk - Shown at the bottom of the Summary',
        },
        {
          title: 'View Analysis Report',
          subText: 'Playbook Alignment - Playbook Tab',
        },
        {
          title: 'View Analysis Report',
          subText:
            'Maximize View - Click the second icon from right in the tabs header',
        },
        {
          title: 'View Analysis Report',
          subText: 'Playbook Alignment Maximized View',
        },
        {
          title: 'View Analysis Report',
          subText:
            'Playbook Alignment - View Playbook in Sidebar - Click the book icon in the tabs header',
        },
        {
          title: 'View Analysis Report',
          subText:
            'Playbook Alignment Maximized View - Show Playbook in Sidebar',
        },
        {
          title: 'View Analysis Report',
          subText: 'Ask Donna - Ask AI any question about the call',
        },
      ],
    },
    // {
    //   title: 'Analyze a sales call',
    //   imageFolder: 'salescall',
    //   numberOfSteps: 10,
    //   steps: [
    //     {
    //       title: 'Upload Sales Call',
    //       subText:
    //         'You can upload new sales call recording by clicking on the left navigation option "Upload Recording"',
    //     },
    //     {
    //       title: 'Pick a sales call',
    //       subText:
    //         'Click on "Choose Recording File" and it will open the file picker. Pick your sales call recording from the file manager.',
    //     },
    //     {
    //       title: 'Analysis Options',
    //       subText:
    //         'Provide the methodoglogies and playbook to analyse the sales call recording and click next. It will upload the file to the server and starts the transcription process',
    //     },
    //     {
    //       title: 'Wait for the transcription to finish',
    //       subText:
    //         'You will get the transcription after transcription finishes',
    //     },
    //     {
    //       title: 'Analyze the call',
    //       subText:
    //         'You can see the transcript now and set the sales person and speaker names in the calls. Click Analyze',
    //     },
    //     {
    //       title: 'View Report',
    //       subText:
    //         'It will take a while to generate the report when you click the analyze button. You can see the report like this.',
    //     },
    //     {
    //       title: 'Fillers/Repetitions in the report',
    //       subText:
    //         'You can view all the fillers and repetitions spoken during the conversation.',
    //     },
    //     {
    //       title: 'Feedbacks and call summary',
    //       subText:
    //         'You can see the variety of the feedbacks and call summary on the bottom section of the report',
    //     },
    //     {
    //       title: 'Analysis History',
    //       subText: 'You can also view all of your analysis done here.',
    //     },
    //     {
    //       title: 'Revisit Report',
    //       subText:
    //         'You can also revisit the report generated for a particular analysis or do the analysis again if you are not satisfied with the results.',
    //     },
    //   ],
    // },
    // {
    //   title: 'Practice Sales Call with Personalized AI Bot',
    //   imageFolder: 'practiceCall',
    //   numberOfSteps: 9,
    //   steps: [
    //     {
    //       title: 'Create New Roleplay',
    //       subText:
    //         'You can proceed with creating new Roleplay by clicking on the left navigation option "New Conversation"',
    //     },
    //     {
    //       title: 'Pick a bot and create conversation',
    //       subText:
    //         'Now you will be prompted with a form. Fill the conversation title, pick a bot, vertical and designation and click "Create Conversation"',
    //     },
    //     {
    //       title: 'Conversation UI',
    //       subText:
    //         'Now you can start pitching your sales call by clicking "Start Conversation"',
    //     },
    //     {
    //       title: 'Bot Response',
    //       subText:
    //         'It will auto detect when you stop speaking and then bot will take few moments to prepare the response and will talk back to you.',
    //     },
    //     {
    //       title: 'End Call',
    //       subText: 'You can hit "End Conversation" to end the call.',
    //     },
    //     {
    //       title: 'Analyze your roleplay conversation',
    //       subText: 'You can analyse this conversation by clicking on analyze.',
    //     },
    //     {
    //       title: 'Analyse your roleplay conversation',
    //       subText:
    //         'Similar to your external actual sales call, you can also analyse your roleplay conversation by providing up the same analysis options.',
    //     },
    //     {
    //       title: 'View Report',
    //       subText:
    //         'It will take a while to generate the report when you click the analyze button. You can see the report like this.',
    //     },
    //     {
    //       title: 'Roleplays History',
    //       subText:
    //         'You can also view all of your previous roleplays done here.',
    //     },
    //   ],
    // },
    // {
    //   title: 'View your performance report',
    //   imageFolder: 'performance',
    //   numberOfSteps: 1,
    //   steps: [
    //     {
    //       title: 'View your performance report',
    //       subText:
    //         'This is your overall performance report for the timerange which you choose from the top radio box(3 months by default)',
    //     },
    //   ],
    // },
    // {
    //   title: 'Inviting and managing team members',
    //   imageFolder: 'manageteam',
    //   numberOfSteps: 6,
    //   steps: [
    //     {
    //       title: 'Team Dashboard',
    //       subText:
    //         'Team Dashboard is the place where you can manage your team members and see their performances throughout the sales calls and roleplays',
    //     },
    //     {
    //       title: 'Team members controls',
    //       subText:
    //         'See your team members performance, schedule role play from here.',
    //     },
    //     {
    //       title: 'Manage Team Members and Roles',
    //       subText:
    //         'Manage your team members role or delete a member from here.',
    //     },
    //     {
    //       title: 'Change Member Role',
    //       subText: 'Change your team mamber role from here.',
    //     },
    //     {
    //       title: 'Confirm Delete Dialog',
    //       subText:
    //         'Confirm delete dialog is shown when you want to delete a team member.',
    //     },
    //     {
    //       title: 'Invite new team members',
    //       subText:
    //         'You can invite a new team member from here. Provide first name, last name, email, role and hit send invite.',
    //     },
    //   ],
    // },
    // {
    //   title: 'Setting up onboarding details',
    //   imageFolder: 'manageprofile',
    //   numberOfSteps: 2,
    //   steps: [
    //     {
    //       title: 'Setting up onboarding details',
    //       subText:
    //         'You can setup your sales prospect details and methodologies being used for your sales call analysis and roleplays. Prospect details will be used for personalizing the bots for roleplays.',
    //     },
    //     {
    //       title: 'Setting up playbooks',
    //       subText:
    //         'You can setup your playbook for anlayzing calls and roleplays. Options to edit, create and view the playbooks can be seen here. You can also set your default playbook that will be used for every new sales call analysis.',
    //     },
    //   ],
    // },
    // {
    //   title: 'Handling Integrations',
    //   imageFolder: 'manageintegrations',
    //   numberOfSteps: 1,
    //   steps: [
    //     {
    //       title: 'Integrations',
    //       subText: 'Handle all the integrations from here.',
    //     },
    //   ],
    // },
  ]);
  const [demoSteps, setDemoSteps] = useState([
    {
      title: 'Teams',
    },
    {
      title: 'Team Setup',
    },
    {
      title: 'Playbook Creation',
    },
    {
      title: 'Sales Call Analysis',
    },
    {
      title: 'Private Roleplays',
    },
    {
      title: 'Managing Team Members',
    },
    {
      title: 'CRM Integrations',
    },
    {
      title: 'Automatic Call Recordings',
    },
    {
      title: 'Automatic Call Analysis',
    },
  ]);

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleProfileMenuClick = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    if (localStorage.getItem('notificationToken')) {
      dispatch(
        updateNotificationPreferences(
          {
            pushnotification: false,
          },
          () => {
            localStorage.removeItem('notificationToken');
            dispatch(
              firebaseLogout(() => {
                dispatch(firebaseCheckSession());
                history.go();
              }),
            );
          },
        ),
      );
    } else {
      dispatch(
        firebaseLogout(() => {
          dispatch(firebaseCheckSession());
          history.go();
        }),
      );
    }
  };

  useEffect(() => {
    if (!showProductHelp) {
      setActiveDemo(0);
      setActiveStep('');
      setActiveStepIndex(0);
    }
  }, [showProductHelp]);

  useEffect(() => {
    setActiveStep(demos?.[activeDemo]?.steps?.[activeStepIndex - 1]?.title);
  }, [activeStepIndex, activeDemo]);

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      // width={1}
      sx={{
        width: {
          xs: '100%',
          sm: 'calc(100% - 80px)',
          md: dashboardSidebarExpanded
            ? 'calc(100% - 216px)'
            : 'calc(100% - 80px)',
        },
        marginLeft: {
          xs: '0px',
          sm: '80px',
          md: dashboardSidebarExpanded ? '216px' : '80px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {showBackButtonOnTopbar || currentTeamToManage ? (
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            sx={{
              marginLeft: 1,
            }}
            onClick={() => {
              console.log(history.location);
              if (currentTeamToManage) {
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'currentTeamToManage',
                    value: null,
                  },
                });
                history.push('/all-teams');
              }
              if (history?.location?.pathname === '/tasks') {
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'showBackButtonOnTopbar',
                    value: false,
                  },
                });
                history.goBack();
              }
              if (currentNavigationLevel > 2) {
                console.log('coming here');
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'currentNavigationLevel',
                    value: currentNavigationLevel - 1,
                  },
                });
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'currentContextData',
                    value: {
                      ...currentContextData,
                      title: '',
                    },
                  },
                });
              } else {
                console.log('coming here 1');
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'currentNavigationLevel',
                    value:
                      currentNavigationLevel > 1
                        ? currentNavigationLevel - 1
                        : 1,
                  },
                });
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'showBackButtonOnTopbar',
                    value: false,
                  },
                });
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'curentContextData',
                    value: null,
                  },
                });
              }
            }}
            color="primary"
            size="small"
          >
            Go Back
          </Button>
        ) : null}
        {currentContextData &&
        Object.keys(currentContextData || {})?.length > 0 ? (
          <Box
            display={'flex'}
            flexDirection={'row'}
            gap={2}
            alignItems={'center'}
          >
            {currentContextData?.currentTeam ? (
              <Typography
                variant="body1"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                {currentContextData?.currentTeam}
              </Typography>
            ) : null}
            {currentContextData?.name || currentContextData?.email ? (
              <>
                {currentContextData?.currentTeam ? (
                  <Divider
                    orientation="vertical"
                    variant="fullWidth"
                    flexItem
                  />
                ) : null}
                <Box display={'flex'} flexDirection={'column'}>
                  {currentContextData?.name ? (
                    <Typography
                      variant="subtitle2"
                      color={'text.secondary'}
                      fontWeight={'bold'}
                    >
                      {currentContextData?.name}
                    </Typography>
                  ) : null}
                  {currentContextData?.email ? (
                    <Typography
                      variant="caption"
                      color={'text.secondary'}
                      // fontWeight={'bold'}
                    >
                      {currentContextData?.email}
                    </Typography>
                  ) : null}
                </Box>
              </>
            ) : null}
            {currentContextData?.title ? (
              <>
                {currentContextData?.currentTeam ||
                currentContextData?.name ||
                currentContextData?.email ? (
                  <Divider
                    orientation="vertical"
                    variant="fullWidth"
                    flexItem
                  />
                ) : null}
                <Typography
                  variant="body1"
                  color={'text.secondary'}
                  fontWeight={'bold'}
                >
                  {currentContextData?.title}
                </Typography>
              </>
            ) : null}
          </Box>
        ) : null}
      </Box>
      {currentContextData?.title && currentContextData?.tab === 'manage' ? (
        <Box display={'flex'} alignItems={'center'}>
          {questionFrameworkSteps.map((step, index) => {
            return (
              <Box
                key={step}
                sx={{
                  padding: '8px 16px',
                  // border: '1px solid #d3d3d3',
                  borderTopLeftRadius: index === 0 ? 4 : 0,
                  borderBottomLeftRadius: index === 0 ? 4 : 0,
                  borderTopRightRadius:
                    index === questionFrameworkSteps?.length - 1 ? 4 : 0,
                  borderBottomRightRadius:
                    index === questionFrameworkSteps?.length - 1 ? 4 : 0,
                  background:
                    activeQuestionFrameworkStep === index
                      ? theme.palette.primary.main
                      : '#f1f1f1',
                  color:
                    activeQuestionFrameworkStep === index
                      ? '#fff'
                      : theme.palette.primary.main,
                  borderRight:
                    index !== questionFrameworkSteps?.length - 1
                      ? '1px solid #d3d3d3'
                      : 'none',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                      key: 'activeQuestionFrameworkStep',
                      value: index,
                    },
                  });
                  dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                      key: 'resetQuestionFrameworkStep',
                      value: true,
                    },
                  });
                }}
              >
                <Typography>{capitalizeText(step?.sectionType)}</Typography>
              </Box>
            );
          })}
        </Box>
      ) : null}
      <Box sx={{ display: { xs: 'flex', md: 'flex' } }} alignItems={'center'}>
        {isLoggedIn && userInfo ? (
          <Box display={'flex'} alignItems={'center'} gap={2}>
            {fetching ||
            userDetails?.plan?.trailEndsOn > new Date().getTime() ||
            userDetails?.plan?.currentPaymentStatus === 'paid' ? null : (
              <Link
                underline="none"
                component="a"
                href="/pricing"
                // color="text.primary"
                variant={'subtitle2'}
              >
                <Typography
                  gutterBottom
                  sx={{
                    // background: 'transparent',
                    fontWeight: 'bold',
                    color: theme.palette.warning.dark,
                    // ':hover': {
                    //   color: theme.palette.error.dark,
                    //   background: 'transparent',
                    // },
                  }}
                  variant={'body1'}
                >
                  Plan Expired
                </Typography>
              </Link>
            )}
            {process.env.REACT_APP_TARGET_ENV === 'local' ||
            process.env.REACT_APP_TARGET_ENV === 'development' ||
            userInfo?.custom?.orgType === 'demo_org' ? (
              <>
                {Object.values(onBoardingCompletionPercentage || {})?.reduce(
                  (ac, cr) => {
                    ac += cr;
                    return ac;
                  },
                  0,
                ) === 100 ? null : (
                  <Tooltip
                    title="Recommended to complete 100% to have better app experience"
                    arrow
                    placement="bottom"
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      gap={1}
                      sx={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        dispatch({
                          type: 'UPDATE_UI_STATE',
                          payload: {
                            key: 'showOnboardingDialog',
                            value: true,
                          },
                        });
                        if (userDetails?.custom?.orgType === 'demo_org') {
                          dispatch({
                            type: 'UPDATE_UI_STATE',
                            payload: {
                              key: 'onBoardingStep',
                              value: 1,
                            },
                          });
                        } else {
                          console.log('coming here');
                          dispatch({
                            type: 'UPDATE_UI_STATE',
                            payload: {
                              key: 'onBoardingStep',
                              value: 0,
                            },
                          });
                        }
                      }}
                    >
                      <ProfileCompletion
                        percentCompleted={Object.values(
                          onBoardingCompletionPercentage || {},
                        )?.reduce((ac, cr) => {
                          ac += cr;
                          return ac;
                        }, 0)}
                        circleSize={35}
                        circleTextSize={10}
                        fontSize={10}
                        thickness={3}
                        dontShowExtraText={true}
                      />
                      <Typography variant="body2" color={'text.secondary'}>
                        {Object.values(
                          onBoardingCompletionPercentage || {},
                        )?.reduce((ac, cr) => {
                          ac += cr;
                          return ac;
                        }, 0) > 0
                          ? 'Continue Onboarding'
                          : 'Open Onboarding'}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </>
            ) : null}
            {userDetails?.custom?.plan?.price?.pricingScheme ===
            'usage_based' ? (
              <Tooltip
                arrow
                placement="bottom"
                title={
                  creditsRemaining <=
                  (userDetails?.custom?.plan?.price?.seatsOrCredits * 5) / 100
                    ? userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                      userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN'
                      ? `Your account is running low on credits with less than 5% remaining. You can buy more credits from the Plans page for uninterrupted functionality.`
                      : `Your account is running low on credits with less than 5% remaining. You can alert your account admin to add more credits for uninterrupted functionality.`
                    : ''
                }
              >
                <Button
                  startIcon={
                    creditsRemaining <=
                    (userDetails?.custom?.plan?.price?.seatsOrCredits * 5) /
                      100 ? (
                      <PriorityHigh />
                    ) : null
                  }
                  variant="outlined"
                  size="small"
                  color={
                    creditsRemaining <=
                    (userDetails?.custom?.plan?.price?.seatsOrCredits * 5) / 100
                      ? 'error'
                      : 'primary'
                  }
                  // onClick={() => setShowProductHelp(true)}
                >
                  <Box display={'flex'} gap={1} alignItems={'center'}>
                    <Chip
                      size="small"
                      label={creditsRemaining}
                      variant="outlined"
                      color={
                        creditsRemaining <=
                        (userDetails?.custom?.plan?.price?.seatsOrCredits * 5) /
                          100
                          ? 'error'
                          : 'primary'
                      }
                    />
                    <Typography variant="body2" color={'text.secondary'}>
                      Credits
                    </Typography>
                  </Box>
                </Button>
              </Tooltip>
            ) : null}
            {process.env.REACT_APP_TARGET_ENV === 'local' ||
            process.env.REACT_APP_TARGET_ENV === 'development' ? (
              <Button
                startIcon={<HelpOutline />}
                variant="outlined"
                size="small"
                onClick={() => setShowProductHelp(true)}
              >
                <Typography variant="body2" color={'text.secondary'}>
                  Help
                </Typography>
              </Button>
            ) : null}
            <Box>
              <Button
                data-trackid={'open_profile_menu'}
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  handleTrackEvent(e, 'open_profile_menu');
                  handleProfileMenuClick(e);
                }}
              >
                <Avatar
                  alt={userInfo?.firstName?.toUpperCase()}
                  src={userDetails?.profilePicture?.url}
                  sx={{
                    width: 40,
                    height: 40,
                  }}
                >
                  {userInfo?.firstName?.[0]?.toUpperCase()}
                </Avatar>
              </Button>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock={true}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                style={{
                  marginTop: '15px',
                }}
              >
                <MenuItem
                  data-trackid={'profile_menu_home_option'}
                  style={{}}
                  onClick={(e) => {
                    handleTrackEvent(e, 'profile_menu_home_option');
                    history.push('/profile');
                    handleClose();
                  }}
                >
                  <Person style={{ marginRight: '10px' }} />
                  Profile Settings
                </MenuItem>
                <MenuItem
                  data-trackid={'profile_menu_logout_option'}
                  style={{}}
                  onClick={(e) => {
                    handleTrackEvent(e, 'profile_menu_logout_option');
                    handleLogoutClick();
                  }}
                >
                  <ExitToApp style={{ marginRight: '10px' }} />
                  Logout
                </MenuItem>
                <MenuItem
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    pointerEvents: 'none',
                  }}
                >
                  {userInfo?.email}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        ) : (
          <Box marginLeft={4}>
            <Button
              data-trackid={'cta_sign_in_sign_up'}
              size={'small'}
              variant="outlined"
              fullWidth
              component="a"
              href="/auth/signin"
              onClick={(e) => {
                handleTrackEvent(e, 'cta_sign_in_sign_up');
              }}
            >
              Sign In / Sign Up
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}
        alignItems={'center'}
      >
        <Button
          data-trackid={'toggle_sidebar'}
          onClick={(e) => {
            onSidebarOpen();
            handleTrackEvent(e, 'toggle_sidebar');
          }}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
      <Modal
        open={showCreditsRequired}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            dispatch({
              type: 'UPDATE_UI_STATE',
              payload: {
                key: 'showCreditsRequired',
                value: false,
              },
            });
          } else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            maxWidth: '450px',
            outline: 'none',
            borderRadius: 1,
            padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Credits Required</Typography>
            <IconButton
              onClick={(e) => {
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'showCreditsRequired',
                    value: false,
                  },
                });
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 24 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={1}>
            <Typography variant="body1" mt={1}>
              {userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
              userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN'
                ? "You don't have sufficient credits to continue. Please buy more credits or upgrade your plan."
                : "You don't have sufficient credits to continue. Please alert your account admin to add more credits."}
            </Typography>
          </Box>
          {userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
          userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN' ? (
            <Box
              sx={{
                width: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
              // mt={2}
            >
              <Button
                variant="contained"
                data-trackid={'close_plan_expired_dialog'}
                color="primary"
                onClick={(e) => {
                  handleTrackEvent(e, 'close_plan_expired_dialog');
                  dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                      key: 'showCreditsRequired',
                      value: false,
                    },
                  });
                  history.push('/pricing');
                }}
              >
                See All Plans
              </Button>
            </Box>
          ) : null}
        </Box>
      </Modal>
      <Modal
        open={showPlanExpired}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            dispatch({
              type: 'UPDATE_UI_STATE',
              payload: {
                key: 'showPlanExpired',
                value: false,
              },
            });
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            maxWidth: '450px',
            outline: 'none',
            borderRadius: 1,
            padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Your current plan has expired.</Typography>
            <IconButton
              data-trackid={'close_plan_expired_dialog'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_plan_expired_dialog');
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'showPlanExpired',
                    value: false,
                  },
                });
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 24 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={1}>
            <Typography variant="body1" mt={1}>
              Please select a plan from the Plans page, click Contact Support
              and we will reach out to you promptly.
            </Typography>
          </Box>
          <Box
            sx={{
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            mt={2}
          >
            <Button
              variant="contained"
              data-trackid={'close_plan_expired_dialog'}
              color="primary"
              onClick={(e) => {
                handleTrackEvent(e, 'close_plan_expired_dialog');
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'showPlanExpired',
                    value: false,
                  },
                });
                history.push('/pricing');
              }}
            >
              See All Plans
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showFeatureRestricted}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            dispatch({
              type: 'UPDATE_UI_STATE',
              payload: {
                key: 'showFeatureRestricted',
                value: false,
              },
            });
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            maxWidth: '450px',
            outline: 'none',
            borderRadius: 1,
            padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Restricted</Typography>
            <IconButton
              data-trackid={'close_plan_expired_dialog'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_plan_expired_dialog');
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'showFeatureRestricted',
                    value: false,
                  },
                });
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 24 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={1}>
            <Typography variant="body1" mt={1}>
              Google Calendar integration lets you send your personal Meetbot to
              record your meetings and have them automatically analyzed. Please
              get in touch with your account administrator to have it added to
              your plan.
            </Typography>
          </Box>
          {/* <Box
            sx={{
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            mt={2}
          >
            <Button
              variant="contained"
              data-trackid={'close_plan_expired_dialog'}
              color="primary"
              onClick={(e) => {
                handleTrackEvent(e, 'close_plan_expired_dialog');
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'showFeatureRestricted',
                    value: false,
                  },
                });
                history.push('/pricing');
              }}
            >
              See All Plans
            </Button>
          </Box> */}
        </Box>
      </Modal>
      <Modal
        open={openContestInactive}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowContestInactive(false);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            maxWidth: '450px',
            outline: 'none',
            borderRadius: 1,
            padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Contest is not live.</Typography>
            <IconButton
              data-trackid={'close_contest_inactive_dialog'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_contest_inactive_dialog');
                setShowContestInactive(false);
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 24 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={1}>
            <Typography variant="body1" mt={1}>
              Oops! This contest has ended.
            </Typography>
            <Typography variant="body1" mt={1}>
              You can try participating in other live contests.
            </Typography>
          </Box>
          <Box
            sx={{
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            mt={2}
          >
            <Button
              variant="contained"
              data-trackid={'contest_inactive_dialog_closed'}
              color="primary"
              onClick={(e) => {
                handleTrackEvent(e, 'contest_inactive_dialog_closed');
                setShowRegistrationClosed(false);
                history.push('/contests');
              }}
            >
              Go to Contests
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openRegistrationClosed}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowRegistrationClosed(false);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            maxWidth: '450px',
            outline: 'none',
            borderRadius: 1,
            padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Registration Closed!</Typography>
            <IconButton
              data-trackid={'close_save_to_calendar_dialog'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_save_to_calendar_dialog');
                setShowRegistrationClosed(false);
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 24 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={1}>
            <Typography variant="body1" mt={1}>
              Oops, this contest is not accepting new entries at this point as
              it is packed to capacity. We’ve added you to the waitlist and will
              let you know as soon as we can accommodate you.
            </Typography>
            <Typography variant="body1" mt={1}>
              In the meantime you can hone your skills with one of our practice
              contests.
            </Typography>
          </Box>
          <Box
            sx={{
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            mt={2}
          >
            <Button
              variant="contained"
              data-trackid={'registration_closed_dialog_close'}
              color="primary"
              onClick={(e) => {
                handleTrackEvent(e, 'registration_closed_dialog_close');
                setShowRegistrationClosed(false);
                history.push('/practice');
              }}
            >
              Go to Practice
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showProductHelp}
        onClose={(e, reason) => {
          setShowProductHelp(false);
          // if (reason !== 'backdropClick') setShowProductHelp(false);
          // else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            maxWidth: '90vw',
            minWidth: '90vw',
            outline: 'none',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <Tooltip title="Close" placement="top">
            <IconButton
              data-trackid={'close_product_help_dialog'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_product_help_dialog');
                setShowProductHelp(false);
              }}
              sx={{
                position: 'absolute',
                top: -10,
                right: -10,
                background: '#d3d3d3',
                ':hover': {
                  background: '#f3f3f3',
                },
                padding: '4px',
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
          <Box display={'flex'}>
            <Box
              flex={1}
              sx={{
                minHeight: '90vh',
                maxHeight: '90vh',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                },
                borderRight: '1px solid #d3d3d3',
              }}
            >
              <Typography
                variant="body1"
                color={'text.secondary'}
                sx={{
                  display: 'block',
                  padding: 2,
                  paddingBottom: 1,
                  fontWeight: 'bold',
                  borderBottom: '1px solid #d3d3d3',
                }}
              >
                Qualification AI Product Help
              </Typography>
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    p: 2,
                  }}
                >
                  {demoSteps?.map((demo, index) => (
                    <Box
                      key={index}
                      sx={{
                        border: '1px solid #d3d3d3',
                        borderRadius: '4px',
                      }}
                    >
                      <ListItemButton
                        selected={activeDemo === index}
                        onClick={() => {
                          setActiveDemo(index);
                          setActiveStepIndex(0);
                          setActiveStep('');
                        }}
                      >
                        <ListItemText
                          primary={demo.title}
                          sx={{
                            '& .MuiTypography-root': {
                              fontWeight: 'bold',
                            },
                          }}
                        />
                      </ListItemButton>
                    </Box>
                  ))}
                </List>
              </Box>
            </Box>
            <Box
              flex={4}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 1,
              }}
            >
              <Box sx={{ minHeight: 'calc(90vh - 80px)' }}></Box>
              <Box
                sx={{
                  borderTop: '1px solid #d3d3d3',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '8px 16px',
                }}
              >
                <Button variant="contained" color="primary" size="small">
                  Prev
                </Button>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Button variant="contained" color="primary" size="small">
                    Next
                  </Button>
                  <Button
                    id={`start_onboarding_demo${activeDemo}`}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setShowProductHelp(false)}
                  >
                    Start Onboarding Demo
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
  setPage: PropTypes.func,
};

export default Topbar;
